@include scope('critical') {
    .r-panel--dossier,
    .r-panel--journaliste {
        padding: space();

        @include sm {
            grid-column: span 2;
        }

        @include md {
            grid-column: span 2;
        }

        @include lg {
            grid-column: span 3;
        }

        r-panel--body {
            @include smmdlg {
                display: grid;
                grid-template-areas: 'img titre'
                'img description';
                grid-template-columns: 1fr 2fr;
                grid-template-rows: auto 1fr;
            }
        }

        .r-panel--title {
            font-size: font-size(h3);

            @include smmdlg {
                grid-area: titre;
                padding-left: space(2);
            }
        }

        .r-panel--img,
        figure {
            @include smmdlg {
                border-radius: space(.5);
                grid-area: img;
            }
        }

        .r-panel--description {
            @include smmdlg {
                grid-area: description;
                padding-left: space(2);
            }
        }
    }
    @include scope('!vdn') {
        .r-panel--journalistes {
            padding: space();

            @include smmdlg {
                display: flex;
            }

            r-panel--body {
                background-color: color(primary);
                background-image: url('#{$domain}/sites/all/themes/enacarbon_lesoir/images/journalistes/defaut.jpg');
                background-position: top center;
                background-repeat: no-repeat;
                background-size: cover;
                padding: space();

                @include smmdlg {
                    background-position: bottom center;
                    border-radius: space(.5);
                    flex: 1;
                    min-height: 200px;
                }
            }

            .r-panel--link {
                align-items: center;
                color: color(light);
                display: flex;
                font-family: $font-family-title;
                font-size: font-size(h4);
                font-weight: bold;
            }

            r-icon {
                font-size: font-size(base);
                margin-left: space(.5);
            }
        }
    }
}// scope

@include scope('!critical') {
    .r-panel--titre {
        box-shadow: 0 0 4px color(gray, light);
        padding: space();
        text-align: center;

        @include scope('vdn') {
            background: color(primary, lighter);
        }

        @include scope('an', 'cp', 'pn') {
            background: color(gray, lighter);
        }

        @include smmdlg {
            border-radius: 12px;
        }

        .r-panel--title {
            @include smmdlg {
                font-size: font-size(h6);
            }
        }
    }

    //.r-panel--chronique {
    //    box-shadow: 0 0 4px color(gray, light);
    //    padding: space();
    //    text-align: center;
    //
    //    @include scope('vdn', 'an', 'cp', 'pn') {
    //        background: color(primary, lighter);
    //    }
    //
    //    @include smmdlg {
    //        border-radius: 12px;
    //    }
    //
    //    r-panel--header {
    //        margin-bottom: space(0.75);
    //        padding-bottom: space(0.5);
    //    }
    //
    //    .r-img {
    //        aspect-ratio: unset;
    //    }
    //
    //    .r-panel--item {
    //        display: flex;
    //    }
    //
    //    .r-panel--title {
    //        margin-left: space(0.5);
    //        margin-top: 0;
    //        text-align: left;
    //
    //        @include smmdlg {
    //            font-size: font-size(h6);
    //        }
    //    }
    //
    //    .r-panel--img {
    //        flex: 0 0 40%;
    //    }
    //}

    .r-panel--top-titre {
        box-shadow: 0 0 0 space() color(light);
        grid-column-start: 1;
        grid-column-end: -1;
        margin-bottom: space();
        padding: space() space() space(3);

        @include xs {
            margin: -1px 0 0;
        }

        @include scope('sb') {
            padding-top: space(2);
            box-shadow: none;
        }

        .r-mini-panel--direct & {
            background: #F3F8FF;
            box-shadow: none;
            color: color(dark);
            display: block;
            margin-bottom: space(.5);

            @include scope('!mes') {
                padding: space(.5) space();
            }

            @include scope('mes') {
                padding: space(.5) 0;
            }
        
            @include scope('an', 'cp', 'pn', 'mes', 'sb') {
                background: #fff;
            }

            h3 {
                counter-increment: mostread;
                display: grid; 
                grid-template-columns: 40px 1fr; 
                grid-template-rows: auto;
                grid-gap: space();

                &::before {
                    color: color(primary);
                    content: counter(mostread);
                    display: flex;
                    font-family: $font-family-title;
                    font-size: 3.5rem;
                    font-weight: 400;
                    justify-content: center;
                    line-height: 1;
                    width: 100%;
                }
            }
        }

        r-panel--header {
            @include scope('!sb') {
            font-family: $font-family-base;
            font-weight: bold;
            }
        }

        r-panel--list {
            counter-reset: mostread;

            @include smmdlg {
                column-gap: space(2);
                display: flex;
                flex-wrap: wrap;
            }
        }

        .r-panel--item {
            counter-increment: mostread;
            display: grid;
            grid-template-areas: 'num img titre';
            grid-template-columns: 2fr 3fr 4fr;
            margin-bottom: -1px;
            margin-top: space(1);
            padding-bottom: space(1);

            @include smmdlg {
                margin-top: space(1.5);
                padding-bottom: space(1.5);
            }

            @include smmdlg {
                border-bottom: 1px solid color(gray, light);
                flex: 0 0 calc(50% - #{space()});
            }

            @include xssm {
                grid-template-areas: 'num titre';
                grid-template-columns: 1fr 4fr;
            }

            &::before {
                content: counter(mostread);
                display: flex;
                font-family: $font-family-title;
                font-size: 3.5rem;
                font-weight: 400;
                grid-area: num;
                line-height: 1;
                text-align: center;




                @include mdlg {
                    align-items: center;
                    font-size: 5rem;
                    padding-left: space(1.5);
                }
            }

            @include scope('sb') {
                grid-template-columns: 2fr 4fr 5fr;
                grid-template-areas: 
                'num img titre'
                'num img meta';
                grid-template-rows: min-content;

                @include xssm {
                    grid-template-areas: 
                    'num titre'
                    'num meta'; 
                }

                @include smmdlg {
                    border-bottom: 1px solid color(gray);
                }
                &::before {
                    color: color(primary, light);
                    font-weight:700;

                    @include xs { 
                        justify-self: center;
                    }
                }

                r-panel--meta {
                    font-size: font-size(aside);
                    margin-top: space(.5);
                    grid-area : meta;
                    text-transform: uppercase;

                    .r-panel--section {
                        font-weight: 700;
                    }

                    .r-pubdate {
                        color: color(black);
                    }

                    @include xs {
                        font-size: font-size(small);
                    }
                }
            }
        }

        .r-panel--img {
            grid-area: img;
            margin-right: space();

            @include xssm {
                display: none;
            }
        }

        figure {
            margin-bottom: 0;
        }

        .r-panel--title {
            font-size: 1.2rem;
            grid-area: titre;
            margin-bottom: 0;
            margin-top: 0;

            @include scope('mes') {
                font-size: font-size(base);
            }

            @include lg {
                font-size: font-size(h4);
            }

            @include smmd {
                font-size: font-size(h5);
            }

             @include scope('sb') {
                .r-panel--link {
                    display: inline;
                }      
            }
        }

        address {
            grid-area: meta;
            grid-column-end: -1;
            grid-column-start: 1;
            margin-top: space(.5);
            text-align: right;
        }
    }

    .r-panel--horizontal,
    .r-panel--wrap {
        @include xssm {
            @include scope('!mag') {
                padding: space() 0 space() space();
            }
        }

        r-panel--list {
            display: flex;
            overflow: auto;
        }

        .r-panel--item {
            @include xs {
                flex: 0 0 85%;
                margin-right: space();
            }

            @include sm {
                flex: 0 0 40%;
                margin-right: space();
            }

            @include lg {
                flex: 1;
                padding: space();
            }

            @include md {
                flex: 1;
                padding: space(.5);
            }
        }

        .r-panel--title {
            margin: 0;

            @include scope(('!special','!mag')) {
                font-size: font-size(h6);
            }
        }

        r-panel--footer {
            padding-top: 0;
        }
    }

    .r-panel--wrap {
        .r-panel--item {
            @include md {
                flex: 0 0 50%;
                max-width: 50%;
            }
            @include lg {
                flex: 0 0 50%;
                max-width: 50%;
            }

            @include wallpaper {
                flex: 0 0 25%;
                max-width: 25%;
            }
        }
    }

    .r-panel--section-description {
        grid-column-start: 1;
        grid-column-end: -1;
        margin-bottom: space(2);

        @include xs {
            padding: space();
        }

        h2 {
            margin-bottom: space(2);

            @include xs {
                font-size: 1.2rem;
                margin-bottom: space();
            }
        }
    }
}// scope

@include scope(('special', '!critical')) {
    .r-panel--panel-left {
        @include sm {
            grid-column: auto / span 2;
        }

        @include wallpaper {
            @include scope('special') {
                grid-column: auto / span 2;
                grid-row: auto / span 2;
            }
        }

        r-panel--list {
            @include mdlg {
                flex-wrap: wrap;
            }
        }

        .r-panel--item {
            @include mdlg {
                flex: 0 0 50%;
                max-width: 50%;
            }

            @include wallpaper {
                flex: 0 0 25%;
                max-width: 25%;
            }
        }
    }
} // scope

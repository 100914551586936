@include scope('!critical') {
.r-dropdown--search {
    background: color(light);
    border-bottom: 1px solid color(black);
    display: flex;
    justify-content: center;
    left: 0;
    margin: auto;
    // max-width: 4 * (300px + space(2));
    padding: space();
    position: absolute;
    right: 0;
    top: 100%;

    .r-search--input {
        border: 1px solid color(black);
        border-radius: 4px;
        color: color(dark);
        flex: 0 0 40%;
        margin-right: 20px;
        max-width: 1200px;
        padding: space(.5);
    }

    .r-search--submit {
        background: color(primary, light);
        border: 0;
        border-radius: 4px;
        color: color(light);
        cursor: pointer;
        font-weight: bold;
        padding: space(.25) space();
        &:hover {
            opacity: .8;
        }
    }
}
}

@include scope('critical') {
r-menu {
    display: block;
}

r-submenu {
    display: none;
}

.r-menu--link {
    display: block;
    padding: space(.5) space();
    white-space: nowrap;

    @include scope('sb') {
        padding: space(.4) space();
        &.-topic-menu-anchor {
            column-gap: space(.5);
            display: flex;
            font-weight: bold;

            &::before {
                color: color(primary, light);
                display: inline-block;
                font-family: $font-family-icons;
                font-size: 18px;
                font-weight: normal;
                line-height: 18px;
            }
        }
    }

}

.r-icon--home {
    align-items: center;
    column-gap: space(.5);
    display: flex;

    &::before {
        display: inline-block;
        font-family: $font-family-icons;
    }

    @include scope('special', 'sport') {
        &::before {
            display: none;
        }
    }

    @include scope('sb') {
        &.-just-icon {
            span {
                display: none;
            }
        }
    }
}
}// scope

@include scope('!critical') {
r-submenu {
    .-is-open + & {
        display: block;

        .r-menu--main[data-menu="megamenu"] & {
            .r-submenu--link {
                display: block;
            }
        }

        .-is-parent & {
            display: block;
        }
    }
}

r-menu[data-menu="megamenu"] {
    .-has-submenu {
        background: color(light);
        position: relative;

        &::after {
            font-family: main;
            font-size: font-size(h4);
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);

            @include scope('!mag') {
                color: color(gray, light);
                content: $icon-menu-right;
            }
        }

        &.-is-open {
    
            &::after {
                @include scope('!mag') {
                    display: none;
                }

                @include scope('mag') {
                    transform: translateY(-50%) rotate(90deg);
                }
            }
    
            &::before {
                @include scope('!mag') {
                    content: $icon-menu-left;
                }
            }
        }
    }
}

.r-menu--main {
    &.-is-active {
        .r-menu--link {
            display: none;
        } 

        .-has-submenu {
            &.-is-open {
                display: block;
            }
        }
    }
}
}// scope

@include scope('critical') {
    r-pub {
        display: block;
        text-align: center;
        
        > div {
            margin: 0 auto;
            max-width: none;

            .-has-bg-light &, 
            .-has-bg-dark &,
            .-has-bg & {
                background: none;
            }
            
            @include xs {
                background: color(pub);
                // > div,
                // > i-pub {
                //     margin-bottom: space();
                //     margin-top: space();
                // }
            }
        }
    
        iframe {
            margin: 0; 
        }

        #rossel-leader-top {
            display:flex!important;
        }
    }

    i-pub {
        display: block;
    }

    .r-pub--oop {
        max-height: 0;
    
        @include xs {
            margin: 0;
        }
    }
    
    .r-pub--leader,
    .r-pub--pave {
        position: relative;
    
        @include smmdlg {
            width: 100%;
        }
    
        > div {
            position: relative;
    
            &::before {
                color: color(gray, dark);
                display: block;
                font-size: font-size(small);

                [lang='nl'] & {
                    content: 'Advertising';
                }

                [lang='fr'] & {
                    content: 'Publicité';
                }
    
                @include xs {
                    margin-bottom: space(.25);
                }
    
                // @include smmdlg {
                //     left: 50%;
                //     position: absolute;
                //     top: 50%;
                //     transform: translate(-50%, -50%);
                //     z-index: 1;
                // }
            }
        }
    
        // > iframe,
        // > div {
        //     align-content: center;
        //     align-items: center;
        //     display: grid !important;
        //     justify-content: center;
        // }
    
        div {
            z-index: 2;
        }
    }
    
    .r-pub--imu {
        //grid-row: auto / span 2;
     
        > div {
            // align-content: center;
            // min-height: 250px + space(2);
            padding: space() 0;
            position: relative;
    
            @include smmdlg {
                padding: space();
            }
    
            @include xs {
                &::before {
                    align-self: center;
                    color: color(gray);
                    display: block;
                    font-size: font-size(small);
                    margin-bottom: space(.25);

                    [lang='nl'] & {
                        content: 'Advertising';
                    }
    
                    [lang='fr'] & {
                        content: 'Publicité';
                    }
                }
            }
        }

        // Pub inread à masquer en desktop

        // &.rossel-imu-right,
        // &.rossel-imu-right-desktop,
        // &.rossel-imu-right-mobile {
        //     grid-row: auto;
    
        //     > div {    
        //         @include mdlg {
        //             min-height: 250px + space(2);
        //         }
        //     }
        // }
    
        &.rossel-imu-right-desktop,
        &.rossel-imu-top-desktop,
        &.rossel-imu-bottom-desktop,
        &.rossel-imu-middle-desktop {
            @include xssm {
                display: none;
            }
        }
    }
    
    .r-pub--leader {
        background: color(pub);
        grid-column-start: 1;
        grid-column-end: -1;
        margin: 0;
        text-align: center;
        position: relative;
    
        @include smmdlg {
            margin-left: calc(-50vw + 50%);
            width: 100vw;
        }
    
        @include wallpaper {
            margin: 0;
            width: 100%;
        }
    
        > iframe,
        > div {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 100px + space(2);
            padding: space() 0;
    
            @include mdlg {
                min-height: 250px + space(2);
            }
        }

        &.rossel-leader-top-desktop {
            > div {
                &:before {
                    @include smmdlg {
                        left: 50%;
                        position: absolute;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        z-index: 1;
                    }
                }
            }
        }
    }

    .rossel-leader-top-desktop {
        @include xs {
            display: none;
        }
    }

    .rossel-leader-top-mobile {
        @include smmdlg {
            display: none;
        }
    }

    .r-pub--sticky {
        align-items: flex-start;
        
        > div,
        > i-pub {
            > * {
                @include smmdlg {
                    position: sticky;
                    top: $header-desktop-height + space();
                }
            }
        }
    
        @include scope('article') {
            &:last-child {
                @include mdlg {
                    position: sticky;
                    top: $header-top-height + space();
                }
            }
        }
    }
}// scope

@include scope('!critical') {
    .rossel-leader-middle,
    .rossel-leader-bottom {
        border-bottom: space() solid color(light);
        border-top: space() solid color(light);

        @include scope('une') {
            border: none;
        }

        @include scope('shopping', 'video') {
            margin-top: space();
        }

        > div {
            &:before {
                @include smmdlg {
                    left: auto;
                    margin-bottom: space(.25);
                    position: relative;
                    top: auto;
                    transform: none;
                }
            }
        }
    }

    .rossel-leader-bottom {
        //background: transparent;
        
        > div {
            min-height: 0;
    
            @include mdlg {
                min-height: 0;
            }
        }
    }

    .r-pub--leader {
        &.rossel-leader-middle-top,
        &.rossel-leader-middle-bottom {
            > iframe,
            > div {    
                @include mdlg {
                    min-height: 100px + space(2);
                }
            }
        }

        &.rossel-leader-middle-top {
            @include mdlg {
                grid-area: leader-middle;
                margin-left: 0;
                margin-top: space();
                width: 100%;
            }
        }
    }
    
    .r-pub--inread {
        > i-pub {
            > div {
                @include xssm {
                    padding: space() 0;
                }
            }
        }
    }

    .r-pub--droite,
    .r-pub--gauche {
        grid-row: auto / span 2;
    }

    .rossel-imu-top {
        @include scope('!article') {
            grid-area: imu-top;
        }
    }

    .rossel-imu-top,
    .rossel-imu-bottom {
        > i-pub {
            @include mdlg {
                min-height: 600px + space(2);
            }
        }
    }

    .rossel-native-top {
        grid-area: inread-top;
    }

    [class*='rossel-native-'] {
        background: color(light);

        > div {
            width: 100%;

            @include scope('!article') {
                padding: space();
            }

            &::before {
                display: none;
            }
        }

        .-has-bg &,
        .-has-bg-light &,
        .-has-bg-dark & {
            background: none;

            @include xs {
                background: color(light);
                padding-top: space(2);
            }
        }

        r-mini-panel & {
            @include xs {
                margin: 0 space(-0.75);
            }
        }
    }
}// scope

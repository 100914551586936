@include scope('!critical') {
.glider-prev,
.glider-next {
    background: color(gray);
    border-radius: 50%;
    color: color(light);
    font-family: $font-family-icons;
    font-size: 7px;
    height: 53px;
    transform: translateY(-50%);
    width: 53px;

    .r-carousel--kiosque & {
        @include mdlg {

        }
    }

    @include scope('sb') {
        background: color(primary);
    }
}

.glider-prev {
    left: 0;

    &::before {
        @include scope('!sb') {
            content: $icon-left-arrow;
        }
        @include scope('sb') {
            content: $icon-arrow-left;
            padding-left: 10px;
        }
    }
}

.glider-next {
    &::before {
        @include scope('!sb') {
            content: $icon-right-arrow;
        }
        @include scope('sb') {
            content: $icon-arrow-right;
            padding-left: 10px;
        }
    }
}
}// scope

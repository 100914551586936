@include scope('!critical') {
    r-mini-panel {
        &.-has-panel {
            z-index: 0;

            i-mini-panel {
                @include smmdlg {
                    background: color(light);
                    margin-top: -1px;
                    padding: 0 2px;
                }

                @include wallpaper(md) {
                    grid-gap: space();
                    grid-template-areas:
                    ". .";
                    grid-template-columns: calc(50% - #{space(.5)}) calc(50% - #{space(.5)});
                }
            }

            .r-mini-panel--title {
                @include xs {
                    padding: space();
                }

                @include smmdlg {
                    padding: space();
                }

                &::after {
                    margin-left: space(.75);
                    margin-right: 0;
                }

                &::before {
                    margin-left: 0;
                    margin-right: space(.75);
                }
            }

            r-panel {
                margin: 0 0 space();

                @include smmdlg {
                    margin: 0 space() space();
                }
            }

            .r-pub--pave {
                grid-row: auto;
            }

            .r-btn--more {
                &::after {
                    @include smmdlg {
                        margin-left: space();
                        margin-right: 0;
                    }
                }

                &::before {
                    @include smmdlg {
                        margin-left: 0;
                        margin-right: space();
                    }
                }
            }
        }

        &.-has-imu {
            i-mini-panel {
                @include smmdlg {
                    background: color(light);
                    column-gap: space(2);
                    display: grid;
                }

                @include lg {
                    grid-template-columns: 1fr 1fr 1fr 300px + space(2);
                }

                @include wallpaper(md) {
                    grid-template-columns: 1fr 1fr 300px + space(2);
                }

                @include sm {
                    grid-template-columns: 1fr 300px + space(2);
                }
            }
        }

        &.-has-bg {
            // background: color(dark);
            background-repeat: no-repeat;
            color: color(light);

            @include scope('!mag') {
                margin-bottom: 0;
            }

            @include scope('mag') {
                margin-bottom: space(1.5);
                padding-bottom: 0;
            }

            @include smmdlg {
                z-index: z-index();
            }

            i-mini-panel {
                //background: none;

                > * {
                    @include smmdlg {
                        background: none;
                    }
                }
            }

            .r-mini-panel--title {
                @include smmdlg {
                    @include scope('!sb'){
                        padding-top: space(3);
                    }
                }

                &::after,
                &::before {
                    background: color(light);
                }
            }

            .r-author--link,
            .r-article--link,
            .r-mini-panel--title,
            .r-article--section,
            .r-article--location {
                color: color(light);
            }

            .r-article--section,
            .r-article--location {
                border-color: color(light);
            }

            .r-meta-info {
                color: color(gray, light);
                padding: 0;
                text-align: left;

                span,
                time {
                    font-size: .8rem;
                }

                @include smmdlg {
                    span,
                    time {
                    font-size: 1rem;
                }
                }
            }

            .r-mini-panel--title {
                color: color(light);

                &::after,
                &::before {
                    background: color(light, .5);
                }

                a {
                    color: color(light);
                }
            }

            r-viewmode {
                border-color: color(light, .5);
            }
        }
    }

    .-has-subtitle {
        @include scope('sb') {
            .r-mini-panel--title {

                font-weight: font-weight(h1);

                &::before {
                    margin : space() space() 0 0;
                }
    
                &::after {
                    margin : space() 0 0 space();
                }
                a {
                    display: grid;
    
                    span {
                        color: color(primary, light);
                        font-size: font-size(aside);
                        font-weight: font-weight(h1);
                        text-align: center;
                        text-transform: uppercase;
                    }
    
                    &:hover {
                        span {
                            text-decoration: none;
                        }
                    }
                } 
            }

            .r-viewmode--panel {
                r-article--chapo {
                    @include xs {
                        display:none;
                    }
                }

                .r-article--title {
                    font-weight: font-weight(h1);
                }
            }

            r-article--meta {
                a { 
                    color: color(primary, light);
                }
            }
    
            .r-btn--primary {
                background : color(primary, light);
                border: none;
                color: black;
            }
        }
    }

    .-has-bg-light {

        @include scope('!sb') {
            background: color(gray, lighter);
        }

        @include scope('sb') {
            background: color(primary, lighten);

            .r-viewmode--panel {
                .r-article--title {
                    font-weight:font-weight(h1);
                }
            }

            .r-alerte {
                background: color(primary, lighten);
            }
        }

        .r-mini-panel--title {
            @include smmdlg {
                @include scope('!sb') {
                    padding-top: space(3);
                }
            }
        }
    }

    .-has-bg-dark {
        background: color(dark);

        @include scope('sb') {
            background: color(video);

            .r-btn--primary {
                background : color(primary, light);
                border: none;
                color: black;
            }
            /*r-article--meta {
                a { 
                    color: color(primary, light);
                }
            }
            .r-mini-panel--title {
                a {
                    color: color(primary, light);
                }
            }*/
        }

        .r-article--title {
            @include scope('special') {
                font-size: font-size(h2);
            }
        }

        .r-article--link,
        .r-panel--link {
            color: color(light);
        }

        .r-pubdate {
            color: color(light);
        }

        .r-alerte {
            color: color(light);
            border: 1px solid color(light);
            background: none;
        }
    }

    .-is-swipable {
        @include xs {
            position: relative;
        }

        i-mini-panel {
            @include xs {
                display: flex;
                overflow: auto;
                padding: space(5) 0 space(2.5);
            }

            > * {
                @include xs {
                    flex: 0 0 80%;
                    margin: space(.5);
                }
            }
        }

        &.-is-mini-panel--horoscope {
            i-mini-panel {
                > * {
                    @include xs {
                        flex: 0 0 24%;
                        margin: space(.5);
                    }
                }
            }
        }

        .r-mini-panel--title {
            @include xs {
                margin: 0;
                position: absolute;
                top: 0;
                width: calc(100% - #{space(2)});
            }
        }

        .r-btn--more {
            @include xs {
                bottom: 0;
                margin: 0;
                position: absolute;
                width: calc(100% - #{space(2)});
            }
        }
    }

    .-is-livetv {
        padding-bottom: space(3);

        i-mini-panel {
            @include lg {
                grid-column-gap: space(2);
                grid-template-areas: '. . .'
                'bigimg bigimg bigtitle'
                'bigimg bigimg .'
                'bigimg bigimg .';
                grid-template-columns: repeat(3, calc((100% - #{space(4)}) / 3));
            }

            @include wallpaper(md) {
                grid-column-gap: 32px;
                grid-template-areas: '. . .'
                'bigimg bigimg bigtitle'
                'bigimg bigimg .'
                'bigimg bigimg .';
                grid-template-columns: repeat(3, calc((100% - #{space(4)}) / 3));
            }

            @include xssm {
                display: block;
            }
        }

        .r-viewmode--article,
        .r-viewmode--manchette {
            @include smmdlg {
                display: contents;
            }
        }

        .r-viewmode--manchette {
            margin: 0;
            padding-bottom: 0;

            .r-article--img,
            #\31 _visible_player_brother,
            #\30 _visible_player_brother {
                @include smmdlg {
                    aspect-ratio: 16/9;
                    grid-area: bigimg;
                    height: auto !important;
                    margin-left: space();
                }
            }

            .r-article--img {
                @include xs {
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            i-article {
                @include smmdlg {
                    grid-area: bigtitle;
                }
            }

            time {
                display: block;
                margin: space() 0 space(.5);

                @include smmdlg {
                    margin: space(1.5) 0 space();
                }
            }

            .r-article--title {
                font-size: font-size(h4);
                text-transform: uppercase;

                @include smmdlg {
                    font-size: font-size(h2);
                    margin-bottom: space();
                }

                @include xs {
                    padding: 0;
                    margin: 0;
                }

                &::before {
                    display: none;
                }
            }
        }

        .r-alerte {
            background: color(red);
            border: none;
            border-radius: 0;
            color: color(light);
            font-size: 1.4rem !important;
            font-weight: 700;
            padding: space(.25) space(.5);
        }

        .r-viewmode--niveau-1 {
            padding: 0;

            @include smmdlg {
                padding: space(.5) 0;

                .r-viewmode--article {
                    display: grid;
                }
            }
        }
    }

    .-is-tabs {
        .r-tabs {
            display: block;
            padding-top: space(3);
            position: relative;

            @include lg {
                margin: auto maxi(7.8vw, calc(50vw - #{$content-max-width / 2}));
            }

            @include md {
                margin: auto maxi(space(4), calc(50vw - #{450px + space(3)}));
                max-width: 900px + space(6);
            }

            @include wallpaper {
                margin: auto;
            }

            ul {
                overflow: auto;

                @include xssm {
                    flex-wrap: inherit;
                }

                @include smmdlg {
                    justify-content: center;
                }

                li {
                    @include xs {
                        flex: 0 0 auto;
                    }
                }
            }
        }

        .r-tabs--btn {
            background: none;
            color: color(dark);
            font-size: font-size(h6);
            font-weight: 900;
            margin: space();
            opacity: .3;
            padding: 0;
            position: relative;
            text-transform: uppercase;
            transition: all .3s ease-in-out;

            &::before {
                bottom: 1px;
                content: '';
                height: 2px;
                left: 50%;
                position: absolute;
                transform: translateX(-50%);
                transition: all .3s ease-in-out;
                width: 0;

                @include scope('special') {
                    background-color: color(primary);
                }
            }

            &.active {
                background: none;
                color: color(dark);
                opacity: 1;

                &::before {
                    width: 100%;
                }
            }

            @include mdlg {
                font-size: font-size(h4);
            }
        }

        tab {
            transition: all .3s ease-in-out;

            @include mdlg {
                margin: 0;
            }

            @include lg {
                grid-template-areas: '. . . ';
                grid-template-columns: 33.33% 33.33% 33.33%;
            }

            &.active {
                .r-viewmode--article {
                    opacity: 1;
                    top: 0;
                }
            }

            r-viewmode {
                @include xs {
                    &:last-child,
                    &:nth-last-child( 2 ) {
                        display: none;
                    }
                }
            }

            .r-viewmode--article {
                opacity: 0;
                padding: space(); 
                position: relative;
                top: 10px;
                transition: all 3s ease-in-out;
            }
        }

        .r-btn--more {
            justify-content: center;

            @include md {
                margin: auto maxi(space(4), calc(50vw - #{450px + space(3)}));
                max-width: 900px + space(6);
            }
        }
    }

    .-is-mp-aussi-en,
    .-is-mp-gray-light,
    .-is-mp-primary-light,
    .-is-mp-primary {
        i-mini-panel {
            > * {
                @include smmdlg {
                    background: none;
                }
            }
        }
    }

    .-is-mp-primary {
        background: color(primary);

        .r-carousel--link {
            color: color(light);
        }

        .glider-prev,
        .glider-next {
            color: color(light);
            font-size: font-size(h2);
        }

        .glider-next {
            right: space(-1);
        }
    }

    .-is-mp-primary-light {
        background: color(primary, lighter);
    }

    .-is-mp-gray-light {
        background: color(gray, lighter);
    }

    .-is-mp-aussi-en {
        @include scope('!mag') {
            background: #f3f8ff;
        }

        @include scope('special') {
            background: color(primary, lighter);
        }

        @include scope('lifestyle') {
            background: #f3f8ff;
        }

        @include scope('mag') {
            padding: 0;
            @include smmdlg {
                grid-column: 1;
                margin-left: 0;
                width: 100%;

                .r-mini-panel--title,
                .r-article--title {
                    margin-left: 0;
                    padding-left: 0;
                    padding-right: 0;
                }

                i-mini-panel {
                    margin: 0;
                }
            }
        }
    }

    .-has-bg-immo-light {
        background-color: color(immo, light);
        margin-bottom: space(1.5);
        margin-top: space();
        padding-bottom: space(1.5);
        padding-right: space();

        .r-mini-panel--title {
            color: color(dark);
            padding-top: space();

            &::after {
                background: color(dark);
            }
        }
    }

    .r-mini-panel-immo-list {
        padding: space();

        &.-is-mobile-swipable {
            padding-right: 0;
        }
    }

    .r-panel--edition-immo + .-has-bg-immo-light {
        margin-top: 0;
    }

    .-has-bg-emploi-light {
        background: color(emploi, light);
        margin: 0;
        padding: 0 space() space();

        @include smmdlg {
            width: auto;
        }

        @include mdlg {
            border-radius: 0 0 20px 20px;
            margin: auto;
        }

        i-mini-panel {
            margin: auto;
        }
    }

    .r-mini-panel-serviciels {
        r-panel--header {
            &::after,
            &::before {
                display: none;
                text-transform: uppercase;
            }
        }

        .r-btn {
            border-radius: 5px;
            font-weight: normal;
            justify-content: center;
            padding: space(.75);

            &:hover {
                opacity: .8;
            }
        }

        r-panel {
            border-radius: 10px;

            @include smmdlg {
                padding: space() space(1.5);
            }

            &:first-child {
                @include xs {
                    margin-bottom: space(.75);
                }
            }
        }

        r-panel--body {
            display: flex;

            @include smmdlg {
                font-size: 20px;
            }

            r-icon {
                font-size: 26px;

                @include smmdlg {
                    font-size: 40px;
                }
            }
        }

        r-panel--header {
            font-size: 1rem;
            grid-column: 1;
            margin: 0 0 space(.5);
            text-transform: none;
        }

        r-panel--footer {
            padding: 0;
        }
    }

    // mini panel des services version horizontal

    .-is-horizontal {
        margin-left: calc(-50vw + 50%);
        padding-bottom: space(2);
        padding-left: space();
        padding-top: space();
        width: 100vw;

        @include xs {
            padding: 0 space() space();
        }

        i-mini-panel {
            @include smmdlg {
                column-gap: space(2);
                display: flex;
                padding: 0 space(.5);
            }
        }

        r-panel {
            align-items: center;
            display: grid;
            grid-column-gap: space();
            grid-template-columns: auto 50px;
            padding: space(.5) space();

            @include smmdlg {
                grid-template-columns: 2fr 1fr;
                flex: 2 1 0;
            }
        }

        r-panel--body {
            align-items: center;
            column-gap: space();
            grid-column: 1;
        }

        .r-btn {
            width: 100%;

            @include xs {
                border-radius: 50%;
                font-size: 0;
                height: 40px;
                width: 40px;
            }

            r-icon {
                margin-right: 0;

                @include smmdlg {
                    display: none;
                }
            }
        }

        r-panel--footer {
            grid-column: 2;
            grid-row: 1 / span 2;
            justify-self: end;
            text-align: center;
        }
    }

    // minipanel des services version vertical (qui prend la place du direct)

    .-is-vertical {
        padding: space();

        @include smmdlg {
            grid-area: direct;
        }

        i-mini-panel {
            margin: auto;
        }

        r-panel {
            margin-bottom: space(1.5);
            padding: space();
        }

        r-panel--body {
            column-gap: space(2);
            flex-direction: row-reverse;
            justify-content: space-between;

            @include smmdlg {
                flex-direction: column;
            }

            r-icon {
                align-self: end;
                font-size: 50px;
                margin-bottom: space(.75);
            }
        }

        .r-btn {
            display: inline-block;

            r-icon {
                display: none;
            }
        }

        h4 {
            font-size: 12px;
        }

        r-panel--footer {
            text-align: left;

            @include smmdlg {
                padding-top: space();
            }
        }
    }

    .-is-emploi {
        r-panel {
            &:first-child {
                background-color: color(emploi);
                color: color(light);

                .r-btn {
                    background-color: color(emploi, dark);
                }

                r-panel--header {
                    color: color(light);
                }
            }

            &:last-child {
                background-color: color(emploi, light);
                color: color(dark);

                .r-btn {
                    background-color: color(emploi);
                }
            }
        }

        .r-btn {
            color: color(light);
        }
    }

    .-is-immo {
        r-panel {
            &:first-child {
                background-color: color(immo);
                color: color(dark);

                .r-btn {
                    background-color: color(immo, light);
                }
            }

            &:last-child {
                background-color: color(immo, light);
                color: color(dark);

                .r-btn {
                    background-color: color(immo);
                }
            }
        }

        .r-btn {
            color: color(dark);
        }
    }

    .-is-legales--hp {
        margin-bottom: space();
        i-mini-panel {
            @include smmdlg {
                display: flex;
                flex-wrap: wrap;
                grid-gap: 0;            
            }
        }
        .r-mini-panel--title {
            width: 100%;
        }
        r-btn {
            margin-bottom: space();
            width: 100%;
            @include smmdlg {
                padding: 0 space();
                width: 50%;

                @include wallpaper {
                    padding: 0 space() 0 0;
                    &:last-of-type {
                        padding: 0;
                    }
                }
            }
        }
        .r-btn--big {
            font-size: 1.2rem;
            width: 100%;
        }
    }

    .-is-panel-right.r-mini-panel--section {
        @include scope('sb') {
            i-mini-panel {
                grid-template-areas:
                '. . . .'
                '. . manchette manchette'
                '. . manchette manchette';

                @include md {
                    grid-template-areas:
                    '. . .'
                    '. manchette manchette'
                    '. manchette manchette'
                    '. . .' !important;
                }

                @include sm {
                    grid-template-areas:
                    '. .'
                    'manchette manchette'
                    '. .';
                }

            }
    
            .r-viewmode--panel {
                grid-area: manchette;

                .r-article--title {
                    font-weight: font-weight(h1);
                }

                r-article--chapo {
                    @include xs {
                        display: none;
                    }
                }

                r-article--meta {
                    time {
                        font-size: font-size(aside);
                        @include xs {
                            font-size: font-size(small);
                        }
                    }
                }

            }

            .r-viewmode--niveau-2 {
                r-article--meta {
                    time {
                        font-size: font-size(aside);
                        @include xs {
                            font-size: font-size(small);
                        }
                    }
                }
            }
        }   
    }

    @include scope('sb') {
        .-is-mp-rulabe.r-mini-panel--section {
            i-mini-panel {
                display: block;
            }
            .r-mini-panel--title {
                &::before {
                    display: none;
                }
                .rula-logo {
                    width: 100px;
                }
                @include xs {
                    padding-bottom: 0;
                }
            }
            @include xs {
                .rulabe {
                    margin-left: -5px;
                    margin-right: -5px;
                } 
            }
        }
        .-is-mp-jobbo.r-mini-panel--section {
            background-color: #EFEFEA;

            i-mini-panel {
                @include smmd {
                    grid-template-areas: '. .';
                    grid-template-columns: 50% 50%;
                }
            }
            
            .r-mini-panel--title {
                display: block;
                &::before, &::after {
                    display: none;
                }
                .jobbo-logo {
                    width: 100px;
                }
                p {
                    font-size: 16px;
                    font-weight: normal;
                    margin-bottom: 0;
                    margin-top: 12px;
                }
                a {
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            .jobbo-cta {
                background-color: #C81A15;
                border-color: #C81A15;
                r-icon {
                    font-size: 1rem;
                    margin: 0 8px 0 0;
                }
            }
            .panel-jobbo-job {
                background-color: white;
                border: 1px solid #CECEC9;
                border-radius: 6px;
                margin-left: space();
                margin-bottom: space();
                padding: 25px 25px 20px 25px;
                text-align: center;
                
                @include xs {
                    margin-left: 0;
                }

                .panel-jobbo-job-info {
                    text-align: left;
                }
                .job-title-wrap {
                    height: 50px;
                }
                h3 {
                    font-family: $font-family-base;
                    font-size: 18px;
                    max-height: 50px;
                    overflow: hidden;
                    position: relative;
                    line-height: 25px;
                    &:before {
                        content: "...";
                        position: absolute;
                        bottom: 0;
                        right: 0;
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        right: 0; /* note: not using bottom */
                        width: 20px;
                        height: 25px;
                        background: white;
                    }
                    a {
                        color: color(black);
                        &:hover {
                            color: color(black);
                        }
                    }
                }
                .job-company {
                    font-size: 14px;
                    height: 20px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;   
                }
                .job-location-wrap {
                    height: 40px;
                    margin-bottom: space();
                }
                .job-location {
                    color: color(gray, dark);
                    font-size: 14px;
                    max-height: 40px;
                    line-height: 20px;
                    overflow: hidden;
                    position: relative;
                    &:before {
                        content: "...";
                        position: absolute;
                        bottom: 0;
                        right: 0;
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        right: 0; /* note: not using bottom */
                        width: 15px;
                        height: 20px;
                        background: white;
                    }
                }
                .job-cta {
                    background: white;
                    border: 1px solid #C81A15;
                    color: #C81A15;
                    &:hover {
                        background-color: #C81A15;
                        color: white;
                    }
                    r-icon {
                        font-size: 12px;
                        margin-left: 3px;
                        margin-right: 0;
                        & + r-icon {
                            margin-left: -2px;
                        }
                    }
                }
            }
            .jobbo-job-logo {
                height: 50px;
                margin-bottom: 0;
                img {
                    max-height: 40px;
                    max-width: 60%;
                }
            }
            .panel-jobbo-cta {
                background-image: url('/sites/all/themes/enacarbon_sb/images/jobbo-cta-illu.svg'), url('/sites/all/themes/enacarbon_sb/images/jobbo-cta-bg.svg'), ;
                background-repeat: no-repeat, no-repeat;
                background-position: right 10px bottom 90px, center;
                background-size: 100px auto, 200px auto;
                margin-left: space();
                margin-bottom: space();
                position: relative;
                @include md {
                    background-position: right 60px bottom 90px, center;
                }
                @include xs {
                    background-position: right 60px bottom 90px, center;
                    border-bottom: 1px solid #CECEC9;
                    min-height: 280px;
                    margin-bottom: 32px;
                    margin-left: 0;
                }

                h3 {
                    padding: space(2) space(4) space(2) space(2);
                    @include md {
                        padding: 32px 120px 32px 120px;
                    }
                    @include xs {
                        padding: 32px 80px 32px 70px;
                    }
                }
                r-btn {
                    bottom: space();
                    position: absolute;
                    width: 100%;
                }
            }
        }
    }

    @include scope('mag') {
        .-is-minipanel-corner {
            background-color: color(corner);
            padding: space() 0 space(2);
        }
    }

    @include scope('special') {
        .-has-bg-mag {
            @include scope('special') {
                background: color(primary);
            }

            .r-article--title {
                @include scope('special') {
                    font-size: font-size(h2);
                }
            }

            .r-article--link {
                color: color(light);
            }

            .r-pubdate {
                color: color(light);
            }

            .r-article--section,
            .r-btn {
                background-color: color(light);

                @include scope('special') {
                    color: color(primary);
                }

                @include scope('lifestyle') {
                    background-color: color(secondary);
                    color: color(light);
                }
            }
        }

        @include scope('lifestyle') {
            .-has-bg-mag-2 {
                background: #6a8988;
            }

            .-has-bg-mag-3 {
                background: #bfcdcd;
            }
        }

        @include scope('terres-champagnes') {
            .-has-bg-mag-bleu {
                background: #102a47;
            }

            .-has-bg-mag-or {
                background: #c0a335;
            }

            .-has-bg-mag-creme {
                background: #efe3c3;
            }
        }

        .-is-align-center {
            .r-mini-panel--title {
                grid-row: 1;
                grid-column: 1 / -1;
                justify-content: center;
            }

            .r-btn--more {
                grid-row: auto;
                grid-column: 1 / -1;
                justify-content: center;
            }
        }
    }// scope

    @include scope('cipiz') {
        .r-mini-panel--title {
            a  {
                border: none;
                color: color(dark);
                font-size: 1.7rem;
                padding: space(.5);
            }
        }
        .r-mini-panel--title,
        .r-btn--more {
            a {
                border: none;
                .-is-mini-panel--gradient & {
                    background: linear-gradient(to right, rgba(255, 90, 43, 1) 0%, rgba(255, 0, 190, 1) 100%);
                }
                .-is-mini-panel--yellow & {
                    background: #FFE800;
                    color: color(dark);
                }
                .-is-mini-panel--orange & {
                    background: #FFAB4D;
                }
                .-is-mini-panel--red & {
                    background: #EF2A57;
                }
                .-is-mini-panel--green & {
                    background: #38D430;
                }
                .-is-mini-panel--pink & {
                    background-color: #FF7276;
                }
                .-is-mini-panel--blue & {
                    background: #0097CE;
                }
                .-is-mini-panel--green-2 & {
                    background: #209E97;
                }
                .-is-mini-panel--pink-2 & {
                    background: #FF40B4;
                }
                .-is-mini-panel--red-2 & {
                    background: #A3244A;
                }
            }
        }
    }

    @include scope('vdn') {
        .-has-no-direct {
            .r-mini-panel--title {
                grid-template-areas: "title";
            }
        }
    }
}// scope

@include scope(('article', '!critical'), ('main', '!critical')) {
    .r-panel--paywall {
        background-color: color(light);
        margin: space(4) 0 space(4);
        position: relative;
        text-align: center;

        &::before {
            content: '';
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 75%);
            height: 100%;
            position: absolute;
            left:0px;
            top: -330px;
            width: 100%;

            @include xs {
                top: -390px;
            }
        }

        @include lg {
            margin-right: -25%;
            margin-left: -25%;
        }

        r-paywall--content {
            color: black;
        }

        r-paywall--btns {
            display: flex;
            flex-wrap: wrap;
            grid-gap: space();
            justify-content: center;
            margin-bottom: space();

            .r-btn--primary {
                background: color(primary, light);
                border: none;
                font-size: font-size(h2);
                font-weight: bold;
                padding: space(.75) space(1.25);

                @include xs {
                    font-size: font-size(h5);
                }
            }
        }

        r-paywall--footer {
            background: black;
            color: color(light);
            display: block;
            margin-top:space(1.5);
            padding: space(1.75) 0 space(1.25) 0;

            p {
                font-size: font-size(h6);
                font-weight: font-weight(h1);
            }
    
            .r-btn--secondary {
                background: color(light);
                color: black;
                font-size: font-size(h6);
                font-weight: bold;
                padding: space(.5) space(1.25);

                &:hover {
                    opacity: .8;
                }

                @include xs {
                    margin-bottom: space();
                }
            }
        }
    }
}// scope

@include scope(('article', 'critical')) {
    .page-node-paywall {

        .r-article {

            header {
                position: relative;

                &::after {
                    @include xs {
                        //background: color(pub);
                        //content: 'Publicité';
                        //color: color(gray, base);
                        //display: block;
                        //font-size: font-size(aside);
                        //height: 300px;
                        //min-height: 480px;
                        //margin-bottom: space(.4);
                        //padding-top: space(.5);
                        //position: relative;
                        //text-align: center;
                        //width: 100%;
                        display: none;
                    }
                }

                @include xs {
                    padding-bottom: 40px;
                }
            }
        }
    }
}// scope


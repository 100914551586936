@include scope('!critical') {
    @include scope('!vdn') {
        .r-panel--chronique {
            box-shadow: 0 0 4px color(gray, light);
            padding: space();
            text-align: center;
        
            @include scope('cp', 'an', 'pn') {
                background: color(gray, lighter);
            }
        
            @include smmdlg {
                border-radius: 12px;
            }
        
            r-panel--header {
                margin-bottom: space(0.75);
                padding-bottom: space(0.5);
            }
        
            .r-img {
                aspect-ratio: unset;
            }
        
            .r-panel--item {
                display: flex;
            }
        
            .r-panel--title {
                margin-left: space(0.5);
                margin-top: 0;
                text-align: left;
        
                @include smmdlg {
                    font-size: font-size(h6);
                }
            }
        
            .r-panel--img {
                flex: 0 0 40%;
            }
        }
        
        .r-panel--edito {
            position: relative;
        
            @include xs {
                min-height: 60vw;
                overflow: hidden;
            }
        
            @include lg {
                @include scope('!sb') {
                    grid-row: span 2;
                }

                @include scope('ee', 'lc', 'uni') {
                    grid-row: span 1;
                }
            }
        
            @include wallpaper {
                grid-row: auto;
            }
        
            r-panel--header {
                color: color(light);
        
                @include scope('special') {
                    color: color(primary);
                }
        
                &::before,
                &::after {
                    display: none;
                }
        
                a {
                    color: color(light);
                }
            }
        
            .r-panel--title {
                @include xs {
                    font-size: font-size(h5);
                }
            }

            @include scope('!sb') {
                i-panel {
                    bottom: 0;
                    left: 0;
                    padding: space();
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }
        
            .r-panel--title {
                text-align: center;
        
                @include wallpaper(xs) {
                    width: 60%;
                }
            }
        
            .r-panel--link {
                @include scope('cp', 'an', 'pn', 'nl') {
                    color: color(light);
                }
        
                @include scope('special') {
                    color: color(dark);
                }
            }
        
            address {
                color: color(light);
                @include scope('special') {
                    color: color(primary);
                    font-family: $font-family-title;
                    font-size: font-size(h3);
                    font-weight: 900;
                }
            }
        
            strong {
                @include scope('special') {
                    font-family: $font-family-base;
                    font-weight: normal;
                }
            }
        
            .r-author--link {
                @include scope('cp', 'an', 'pn') {
                    color: color(light);
                }
        
                @include scope('special') {
                    color: color(primary);
                    font-weight: 900;
                }
            }
        
            .r-img--background {
                width: 100%;
        
                @include xs {
                    bottom: 0;
                    position: absolute;
                }
        
                @include smmdlg {
                    border-radius: space(0.5);
                }
            }
        
            .-is-vertical {
                padding: 0;
                @include xs {
                    display: none;
                }
        
                @include wallpaper(md) {
                    display: none;
                }
            }
        
            .-is-square {
                @include sm {
                    display: none;
                }
        
                @include lg {
                    display: none;
                }
        
                @include wallpaper {
                    display: block;
                }
            }

            @include scope('sb') {
                grid-area: dossier;

                @include xs {
                    margin-bottom: space();
                }
                
                i-panel {
                    background: color(primary, light);
                    background-image: url("#{$domain}/sites/all/themes/enacarbon_sb/images/edito_pattern.png");
                    background-position: top;
                    background-size: cover;
                    border-radius: 10px;
                    display: grid;
                    grid-template-rows: auto;
                    margin: space();
                    padding: space();
                    position: relative;

                    @include xssm {
                        background-position: inherit;
                        grid-template-areas: 
                        'title title'
                        'body img';
                        grid-template-rows: auto;
                        grid-template-columns: 2fr 1fr;
                        grid-gap: space();
                        padding: space();
                    }
                }

                r-panel--header {
                    border-bottom : 1px solid black;
                    color : black; 
                    margin: 0 space(2);
                    padding: space(.5) 0 space();

                    h3 {
                        margin:0;
                        font-weight: font-weight(h1);
                        a {
                            color: color(black);
                            &:hover {
                                color: color(black);
                            }
                        }
                    }

                   address {
                    color:black;
                    font-family: $font-family-title;
                   }

                   @include xssm {
                    grid-area: title;
                    padding: 0 0 space(.5);
                   }
                }

                r-panel--body {
                    align-self: center;
                    text-align: center;
                    padding : 0 space();

                    @include xssm {
                        align-self: flex-start;
                        grid-area: body;
                        padding : 0;
                        text-align: left;
                        h4 {
                            font-size: font-size(h6);
                            margin:0;
                        }
                    }
                }

                r-panel--img {
                    align-self: start;
                    figure {
                        margin: 0;
                    }

                    @include xssm {
                        grid-area: img;
                       }
                }
            }
        }
        
        // Bloc Kroll
        .-is-kroll {
            &.r-panel--image {
                box-shadow: 0 0 3px color(gray, light);
        
                @include smmdlg {
                    border-radius: space(0.5);
                    padding: space(0.5) space() space();
        
                    @include scope('special') {
                        grid-column: span 2;
                        grid-row: span 2;
                    }
                }
        
                @include lg {
                    grid-column: span 2;
                    grid-row: span 2;

                    @include scope('ee', 'lc', 'uni') {
                        grid-row: span 1;
                    }
                }
        
                @include wallpaper {
                    grid-column: auto;
                    grid-row: auto;
                }
        
                r-panel--header {
                    margin-bottom: space();
                    padding-top: space(0.5);
                }
            }
        
            .r-viewmode--article,
            .r-panel--item {
                padding: 0 space();
        
                .r-anonyme &,
                .r-connecte-sd & {
                    background-repeat: no-repeat;
                    background-position: center center;
                    @include scope('an') {
                        background-image: url("#{$domain}/sites/all/themes/enacarbon_an/images/alex.svg");
                    }
                    @include scope('cp') {
                        background-image: url("#{$domain}/sites/all/themes/enacarbon_cp/images/alex.svg");
                    }
                    @include scope('pn') {
                        background-image: url("#{$domain}/sites/all/themes/enacarbon_pn/images/alex.svg");
                    }
                    @include scope('ard') {
                        background-image: url("#{$domain}/sites/all/themes/enacarbon_ard/images/alex.svg");
                    }
                    @include scope('ee') {
                        background-image: url("#{$domain}/sites/all/themes/enacarbon_ee/images/chaunu.svg");
                    }
                    @include scope('lc') {
                        background-image: url("#{$domain}/sites/all/themes/enacarbon_lc/images/alex.svg");
                    }
                    @include scope('uni') {
                        background-image: url("#{$domain}/sites/all/themes/enacarbon_uni/images/chaunu.svg");
                    }
                }
            }
        
            .r-article--img {
                margin: 0 space();
        
                @include lg {
                    margin: 0 space(3);
                }
        
                .r-anonyme &,
                .r-connecte-sd & {
                    @include scope('cp', 'an', 'pn', 'ard', 'ee', 'lc', 'uni' ) {
                        filter: blur(10px) opacity(0.5);
                    }
                }
            }
        
            .r-img {
                &:hover {
                    transform: none;
                }
            }
        }
        .r-panel--chronique,
        .r-panel--edito,
        .r-panel--titre,
        .-is-kroll {
            r-panel--header {
                display: block;
                font-size: 20px;
                justify-content: center;
                text-transform: none;
                &::before,
                &::after {
                    content: none;
                }
            }
            .r-icon--abonne {
                margin-right: space(.5);
            }
        }
        .r-panel--chronique,
        .r-panel--titre,
        .-is-kroll {
            address,
            .r-author--link {
                color: color(gray);
            }
        }
    }// SCOPE


    @include scope('vdn') {
        .r-panel--edito {
            grid-area: direct;
            padding: space() space(2) space() space();

            r-panel--header {
                display: block;
                font-size: 28px;
                font-weight: 700;
                line-height: 1.2;
                text-align: left;
                text-transform: none;

                &::before,
                &::after {
                    display: none;
                }
            }

            address {
                font-size: 25px;
                font-weight: 400;
                display: block;
            }
        }
    }// scope
}// scope

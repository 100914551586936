@charset "UTF-8";

r-modal,
r-modal--backdrop {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

r-modal {
  align-items: center;
  display: none;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: 150;
}

r-modal.-is-open {
  display: flex;
}

r-modal--backdrop {
  background-color: rgba(106, 140, 31, 0.5);
}

.r-modal--close {
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  height: 32px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  text-align: center;
  user-select: none;
  width: 32px;
}

.r-modal--close::after {
  color: white;
  content: "\2715";
  display: block;
  height: 32px;
  line-height: 32px;
  width: 32px;
}

.r-modal--close:hover {
  opacity: 1;
  text-decoration: none;
}

r-modal--content {
  margin: 0 auto;
  max-height: calc(100vh - 40px);
  max-width: 640px;
  overflow: auto;
  padding: 16px;
  position: relative;
}

r-pagination {
  align-items: center;
  display: flex;
  justify-content: center;
  list-style: none;
}

r-pagination--item a {
  display: block;
  padding: 8px 16px;
  position: relative;
  text-align: center;
}

r-pagination--item a:hover {
  text-decoration: none;
}

.r-modal--old-browser {
  background: rgba(14, 13, 14, 0.8);
  display: flex;
}

.r-modal--old-browser i-modal {
  display: block;
  width: 80%;
  background: white;
  margin: auto;
}

.r-modal--old-browser header {
  background: #6a8c1f;
  color: white;
  padding: 32px;
}

.r-modal--old-browser .r-modal--title {
  font-size: 1.3rem;
  margin-bottom: 16px;
}

.r-modal--old-browser .r-modal--list {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 32px;
}

.r-modal--old-browser .r-modal--item {
  flex: 1;
  padding: 0 16px;
  text-align: center;
}

.r-modal--old-browser .r-modal--label {
  font-size: 1.05rem;
}

.-is-collapse {
  height: 300px;
  overflow: hidden;
  position: relative;
}

.-is-collapse::after {
  background: linear-gradient(to top, white 20%, rgba(255, 255, 255, 0) 100%);
  bottom: 0;
  content: '';
  height: 30%;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 2;
}

.-is-collapse .r-btn--collapse {
  display: flex;
  align-items: flex-start;
  padding: 8px 8px 6px 16px;
}

.r-btn--collapse {
  bottom: 16px;
  display: none;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  z-index: 3;
}

.glider-contain {
  margin: 0 auto;
  position: relative;
  width: 100%;
}

.glider {
  margin: 0 auto;
  opacity: 1;
  overflow-y: hidden;
  position: relative;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.glider.draggable {
  cursor: -webkit-grab;
  cursor: grab;
  user-select: none;
}

.glider.draggable .glider-slide img {
  pointer-events: none;
  user-select: none;
}

.glider.drag {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.glider-track {
  display: flex;
  margin: 0;
  padding: 0;
  width: 100%;
  z-index: 1;
}

.glider-slide {
  align-content: center;
  justify-content: center;
  margin: 0 8px;
  max-width: none;
  user-select: none;
  width: 100%;
}

.glider-slide img {
  max-width: 100%;
}

.glider-slide::-webkit-scrollbar {
  height: 0;
  opacity: 0;
}

.glider-prev,
.glider-next {
  background: none;
  border: 0;
  color: #e6e6e6;
  cursor: pointer;
  left: 0;
  line-height: 1;
  opacity: 1;
  outline: none;
  padding: 0;
  position: absolute;
  text-decoration: none;
  top: 50%;
  transition: opacity 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67), color 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67);
  user-select: none;
  z-index: 2;
}

.glider-prev.disabled,
.glider-next.disabled {
  display: none;
}

.glider-next {
  left: auto;
  right: 0;
}

.glider-slide {
  min-width: 150px;
}

.glider-hide {
  opacity: 0;
}

.glider-dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
  user-select: none;
}

.glider-dot {
  background: #f3f3f3;
  border: 0;
  border-radius: 999px;
  color: #f3f3f3;
  cursor: pointer;
  display: block;
  height: 12px;
  margin: 7px;
  outline: none;
  padding: 0;
  user-select: none;
  width: 12px;
}

.glider-dot.active {
  background: #6a8c1f;
}

i-modal {
  z-index: 4;
}

r-pager {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 16px;
}

.r-pager--link {
  border-radius: 5px;
  box-shadow: 0 0 16px #f3f3f3;
  margin: 8px;
  padding: 8px 16px;
  white-space: nowrap;
}

.r-pager--link.-is-active {
  background: #6a8c1f;
  color: white;
}

.r-pub--crea {
  grid-column: 1 / -1;
}

.r-pub--rec > div,
.r-pub--imu > div,
.r-pub--flexs > div,
.r-pub--flexm > div,
.r-pub--flexl > div {
  background: #f3f3f3 url("/sites/all/themes/enacarbon/images/pub/pub_logo_default.svg") center center no-repeat;
  background-size: auto 10px;
  width: 100%;
}

.r-pub--rec > div.adm-ad-rendered,
.r-pub--imu > div.adm-ad-rendered,
.r-pub--flexs > div.adm-ad-rendered,
.r-pub--flexm > div.adm-ad-rendered,
.r-pub--flexl > div.adm-ad-rendered {
  background: none;
}

.r-pub--rec > div {
  min-height: 250px;
}

.r-pub--imu > div {
  min-height: 250px;
}

.r-pub--flexs + .r-pub--flexs {
  margin-top: 16px;
}

.r-pub--flexl {
  align-items: center;
  background: #f3f3f3;
  display: flex;
  grid-column: 1 / -1;
  justify-content: center;
  margin-left: calc(-50vw + 50%);
  width: 100vw;
}

.arche .r-pub--flexl {
  margin-left: 0;
  width: 100%;
}

.r-pub--flexl > div {
  align-items: center;
  background: url("/sites/all/themes/enacarbon/images/pub/pub_logo_default.svg") center center no-repeat;
  background-size: auto 10px;
  display: flex;
  justify-content: center;
  margin: 0;
  max-width: 100%;
  padding: 0;
}

.r-pub--flexl > div:not(:empty) {
  padding: 16px 0;
}

.r-pub--flexl > div.adm-ad-rendered {
  background: none;
}

.r-blockquote {
  margin: 32px 0;
}

.r-blockquote figcaption {
  margin-top: 8px;
  color: #0e0d0e;
}

blockquote {
  font-family: "Work Sans", arial, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0;
}

blockquote::before {
  color: #6a8c1f;
  content: "\ea32";
  display: block;
  font-family: main;
  font-size: 2.5rem;
  margin-bottom: 8px;
}

blockquote blockquote {
  border: 0;
  margin: 0;
  padding: 0;
}

blockquote blockquote::before {
  display: none;
}

.r-epigraph {
  border-left: 3px solid #6a8c1f;
  padding-left: 16px;
  margin: 32px 0;
  max-width: 400px;
}

epigraph {
  display: block;
  font-size: 1.5rem;
  font-weight: 700;
}

.r-article li::marker {
  color: #6a8c1f;
  font-size: 1.5rem;
}

r-btn--share {
  display: flex;
  flex-wrap: wrap;
}

.r-article r-btn--share .r-icon--facebook:hover {
  background-color: #1778f2;
}

.r-article r-btn--share .r-icon--twitter:hover {
  background-color: black;
}

.r-article r-btn--share .r-icon--bluesky:hover {
  background-color: #1083fe;
}

.r-article r-btn--share .r-icon--linkedin:hover {
  background-color: #0d66c2;
}

.r-article r-btn--share .r-icon--messenger:hover {
  background-color: #1778f2;
}

.r-article r-btn--share .r-icon--whatsapp:hover {
  background-color: #25d366;
}

.r-article r-btn--share .r-icon--mail:hover {
  background-color: #0e0d0e;
}

.r-article r-btn--share .r-icon--print:hover {
  background-color: #0e0d0e;
}

r-viewmode r-btn--share {
  margin-top: 8px;
}

.r-menu--share-link {
  color: #0e0d0e;
  margin-bottom: 11.2px;
}

.r-menu--share-link:hover {
  background: none;
  text-decoration: none;
}

.r-grand-format .r-menu--share-link r-icon,
.r-article .r-menu--share-link r-icon {
  align-items: center;
  background-color: white;
  border-radius: 100%;
  display: flex;
  font-size: 2rem;
  height: 40px;
  justify-content: center;
  width: 40px;
}

.r-grand-format .r-menu--share-link r-icon:hover,
.r-article .r-menu--share-link r-icon:hover {
  color: white;
}

r-viewmode .r-menu--share-link {
  font-size: 2rem;
  padding-right: 8px;
}

.icon-arrow-left::before {
  content: "\ea02";
  font-family: main;
}

.icon-arrow-right::before {
  content: "\ea03";
  font-family: main;
}

.glider-track {
  margin: 0 auto;
}

.r-carousel--liseuse {
  margin: 0 16px;
  width: auto;
}

.r-carousel--liseuse .glider-prev,
.r-carousel--liseuse .glider-next {
  background: #6a8c1f;
  color: white;
  padding: 24px 12px;
}

.r-carousel--liseuse .glider-prev {
  border-radius: 50px 0 0 50px;
  left: -24px;
}

.r-carousel--liseuse .glider-next {
  border-radius: 0 50px 50px 0;
  right: -24px;
}

.r-digiteka {
  padding: 16px 16px 32px;
  z-index: 101;
}

.r-digiteka--title {
  border-bottom: 1px solid #6a8c1f;
  font-size: 2rem;
  line-height: 1.2;
  margin: 0 0 16px;
  padding: 16px 0;
  text-align: center;
}

.arche .r-digiteka--title {
  margin: auto auto 16px;
}

#ultimedia_wrapper,
#ultimedia_live {
  margin: auto;
}

.gr-media--digiteka .gr-date {
  display: none;
}

.r-contact {
  padding: 1.5rem .25rem;
  padding: 0;
}

.r-contact label,
.r-contact p {
  font-size: 1rem;
}

.r-contact label {
  display: flex;
  padding: 1.5rem .25rem .3rem;
}

.r-contact input[type='text'],
.r-contact input[type='email'],
.r-contact input[type='phone'],
.r-contact input[type='password'],
.r-contact textarea {
  border: 1px solid #f3f3f3;
  border-radius: 4.8px;
  max-width: 100%;
  padding: 8px;
}

.r-contact input[type='text']:focus,
.r-contact input[type='email']:focus,
.r-contact input[type='phone']:focus,
.r-contact input[type='password']:focus,
.r-contact textarea:focus {
  border-color: #0e0d0e;
  box-shadow: inset 0 1px 1px rgba(14, 13, 14, 0.075), 0 0 8px rgba(253, 200, 0, 0.6);
}

.r-contact input[type='submit'] {
  display: flex;
  margin: 16px 0;
}

.r-contact select {
  appearance: auto;
  border: 1px solid #f3f3f3;
  border-radius: 4.8px;
  padding: 8px;
}

.r-contact .r-text--small {
  font-size: 0.9rem;
  color: #e6e6e6;
}

.r-contact .r-article--header p {
  max-width: none;
  margin-bottom: 32px;
}

.r-contact .form-select {
  width: 40%;
}

.r-contact select,
.r-contact input[type='text'],
.r-contact input[type='email'],
.r-contact input[type='phone'],
.r-contact input[type='password'],
.r-contact textarea {
  border: 1px solid #9f9f9f;
}

.r-contact select:focus,
.r-contact input[type='text']:focus,
.r-contact input[type='email']:focus,
.r-contact input[type='phone']:focus,
.r-contact input[type='password']:focus,
.r-contact textarea:focus {
  border-color: black;
  box-shadow: none;
}

.r-contact .r-btn--primary {
  margin-top: 32px;
}

.r-contact .r-text--small {
  color: #9f9f9f;
  margin-top: 32px;
}

.glider-prev,
.glider-next {
  background: #e6e6e6;
  border-radius: 50%;
  color: white;
  font-family: main;
  font-size: 7px;
  height: 53px;
  transform: translateY(-50%);
  width: 53px;
  background: #6a8c1f;
}

.glider-prev {
  left: 0;
}

.glider-prev::before {
  content: "\ea02";
  padding-left: 10px;
}

.glider-next::before {
  content: "\ea03";
  padding-left: 10px;
}

.r-img {
  transition: transform .5s ease;
}

.-is-open + r-submenu {
  display: block;
}

.r-menu--main[data-menu="megamenu"] .-is-open + r-submenu .r-submenu--link {
  display: block;
}

.-is-parent .-is-open + r-submenu {
  display: block;
}

r-menu[data-menu="megamenu"] .-has-submenu {
  background: white;
  position: relative;
}

r-menu[data-menu="megamenu"] .-has-submenu::after {
  font-family: main;
  font-size: 1.5rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #f3f3f3;
  content: "\ea06";
}

r-menu[data-menu="megamenu"] .-has-submenu.-is-open::after {
  display: none;
}

r-menu[data-menu="megamenu"] .-has-submenu.-is-open::before {
  content: "\ea05";
}

.r-menu--main.-is-active .r-menu--link {
  display: none;
}

.r-menu--main.-is-active .-has-submenu.-is-open {
  display: block;
}

.r-mini-panel--menu {
  background: rgba(159, 159, 159, 0.5);
  content-visibility: hidden;
  display: flex;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 151;
}

.r-mini-panel--menu i-mini-panel {
  background: white;
  height: 100vh;
  overflow: auto;
  position: relative;
  width: 80%;
  z-index: 5;
}

.r-mini-panel--overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}

.-is-toggle-r-mini-panel--menu {
  overflow: hidden;
}

.r-mini-panel--menu .r-menu--topics .r-btn--outline {
  border: 1px solid #a0ca4b;
  font-size: 0.75rem;
  height: auto;
  padding: 6px 8px;
}

.r-menu--main {
  position: relative;
}

.r-menu--main .r-menu--link,
.r-menu--main .r-submenu--link {
  align-items: center;
  color: #0e0d0e;
  display: flex;
  font-size: 1.05rem;
  margin: 0 32px;
  padding: 16px 0;
  border-bottom: 1px solid #f3f3f3;
  text-transform: uppercase;
  font-weight: bold;
}

.r-menu--main .r-menu--link::before,
.r-menu--main .r-submenu--link::before {
  color: #f3f3f3;
  font-family: main;
  font-size: 1.5rem;
  line-height: 1;
  padding-right: 10px;
  position: relative;
  top: -1px;
  vertical-align: middle;
}

.r-menu--main .r-menu--link:hover,
.r-menu--main .r-submenu--link:hover {
  text-decoration: none;
  color: #6a8c1f;
}

.r-menu--main .r-menu--link:hover::after,
.r-menu--main .r-submenu--link:hover::after {
  color: #6a8c1f;
}

.r-menu--main .r-link--image {
  padding: 8px 8px 8px 24px;
}

.r-menu--main .r-link--image:hover {
  background: #a0ca4b;
}

.r-menu--main [class*='r-icon--'] {
  font-weight: bold;
  text-transform: none;
  color: #6a8c1f;
}

.r-menu--main [class*='r-icon--']::before {
  font-weight: normal;
  color: #6a8c1f;
}

.r-menu--main[data-menu="megamenu"] r-submenu {
  background: white;
  z-index: 1;
}

.r-menu--main[data-menu="megamenu"] r-submenu.-is-parent {
  margin-top: -55px;
  position: relative;
  background: white;
}

.r-menu--main[data-menu="megamenu"] r-submenu.-is-parent > .r-submenu--link {
  display: none;
}

.r-menu--main[data-menu="megamenu"] r-submenu.-is-parent > .r-submenu--link.-is-open {
  display: block;
}

.r-menu--main[data-menu="megamenu"] r-submenu.-is-active {
  z-index: 2;
}

.r-menu--main[data-menu="expand"] .r-menu--link,
.r-menu--main[data-menu="expand"] .r-submenu--link {
  font-size: 1.3rem;
  text-transform: none;
}

.r-menu--main[data-menu="expand"] .-has-submenu {
  position: relative;
}

.r-menu--main[data-menu="expand"] .-has-submenu::after {
  content: "\ea03";
  font-family: main;
  font-size: 1rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.r-menu--main[data-menu="expand"] r-submenu .r-submenu--link {
  border-bottom: 1px solid #f3f3f3;
  font-size: 1.05rem;
  position: relative;
}

.r-menu--user {
  background: white;
  border-radius: 8px;
  box-shadow: 2px 2px 4px rgba(14, 13, 14, 0.2);
  font-family: "Open Sans", arial, sans-serif;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 50px;
  visibility: hidden;
  width: 230px;
}

.r-menu--user a {
  color: #0e0d0e;
  display: block;
  padding: 8px 32px;
}

.r-menu--user a:hover {
  background: white;
  text-decoration: none;
}

.r-menu--user a:hover {
  background: #f3f3f3;
  font-weight: 700;
}

.r-menu--user .r-menu--label {
  background: #f3f8ff;
  border-bottom: 0;
  border-radius: 8px 8px 0 0;
  color: #0e0d0e;
  font-family: "Open Sans", arial, sans-serif;
  font-size: 1.05rem;
  font-weight: bold;
  line-height: 1.3;
  margin: 0;
  padding: 16px;
}

.r-menu--user span {
  color: #0e0d0e;
  font-size: .9rem;
}

.r-menu--user strong {
  display: block;
  font-weight: 300;
  line-height: 32px;
}

.-is-open + r-submenu {
  display: block;
}

r-menu[data-menu="megamenu"] .-has-submenu {
  background: white;
  position: relative;
}

r-menu[data-menu="megamenu"] .-has-submenu::after {
  color: #9f9f9f;
  content: "\ea06";
  font-family: main;
  font-size: 1.5rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

r-menu[data-menu="megamenu"] .-has-submenu.-is-open {
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}

r-menu[data-menu="megamenu"] .-has-submenu.-is-open::after {
  display: none;
}

r-menu[data-menu="megamenu"] .-has-submenu.-is-open::before {
  content: "\ea05";
}

.r-mini-panel--menu {
  background: rgba(159, 159, 159, 0.5);
  content-visibility: hidden;
  display: flex;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 152;
}

.r-mini-panel--menu i-mini-panel {
  background: white;
  height: 100vh;
  overflow: auto;
  position: relative;
  width: 80%;
  z-index: 6;
}

.r-mini-panel--overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}

.-is-toggle-r-mini-panel--menu {
  overflow: hidden;
}

.r-menu--main {
  position: relative;
}

.r-menu--main .r-menu--link,
.r-menu--main .r-submenu--link {
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
  color: #0e0d0e;
  display: flex;
  font-size: 1.05rem;
  margin: 0 32px;
  padding: 16px 0;
  text-transform: uppercase;
  font-weight: bold;
}

.r-menu--main .r-menu--link::before,
.r-menu--main .r-submenu--link::before {
  color: #9f9f9f;
  font-family: main;
  font-size: 1.5rem;
  line-height: 1;
  padding-right: 10px;
  position: relative;
  top: -1px;
  vertical-align: middle;
}

.r-menu--main .r-menu--link:hover,
.r-menu--main .r-submenu--link:hover {
  text-decoration: none;
  color: #6a8c1f;
}

.r-menu--main .r-menu--link:hover::after,
.r-menu--main .r-submenu--link:hover::after {
  color: #6a8c1f;
}

.r-menu--main .r-link--image {
  padding: 8px 8px 8px 24px;
}

.r-menu--main .r-link--image:hover {
  background: #a0ca4b;
}

.r-menu--main [class*='r-icon--'] {
  font-weight: bold;
  text-transform: none;
  color: #6a8c1f;
  text-transform: none;
}

.r-menu--main [class*='r-icon--']::before {
  font-weight: bold;
  color: #6a8c1f;
}

.r-menu--main r-submenu {
  background: white;
  bottom: 0;
  left: 0;
  padding-top: 57px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.r-menu--main r-submenu.-is-active {
  z-index: 2;
}

r-mini-panel {
  display: block;
  z-index: 0;
}

.arche r-mini-panel {
  margin-left: 0;
  width: auto;
}

r-mini-panel .r-btn--more .r-btn {
  margin: 0 8px;
}

i-mini-panel {
  display: block;
  margin: 0;
}

.arche i-mini-panel {
  margin: auto;
}

i-mini-panel r-viewmode:last-of-type {
  border: 0;
  margin-bottom: 0;
}

r-sidebar i-mini-panel {
  margin: auto;
}

.r-m {
  display: block;
  position: relative;
  top: -110px;
}

[data-scrolldir='down'] .r-m {
  top: -50px;
}

.r-mini-panel--title r-icon {
  font-size: 1.2rem;
  margin-left: 16px;
  font-weight: 700;
}

.-has-bg-light .r-mini-panel--title r-icon {
  background: white;
  border-color: white;
}

.r-mini-panel--title .r-icon--location {
  color: #6a8c1f;
  font-size: 36px;
  margin-left: 0;
  margin-right: 12px;
}

.r-mini-panel--title .r-icon--pencil-square {
  font-size: .9rem;
  margin-left: 0;
}

.r-mini-panel--title .r-btn--more {
  font-family: "Open Sans", arial, sans-serif;
  font-weight: normal;
  margin-left: 16px;
  padding: 0;
  text-transform: none;
}

.r-mini-panel--offres-emploi .r-mini-panel--title .r-btn--more {
  color: #209e97;
  font-size: 0;
}

.r-mini-panel--offres-emploi .r-mini-panel--title .r-btn--more r-icon {
  margin: 0 16px 0 0;
}

.-is-ma-commune .r-mini-panel--title {
  align-items: end;
  display: grid;
  grid-template-columns: 37px minmax(0, auto) minmax(0, auto) 2fr;
  grid-template-rows: 1fr 1fr;
}

.-is-ma-commune .r-mini-panel--title .r-icon--location {
  grid-column: 1;
  grid-row: span 2;
  margin-bottom: 4px;
}

.-is-ma-commune .r-mini-panel--title span {
  color: #6a8c1f;
  font-size: .9rem;
  grid-column: 2;
  grid-row: 1;
}

.-is-ma-commune .r-mini-panel--title strong {
  grid-column: 2;
  grid-row: 2;
  line-height: 1;
}

.-is-ma-commune .r-mini-panel--title::after {
  grid-column: 4;
  grid-row: span 2;
}

.r-mini-panel-immo-list .r-mini-panel--title span {
  margin-left: 8px;
}

.r-mini-panel-immo-list .r-mini-panel--title,
.r-mini-panel--offres-emploi .r-mini-panel--title {
  font-size: 1.7rem;
}

.r-mini-panel--offres-emploi .r-mini-panel--title {
  color: #209e97;
}

.r-btn--macommune {
  border: 1px solid #6a8c1f;
  border-radius: 30px;
  color: #6a8c1f;
  font-size: 1rem;
  font-weight: bold;
  grid-column: 3;
  grid-row: span 2;
  justify-content: center;
  padding: 8px 16px;
  text-transform: none;
}

.r-btn--macommune span {
  font-size: 1rem;
  margin-left: 4px;
}

.arche .r-mini-panel--section {
  margin-left: 0;
  width: auto;
}

.arche .r-mini-panel--section i-mini-panel {
  grid-template-areas: '. . .';
  grid-template-columns: 33.33% 33.33% 33.33%;
  margin: auto;
}

.r-mini-panel--section.-is-mini-panel--horoscope {
  margin-bottom: 32px;
}

.r-mini-panel--section.-is-mini-panel--horoscope .r-mini-panel--item-horoscope {
  align-items: center;
  color: #0e0d0e;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.r-mini-panel--section.-is-mini-panel--horoscope .r-mini-panel--item-horoscope:hover {
  color: #0e0d0e;
  text-decoration: none;
}

.r-mini-panel--section.-is-mini-panel--horoscope svg {
  background: white;
  border-radius: 50%;
  fill: none;
  height: 50px;
  padding: 4px;
  width: 50px;
}

.page-node .r-mini-panel--section {
  margin-bottom: 16px;
}

.r-mini-panel--partenaires a {
  flex: 0 0 60%;
  padding: 0;
}

.arche .r-mini-panel--recruteurs {
  margin-left: 0;
  width: auto;
}

.r-mini-panel--recruteurs i-mini-panel r-viewmode:last-of-type {
  margin-bottom: 16px;
}

.lazyloaded.-is-mp-video {
  background-size: cover;
  background-color: black;
}

.lazyloaded.-is-mp-video time {
  color: white;
}

.lazyloaded.-is-mp-video.-has-bg .r-mini-panel--title a {
  color: #a0ca4b;
}

.lazyloaded.-is-mp-digiteka-visible {
  z-index: 102;
}

.r-mini-panel--services,
.r-mini-panel--tabs {
  background: #e6e6e6;
}

.r-mini-panel--services r-panel:first-child r-panel--header,
.r-mini-panel--tabs r-panel:first-child r-panel--header {
  border: 0;
}

#panel-test {
  background: white;
}

.r-mini-panel--services {
  margin-top: 32px;
}

.r-mini-panel--services r-panel--header {
  padding-top: 32px;
}

.r-mini-panel--services r-panel--header::before,
.r-mini-panel--services r-panel--header::after {
  display: none;
}

.r-mini-panel--services r-panel--header,
.r-mini-panel--services .r-mini-panel--title {
  align-items: center;
  display: flex;
  font-weight: 400;
  line-height: 1.2;
  margin: 0;
  padding: 24px 0;
}

.r-mini-panel--services r-panel--header::after,
.r-mini-panel--services r-panel--header::before,
.r-mini-panel--services .r-mini-panel--title::after,
.r-mini-panel--services .r-mini-panel--title::before {
  background: black;
  content: '';
  display: block;
  flex: 1;
  height: 1px;
}

.r-mini-panel--services r-panel--header::after,
.r-mini-panel--services .r-mini-panel--title::after {
  margin: 16px 0 16px 16px;
  padding: 0 16px;
}

.r-mini-panel--services r-panel--header::before,
.r-mini-panel--services .r-mini-panel--title::before {
  margin: 16px 16px 16px 0;
  padding: 0 16px;
}

.r-mini-panel--services .r-panel--item .r-panel--title,
.r-mini-panel--services .r-viewmode--niveau-2 .r-panel--title {
  font-weight: 400;
  font-size: 1.3rem;
}

.r-mini-panel--services .r-panel--item .r-panel--link,
.r-mini-panel--services .r-viewmode--niveau-2 .r-panel--link {
  display: inline;
}

.r-mini-panel--services .r-panel--item .r-article--link,
.r-mini-panel--services .r-viewmode--niveau-2 .r-article--link {
  display: inline;
  font-weight: 400;
  font-size: 1.3rem;
}

.r-mini-panel--services r-panel--meta,
.r-mini-panel--services r-article--meta {
  display: block;
  margin-top: 8px;
  text-transform: uppercase;
}

.r-mini-panel--services r-panel--meta a,
.r-mini-panel--services r-article--meta a {
  color: black;
  font-weight: 400;
}

.arche .r-mini-panel--service i-mini-panel {
  grid-template-columns: 66.66% 33.33%;
}

.r-mini-panel--service r-panel {
  margin: 0;
}

.r-mini-panel--service .r-panel--horizontal r-panel--header {
  border: 0;
  padding: 0;
}

.r-mini-panel--list {
  margin-bottom: 0;
  padding: 0 0 16px 16px;
}

.r-mini-panel--list.r-panel--horizontal .r-panel--title {
  font-size: 1.7rem;
}

.r-mini-panel--list .r-mini-panel--title {
  padding: 48px 0 16px;
}

.r-mini-panel--list .r-panel--list {
  grid-column: 1 / -1;
  grid-row: 2;
  padding: 0;
}

.r-mini-panel--list .r-viewmode--panel-left {
  grid-column: 1;
}

.r-mini-panel--list .r-viewmode--panel-left .r-viewmode--article {
  position: relative;
}

.r-mini-panel--list .r-viewmode--panel-left i-article {
  background-color: white;
  border-radius: 16px;
  left: 0;
  margin-top: -40px;
  padding: 16px;
  position: relative;
  text-align: left;
  z-index: 9;
  width: 100%;
}

.r-mini-panel--list .r-viewmode--panel-left .r-article--title {
  margin: 0;
}

.r-mini-panel--list .r-viewmode--panel-left figure {
  margin-bottom: 0;
}

.r-mini-panel--list .r-panel--panel-left {
  padding: 16px 0;
}

.r-mini-panel--article r-selligent {
  grid-row: 3;
}

.arche .r-mini-panel--article r-pub {
  grid-column: 3;
  grid-row: 2 / span 2;
}

.r-mini-panel--froomle {
  background: #6a8c1f;
}

.r-mini-panel--froomle i-mini-panel {
  color: white;
}

.r-mini-panel--froomle:not(:has(.r-froomle--anonyme)) a {
  color: white;
}

.r-mini-panel--froomle:not(:has(.r-froomle--anonyme)) a:hover {
  color: white;
}

.r-mini-panel--froomle .r-mini-panel--title span {
  display: flex;
  align-items: center;
}

.r-mini-panel--froomle .r-mini-panel--title span::after {
  content: "\ea03";
  font-family: main;
  font-size: 1.2rem;
  margin-left: 16px;
  font-weight: 700;
}

.r-mini-panel--froomle w-viewmode {
  display: flex;
  overflow: auto;
  grid-column: 1/-1;
}

.r-mini-panel--froomle w-viewmode r-viewmode {
  flex: 0 0 300px;
  width: 300px;
}

.arche .r-mini-panel--froomle w-viewmode r-viewmode:last-child {
  display: none;
}

.r-froomle--anonyme,
.r-froomle--cookies {
  align-items: center;
  background-image: url("/sites/all/themes/enacarbon_sb/images/bg_froomle_anonyme.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  box-shadow: inset 0 0 0 1000px rgba(243, 243, 243, 0.7);
  border-radius: 10px;
  border: 1px solid #6a8c1f;
  color: #6a8c1f;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  grid-column: 1/-1;
  margin: 16px;
  padding: 48px;
  text-align: center;
}

.r-froomle--anonyme p,
.r-froomle--cookies p {
  font-size: 1.5rem;
  margin-bottom: 48px;
}

.r-froomle--anonyme a,
.r-froomle--cookies a {
  font-size: 1rem;
}

.r-froomle--login {
  margin-top: 16px;
  text-decoration: underline;
}

r-mini-panel.-has-panel {
  z-index: 0;
}

.arche r-mini-panel.-has-panel i-mini-panel {
  grid-gap: 16px;
  grid-template-areas: ". .";
  grid-template-columns: calc(50% - 8px) calc(50% - 8px);
}

r-mini-panel.-has-panel .r-mini-panel--title::after {
  margin-left: 12px;
  margin-right: 0;
}

r-mini-panel.-has-panel .r-mini-panel--title::before {
  margin-left: 0;
  margin-right: 12px;
}

r-mini-panel.-has-panel r-panel {
  margin: 0 0 16px;
}

r-mini-panel.-has-panel .r-pub--pave {
  grid-row: auto;
}

.arche r-mini-panel.-has-imu i-mini-panel {
  grid-template-columns: 1fr 1fr 332px;
}

r-mini-panel.-has-bg {
  background-repeat: no-repeat;
  color: white;
  margin-bottom: 0;
}

r-mini-panel.-has-bg .r-mini-panel--title::after,
r-mini-panel.-has-bg .r-mini-panel--title::before {
  background: white;
}

r-mini-panel.-has-bg .r-author--link,
r-mini-panel.-has-bg .r-article--link,
r-mini-panel.-has-bg .r-mini-panel--title,
r-mini-panel.-has-bg .r-article--section,
r-mini-panel.-has-bg .r-article--location {
  color: white;
}

r-mini-panel.-has-bg .r-article--section,
r-mini-panel.-has-bg .r-article--location {
  border-color: white;
}

r-mini-panel.-has-bg .r-meta-info {
  color: #f3f3f3;
  padding: 0;
  text-align: left;
}

r-mini-panel.-has-bg .r-meta-info span,
r-mini-panel.-has-bg .r-meta-info time {
  font-size: .8rem;
}

r-mini-panel.-has-bg .r-mini-panel--title {
  color: white;
}

r-mini-panel.-has-bg .r-mini-panel--title::after,
r-mini-panel.-has-bg .r-mini-panel--title::before {
  background: rgba(255, 255, 255, 0.5);
}

r-mini-panel.-has-bg .r-mini-panel--title a {
  color: white;
}

r-mini-panel.-has-bg r-viewmode {
  border-color: rgba(255, 255, 255, 0.5);
}

.-has-subtitle .r-mini-panel--title {
  font-weight: 700;
}

.-has-subtitle .r-mini-panel--title::before {
  margin: 16px 16px 0 0;
}

.-has-subtitle .r-mini-panel--title::after {
  margin: 16px 0 0 16px;
}

.-has-subtitle .r-mini-panel--title a {
  display: grid;
}

.-has-subtitle .r-mini-panel--title a span {
  color: #a0ca4b;
  font-size: 0.9rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.-has-subtitle .r-mini-panel--title a:hover span {
  text-decoration: none;
}

.-has-subtitle .r-viewmode--panel .r-article--title {
  font-weight: 700;
}

.-has-subtitle r-article--meta a {
  color: #a0ca4b;
}

.-has-subtitle .r-btn--primary {
  background: #a0ca4b;
  border: none;
  color: black;
}

.-has-bg-light {
  background: #f5fbeb;
}

.-has-bg-light .r-viewmode--panel .r-article--title {
  font-weight: 700;
}

.-has-bg-light .r-alerte {
  background: #f5fbeb;
}

.-has-bg-dark {
  background: #0e0d0e;
  background: black;
  /*r-article--meta {
                a { 
                    color: color(primary, light);
                }
            }
            .r-mini-panel--title {
                a {
                    color: color(primary, light);
                }
            }*/
}

.-has-bg-dark .r-btn--primary {
  background: #a0ca4b;
  border: none;
  color: black;
}

.-has-bg-dark .r-article--link,
.-has-bg-dark .r-panel--link {
  color: white;
}

.-has-bg-dark .r-pubdate {
  color: white;
}

.-has-bg-dark .r-alerte {
  color: white;
  border: 1px solid white;
  background: none;
}

.-is-livetv {
  padding-bottom: 48px;
}

.arche .-is-livetv i-mini-panel {
  grid-column-gap: 32px;
  grid-template-areas: '. . .' 'bigimg bigimg bigtitle' 'bigimg bigimg .' 'bigimg bigimg .';
  grid-template-columns: repeat(3, calc((100% - 64px) / 3));
}

.-is-livetv .r-viewmode--manchette {
  margin: 0;
  padding-bottom: 0;
}

.-is-livetv .r-viewmode--manchette time {
  display: block;
  margin: 16px 0 8px;
}

.-is-livetv .r-viewmode--manchette .r-article--title {
  font-size: 1.5rem;
  text-transform: uppercase;
}

.-is-livetv .r-viewmode--manchette .r-article--title::before {
  display: none;
}

.-is-livetv .r-alerte {
  background: #fc8029;
  border: none;
  border-radius: 0;
  color: white;
  font-size: 1.4rem !important;
  font-weight: 700;
  padding: 4px 8px;
}

.-is-livetv .r-viewmode--niveau-1 {
  padding: 0;
}

.-is-tabs .r-tabs {
  display: block;
  padding-top: 48px;
  position: relative;
}

.arche .-is-tabs .r-tabs {
  margin: auto;
}

.-is-tabs .r-tabs ul {
  overflow: auto;
}

.-is-tabs .r-tabs--btn {
  background: none;
  color: #0e0d0e;
  font-size: 1.05rem;
  font-weight: 900;
  margin: 16px;
  opacity: .3;
  padding: 0;
  position: relative;
  text-transform: uppercase;
  transition: all .3s ease-in-out;
}

.-is-tabs .r-tabs--btn::before {
  bottom: 1px;
  content: '';
  height: 2px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  transition: all .3s ease-in-out;
  width: 0;
}

.-is-tabs .r-tabs--btn.active {
  background: none;
  color: #0e0d0e;
  opacity: 1;
}

.-is-tabs .r-tabs--btn.active::before {
  width: 100%;
}

.-is-tabs tab {
  transition: all .3s ease-in-out;
}

.-is-tabs tab.active .r-viewmode--article {
  opacity: 1;
  top: 0;
}

.-is-tabs tab .r-viewmode--article {
  opacity: 0;
  padding: 16px;
  position: relative;
  top: 10px;
  transition: all 3s ease-in-out;
}

.-is-tabs .r-btn--more {
  justify-content: center;
}

.-is-mp-primary {
  background: #6a8c1f;
}

.-is-mp-primary .r-carousel--link {
  color: white;
}

.-is-mp-primary .glider-prev,
.-is-mp-primary .glider-next {
  color: white;
  font-size: 2rem;
}

.-is-mp-primary .glider-next {
  right: -16px;
}

.-is-mp-primary-light {
  background: #a0ca4b;
}

.-is-mp-gray-light {
  background: white;
}

.-is-mp-aussi-en {
  background: #f3f8ff;
}

.-has-bg-immo-light {
  background-color: #fff49e;
  margin-bottom: 24px;
  margin-top: 16px;
  padding-bottom: 24px;
  padding-right: 16px;
}

.-has-bg-immo-light .r-mini-panel--title {
  color: #0e0d0e;
  padding-top: 16px;
}

.-has-bg-immo-light .r-mini-panel--title::after {
  background: #0e0d0e;
}

.r-mini-panel-immo-list {
  padding: 16px;
}

.r-mini-panel-immo-list.-is-mobile-swipable {
  padding-right: 0;
}

.r-panel--edition-immo + .-has-bg-immo-light {
  margin-top: 0;
}

.-has-bg-emploi-light {
  background: #cbebe9;
  margin: 0;
  padding: 0 16px 16px;
}

.-has-bg-emploi-light i-mini-panel {
  margin: auto;
}

.r-mini-panel-serviciels r-panel--header::after,
.r-mini-panel-serviciels r-panel--header::before {
  display: none;
  text-transform: uppercase;
}

.r-mini-panel-serviciels .r-btn {
  border-radius: 5px;
  font-weight: normal;
  justify-content: center;
  padding: 12px;
}

.r-mini-panel-serviciels .r-btn:hover {
  opacity: .8;
}

.r-mini-panel-serviciels r-panel {
  border-radius: 10px;
}

.r-mini-panel-serviciels r-panel--body {
  display: flex;
}

.r-mini-panel-serviciels r-panel--body r-icon {
  font-size: 26px;
}

.r-mini-panel-serviciels r-panel--header {
  font-size: 1rem;
  grid-column: 1;
  margin: 0 0 8px;
  text-transform: none;
}

.r-mini-panel-serviciels r-panel--footer {
  padding: 0;
}

.-is-horizontal {
  margin-left: calc(-50vw + 50%);
  padding-bottom: 32px;
  padding-left: 16px;
  padding-top: 16px;
  width: 100vw;
}

.-is-horizontal r-panel {
  align-items: center;
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: auto 50px;
  padding: 8px 16px;
}

.-is-horizontal r-panel--body {
  align-items: center;
  column-gap: 16px;
  grid-column: 1;
}

.-is-horizontal .r-btn {
  width: 100%;
}

.-is-horizontal .r-btn r-icon {
  margin-right: 0;
}

.-is-horizontal r-panel--footer {
  grid-column: 2;
  grid-row: 1 / span 2;
  justify-self: end;
  text-align: center;
}

.-is-vertical {
  padding: 16px;
}

.-is-vertical i-mini-panel {
  margin: auto;
}

.-is-vertical r-panel {
  margin-bottom: 24px;
  padding: 16px;
}

.-is-vertical r-panel--body {
  column-gap: 32px;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.-is-vertical r-panel--body r-icon {
  align-self: end;
  font-size: 50px;
  margin-bottom: 12px;
}

.-is-vertical .r-btn {
  display: inline-block;
}

.-is-vertical .r-btn r-icon {
  display: none;
}

.-is-vertical h4 {
  font-size: 12px;
}

.-is-vertical r-panel--footer {
  text-align: left;
}

.-is-emploi r-panel:first-child {
  background-color: #209e97;
  color: white;
}

.-is-emploi r-panel:first-child .r-btn {
  background-color: #0b514d;
}

.-is-emploi r-panel:first-child r-panel--header {
  color: white;
}

.-is-emploi r-panel:last-child {
  background-color: #cbebe9;
  color: #0e0d0e;
}

.-is-emploi r-panel:last-child .r-btn {
  background-color: #209e97;
}

.-is-emploi .r-btn {
  color: white;
}

.-is-immo r-panel:first-child {
  background-color: #ffe500;
  color: #0e0d0e;
}

.-is-immo r-panel:first-child .r-btn {
  background-color: #fff49e;
}

.-is-immo r-panel:last-child {
  background-color: #fff49e;
  color: #0e0d0e;
}

.-is-immo r-panel:last-child .r-btn {
  background-color: #ffe500;
}

.-is-immo .r-btn {
  color: #0e0d0e;
}

.-is-legales--hp {
  margin-bottom: 16px;
}

.-is-legales--hp .r-mini-panel--title {
  width: 100%;
}

.-is-legales--hp r-btn {
  margin-bottom: 16px;
  width: 100%;
}

.-is-legales--hp .r-btn--big {
  font-size: 1.2rem;
  width: 100%;
}

.-is-panel-right.r-mini-panel--section i-mini-panel {
  grid-template-areas: '. . . .' '. . manchette manchette' '. . manchette manchette';
}

.-is-panel-right.r-mini-panel--section .r-viewmode--panel {
  grid-area: manchette;
}

.-is-panel-right.r-mini-panel--section .r-viewmode--panel .r-article--title {
  font-weight: 700;
}

.-is-panel-right.r-mini-panel--section .r-viewmode--panel r-article--meta time {
  font-size: 0.9rem;
}

.-is-panel-right.r-mini-panel--section .r-viewmode--niveau-2 r-article--meta time {
  font-size: 0.9rem;
}

.-is-mp-rulabe.r-mini-panel--section i-mini-panel {
  display: block;
}

.-is-mp-rulabe.r-mini-panel--section .r-mini-panel--title::before {
  display: none;
}

.-is-mp-rulabe.r-mini-panel--section .r-mini-panel--title .rula-logo {
  width: 100px;
}

.-is-mp-jobbo.r-mini-panel--section {
  background-color: #EFEFEA;
}

.-is-mp-jobbo.r-mini-panel--section .r-mini-panel--title {
  display: block;
}

.-is-mp-jobbo.r-mini-panel--section .r-mini-panel--title::before,
.-is-mp-jobbo.r-mini-panel--section .r-mini-panel--title::after {
  display: none;
}

.-is-mp-jobbo.r-mini-panel--section .r-mini-panel--title .jobbo-logo {
  width: 100px;
}

.-is-mp-jobbo.r-mini-panel--section .r-mini-panel--title p {
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 0;
  margin-top: 12px;
}

.-is-mp-jobbo.r-mini-panel--section .r-mini-panel--title a {
  text-decoration: none;
}

.-is-mp-jobbo.r-mini-panel--section .r-mini-panel--title a:hover {
  text-decoration: underline;
}

.-is-mp-jobbo.r-mini-panel--section .jobbo-cta {
  background-color: #C81A15;
  border-color: #C81A15;
}

.-is-mp-jobbo.r-mini-panel--section .jobbo-cta r-icon {
  font-size: 1rem;
  margin: 0 8px 0 0;
}

.-is-mp-jobbo.r-mini-panel--section .panel-jobbo-job {
  background-color: white;
  border: 1px solid #CECEC9;
  border-radius: 6px;
  margin-left: 16px;
  margin-bottom: 16px;
  padding: 25px 25px 20px 25px;
  text-align: center;
}

.-is-mp-jobbo.r-mini-panel--section .panel-jobbo-job .panel-jobbo-job-info {
  text-align: left;
}

.-is-mp-jobbo.r-mini-panel--section .panel-jobbo-job .job-title-wrap {
  height: 50px;
}

.-is-mp-jobbo.r-mini-panel--section .panel-jobbo-job h3 {
  font-family: "Open Sans", arial, sans-serif;
  font-size: 18px;
  max-height: 50px;
  overflow: hidden;
  position: relative;
  line-height: 25px;
}

.-is-mp-jobbo.r-mini-panel--section .panel-jobbo-job h3:before {
  content: "...";
  position: absolute;
  bottom: 0;
  right: 0;
}

.-is-mp-jobbo.r-mini-panel--section .panel-jobbo-job h3:after {
  content: "";
  position: absolute;
  right: 0;
  /* note: not using bottom */
  width: 20px;
  height: 25px;
  background: white;
}

.-is-mp-jobbo.r-mini-panel--section .panel-jobbo-job h3 a {
  color: #0e0d0e;
}

.-is-mp-jobbo.r-mini-panel--section .panel-jobbo-job h3 a:hover {
  color: #0e0d0e;
}

.-is-mp-jobbo.r-mini-panel--section .panel-jobbo-job .job-company {
  font-size: 14px;
  height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.-is-mp-jobbo.r-mini-panel--section .panel-jobbo-job .job-location-wrap {
  height: 40px;
  margin-bottom: 16px;
}

.-is-mp-jobbo.r-mini-panel--section .panel-jobbo-job .job-location {
  color: #9f9f9f;
  font-size: 14px;
  max-height: 40px;
  line-height: 20px;
  overflow: hidden;
  position: relative;
}

.-is-mp-jobbo.r-mini-panel--section .panel-jobbo-job .job-location:before {
  content: "...";
  position: absolute;
  bottom: 0;
  right: 0;
}

.-is-mp-jobbo.r-mini-panel--section .panel-jobbo-job .job-location:after {
  content: "";
  position: absolute;
  right: 0;
  /* note: not using bottom */
  width: 15px;
  height: 20px;
  background: white;
}

.-is-mp-jobbo.r-mini-panel--section .panel-jobbo-job .job-cta {
  background: white;
  border: 1px solid #C81A15;
  color: #C81A15;
}

.-is-mp-jobbo.r-mini-panel--section .panel-jobbo-job .job-cta:hover {
  background-color: #C81A15;
  color: white;
}

.-is-mp-jobbo.r-mini-panel--section .panel-jobbo-job .job-cta r-icon {
  font-size: 12px;
  margin-left: 3px;
  margin-right: 0;
}

.-is-mp-jobbo.r-mini-panel--section .panel-jobbo-job .job-cta r-icon + r-icon {
  margin-left: -2px;
}

.-is-mp-jobbo.r-mini-panel--section .jobbo-job-logo {
  height: 50px;
  margin-bottom: 0;
}

.-is-mp-jobbo.r-mini-panel--section .jobbo-job-logo img {
  max-height: 40px;
  max-width: 60%;
}

.-is-mp-jobbo.r-mini-panel--section .panel-jobbo-cta {
  background-image: url("/sites/all/themes/enacarbon_sb/images/jobbo-cta-illu.svg"), url("/sites/all/themes/enacarbon_sb/images/jobbo-cta-bg.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: right 10px bottom 90px, center;
  background-size: 100px auto, 200px auto;
  margin-left: 16px;
  margin-bottom: 16px;
  position: relative;
}

.-is-mp-jobbo.r-mini-panel--section .panel-jobbo-cta h3 {
  padding: 32px 64px 32px 32px;
}

.-is-mp-jobbo.r-mini-panel--section .panel-jobbo-cta r-btn {
  bottom: 16px;
  position: absolute;
  width: 100%;
}

r-modal--backdrop {
  background-color: rgba(14, 13, 14, 0.4);
  z-index: 153;
}

r-modal {
  background: none;
  z-index: 155;
}

r-modal.-is-toggle {
  display: flex;
}

r-modal--content {
  background: white;
  box-shadow: 0 5px 20px rgba(14, 13, 14, 0.15);
  border-radius: 5px;
  max-width: 800px;
  padding: 16px;
  width: 90%;
  z-index: 155;
}

r-modal--content iframe {
  border: none;
}

.r-modal--close {
  font-style: normal;
  line-height: 32px;
  opacity: 1;
  position: absolute;
  right: 8px;
  top: 8px;
}

.r-modal--close::after {
  display: none;
}

.r-modal--close:hover {
  opacity: .5;
}

.r-modal--limit-connect r-modal--close {
  color: white;
}

.r-modal--limit-connect .r-modal--header {
  background: #6a8c1f;
  color: white;
  margin: -16px -16px 16px;
  padding: 16px 32px;
  text-align: center;
}

.r-modal--limit-connect .r-modal--title {
  font-size: 1.7rem;
  margin: 0;
}

.r-modal--limit-connect strong,
.r-modal--limit-connect p a {
  color: #6a8c1f;
}

.r-modal--limit-connect p a {
  font-weight: bold;
}

.r-modal--limit-connect .r-btn--primary {
  font-size: 1.2rem;
}

.r-modal--limit-connect .r-links {
  border-top: 1px solid #f3f3f3;
  margin-top: 16px;
  padding-top: 16px;
}

.r-modal--limit-connect .r-links p:last-of-type {
  margin-bottom: 0;
}

.r-modal--macommune {
  color: #0e0d0e;
}

.r-modal--macommune .r-modal--title {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  margin-bottom: 32px;
  text-align: center;
}

.r-modal--macommune .r-icon--location {
  color: #6a8c1f;
  font-size: 45px;
}

.r-modal--macommune .r-modal--body {
  display: grid;
  grid-gap: 16px;
  grid-template-areas: '. . ';
  grid-template-columns: repeat(2, 1fr);
}

.r-modal--macommune .r-modal--body.-is-anonyme {
  justify-content: center;
}

.r-modal--macommune .r-modal--body a:not(.r-btn) {
  color: #0e0d0e;
  flex: 0 0 50%;
  text-transform: uppercase;
}

.r-modal--macommune .r-modal--body a:not(.r-btn):hover {
  color: #6a8c1f;
  text-decoration: none;
}

.r-modal--macommune .r-modal--footer {
  display: flex;
  justify-content: space-evenly;
}

.r-modal--macommune .r-modal--footer a {
  text-decoration: underline;
}

.r-modal--macommune .r-modal--footer a:hover {
  text-decoration: none;
}

r-pager {
  padding: 32px 16px;
}

.r-pager--dynamic {
  display: flex;
  list-style: none;
  padding: 0;
}

.r-pager--dynamic span,
.r-pager--dynamic .r-pager--link {
  align-items: center;
  border: 1px solid #f3f3f3;
  border-radius: 100%;
  display: flex;
  height: 32px;
  justify-content: center;
  padding: 0;
  width: 32px;
}

.r-pager--dynamic .r-pager--link {
  border: 1px solid black;
}

.r-pager--dynamic span {
  background-color: white;
  color: #e6e6e6;
  margin: 3.2px;
}

.r-pager--dynamic .r-pager--select button {
  align-items: center;
  background-color: white;
  border: 1px solid #f3f3f3;
  border-radius: 32px;
  display: flex;
  height: 32px;
  justify-content: center;
  margin: 3.2px;
  padding: 0 24px 0 8px;
  position: relative;
  width: 64px;
  border: 1px solid black;
}

.r-pager--dynamic .r-pager--select button::after {
  content: "\ea03";
  font-family: 'main';
  font-size: 0.9rem;
  position: absolute;
  transform: rotate(90deg);
  transition: all .3s ease-in-out;
  right: 12.8px;
  top: 6.4px;
}

.r-pager--dynamic .r-pager--select.-is-toggle button::after {
  transform: rotate(-90deg) translateX(1px);
}

.r-pager--dynamic .r-pager--select.-is-toggle .r-pager--sublist {
  height: 260px;
  overflow: scroll;
  border: 1px solid #f3f3f3;
  border: 1px solid black;
  padding: 0 16px;
  z-index: 10;
}

.r-pager--dynamic .r-pager--select.-is-toggle li {
  border-bottom: 1px solid white;
  border-bottom: 1px solid black;
}

.r-pager--dynamic .r-pager--sublist {
  background-image: linear-gradient(to bottom, white 80%, rgba(0, 0, 0, 0.1));
  background-color: white;
  border-radius: 32px;
  display: flex;
  flex-wrap: wrap;
  height: 0;
  list-style: none;
  left: 50%;
  margin-top: 8px;
  overflow: hidden;
  transform: translateX(-50%);
  transition: all .3s ease-in-out;
  padding: 0;
  position: absolute;
  width: 200px;
  z-index: 11;
  border-radius: 0;
}

.r-pager--dynamic .r-pager--sublist li {
  flex: 0 0 100%;
  transition: all .3s ease-in-out;
  padding: 3.2px 0;
}

.r-pager--dynamic .r-pager--sublist .r-pager--link {
  border: 0;
  margin: auto;
  width: 64px;
}

.r-pager--dynamic .r-pager--active {
  font-weight: 700;
}

.r-pager--link {
  box-shadow: none;
  color: #0e0d0e;
  margin: 3.2px;
}

.r-panel--carousel {
  background: #f3f3f3;
  margin-bottom: 16px;
  padding: 16px;
  position: relative;
}

.arche .r-panel--carousel {
  margin: 0 16px;
}

.r-panel--carousel r-panel--header {
  margin: 0;
  padding: 16px 0 12px;
}

.r-panel--carousel r-panel--footer {
  padding: 8px 0 0;
  text-align: center;
}

.r-panel--carousel .r-btn {
  background-color: #6a8c1f;
  color: white;
  margin: auto;
}

.r-panel--carousel .r-btn:hover {
  color: #fff;
  opacity: .8;
}

.r-panel--carousel .owl-nav {
  display: flex;
  position: absolute;
  right: 0;
  top: -40px;
}

.arche i-mini-panel .r-panel--carousel {
  grid-column: span 2;
  grid-row: span 2;
  width: calc(100% - 32px);
}

.r-panel--liseuse r-panel--footer {
  text-align: center;
}

.r-panel--info-commune {
  background: 0/cover #6a8c1f;
  grid-column-start: 1;
  grid-column-end: -1;
  margin-top: 0;
  padding: 24px 16px;
}

.r-panel--info-commune h2 {
  align-items: center;
  color: white;
  column-gap: 16px;
  display: flex;
  font-size: 1.5rem;
  line-height: 1.2;
  margin: 0;
  padding: 16px 0;
  text-align: center;
  text-transform: uppercase;
}

.r-panel--info-commune h2::after {
  background: white;
  content: '';
  -webkit-flex: 1;
  flex: 1;
  height: 1px;
  margin: 16px 0;
}

.r-panel--info-commune r-menu {
  align-items: center;
  column-gap: 12px;
  display: flex;
  overflow-x: auto;
}

.r-panel--info-commune .r-btn {
  flex: 0 0 auto;
  margin: 8px 0;
}

.r-panel--info-commune .r-search--autocomplete {
  border: none;
  height: 45px;
  padding: 12px 16px 12px 32px;
  width: 100%;
}

.r-panel--info-commune .r-icon--search {
  font-size: 20px;
  height: 45px;
  line-height: 45px;
}

.r-panel--info-commune form {
  display: block;
  margin: 16px auto;
  position: relative;
  width: 100%;
}

.r-panel--info-commune button {
  position: absolute;
  left: 0;
  top: 0;
}

.r-panel--info-commune-immobilier {
  background: 0/cover #ffe500;
  margin-bottom: 32px;
  padding: 16px;
}

.r-panel--info-commune-immobilier h2 {
  color: #0e0d0e;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  padding-top: 0;
}

.r-panel--info-commune-immobilier h2::before {
  display: none;
}

.r-panel--info-commune-immobilier h2::after {
  display: none;
}

.r-panel--info-commune-immobilier .r-btn--white:hover {
  background: #0e0d0e;
}

.r-panel--macommune,
.r-menu--dropdown {
  background: white;
  box-shadow: 0 1px 1px #f3f3f3;
  color: #0e0d0e;
  padding: 16px;
  position: absolute;
  top: 100%;
  width: 100%;
}

.r-panel--macommune .r-panel--link,
.r-menu--dropdown .r-panel--link {
  color: #0e0d0e;
}

.r-panel--macommune r-panel--header,
.r-menu--dropdown r-panel--header {
  display: block;
  text-transform: none;
}

.r-panel--macommune r-panel--header::after,
.r-panel--macommune r-panel--header::before,
.r-menu--dropdown r-panel--header::after,
.r-menu--dropdown r-panel--header::before {
  display: none;
}

.r-panel--macommune r-panel--header r-icon,
.r-menu--dropdown r-panel--header r-icon {
  color: #6a8c1f;
  display: block;
  font-size: 2rem;
  margin-bottom: 8px;
}

.r-panel--macommune .r-panel--link,
.r-menu--dropdown .r-panel--link {
  display: block;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.r-panel--macommune .r-search--autocomplete,
.r-menu--dropdown .r-search--autocomplete {
  border: 0;
  background: white;
  padding: 8px 8px 8px 32px;
  width: 300px;
  border-radius: 4px;
}

.r-panel--macommune .r-search--autocomplete::before,
.r-menu--dropdown .r-search--autocomplete::before {
  content: "\ea16";
}

.r-panel--macommune form,
.r-menu--dropdown form {
  display: inline-block;
  position: relative;
}

.r-panel--macommune .r-search--submit,
.r-menu--dropdown .r-search--submit {
  left: 8px;
  position: absolute;
  top: 10px;
}

.r-panel--macommune r-panel--header {
  margin-bottom: 16px;
}

.r-panel--macommune r-panel--footer {
  margin-top: 16px;
  text-align: center;
}

.r-menu--dropdown r-panel--header {
  margin-bottom: 6px;
}

.r-menu--dropdown .r-panel--link-title {
  font-size: 20px;
  font-weight: bold;
  margin-top: 24px;
}

.r-menu--dropdown r-panel--footer {
  text-align: center;
}

.r-menu--dropdown r-panel--footer a {
  align-items: center;
  display: flex;
  column-gap: 8px;
  justify-content: center;
}

.r-panel--chronique {
  box-shadow: 0 0 4px #f3f3f3;
  padding: 16px;
  text-align: center;
}

.r-panel--chronique r-panel--header {
  margin-bottom: 12px;
  padding-bottom: 8px;
}

.r-panel--chronique .r-img {
  aspect-ratio: unset;
}

.r-panel--chronique .r-panel--item {
  display: flex;
}

.r-panel--chronique .r-panel--title {
  margin-left: 8px;
  margin-top: 0;
  text-align: left;
}

.r-panel--chronique .r-panel--img {
  flex: 0 0 40%;
}

.r-panel--edito {
  position: relative;
  grid-area: dossier;
}

.arche .r-panel--edito {
  grid-row: auto;
}

.r-panel--edito r-panel--header {
  color: white;
}

.r-panel--edito r-panel--header::before,
.r-panel--edito r-panel--header::after {
  display: none;
}

.r-panel--edito r-panel--header a {
  color: white;
}

.r-panel--edito .r-panel--title {
  text-align: center;
}

.arche .r-panel--edito .r-panel--title {
  width: 60%;
}

.r-panel--edito address {
  color: white;
}

.r-panel--edito .r-img--background {
  width: 100%;
}

.r-panel--edito .-is-vertical {
  padding: 0;
}

.arche .r-panel--edito .-is-vertical {
  display: none;
}

.arche .r-panel--edito .-is-square {
  display: block;
}

.r-panel--edito i-panel {
  background: #a0ca4b;
  background-image: url("/sites/all/themes/enacarbon_sb/images/edito_pattern.png");
  background-position: top;
  background-size: cover;
  border-radius: 10px;
  display: grid;
  grid-template-rows: auto;
  margin: 16px;
  padding: 16px;
  position: relative;
}

.r-panel--edito r-panel--header {
  border-bottom: 1px solid black;
  color: black;
  margin: 0 32px;
  padding: 8px 0 16px;
}

.r-panel--edito r-panel--header h3 {
  margin: 0;
  font-weight: 700;
}

.r-panel--edito r-panel--header h3 a {
  color: #0e0d0e;
}

.r-panel--edito r-panel--header h3 a:hover {
  color: #0e0d0e;
}

.r-panel--edito r-panel--header address {
  color: black;
  font-family: "Work Sans", arial, sans-serif;
}

.r-panel--edito r-panel--body {
  align-self: center;
  text-align: center;
  padding: 0 16px;
}

.r-panel--edito r-panel--img {
  align-self: start;
}

.r-panel--edito r-panel--img figure {
  margin: 0;
}

.-is-kroll.r-panel--image {
  box-shadow: 0 0 3px #f3f3f3;
}

.arche .-is-kroll.r-panel--image {
  grid-column: auto;
  grid-row: auto;
}

.-is-kroll.r-panel--image r-panel--header {
  margin-bottom: 16px;
  padding-top: 8px;
}

.-is-kroll .r-viewmode--article,
.-is-kroll .r-panel--item {
  padding: 0 16px;
}

.r-anonyme .-is-kroll .r-viewmode--article,
.r-connecte-sd .-is-kroll .r-viewmode--article,
.r-anonyme
  .-is-kroll .r-panel--item,
.r-connecte-sd
  .-is-kroll .r-panel--item {
  background-repeat: no-repeat;
  background-position: center center;
}

.-is-kroll .r-article--img {
  margin: 0 16px;
}

.-is-kroll .r-img:hover {
  transform: none;
}

.r-panel--chronique r-panel--header,
.r-panel--edito r-panel--header,
.r-panel--titre r-panel--header,
.-is-kroll r-panel--header {
  display: block;
  font-size: 20px;
  justify-content: center;
  text-transform: none;
}

.r-panel--chronique r-panel--header::before,
.r-panel--chronique r-panel--header::after,
.r-panel--edito r-panel--header::before,
.r-panel--edito r-panel--header::after,
.r-panel--titre r-panel--header::before,
.r-panel--titre r-panel--header::after,
.-is-kroll r-panel--header::before,
.-is-kroll r-panel--header::after {
  content: none;
}

.r-panel--chronique .r-icon--abonne,
.r-panel--edito .r-icon--abonne,
.r-panel--titre .r-icon--abonne,
.-is-kroll .r-icon--abonne {
  margin-right: 8px;
}

.r-panel--chronique address,
.r-panel--chronique .r-author--link,
.r-panel--titre address,
.r-panel--titre .r-author--link,
.-is-kroll address,
.-is-kroll .r-author--link {
  color: #e6e6e6;
}

.r-mini-panel--services r-panel--header {
  background-repeat: no-repeat;
  background-position: bottom left;
  text-indent: -999em;
}

.r-panel--boutons .r-panel--link {
  flex: 1;
}

.r-panel--titre {
  box-shadow: 0 0 4px #f3f3f3;
  padding: 16px;
  text-align: center;
}

.r-panel--top-titre {
  box-shadow: 0 0 0 16px white;
  grid-column-start: 1;
  grid-column-end: -1;
  margin-bottom: 16px;
  padding: 16px 16px 48px;
  padding-top: 32px;
  box-shadow: none;
}

.r-mini-panel--direct .r-panel--top-titre {
  background: #F3F8FF;
  box-shadow: none;
  color: #0e0d0e;
  display: block;
  margin-bottom: 8px;
  padding: 8px 16px;
  background: #fff;
}

.r-mini-panel--direct .r-panel--top-titre h3 {
  counter-increment: mostread;
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-template-rows: auto;
  grid-gap: 16px;
}

.r-mini-panel--direct .r-panel--top-titre h3::before {
  color: #6a8c1f;
  content: counter(mostread);
  display: flex;
  font-family: "Work Sans", arial, sans-serif;
  font-size: 3.5rem;
  font-weight: 400;
  justify-content: center;
  line-height: 1;
  width: 100%;
}

.r-panel--top-titre r-panel--list {
  counter-reset: mostread;
}

.r-panel--top-titre .r-panel--item {
  counter-increment: mostread;
  display: grid;
  grid-template-areas: 'num img titre';
  grid-template-columns: 2fr 3fr 4fr;
  margin-bottom: -1px;
  margin-top: 16px;
  padding-bottom: 16px;
  grid-template-columns: 2fr 4fr 5fr;
  grid-template-areas: 'num img titre' 'num img meta';
  grid-template-rows: min-content;
}

.r-panel--top-titre .r-panel--item::before {
  content: counter(mostread);
  display: flex;
  font-family: "Work Sans", arial, sans-serif;
  font-size: 3.5rem;
  font-weight: 400;
  grid-area: num;
  line-height: 1;
  text-align: center;
}

.r-panel--top-titre .r-panel--item::before {
  color: #a0ca4b;
  font-weight: 700;
}

.r-panel--top-titre .r-panel--item r-panel--meta {
  font-size: 0.9rem;
  margin-top: 8px;
  grid-area: meta;
  text-transform: uppercase;
}

.r-panel--top-titre .r-panel--item r-panel--meta .r-panel--section {
  font-weight: 700;
}

.r-panel--top-titre .r-panel--item r-panel--meta .r-pubdate {
  color: #0e0d0e;
}

.r-panel--top-titre .r-panel--img {
  grid-area: img;
  margin-right: 16px;
}

.r-panel--top-titre figure {
  margin-bottom: 0;
}

.r-panel--top-titre .r-panel--title {
  font-size: 1.2rem;
  grid-area: titre;
  margin-bottom: 0;
  margin-top: 0;
}

.r-panel--top-titre .r-panel--title .r-panel--link {
  display: inline;
}

.r-panel--top-titre address {
  grid-area: meta;
  grid-column-end: -1;
  grid-column-start: 1;
  margin-top: 8px;
  text-align: right;
}

.r-panel--horizontal r-panel--list,
.r-panel--wrap r-panel--list {
  display: flex;
  overflow: auto;
}

.r-panel--horizontal .r-panel--title,
.r-panel--wrap .r-panel--title {
  margin: 0;
  font-size: 1.05rem;
}

.r-panel--horizontal r-panel--footer,
.r-panel--wrap r-panel--footer {
  padding-top: 0;
}

.arche .r-panel--wrap .r-panel--item {
  flex: 0 0 25%;
  max-width: 25%;
}

.r-panel--section-description {
  grid-column-start: 1;
  grid-column-end: -1;
  margin-bottom: 32px;
}

.r-panel--section-description h2 {
  margin-bottom: 32px;
}

.r-panel--edition-immo {
  background: #ffe500;
}

.arche .r-panel--edition-immo {
  grid-template-areas: '. .';
  grid-template-columns: none;
  margin-left: 0;
  width: auto;
}

.r-panel--edition-immo label {
  display: block;
  font-size: 0.9rem;
  margin-bottom: 12px;
}

.r-panel--edition-immo .r-form--group {
  position: relative;
}

.r-panel--edition-immo input {
  border-radius: 5px;
  border: none;
  height: 45px;
  padding: 12px 48px 12px 16px;
  width: 100%;
}

.r-panel--edition-immo .r-search--immo-submit {
  align-items: center;
  background: #ffe500;
  border-radius: 5px;
  color: white;
  cursor: auto;
  display: flex;
  font-size: 24px;
  height: 39px;
  justify-content: center;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 3px;
  top: 3px;
  width: 39px;
}

.r-panel--edition-immo img {
  display: none;
}

.arche .r-panel--edition-immo img {
  display: none;
}

.r-panel--edition-immo .r-btn {
  background-color: #fff49e;
  color: #0e0d0e;
  grid-row: 2;
  grid-column: 2;
  justify-self: baseline;
}

.r-panel--edition-immo .-is-location-set {
  display: none;
}

.r-panel--produits .r-panel--item {
  background: radial-gradient(ellipse closest-side at 50% 70%, #0e0d0e, #6a8c1f);
  border-radius: 10px;
  overflow: hidden;
  padding: 44px 0 0;
  position: relative;
}

.r-panel--produits .r-article--img {
  display: block;
  margin: auto;
  width: 65%;
}

.r-panel--produits .r-panel--title {
  padding-bottom: 12px;
}

.r-panel--produits i-article {
  background: #f3f3f3;
  bottom: 0;
  display: block;
  padding: 16px;
  position: absolute;
  text-align: center;
  width: 100%;
}

.pane-enrichissement {
  padding-bottom: 32px;
  grid-column-start: 1;
  grid-column-end: -1;
}

r-pager {
  padding: 32px 16px;
}

.r-pager--dynamic {
  display: flex;
  list-style: none;
  padding: 0;
}

.r-pager--dynamic span,
.r-pager--dynamic .r-pager--link {
  align-items: center;
  border: 1px solid #f3f3f3;
  border-radius: 100%;
  display: flex;
  height: 32px;
  justify-content: center;
  padding: 0;
  width: 32px;
}

.r-pager--dynamic .r-pager--link {
  border: 1px solid black;
}

.r-pager--dynamic span {
  background-color: white;
  color: #e6e6e6;
  margin: 3.2px;
}

.r-pager--dynamic .r-pager--select button {
  align-items: center;
  background-color: white;
  border: 1px solid #f3f3f3;
  border-radius: 32px;
  display: flex;
  height: 32px;
  justify-content: center;
  margin: 3.2px;
  padding: 0 24px 0 8px;
  position: relative;
  width: 64px;
  border: 1px solid black;
}

.r-pager--dynamic .r-pager--select button::after {
  content: "\ea03";
  font-family: 'main';
  font-size: 0.9rem;
  position: absolute;
  transform: rotate(90deg);
  transition: all .3s ease-in-out;
  right: 12.8px;
  top: 6.4px;
}

.r-pager--dynamic .r-pager--select.-is-toggle button::after {
  transform: rotate(-90deg) translateX(1px);
}

.r-pager--dynamic .r-pager--select.-is-toggle .r-pager--sublist {
  height: 260px;
  overflow: scroll;
  border: 1px solid #f3f3f3;
  border: 1px solid black;
  padding: 0 16px;
  z-index: 10;
}

.r-pager--dynamic .r-pager--select.-is-toggle li {
  border-bottom: 1px solid white;
  border-bottom: 1px solid black;
}

.r-pager--dynamic .r-pager--sublist {
  background-image: linear-gradient(to bottom, white 80%, rgba(0, 0, 0, 0.1));
  background-color: white;
  border-radius: 32px;
  display: flex;
  flex-wrap: wrap;
  height: 0;
  list-style: none;
  left: 50%;
  margin-top: 8px;
  overflow: hidden;
  transform: translateX(-50%);
  transition: all .3s ease-in-out;
  padding: 0;
  position: absolute;
  width: 200px;
  z-index: 12;
  border-radius: 0;
}

.r-pager--dynamic .r-pager--sublist li {
  flex: 0 0 100%;
  transition: all .3s ease-in-out;
  padding: 3.2px 0;
}

.r-pager--dynamic .r-pager--sublist .r-pager--link {
  border: 0;
  margin: auto;
  width: 64px;
}

.r-pager--dynamic .r-pager--active {
  font-weight: 700;
}

.r-pager--link {
  box-shadow: none;
  color: #0e0d0e;
  margin: 3.2px;
}

.r-panel--paywall {
  background-color: white;
  margin: 64px 0 64px;
  position: relative;
  text-align: center;
}

.r-panel--paywall::before {
  content: '';
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 75%);
  height: 100%;
  position: absolute;
  left: 0px;
  top: -330px;
  width: 100%;
}

.r-panel--paywall r-paywall--content {
  color: black;
}

.r-panel--paywall r-paywall--btns {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 16px;
  justify-content: center;
  margin-bottom: 16px;
}

.r-panel--paywall r-paywall--btns .r-btn--primary {
  background: #a0ca4b;
  border: none;
  font-size: 2rem;
  font-weight: bold;
  padding: 12px 20px;
}

.r-panel--paywall r-paywall--footer {
  background: black;
  color: white;
  display: block;
  margin-top: 24px;
  padding: 28px 0 20px 0;
}

.r-panel--paywall r-paywall--footer p {
  font-size: 1.05rem;
  font-weight: 700;
}

.r-panel--paywall r-paywall--footer .r-btn--secondary {
  background: white;
  color: black;
  font-size: 1.05rem;
  font-weight: bold;
  padding: 8px 20px;
}

.r-panel--paywall r-paywall--footer .r-btn--secondary:hover {
  opacity: .8;
}

.rossel-leader-middle,
.rossel-leader-bottom {
  border-bottom: 16px solid white;
  border-top: 16px solid white;
  border: none;
}

.rossel-leader-bottom > div {
  min-height: 0;
}

.r-pub--droite,
.r-pub--gauche {
  grid-row: auto / span 2;
}

.rossel-imu-top {
  grid-area: imu-top;
}

.rossel-native-top {
  grid-area: inread-top;
}

[class*='rossel-native-'] {
  background: white;
}

[class*='rossel-native-'] > div {
  width: 100%;
  padding: 16px;
}

[class*='rossel-native-'] > div::before {
  display: none;
}

.-has-bg [class*='rossel-native-'],
.-has-bg-light [class*='rossel-native-'],
.-has-bg-dark [class*='rossel-native-'] {
  background: none;
}

.r-search--widget {
  background: #F4F4F4;
  border-radius: 5px;
  display: flex;
  font-size: 1.05rem;
  margin: 16px 16px 0;
  padding: 4px;
}

.r-search--widget::before {
  color: black;
  content: "\ea16";
  font-family: main;
  font-size: 1.5rem;
  padding: 0 12px;
  vertical-align: middle;
}

.r-search--widget .r-search--input {
  background: none;
  border: 0;
  box-shadow: none;
  color: #9f9f9f;
  font-size: 0.8rem;
  height: auto;
  outline: none;
  padding: 0;
  width: 100%;
}

.r-search--widget .r-search--input::placeholder {
  color: #9f9f9f;
  font-family: "Open Sans", arial, sans-serif;
}

.r-search--widget .r-search--input:focus + .r-search--submit {
  display: block;
}

.r-search--widget .r-search--submit {
  background: #6a8c1f;
  border: 0;
  border-radius: 20px;
  color: white;
  cursor: pointer;
  display: none;
  font-size: 0.75rem;
  padding: 3px 12px;
}

.r-search--widget .r-search--submit:hover {
  display: block;
}

.r-search--widget .r-search--submit:focus {
  outline: none;
}

r-selligent {
  display: block;
}

r-selligent h3 {
  font-family: "Open Sans", arial, sans-serif;
}

.r-selligent--body {
  margin: 16px;
  padding: 24px;
}

.r-selligent--article-mobile .r-selligent--body,
.r-selligent--article-desktop .r-selligent--body,
.r-selligent--imu-bottom .r-selligent--body,
.r-selligent--imu .r-selligent--body,
.r-selligent--agenda .r-selligent--body {
  color: white;
  height: calc(100% - 32px);
  background-color: #6a8c1f;
  background-color: #a0ca4b;
  border-radius: 10px;
  color: black;
}

.r-selligent--article-mobile .r-selligent--body .r-selligent--title,
.r-selligent--article-desktop .r-selligent--body .r-selligent--title,
.r-selligent--imu-bottom .r-selligent--body .r-selligent--title,
.r-selligent--imu .r-selligent--body .r-selligent--title,
.r-selligent--agenda .r-selligent--body .r-selligent--title {
  display: grid;
  grid-template-columns: 3fr 2fr;
}

.r-selligent--article-mobile .r-selligent--body h6,
.r-selligent--article-desktop .r-selligent--body h6,
.r-selligent--imu-bottom .r-selligent--body h6,
.r-selligent--imu .r-selligent--body h6,
.r-selligent--agenda .r-selligent--body h6 {
  margin: 0;
}

.r-selligent--article-mobile .r-selligent--body p,
.r-selligent--article-desktop .r-selligent--body p,
.r-selligent--imu-bottom .r-selligent--body p,
.r-selligent--imu .r-selligent--body p,
.r-selligent--agenda .r-selligent--body p {
  margin-top: 24px;
}

.r-selligent--article-mobile .r-selligent--body r-icon,
.r-selligent--article-desktop .r-selligent--body r-icon,
.r-selligent--imu-bottom .r-selligent--body r-icon,
.r-selligent--imu .r-selligent--body r-icon,
.r-selligent--agenda .r-selligent--body r-icon {
  color: white;
  font-size: 4rem;
  justify-self: flex-end;
}

.r-selligent--article-mobile svg,
.r-selligent--article-desktop svg,
.r-selligent--imu-bottom svg,
.r-selligent--imu svg,
.r-selligent--agenda svg {
  margin-bottom: 16px;
  width: 90px;
}

.r-selligent--article-mobile h3,
.r-selligent--article-desktop h3,
.r-selligent--imu-bottom h3,
.r-selligent--imu h3,
.r-selligent--agenda h3 {
  font-size: 1.3rem;
}

.r-selligent--imu {
  grid-area: selligent;
}

.r-selligent--agenda {
  grid-area: agenda;
}

.r-selligent--agenda .r-selligent--body r-icon {
  font-size: 5rem;
}

.r-selligent--agenda .r-selligent--body h4 {
  font-weight: 400;
  margin-right: 30%;
}

.r-selligent--bandeau {
  grid-column-start: 1;
  grid-column-end: -1;
  margin-top: -1px;
}

.r-selligent--bandeau .r-selligent--body {
  align-items: center;
  background-color: #f3f3f3;
  border-radius: 15px;
  color: #6a8c1f;
}

.r-selligent--bandeau .r-selligent--intro {
  margin-right: 32px;
}

.r-selligent--bandeau h3 {
  font-size: 1.3rem;
}

.r-selligent--menu {
  display: none;
  margin: 8px;
}

.r-selligent--newsletter {
  margin: 8px auto 8px;
  max-width: 662px;
}

.r-selligent--newsletter h3 {
  align-items: center;
  display: flex;
  font-family: "Work Sans", arial, sans-serif;
  font-size: 1.3rem;
  color: #6a8c1f;
}

.r-selligent--newsletter h3::after {
  content: '';
  display: block;
  height: 1px;
  margin-left: 16px;
  width: 100%;
  background: #6a8c1f;
}

.r-selligent--newsletter .r-btn {
  white-space: nowrap;
}

.r-selligent--newsletter p {
  margin: 0 16px 0 0;
}

.r-selligent--newsletter .r-selligent--body {
  align-items: center;
  display: flex;
  margin: 0;
  padding: 0 0 16px;
  border-bottom: 1px solid #6a8c1f;
}

.-has-header-fixed .reabon {
  position: fixed;
  left: 0;
  right: 0;
  top: 35px;
  z-index: 90;
}

[data-scrolldir='up'] .-has-header-fixed .reabon {
  top: 115px;
}

[data-scrolldir='up'] .page-node:not(.-is-grand-format).-has-header-fixed .reabon {
  top: 115px;
}

[data-scrolldir='down'] .page-node:not(.-is-grand-format).-has-header-fixed .reabon {
  top: 0;
}

.r-selligent--overlay {
  font-family: "Open Sans", arial, sans-serif;
}

.r-selligent--overlay .r-selligent--overlay-container {
  display: none;
  font-family: "Open Sans", arial, sans-serif;
  position: relative;
  z-index: 156;
}

.r-selligent--overlay .r-selligent--overlay-container.show {
  display: block;
}

.r-selligent--overlay .r-selligent--overlay-background {
  background-color: rgba(0, 0, 0, 0.3);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.r-selligent--overlay .r-selligent--overlay-content {
  background-color: white;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  padding: 32px;
  position: fixed;
  text-align: center;
  width: 100%;
}

.r-selligent--overlay .r-selligent--overlay-content-center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.r-selligent--overlay .r-selligent--overlay-content-bottom {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.r-selligent--overlay .r-selligent--overlay-content-bottom-right {
  bottom: 0;
  right: 0;
}

.r-selligent--overlay .r-icon--cancel {
  cursor: pointer;
  font-size: .7rem;
  padding: 16px;
  position: absolute;
  right: 0;
  top: 0;
}

.r-selligent--overlay-btn-active {
  background-color: #0e0d0e;
  bottom: 10%;
  color: #0e0d0e;
  cursor: pointer;
  font-weight: bold;
  position: fixed;
  right: 0;
  writing-mode: vertical-rl;
  z-index: 157;
}

.r-selligent--overlay-btn-active a {
  color: #0e0d0e;
  padding: 8px;
}

.r-selligent--overlay-btn-active .r-icon--cancel {
  background: #0e0d0e;
  border: 2px solid white;
  border-radius: 50%;
  font-size: 0.5rem;
  left: 0;
  padding: 8px;
  right: auto;
  transform: translate(-50%, -50%);
}

.first-question-satisfactory {
  border: none;
  margin: 0;
  padding: 0;
}

.first-question-satisfactory .title_first_question {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 8px;
  margin-top: 0;
}

.first-question-satisfactory .subtitle_first_question {
  font-size: 1rem;
  font-weight: normal;
  margin-bottom: 16px;
  margin-top: 0;
}

.rating .r-btn {
  margin-top: 8px;
  opacity: 0;
  pointer-events: none;
}

.rating .r-btn.show {
  opacity: 1;
  pointer-events: all;
}

.rating_stars form {
  direction: rtl;
  text-align: center;
}

.rating_stars input.star {
  display: none;
}

.rating_stars label.star {
  cursor: pointer;
  display: inline-block;
  font-size: 30px;
  line-height: 1;
  padding: 8px;
  position: relative;
  transition: all .3s;
}

.rating_stars .link_satisfactory {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.rating_stars input.star:checked ~ label.star:before,
.rating_stars input.star:hover ~ label.star:before {
  content: "\ea2c";
  transition: all .25s;
}

.rating_stars label.star:before {
  color: #6a8c1f;
  content: "\ea2a";
  font-family: 'main';
}

.rating_nps form {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  text-align: center;
}

.rating_nps input.nps {
  display: none;
}

.rating_nps label.nps {
  background: white;
  border: 1px solid white;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  height: 32px;
  line-height: 1;
  padding: 8px;
  position: relative;
  transition: all .3s;
  width: 32px;
}

.rating_nps label.nps:hover .link-nps,
.rating_nps label.nps:focus .link-nps {
  color: white;
}

.rating_nps input.nps-1:checked + label.nps-1,
.rating_nps input.nps-2:checked + label.nps-2,
.rating_nps input.nps-3:checked + label.nps-3,
.rating_nps input.nps-4:checked + label.nps-4,
.rating_nps input.nps-5:checked + label.nps-5,
.rating_nps input.nps-6:checked + label.nps-6,
.rating_nps label.nps-1:hover,
.rating_nps label.nps-1:focus,
.rating_nps label.nps-2:hover,
.rating_nps label.nps-2:focus,
.rating_nps label.nps-3:hover,
.rating_nps label.nps-3:focus,
.rating_nps label.nps-4:hover,
.rating_nps label.nps-4:focus,
.rating_nps label.nps-5:hover,
.rating_nps label.nps-5:focus,
.rating_nps label.nps-6:hover,
.rating_nps label.nps-6:focus {
  background: #fa5454;
  color: white;
}

.rating_nps input.nps-7:checked + label.nps-7,
.rating_nps input.nps-8:checked + label.nps-8,
.rating_nps label.nps-7:hover,
.rating_nps label.nps-7:focus,
.rating_nps label.nps-8:hover,
.rating_nps label.nps-8:focus {
  background: #FAA154;
  color: white;
}

.rating_nps input.nps-9:checked + label.nps-9,
.rating_nps input.nps-10:checked + label.nps-10,
.rating_nps label.nps-9:hover,
.rating_nps label.nps-9:focus,
.rating_nps label.nps-10:hover,
.rating_nps label.nps-10:focus {
  background: #57cb4a;
  color: white;
}

.rating_nps .link_nps {
  align-items: center;
  color: inherit;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.rating_nps .link_nps:hover {
  text-decoration: none;
}

.r-viewmode--o-list article,
.r-viewmode--list article {
  display: grid;
}

.r-viewmode--o-list .r-article--title::before,
.r-viewmode--list .r-article--title::before {
  content: '';
  display: block;
  float: right;
  height: 20px;
  width: 20px;
}

.r-viewmode--o-list .r-article--link,
.r-viewmode--list .r-article--link {
  display: inline;
}

.r-viewmode--o-panel .r-article--section,
.r-viewmode--panel .r-article--section,
.r-viewmode--o-panel-left .r-article--section,
.r-viewmode--panel-left .r-article--section,
.r-viewmode--o-panel-3row .r-article--section,
.r-viewmode--panel-3row .r-article--section,
.r-viewmode--panel-center .r-article--section {
  margin-top: 8px;
  font-weight: 700;
}

.r-viewmode--o-panel i-article,
.r-viewmode--panel i-article,
.r-viewmode--o-panel-left i-article,
.r-viewmode--panel-left i-article,
.r-viewmode--o-panel-3row i-article,
.r-viewmode--panel-3row i-article,
.r-viewmode--panel-center i-article {
  display: block;
  position: relative;
  z-index: 1;
  background: white;
  margin-top: -80px;
  padding: 16px;
}

.r-viewmode--o-panel-left i-article,
.r-viewmode--panel-left i-article,
.r-viewmode--o-panel-3row i-article,
.r-viewmode--panel-3row i-article {
  display: block;
  position: relative;
  z-index: 1;
  background: white;
  color: #0e0d0e;
  margin-top: -80px;
  padding: 16px;
}

.-has-bg .r-viewmode--o-panel-left i-article .r-article--link,
.-has-bg
  .r-viewmode--panel-left i-article .r-article--link,
.-has-bg
  .r-viewmode--o-panel-3row i-article .r-article--link,
.-has-bg
  .r-viewmode--panel-3row i-article .r-article--link {
  color: #0e0d0e;
}

.-has-bg .r-viewmode--o-panel-left i-article .r-author--link,
.-has-bg
  .r-viewmode--panel-left i-article .r-author--link,
.-has-bg
  .r-viewmode--o-panel-3row i-article .r-author--link,
.-has-bg
  .r-viewmode--panel-3row i-article .r-author--link {
  color: #6a8c1f;
}

.r-viewmode--o-panel-left .r-article--list,
.r-viewmode--panel-left .r-article--list,
.r-viewmode--o-panel-3row .r-article--list,
.r-viewmode--panel-3row .r-article--list {
  margin-top: 24px;
}

.r-viewmode--o-panel-left r-article--section,
.r-viewmode--panel-left r-article--section,
.r-viewmode--o-panel-3row r-article--section,
.r-viewmode--panel-3row r-article--section {
  color: #6a8c1f;
}

.front-maga.arche .r-viewmode--o-panel,
.front-maga.arche
.r-viewmode--panel,
.front-maga.arche
.r-viewmode--o-panel-left,
.front-maga.arche
.r-viewmode--panel-left {
  grid-column: auto / span 1;
  grid-row: auto / span 1;
}

.r-viewmode--o-panel .r-article--title,
.r-viewmode--panel .r-article--title,
.r-viewmode--o-panel-left .r-article--title,
.r-viewmode--panel-left .r-article--title {
  font-weight: 700;
}

.r-viewmode--o-panel r-article--chapo,
.r-viewmode--panel r-article--chapo,
.r-viewmode--o-panel-left r-article--chapo,
.r-viewmode--panel-left r-article--chapo {
  display: block;
  margin-top: 8px;
}

.is-page-immo .r-link--immo {
  align-items: center;
  background: #ffe500;
  color: #0e0d0e;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  justify-content: center;
  height: 165px;
  padding: 16px;
  text-align: center;
}

.is-page-immo .r-link--immo r-icon {
  font-size: 2rem;
  margin-bottom: 8px;
}

.is-page-immo .r-link--immo span {
  align-items: center;
  display: flex;
}

.is-page-immo .r-link--immo span::after {
  content: "\ea03";
  font-family: 'main';
  font-size: 0.9rem;
  margin-left: 8px;
}

.r-link--grid-immo {
  display: grid;
  grid-gap: 16px;
  grid-template-areas: '.';
  grid-template-columns: 100%;
  grid-column: 1/3;
  padding: 16px 0;
}

.arche .r-viewmode--o-panel-3row,
.arche
.r-viewmode--panel-3row {
  grid-column: auto / span 2;
  grid-row: auto / span 4;
}

.r-viewmode--o-panel-3row r-article--chapo,
.r-viewmode--panel-3row r-article--chapo {
  margin-top: 8px;
}

.arche .r-viewmode--o-panel-center,
.arche
.r-viewmode--panel-center {
  grid-column: auto / span 2;
  grid-row: auto / span 2;
}

.r-viewmode--o-panel-center .r-icon--abonne + .r-alerte,
.r-viewmode--panel-center .r-icon--abonne + .r-alerte {
  margin-left: 8px;
}

.r-viewmode--o-panel-center .r-article--title,
.r-viewmode--panel-center .r-article--title {
  font-weight: 700;
}

.r-viewmode--o-panel-center .r-article--img,
.r-viewmode--panel-center .r-article--img {
  margin-right: -16px;
  margin-left: -16px;
}

.r-viewmode--o-panel-center r-article--chapo,
.r-viewmode--panel-center r-article--chapo {
  display: block;
  margin-top: 8px;
}

.r-viewmode--o-panel-center strong,
.r-viewmode--panel-center strong {
  width: 100%;
}

.r-viewmode--o-panel-center .r-article--list,
.r-viewmode--panel-center .r-article--list {
  margin-top: 24px;
  text-align: left;
}

.r-viewmode--entreprise {
  border: 1px solid #209e97;
  border-radius: 16px;
  margin: 16px;
  padding: 16px 32px;
}

.r-viewmode--entreprise .r-entreprise-heading {
  border-radius: 12px;
  margin: 0 -16px;
  position: relative;
  z-index: 10;
}

.r-viewmode--entreprise .r-logo--entreprise {
  background: white;
  border: 2px solid white;
  border-radius: 8px;
  height: 90px;
  margin-top: -45px;
  position: relative;
  width: 90px;
  z-index: 15;
}

.r-viewmode--entreprise .r-logo--entreprise img {
  max-height: 100%;
  max-width: 100%;
}

.r-viewmode--entreprise h2 {
  color: #209e97;
}

.r-viewmode--entreprise h2 a {
  color: #209e97;
}

.r-viewmode--entreprise p {
  color: #e6e6e6;
  font-size: 1.05rem;
  margin: 0;
}

.r-viewmode--entreprise p r-icon {
  color: #209e97;
  font-size: 1rem;
  vertical-align: middle;
  text-align: center;
  width: 20px;
}

.r-viewmode--entreprise p a {
  color: #209e97;
  font-weight: 700;
}

.r-viewmode--entreprise r-btn {
  font-weight: 700;
  margin-top: 12px;
}

.r-viewmode--entreprise r-btn r-icon {
  font-size: 0.75rem;
  margin-left: 8px;
  vertical-align: middle;
}

.r-viewmode--entreprise .r-btn--primary {
  background: #209e97;
  border: 1px solid #209e97;
}

.r-viewmode--cta {
  align-items: center;
  background: #cbebe9;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 16px;
  padding: 16px;
  text-align: center;
}

.r-viewmode--cta h2 {
  color: #209e97;
  margin: 0;
}

.r-viewmode--cta p {
  font-size: 1.05rem;
  margin: 16px 16px 24px;
}

.r-viewmode--cta .r-btn {
  font-size: 1.2rem;
}

.r-viewmode--cta .r-btn--primary {
  background: #209e97;
  border: 1px solid #209e97;
}

.r-viewmode--agenda h2 {
  line-height: 1.2;
}

.r-viewmode--agenda h2 a {
  color: #0e0d0e;
}

.r-viewmode--agenda h2 a strong {
  color: #6a8c1f;
  display: block;
}

.r-viewmode--image-title {
  grid-column: 1 / -1;
  position: relative;
}

.r-viewmode--more {
  display: contents;
}

.viewmode-horizon-more1 .r-viewmode--more > article:nth-of-type(1n+21),
.viewmode-horizon-more1 .r-viewmode--more
> r-viewmode:nth-of-type(1n+21) {
  display: none;
}

.viewmode-horizon-more2 .r-viewmode--more > article:nth-of-type(1n+41),
.viewmode-horizon-more2 .r-viewmode--more
> r-viewmode:nth-of-type(1n+41) {
  display: none;
}

.r-viewmode--o-dossier .r-article--title,
.r-viewmode--dossier .r-article--title {
  position: relative;
  margin-top: 0;
}

.r-viewmode--o-dossier r-article--chapo,
.r-viewmode--dossier r-article--chapo {
  margin-top: 8px;
}

.r-viewmode--horizontal,
.r-viewmode--o-horizontal {
  grid-column: span 3;
  grid-column-end: -1;
}

.r-page--shopping.arche .r-viewmode--horizontal,
.r-page--shopping.arche
  .r-viewmode--o-horizontal {
  grid-column: span 2 / -1;
}

.r-viewmode--horizontal article,
.r-viewmode--o-horizontal article {
  column-gap: 16px;
  display: grid;
  grid-template-areas: 'titre img' 'chapo img' 'meta img';
  grid-template-columns: 70% 30%;
  grid-template-rows: auto auto 1fr;
}

.r-viewmode--horizontal .r-article--img,
.r-viewmode--o-horizontal .r-article--img {
  aspect-ratio: 16/9;
  grid-area: img;
}

.r-viewmode--horizontal figure,
.r-viewmode--o-horizontal figure {
  margin-bottom: 0;
}

.r-viewmode--horizontal .r-article--meta,
.r-viewmode--o-horizontal .r-article--meta {
  grid-area: meta;
}

.r-viewmode--horizontal .r-article--title,
.r-viewmode--o-horizontal .r-article--title {
  grid-area: titre;
}

.r-viewmode--horizontal r-article--chapo,
.r-viewmode--o-horizontal r-article--chapo {
  grid-area: chapo;
}

.r-viewmode--horizontal .r-viewmode--article,
.r-viewmode--o-horizontal .r-viewmode--article {
  grid-template-columns: 70% 28%;
}

.r-viewmode--horizontal .r-viewmode--article .r-article--img,
.r-viewmode--o-horizontal .r-viewmode--article .r-article--img {
  max-width: 315px;
  justify-self: stretch;
}

.r-viewmode--horizontal .r-viewmode--article .r-article--title,
.r-viewmode--o-horizontal .r-viewmode--article .r-article--title {
  margin-top: 0;
}

.r-viewmode--agenda article {
  background-color: #F8F8F8;
  border-radius: 6px;
  display: grid;
  grid-template-rows: min-content auto auto;
  height: 100%;
  overflow: hidden;
}

.r-viewmode--agenda address {
  color: #6a8c1f;
  font-weight: 700;
  grid-area: location;
}

.r-viewmode--agenda address r-icon {
  margin-right: 8px;
}

.r-viewmode--agenda time {
  color: #e6e6e6;
  grid-area: date;
}

.r-viewmode--agenda .r-article--title {
  font-size: 1.3rem;
  padding: 0 12px 8px;
}

.r-viewmode--agenda .r-article--meta-link {
  grid-area: link;
  justify-self: end;
}

.r-viewmode--agenda .r-article--no-picture {
  align-items: center;
  aspect-ratio: 16/9;
  background: #6a8c1f;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 24px;
  padding: 12px;
  row-gap: 8px;
  text-align: center;
}

.r-viewmode--agenda .r-article--no-picture:hover {
  text-decoration: none;
}

.r-viewmode--agenda .r-article--title-no-picture {
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
}

.r-viewmode--agenda r-article--meta {
  align-items: center;
  align-self: end;
  column-gap: 16px;
  display: grid;
  grid-template-areas: "location link" "date link";
  padding: 0 12px 12px;
  row-gap: 8px;
}

.r-viewmode--agenda .r-icon--fleche {
  border: 1px solid #0e0d0e;
  border-radius: 4px;
  color: #0e0d0e;
  display: inline-block;
  padding: 8px;
}

.r-viewmode--agenda .r-article--link {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.r-selligent--banniere-container {
  background-color: #6a8c1f;
}

.r-selligent--banniere-surf.r-selligent--body {
  border-color: #6a8c1f;
}

.r-selligent--banniere-surf h3 {
  color: #6a8c1f;
}

.r-selligent--megamenu-container {
  display: contents;
}

.r-selligent--megamenu-container .r-selligent--megamenu-content {
  background-color: #dff0ba;
  margin-top: 32px;
  padding: 32px;
}

.r-selligent--megamenu-container .r-selligent--title {
  align-items: center;
  display: block;
  margin-top: -48px;
}

.r-selligent--megamenu-container .r-selligent--logo-carre::before {
  content: url("/sites/all/themes/enacarbon_sb/images/logo-carre-plein.svg");
  display: block;
  margin: auto;
  width: 80px;
}

.r-selligent--megamenu-container h2 {
  color: #6a8c1f;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.r-selligent--megamenu-container ul {
  padding-left: 26px;
}

.r-selligent--megamenu-container li {
  color: #e6e6e6;
  font-size: 16px;
  list-style: square;
}

.r-selligent--megamenu-container li::marker {
  color: #6a8c1f;
  font-size: 2rem;
}

.r-selligent--megamenu-container .r-selligent--megamenu-btn {
  align-items: center;
  background-color: #cce790;
  display: flex;
  justify-content: center;
  padding: 16px;
}

.r-selligent--megamenu-container .r-selligent--megamenu-sticky {
  bottom: 0;
  position: sticky;
}

.r-selligent--megamenu-container .r-selligent--baseline {
  color: #6a8c1f;
  font-size: 12px;
  padding-right: 8px;
}

.r-selligent--megamenu-container .r-btn {
  font-size: 11px;
}

.r-selligent--pave-newsletter-container .r-selligent--pave-content {
  align-items: flex-start;
  border-radius: 10px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
}

.r-selligent--pave-newsletter-container .r-selligent--title {
  align-items: flex-start;
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  margin-bottom: 32px;
}

.r-selligent--pave-newsletter-container h2 {
  font-size: 1rem;
  margin-top: 0;
  padding-right: 16px;
}

.r-selligent--pave-newsletter-container r-icon {
  font-size: 3rem;
  margin-bottom: 16px;
}

.r-selligent--pave-newsletter-container .logo_horizontal {
  height: auto;
  margin-bottom: 16px;
  width: 120px;
}

.r-selligent--pave-newsletter-container .logo_carre {
  height: auto;
  margin-bottom: 16px;
  width: 50px;
}

.r-selligent--pave-newsletter-container .r-selligent--baseline {
  font-size: 1.2rem;
  display: block;
  margin-bottom: 16px;
}

.r-selligent--pave-newsletter-container .r-btn {
  border-radius: 5px;
}

.r-selligent--pave-newsletter-container .r-btn.r-btn--black {
  background-color: #0e0d0e;
  color: white;
}

.r-selligent--pave-newsletter-container .r-btn.r-btn--white {
  background-color: white;
  color: #0e0d0e;
}

.r-selligent--under-rs-newsletter-container .r-selligent--under-rs-content {
  align-items: flex-start;
  border-radius: 10px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
}

.r-selligent--under-rs-newsletter-container .r-selligent--title {
  align-items: flex-start;
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  margin-bottom: 32px;
}

.r-selligent--under-rs-newsletter-container h2 {
  font-size: 1rem;
  margin-top: 0;
  padding-right: 16px;
}

.r-selligent--under-rs-newsletter-container r-icon {
  font-size: 3rem;
  margin-bottom: 16px;
}

.r-selligent--under-rs-newsletter-container .logo_horizontal {
  height: auto;
  margin-bottom: 16px;
  width: 120px;
}

.r-selligent--under-rs-newsletter-container .logo_carre {
  height: auto;
  margin-bottom: 16px;
  width: 50px;
}

.r-selligent--under-rs-newsletter-container .r-selligent--baseline {
  font-size: 1.2rem;
  display: block;
  margin-bottom: 16px;
}

.r-selligent--under-rs-newsletter-container .r-btn {
  border-radius: 5px;
}

.r-selligent--under-rs-newsletter-container .r-btn.r-btn--black {
  background-color: #0e0d0e;
  color: white;
}

.r-selligent--under-rs-newsletter-container .r-btn.r-btn--white {
  background-color: white;
  color: #0e0d0e;
}

.r-selligent--pave-offre-container .r-selligent--pave-content {
  align-items: flex-start;
  background-color: #6a8c1f;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
}

.r-selligent--pave-offre-container h2 {
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1;
  margin: 12px 0;
}

.r-selligent--pave-offre-container .r-selligent--logo::before {
  content: url("/sites/all/themes/enacarbon_sb/images/logo-carre-plein.svg");
  display: block;
}

.r-selligent--pave-offre-container .r-selligent--logo-carre::before {
  width: 50px;
}

.r-selligent--pave-offre-container .r-selligent--logo-horizontal::before {
  width: 120px;
}

.r-selligent--pave-offre-container .logo_horizontal {
  height: auto;
  margin-bottom: 16px;
  width: 120px;
}

.r-selligent--pave-offre-container .logo_carre {
  height: auto;
  margin-bottom: 16px;
  width: 50px;
}

.r-selligent--pave-offre-container .r-selligent--btn {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.r-selligent--pave-offre-container .r-selligent--baseline {
  display: block;
  font-size: 1rem;
  margin-right: 16px;
  width: 50%;
}

.r-selligent--pave-offre-container .r-btn {
  background-color: white;
  border-radius: 20px;
  color: #6a8c1f;
  text-align: center;
  width: auto;
}

.r-selligent--pave-surf.r-selligent--body {
  border-color: #6a8c1f;
}

.r-selligent--pave-surf h3 {
  color: #6a8c1f;
}

.r-anonyme .btn_ano {
  display: inline-block;
}

.r-anonyme .btn_co {
  display: none;
}

.r-connecte .btn_ano {
  display: none;
}

.r-connecte .btn_co {
  display: inline-block;
}

.r-selligent--pave-app .r-selligent--pave-content {
  align-items: flex-start;
  background-color: #6a8c1f !important;
  color: white !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.r-selligent--pave-app .r-selligent--pave-content h2 {
  color: white;
}

.r-selligent--pave-app .r-selligent--btn-app {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.r-selligent--pave-app .r-selligent--baseline {
  margin: 0;
  width: 100%;
}

.r-selligent--pave-app .r-selligent--btn-app-apple,
.r-selligent--pave-app .r-selligent--btn-app-google {
  text-align: center;
  width: 50%;
}

/* 
leaflet@1.9.3 - https://unpkg.com/leaflet@1.9.3/dist/leaflet.css
required styles */

.leaflet-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-container,
.leaflet-pane > svg,
.leaflet-pane > canvas,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
  position: absolute;
  left: 0;
  top: 0;
}

.leaflet-container {
  overflow: hidden;
}

.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

/* Prevents IE11 from highlighting tiles in blue */

.leaflet-tile::selection {
  background: transparent;
}

/* Safari renders non-retina tile on retina better with this, but Chrome is worse */

.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast;
}

/* hack that prevents hw layers "stretching" when loading new tiles */

.leaflet-safari .leaflet-tile-container {
  width: 1600px;
  height: 1600px;
  -webkit-transform-origin: 0 0;
}

.leaflet-marker-icon,
.leaflet-marker-shadow {
  display: block;
}

/* .leaflet-container svg: reset svg max-width decleration shipped in Joomla! (joomla.org) 3.x */

/* .leaflet-container img: map is broken in FF if you have max-width: 100% on tiles */

.leaflet-container .leaflet-overlay-pane svg {
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer,
.leaflet-container .leaflet-tile {
  max-width: none !important;
  max-height: none !important;
  width: auto;
  padding: 0;
}

.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y;
}

.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
  /* Fallback for FF which doesn't support pinch-zoom */
  touch-action: none;
  touch-action: pinch-zoom;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none;
}

.leaflet-container {
  -webkit-tap-highlight-color: transparent;
}

.leaflet-container a {
  -webkit-tap-highlight-color: rgba(51, 181, 229, 0.4);
}

.leaflet-tile {
  filter: inherit;
  visibility: hidden;
}

.leaflet-tile-loaded {
  visibility: inherit;
}

.leaflet-zoom-box {
  width: 0;
  height: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 800;
}

/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */

.leaflet-overlay-pane svg {
  -moz-user-select: none;
}

.leaflet-pane {
  z-index: 400;
}

.leaflet-tile-pane {
  z-index: 200;
}

.leaflet-overlay-pane {
  z-index: 400;
}

.leaflet-shadow-pane {
  z-index: 500;
}

.leaflet-marker-pane {
  z-index: 600;
}

.leaflet-tooltip-pane {
  z-index: 650;
}

.leaflet-popup-pane {
  z-index: 700;
}

.leaflet-map-pane canvas {
  z-index: 100;
}

.leaflet-map-pane svg {
  z-index: 200;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}

.lvml {
  behavior: url(#default#VML);
  display: inline-block;
  position: absolute;
}

/* control positioning */

.leaflet-control {
  position: relative;
  z-index: 800;
  pointer-events: visiblePainted;
  /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}

.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
}

.leaflet-top {
  top: 0;
}

.leaflet-right {
  right: 0;
}

.leaflet-bottom {
  bottom: 0;
}

.leaflet-left {
  left: 0;
}

.leaflet-control {
  float: left;
  clear: both;
}

.leaflet-right .leaflet-control {
  float: right;
}

.leaflet-top .leaflet-control {
  margin-top: 10px;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}

.leaflet-left .leaflet-control {
  margin-left: 10px;
}

.leaflet-right .leaflet-control {
  margin-right: 10px;
}

/* zoom and fade animations */

.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}

.leaflet-zoom-animated {
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
}

svg.leaflet-zoom-animated {
  will-change: transform;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1);
}

.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile {
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}

/* cursors */

.leaflet-interactive {
  cursor: pointer;
}

.leaflet-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.leaflet-crosshair,
.leaflet-crosshair .leaflet-interactive {
  cursor: crosshair;
}

.leaflet-popup-pane,
.leaflet-control {
  cursor: auto;
}

.leaflet-dragging .leaflet-grab,
.leaflet-dragging .leaflet-grab .leaflet-interactive,
.leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

/* marker & overlays interactivity */

.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-image-layer,
.leaflet-pane > svg path,
.leaflet-tile-container {
  pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive,
svg.leaflet-image-layer.leaflet-interactive path {
  pointer-events: visiblePainted;
  /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}

/* visual tweaks */

.leaflet-container {
  background: #ddd;
  outline-offset: 1px;
}

.leaflet-container a {
  color: #0078A8;
}

.leaflet-zoom-box {
  border: 2px dotted #38f;
  background: rgba(255, 255, 255, 0.5);
}

/* general typography */

.leaflet-container {
  font-family: "Open Sans", arial, sans-serif;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.5;
}

/* general toolbar styles */

.leaflet-bar {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 4px;
}

.leaflet-bar a {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black;
}

.leaflet-bar a,
.leaflet-control-layers-toggle {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
}

.leaflet-bar a:hover,
.leaflet-bar a:focus {
  background-color: #f4f4f4;
}

.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.leaflet-bar a:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: none;
}

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  background-color: #f4f4f4;
  color: #bbb;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

/* zoom control */

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  font: bold 18px 'Lucida Console', Monaco, monospace;
  text-indent: 1px;
}

.leaflet-touch .leaflet-control-zoom-in,
.leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px;
}

/* layers control */

.leaflet-control-layers {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  background: #fff;
  border-radius: 5px;
}

.leaflet-control-layers-toggle {
  background-image: url(images/layers.png);
  width: 36px;
  height: 36px;
}

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url(images/layers-2x.png);
  background-size: 26px 26px;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}

.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}

.leaflet-control-layers-expanded {
  padding: 6px 10px 6px 6px;
  color: #333;
  background: #fff;
}

.leaflet-control-layers-scrollbar {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 5px;
}

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}

.leaflet-control-layers label {
  display: block;
  font-size: 13px;
  font-size: 1.08333em;
}

.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid #ddd;
  margin: 5px -10px 5px -6px;
}

/* Default icon URLs */

.leaflet-default-icon-path {
  /* used only in path-guessing heuristic, see L.Icon.Default */
  background-image: url(images/marker-icon.png);
}

/* attribution and scale controls */

.leaflet-container .leaflet-control-attribution {
  background: #fff;
  background: rgba(255, 255, 255, 0.8);
  margin: 0;
}

.leaflet-control-attribution,
.leaflet-control-scale-line {
  padding: 0 5px;
  color: #333;
  line-height: 1.4;
}

.leaflet-control-attribution a {
  text-decoration: none;
}

.leaflet-control-attribution a:hover,
.leaflet-control-attribution a:focus {
  text-decoration: underline;
}

.leaflet-attribution-flag {
  display: inline !important;
  vertical-align: baseline !important;
  width: 1em;
  height: 0.6669em;
}

.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}

.leaflet-control-scale-line {
  border: 2px solid #777;
  border-top: none;
  line-height: 1.1;
  padding: 2px 5px 1px;
  white-space: nowrap;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.8);
  text-shadow: 1px 1px #fff;
}

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  box-shadow: none;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
}

/* popup */

.leaflet-popup {
  position: absolute;
  text-align: center;
  margin-bottom: 20px;
}

.leaflet-popup-content-wrapper {
  padding: 1px;
  text-align: left;
  border-radius: 12px;
}

.leaflet-popup-content {
  margin: 13px 24px 13px 20px;
  line-height: 1.3;
  font-size: 13px;
  font-size: 1.08333em;
  min-height: 1px;
}

.leaflet-popup-content p {
  margin: 17px 0;
  margin: 1.3em 0;
}

.leaflet-popup-tip-container {
  width: 40px;
  height: 20px;
  position: absolute;
  left: 50%;
  margin-top: -1px;
  margin-left: -20px;
  overflow: hidden;
  pointer-events: none;
}

.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  padding: 1px;
  margin: -10px auto 0;
  pointer-events: auto;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: white;
  color: #333;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}

.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  text-align: center;
  width: 24px;
  height: 24px;
  font: 16px/24px Tahoma, Verdana, sans-serif;
  color: #757575;
  text-decoration: none;
  background: transparent;
}

.leaflet-container a.leaflet-popup-close-button:hover,
.leaflet-container a.leaflet-popup-close-button:focus {
  color: #585858;
}

.leaflet-popup-scrolled {
  overflow: auto;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  -ms-zoom: 1;
}

.leaflet-oldie .leaflet-popup-tip {
  width: 24px;
  margin: 0 auto;
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678);
}

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

/* div icon */

/* Tooltip */

/* Base styles for the element that has a tooltip */

.leaflet-tooltip {
  position: absolute;
  padding: 6px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

.leaflet-tooltip.leaflet-interactive {
  cursor: pointer;
  pointer-events: auto;
}

.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  position: absolute;
  pointer-events: none;
  border: 6px solid transparent;
  background: transparent;
  content: "";
}

/* Directions */

.leaflet-tooltip-bottom {
  margin-top: 6px;
}

.leaflet-tooltip-top {
  margin-top: -6px;
}

.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before {
  left: 50%;
  margin-left: -6px;
}

.leaflet-tooltip-top:before {
  bottom: 0;
  margin-bottom: -12px;
  border-top-color: #fff;
}

.leaflet-tooltip-bottom:before {
  top: 0;
  margin-top: -12px;
  margin-left: -6px;
  border-bottom-color: #fff;
}

.leaflet-tooltip-left {
  margin-left: -6px;
}

.leaflet-tooltip-right {
  margin-left: 6px;
}

.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  top: 50%;
  margin-top: -6px;
}

.leaflet-tooltip-left:before {
  right: 0;
  margin-right: -12px;
  border-left-color: #fff;
}

.leaflet-tooltip-right:before {
  left: 0;
  margin-left: -12px;
  border-right-color: #fff;
}

/* Printing */

.panel-markets-une-latest.marches--latest {
  grid-area: mp-latest;
  padding: 16px;
}

.panel-markets-une-latest.marches--latest h3 {
  font-size: 20px;
}

.panel-markets-une-latest.marches--latest ul {
  column-count: 2;
  column-gap: 20px;
}

.page-archive-index .pane-dpiindex-dpiindex-dates {
  margin-top: 32px;
}

.page-archive-index .pane-dpiindex-dpiindex-dates h3,
.page-archive-index .pane-dpiindex-dpiindex h3,
.page-archive-index .pane-dpiindex-dpiindex-navigation-links h3 {
  margin: 0 0 16px;
  font-weight: 700;
}

.page-archive-index .pane-dpiindex-dpiindex-dates ul,
.page-archive-index .pane-dpiindex-dpiindex ul,
.page-archive-index .pane-dpiindex-dpiindex-navigation-links ul {
  margin-bottom: 16px;
  padding: 0;
}

.page-archive-index .pane-dpiindex-dpiindex-dates .index-articles,
.page-archive-index .pane-dpiindex-dpiindex-dates .item-list,
.page-archive-index .pane-dpiindex-dpiindex-dates .date-list,
.page-archive-index .pane-dpiindex-dpiindex .index-articles,
.page-archive-index .pane-dpiindex-dpiindex .item-list,
.page-archive-index .pane-dpiindex-dpiindex .date-list,
.page-archive-index .pane-dpiindex-dpiindex-navigation-links .index-articles,
.page-archive-index .pane-dpiindex-dpiindex-navigation-links .item-list,
.page-archive-index .pane-dpiindex-dpiindex-navigation-links .date-list {
  background: black;
  color: white;
  margin-bottom: 32px;
  padding: 32px;
}

.page-archive-index .pane-dpiindex-dpiindex-dates .date-list,
.page-archive-index .pane-dpiindex-dpiindex .date-list,
.page-archive-index .pane-dpiindex-dpiindex-navigation-links .date-list {
  padding: 32px;
}

.page-archive-index .pane-dpiindex-dpiindex-dates .item-list li,
.page-archive-index .pane-dpiindex-dpiindex-dates .date-list li,
.page-archive-index .pane-dpiindex-dpiindex-dates .index-articles:first-of-type li,
.page-archive-index .pane-dpiindex-dpiindex .item-list li,
.page-archive-index .pane-dpiindex-dpiindex .date-list li,
.page-archive-index .pane-dpiindex-dpiindex .index-articles:first-of-type li,
.page-archive-index .pane-dpiindex-dpiindex-navigation-links .item-list li,
.page-archive-index .pane-dpiindex-dpiindex-navigation-links .date-list li,
.page-archive-index .pane-dpiindex-dpiindex-navigation-links .index-articles:first-of-type li {
  display: inline-block;
  margin: 8px 4px;
}

.page-archive-index .pane-dpiindex-dpiindex-dates .item-list li a,
.page-archive-index .pane-dpiindex-dpiindex-dates .date-list li a,
.page-archive-index .pane-dpiindex-dpiindex-dates .index-articles:first-of-type li a,
.page-archive-index .pane-dpiindex-dpiindex .item-list li a,
.page-archive-index .pane-dpiindex-dpiindex .date-list li a,
.page-archive-index .pane-dpiindex-dpiindex .index-articles:first-of-type li a,
.page-archive-index .pane-dpiindex-dpiindex-navigation-links .item-list li a,
.page-archive-index .pane-dpiindex-dpiindex-navigation-links .date-list li a,
.page-archive-index .pane-dpiindex-dpiindex-navigation-links .index-articles:first-of-type li a {
  background: white;
  border: 1px solid white;
  border-radius: 30px;
  color: #0e0d0e;
  font-size: .9rem;
  padding: 4px 16px;
}

.page-archive-index .pane-dpiindex-dpiindex-dates .item-list li a:hover,
.page-archive-index .pane-dpiindex-dpiindex-dates .date-list li a:hover,
.page-archive-index .pane-dpiindex-dpiindex-dates .index-articles:first-of-type li a:hover,
.page-archive-index .pane-dpiindex-dpiindex .item-list li a:hover,
.page-archive-index .pane-dpiindex-dpiindex .date-list li a:hover,
.page-archive-index .pane-dpiindex-dpiindex .index-articles:first-of-type li a:hover,
.page-archive-index .pane-dpiindex-dpiindex-navigation-links .item-list li a:hover,
.page-archive-index .pane-dpiindex-dpiindex-navigation-links .date-list li a:hover,
.page-archive-index .pane-dpiindex-dpiindex-navigation-links .index-articles:first-of-type li a:hover {
  background: #6a8c1f;
  border: 1px solid #6a8c1f;
  color: white;
  text-decoration: none;
}

.page-archive-index .pane-dpiindex-dpiindex-dates .item-list li .selected,
.page-archive-index .pane-dpiindex-dpiindex-dates .date-list li .selected,
.page-archive-index .pane-dpiindex-dpiindex-dates .index-articles:first-of-type li .selected,
.page-archive-index .pane-dpiindex-dpiindex .item-list li .selected,
.page-archive-index .pane-dpiindex-dpiindex .date-list li .selected,
.page-archive-index .pane-dpiindex-dpiindex .index-articles:first-of-type li .selected,
.page-archive-index .pane-dpiindex-dpiindex-navigation-links .item-list li .selected,
.page-archive-index .pane-dpiindex-dpiindex-navigation-links .date-list li .selected,
.page-archive-index .pane-dpiindex-dpiindex-navigation-links .index-articles:first-of-type li .selected {
  background: #6a8c1f;
  border: 1px solid #6a8c1f;
  color: white;
  font-weight: 700;
}

.page-archive-index .pane-dpiindex-dpiindex-dates .index-articles li,
.page-archive-index .pane-dpiindex-dpiindex .index-articles li,
.page-archive-index .pane-dpiindex-dpiindex-navigation-links .index-articles li {
  margin: 8px;
}

.page-archive-index .pane-dpiindex-dpiindex-dates .index-articles:first-of-type li,
.page-archive-index .pane-dpiindex-dpiindex .index-articles:first-of-type li,
.page-archive-index .pane-dpiindex-dpiindex-navigation-links .index-articles:first-of-type li {
  width: 100%;
}

.page-archive-index .pane-dpiindex-dpiindex-dates .date-list ul,
.page-archive-index .pane-dpiindex-dpiindex .date-list ul,
.page-archive-index .pane-dpiindex-dpiindex-navigation-links .date-list ul {
  columns: auto;
  list-style: none;
}

.page-archive-index .pane-dpiindex-dpiindex-dates .date-list li,
.page-archive-index .pane-dpiindex-dpiindex .date-list li,
.page-archive-index .pane-dpiindex-dpiindex-navigation-links .date-list li {
  margin: 0 8px 8px 0;
}

.page-archive-index .pane-dpiindex-dpiindex-navigation-links .item-list li {
  display: block;
  margin: 0 0 16px 0;
}

.gr-nav-articles {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  padding: 0;
}

.gr-nav-articles li {
  list-style: none;
  padding: 0;
}

.gr-nav-articles a {
  background: black;
  border: 1px solid black;
  color: white;
  font-weight: 700;
  padding: 8px 16px;
  border-radius: 25px;
  display: inline-block;
}

.gr-nav-articles a:hover,
.gr-nav-articles a:focus {
  background: white;
  border: 1px solid black;
  text-decoration: none;
  color: black;
}

.gr-nav-articles a r-icon {
  vertical-align: middle;
}

.gr-nav-articles a r-icon.r-icon--arrow-left {
  margin-right: 8px;
  margin-top: -1px;
}

.gr-nav-articles a r-icon.r-icon--arrow-right {
  margin-left: 8px;
  margin-top: -1px;
}

.gr-nav-articles li:nth-of-type(2) a {
  background: white;
  border: 1px solid black;
  color: black;
}

.gr-nav-articles li:nth-of-type(2) a:hover,
.gr-nav-articles li:nth-of-type(2) a:focus {
  opacity: .6;
}

.fa-long-arrow-right {
  font-style: normal;
}

.fa-long-arrow-right::before {
  font-family: main;
  content: "\ea49";
}

.fa-chevron-right {
  font-style: normal;
}

.fa-chevron-right::before {
  font-family: main;
  content: "\ea03";
}

.page-marches .r-panel--paywall {
  margin: 0;
}

.page-marches .r-panel--paywall::before {
  top: -320px;
}

.page-marches .r-panel--paywall r-paywall--content h2 {
  font-family: "Open Sans", arial, sans-serif;
  font-size: 1.7rem;
  margin: 32px 0 16px;
}

.page-marches .r-panel--paywall r-paywall--content .r-paywall--heading {
  font-size: 1.2rem;
}

.pane-marches-trend {
  border-radius: 4px;
  font-size: 16px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  width: 25px;
}

.pane-marches-trend::before {
  display: inline-block;
}

.pane-marches-trend.pane-marches-trend__down {
  color: white;
  background-color: #FC8029;
}

.pane-marches-trend.pane-marches-trend__down::before {
  transform: rotate(45deg);
}

.pane-marches-trend.pane-marches-trend__up {
  color: white;
  background-color: #a0ca4b;
}

.pane-marches-trend.pane-marches-trend__up::before {
  transform: rotate(-45deg);
}

.pane-marches-trend.pane-marches-trend__same {
  color: white;
  background-color: #9F9F9F;
}

.marches-trend-small {
  font-size: 16px;
  line-height: 5px;
  text-align: center;
}

.marches-trend-small::before {
  display: inline-block;
}

.marches-trend-small.marches-trend-small__down::before {
  color: #DA3C0A;
  transform: rotate(45deg);
}

.marches-trend-small.marches-trend-small__up::before {
  color: #6a8c1f;
  transform: rotate(-45deg);
}

.marches-trend-small.marches-trend-small__same::before {
  color: #9F9F9F;
}

.pane-marches_crumbs {
  font-family: "Work Sans", arial, sans-serif;
  font-weight: font-weigh(base);
  font-size: 13px;
}

.pane-marches_crumbs a {
  border: 1px solid #F0F0F0;
  color: #0e0d0e;
  display: inline-block;
  padding: 10px 15px 10px 10px;
  margin-right: 3px;
  text-decoration: none;
  background-color: white;
  border-color: #0e0d0e;
}

.pane-marches_crumbs a:hover {
  background-color: #0e0d0e;
  color: white;
}

.pane-marches_crumbs a::before {
  font-size: 12px;
  line-height: 12px;
  padding-right: 10px;
  font-family: main;
  content: "\ea02";
}

.r-mini-panel--section.-is-mp-markets i-mini-panel {
  padding-bottom: 32px;
}

.r-mini-panel--section.-is-mp-markets i-mini-panel .r-mini-panel--title {
  grid-area: mp-title;
  padding-bottom: 0;
}

.r-mini-panel--section.-is-mp-markets i-mini-panel .r-mini-panel--title:after {
  display: none;
}

.r-mini-panel--section.-is-mp-markets i-mini-panel .r-mini-panel--title:before {
  display: none;
}

.r-mini-panel--section.-is-mp-markets-home i-mini-panel {
  padding-bottom: 32px;
}

.r-mini-panel--section.-is-mp-markets-home i-mini-panel .markets-home--intro {
  background: no-repeat left bottom url("/sites/all/themes/enacarbon_sb/images/marches-landscape.svg");
  grid-area: intro;
  margin-left: 16px;
  padding: 16px;
}

.r-mini-panel--section.-is-mp-markets-home i-mini-panel .markets-home--intro h3 {
  margin-bottom: 32px;
}

.r-mini-panel--section.-is-mp-markets-home i-mini-panel .markets-home--intro ul {
  list-style-type: none;
  margin: 32px 0;
  padding: 0;
}

.r-mini-panel--section.-is-mp-markets-home i-mini-panel .markets-home--intro ul li {
  font-size: 16px;
  line-height: 20px;
  padding: 5px 0;
}

.r-mini-panel--section.-is-mp-markets-home i-mini-panel .markets-home--intro ul li::before {
  color: #6a8c1f;
  content: "\ea45";
  font-family: main;
  font-size: 20px;
  line-height: 20px;
  padding-right: 7px;
}

.r-mini-panel--section.-is-mp-markets-home i-mini-panel .markets-home--sectionlist {
  grid-area: sections;
  padding: 16px;
}

.r-mini-panel--section.-is-mp-markets-home i-mini-panel .markets-home--latest {
  grid-area: latest;
  padding: 16px;
}

.panel-markets-une-trends {
  grid-area: mp-trends;
  padding: 16px;
}

.panel-markets-une-trends h3 {
  font-size: 20px;
}

.panel-markets-une-trends ul {
  column-count: 2;
  column-gap: 20px;
  list-style-type: none;
  margin-top: 0;
  padding: 0;
}

.panel-markets-une-trends ul li {
  border-top: 1px dashed #9f9f9f;
  padding: 6px 0;
}

.panel-markets-une-trends ul li a {
  color: #0e0d0e;
  display: flex;
  text-decoration: none;
}

.panel-markets-une-trends ul li a:hover {
  text-decoration: none;
}

.panel-markets-une-trends ul li a:hover .markets-trends-section {
  text-decoration: underline;
}

.panel-markets-une-trends ul li .pane-marches-trend {
  font-size: 22px;
  height: 36px;
  line-height: 36px;
  width: 35px;
}

.panel-markets-une-trends ul li .markets-trends-section {
  font-size: 20px;
  line-height: 36px;
  padding-left: 8px;
  padding-right: 8px;
}

.panel-markets-une-trends ul li .markets-trends-section:hover {
  text-decoration: underline;
}

.panel-markets-une-trends ul li .pane-marches-home__icon {
  height: 36px;
  position: initial;
  width: 36px;
}

.panel-markets-une-trends ul li .pane-marches-home__icon.pane-marches-home__icon-1 {
  background-size: 80%;
}

.panel-markets-une-trends ul li .pane-marches-home__icon.pane-marches-home__icon-2 {
  background-position: 0px 17px,9px 11px;
}

.panel-markets-une-trends ul li .pane-marches-home__icon.pane-marches-home__icon-3 {
  background-size: auto 70%;
}

.panel-markets-une-trends ul li .pane-marches-home__icon.pane-marches-home__icon-4 {
  background-size: 80%;
}

.panel-markets-une-trends ul li .pane-marches-home__icon.pane-marches-home__icon-5 {
  background-position: 2px 17px,15px 12px;
}

.panel-markets-une-trends ul li .pane-marches-home__icon.pane-marches-home__icon-6 {
  background-size: 65%;
}

.panel-markets-une-trends ul li .pane-marches-home__icon.pane-marches-home__icon-7 {
  background-size: 60%;
}

.panel-markets-une-trends ul li .pane-marches-home__icon.pane-marches-home__icon-8 {
  background-size: 50%;
}

.panel-markets-une-trends ul li .pane-marches-home__icon.pane-marches-home__icon-9 {
  background-size: 50%;
}

.panel-markets-une-trends ul li .pane-marches-home__icon.pane-marches-home__icon-10 {
  background-size: 52%;
}

.panel-markets-une-trends ul li .pane-marches-home__icon.pane-marches-home__icon-11 {
  background-size: 80%;
}

.pane-marches__accordion > .pane-marches__accordion__trigger {
  cursor: pointer;
  list-style-type: none;
}

.pane-marches__accordion > .pane-marches__accordion__trigger::-webkit-details-marker {
  display: none;
}

.pane-marches__accordion > .pane-marches__accordion__trigger:focus {
  outline: none;
}

.pane-marches__accordion > .pane-marches__accordion__trigger > .pane-marches__accordion__trigger__title {
  border-bottom: 1px solid white;
}

.pane-marches__accordion > .pane-marches__accordion__trigger > .pane-marches__accordion__trigger__title::after {
  font-family: main;
  content: "\ea09";
  font-size: 22px;
  position: absolute;
}

.pane-marches__accordion > .pane-marches__accordion__trigger > h2.pane-marches__accordion__trigger__title {
  margin-bottom: 0;
}

.pane-marches__accordion > .pane-marches__accordion__trigger > h2.pane-marches__accordion__trigger__title::after {
  right: 24px;
  font-size: 20px;
}

.pane-marches__accordion > .pane-marches__accordion__trigger > h3.pane-marches__accordion__trigger__title::after {
  right: 0;
}

.pane-marches__accordion > .pane-marches__accordion__trigger > h4.pane-marches__accordion__trigger__title::after {
  right: 20px;
}

.pane-marches__accordion > .pane-marches__accordion__trigger > h5.pane-marches__accordion__trigger__title {
  background-color: #F0F0F0;
}

.pane-marches__accordion > .pane-marches__accordion__trigger > h5.pane-marches__accordion__trigger__title::after {
  font-size: 18px;
  right: 30px;
}

.pane-marches__accordion > .pane-marches__accordion__trigger:hover > h2.pane-marches__accordion__trigger__title {
  background-color: #F7F7F7;
}

.pane-marches__accordion > .pane-marches__accordion__trigger:hover > h3.pane-marches__accordion__trigger__title {
  border-bottom: 1px solid #0e0d0e;
}

.pane-marches__accordion > .pane-marches__accordion__trigger:hover > h4.pane-marches__accordion__trigger__title {
  border-bottom: 1px solid #0e0d0e;
}

.pane-marches__accordion > .pane-marches__accordion__trigger:hover > h5.pane-marches__accordion__trigger__title {
  border-bottom: 1px solid #0e0d0e;
}

.pane-marches__accordion > .pane-marches__accordion__trigger.market-name {
  list-style: none;
}

.pane-marches__accordion > .pane-marches__accordion__trigger.market-name > .pane-marches__accordion__trigger__title::after {
  content: "\ea51";
  transform: rotate(0);
  transition: 0.15s transform ease-in;
}

.pane-marches__accordion > .pane-marches__accordion__content {
  background-color: white;
  max-height: 0;
  transition: 1s max-height ease-in-out;
  overflow: hidden;
}

.pane-marches__accordion[open] > .pane-marches__accordion__trigger > .pane-marches__accordion__trigger__title::after {
  content: "\ea08";
}

.pane-marches__accordion[open] > .pane-marches__accordion__trigger > h4.pane-marches__accordion__trigger__title,
.pane-marches__accordion[open] > .pane-marches__accordion__trigger > h5.pane-marches__accordion__trigger__title {
  background-color: #0e0d0e;
  color: white;
}

.pane-marches__accordion[open] > .pane-marches__accordion__trigger.market-name > .pane-marches__accordion__trigger__title::after {
  content: "\ea51";
  transform: rotate(180deg);
}

.pane-marches__accordion[open] > .pane-marches__accordion__content {
  max-height: 10000px;
}

.pane-marches__accordion[open] .pane-marches-section__news {
  display: block;
}

.pane-marches__accordion h4.pane-marches__accordion__trigger__title,
.pane-marches__accordion h5.pane-marches__accordion__trigger__title {
  margin-right: 0px;
  position: relative;
}

.pane-marches__accordion h5.pane-marches__accordion__trigger__title::after {
  right: 22px !important;
}

.pane-marches-section__news {
  display: block;
  background-color: #F5F5F5;
  font-size: 13px;
  padding: 12px 21px;
}

.pane-marches-section__news .pane-marches-section__news-title {
  color: #0e0d0e;
  font-family: "Work Sans", arial, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
}

.pane-marches-section__news .pane-marches-section__news-market {
  color: #9F9F9F;
}

.pane-marches-section__news .pane-marches-section__news-text {
  padding-top: 3px;
}

.pane-marches__accordion .pane-marches-section__news {
  display: none;
}

.pane-marches-product__news {
  font-size: 13px;
  margin-bottom: 20px;
  margin-top: -18px;
  max-width: 500px;
}

.pane-marches-product__news .pane-marches-product__news-title {
  color: #0e0d0e;
  font-family: "Work Sans", arial, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
}

.pane-marches-product__news .pane-marches-product__news-market {
  color: #9F9F9F;
}

.pane-marches-product__news .pane-marches-product__news-text {
  padding-top: 5px;
}

.markets-home--sectionlist {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;
}

.markets-home--sectionlist h2 {
  border-bottom: 1px solid #F0F0F0;
  font-family: "Work Sans", arial, sans-serif;
  font-size: 20px;
  font-weight: 500;
  margin: 0;
  position: relative;
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  max-width: 500px;
  width: 98%;
}

.markets-home--sectionlist h2 a {
  color: #0e0d0e;
  display: block;
  padding: 15px 0 15px 60px;
  text-decoration: underline;
}

.markets-home--sectionlist h2 a:hover,
.markets-home--sectionlist h2 a:active,
.markets-home--sectionlist h2 a:focus {
  background-color: #F0F0F0;
  color: #0e0d0e;
}

.markets-home--sectionlist h2 a::after {
  color: #0e0d0e;
  font-size: 20px;
  line-height: 30px;
  position: absolute;
  right: 10px;
  content: "\ea03";
  font-family: main;
}

.markets-home--sectionlist h2 .pane-marches-trend {
  margin-top: 3px;
  position: absolute;
  right: 35px;
}

.markets-home--sectionlist-title {
  text-decoration: underline;
}

.pane-marches-home__icon {
  display: inline-block;
  height: 50px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 50px;
}

.pane-marches-home__icon.pane-marches-home__icon-1 {
  background: no-repeat center center url("/sites/all/themes/enacarbon_sb/images/icons/markets/cow.svg");
  transform: scaleX(-1);
}

.pane-marches-home__icon.pane-marches-home__icon-2 {
  background-image: url("/sites/all/themes/enacarbon_sb/images/icons/markets/cow.svg"), url("/sites/all/themes/enacarbon_sb/images/icons/markets/cow.svg");
  background-size: 60%, 60%;
  background-position: 2px 24px,15px 15px;
  background-repeat: no-repeat, no-repeat;
  transform: scaleX(-1);
}

.pane-marches-home__icon.pane-marches-home__icon-3 {
  background: no-repeat center center url("/sites/all/themes/enacarbon_sb/images/icons/markets/milk.svg");
}

.pane-marches-home__icon.pane-marches-home__icon-4 {
  background: no-repeat center center url("/sites/all/themes/enacarbon_sb/images/icons/markets/porc.svg");
  transform: scaleX(-1);
}

.pane-marches-home__icon.pane-marches-home__icon-5 {
  background-image: url("/sites/all/themes/enacarbon_sb/images/icons/markets/porc.svg"), url("/sites/all/themes/enacarbon_sb/images/icons/markets/porc.svg");
  background-size: 60%, 60%;
  background-position: 2px 25px,15px 20px;
  background-repeat: no-repeat, no-repeat;
  transform: scaleX(-1);
}

.pane-marches-home__icon.pane-marches-home__icon-6 {
  background: no-repeat center center url("/sites/all/themes/enacarbon_sb/images/icons/markets/sheep.svg");
}

.pane-marches-home__icon.pane-marches-home__icon-7 {
  background: no-repeat center center url("/sites/all/themes/enacarbon_sb/images/icons/markets/eggs-y.svg");
}

.pane-marches-home__icon.pane-marches-home__icon-8 {
  background: no-repeat center center url("/sites/all/themes/enacarbon_sb/images/icons/markets/chicken.svg");
}

.pane-marches-home__icon.pane-marches-home__icon-9 {
  background: no-repeat center center url("/sites/all/themes/enacarbon_sb/images/icons/markets/grains.svg");
}

.pane-marches-home__icon.pane-marches-home__icon-10 {
  background: no-repeat center center url("/sites/all/themes/enacarbon_sb/images/icons/markets/mat-prem.svg");
}

.pane-marches-home__icon.pane-marches-home__icon-11 {
  background: no-repeat center center url("/sites/all/themes/enacarbon_sb/images/icons/markets/potatoes.svg");
}

.pane-marches-home__icon.pane-marches-home__icon-12 {
  background: no-repeat center center url("/sites/all/themes/enacarbon_sb/images/icons/markets/petrol.svg");
}

.pane-marches-home__icon.pane-marches-home__icon-13 {
  background: no-repeat center center url("/sites/all/themes/enacarbon_sb/images/icons/markets/oleo.svg");
  background-size: 56%;
}

.pane-marches-home__icon.pane-marches-home__icon-14 {
  background: no-repeat center center url("/sites/all/themes/enacarbon_sb/images/icons/markets/sugar.svg");
  background-size: 65%;
}

.pane-marches-home__icon.pane-marches-home__icon-15 {
  background: no-repeat center center url("/sites/all/themes/enacarbon_sb/images/icons/markets/fruitveg.svg");
  background-size: 75%;
}

.pane-marches-home__icon.pane-marches-home__icon-16 {
  background: no-repeat center center url("/sites/all/themes/enacarbon_sb/images/icons/markets/wine.svg");
  background-size: 50%;
}

.pane-marches-section h1 {
  font-family: "Work Sans", arial, sans-serif;
  font-weight: 600;
}

.pane-marches-section h2 {
  background-color: #F0F0F0;
  border-top: 1px solid #0e0d0e;
  font-family: "Work Sans", arial, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 0;
  padding: 15px 40px 15px 20px;
  position: relative;
}

.pane-marches-section h2.eu {
  background-size: 30px auto;
  background-position-x: 20px;
  background-position-y: center;
  background-repeat: no-repeat;
  background-image: url("/sites/all/themes/enacarbon_sb/images/europeanunion.svg");
  padding-left: 60px;
}

.pane-marches-section h3 {
  font-family: "Work Sans", arial, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  margin: 5px 20px 0 20px;
  padding: 16px 25px 15px 0px;
  position: relative;
}

.pane-marches-section h4 {
  font-family: "Work Sans", arial, sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 17px;
  margin: 0 20px;
  padding: 10px 20px;
}

.pane-marches-section h5 {
  font-family: "Work Sans", arial, sans-serif;
  font-size: 15px;
  font-weight: normal;
  line-height: 15px;
  margin: 0px 20px;
  padding: 10px 20px 10px 25px;
}

.pane-marches-section__table {
  margin: 0;
  width: 100%;
}

.pane-marches-section__table-wrap {
  margin: 10px 20px 0 20px;
}

.pane-marches-section__table thead {
  border-top: 1px solid #0e0d0e;
  border-bottom: 2px solid #0e0d0e;
}

.pane-marches-section__table thead th {
  text-align: left;
  font-size: 12px;
  line-height: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.pane-marches-section__table thead th.pane-marches-section__itemhead {
  min-width: 40%;
}

.pane-marches-section__table thead th.pane-marches-section__trendhead {
  min-width: 70px;
  text-align: center;
}

.pane-marches-section__table thead th.pane-marches-section__arrowhead {
  width: 28px;
}

.pane-marches-section__table tbody tr {
  border-top: 1px solid #9F9F9F;
  cursor: pointer;
}

.pane-marches-section__table tbody tr:hover,
.pane-marches-section__table tbody tr:active,
.pane-marches-section__table tbody tr:focus {
  background-color: #f0f0f0;
}

.pane-marches-section__table tbody tr:hover td.pane-marches-section__arrow i,
.pane-marches-section__table tbody tr:active td.pane-marches-section__arrow i,
.pane-marches-section__table tbody tr:focus td.pane-marches-section__arrow i {
  transform: translateX(2px);
}

.pane-marches-section__table tbody td {
  font-size: 14px;
  line-height: 18px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 10px;
  vertical-align: top;
}

.pane-marches-section__table tbody td a:hover {
  text-decoration: none;
}

.pane-marches-section__table tbody td.pane-marches-section__item a {
  color: #0e0d0e;
  text-decoration: underline;
}

.pane-marches-section__table tbody td.pane-marches-section__trend {
  text-align: center;
}

.pane-marches-section__table tbody td.pane-marches-section__trend .percentage {
  font-weight: bold;
  line-height: 20px;
}

.pane-marches-section__table tbody td.pane-marches-section__trend.up {
  background-color: rgba(160, 202, 75, 0.5);
}

.pane-marches-section__table tbody td.pane-marches-section__trend.up .percentage {
  color: #6a8c1f;
}

.pane-marches-section__table tbody td.pane-marches-section__trend.down {
  background-color: rgba(252, 128, 41, 0.5);
}

.pane-marches-section__table tbody td.pane-marches-section__trend.down .percentage {
  color: #DA3C0A;
}

.pane-marches-section__table tbody td.pane-marches-section__trend.same {
  background-color: rgba(240, 240, 240, 0.5);
}

.pane-marches-section__table tbody td.pane-marches-section__trend.same .percentage {
  color: #9F9F9F;
}

.pane-marches-section__table tbody td.pane-marches-section__arrow {
  padding: 0;
  position: relative;
}

.pane-marches-section__table tbody td.pane-marches-section__arrow i {
  background-color: #0e0d0e;
  border-radius: 25px;
  color: white;
  font-size: 17px;
  height: 35px;
  left: 3px;
  line-height: 35px;
  padding-left: 3px;
  position: absolute;
  top: 17px;
  text-align: center;
  transition: transform 0.3s ease;
  width: 35px;
}

.pane-marches-section__table tbody td .units {
  font-size: 12px;
  line-height: 20px;
  color: #9F9F9F;
}

.pane-marches-section__table tbody td .allow-inline {
  display: inline-block;
}

.pane-marches-product h1 {
  font-family: "Work Sans", arial, sans-serif;
  font-weight: 600;
}

.pane-marches-product h2 {
  font-family: "Work Sans", arial, sans-serif;
  font-weight: normal;
  font-size: 17px;
  margin-top: 40px;
}

.pane-marches-product .pane-marches-product__pill-trend {
  border-radius: 4px;
  color: white;
  display: inline-block;
  font-family: "Work Sans", arial, sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  padding: 3px 7px;
  vertical-align: middle;
}

.pane-marches-product .pane-marches-product__pill-trend::before {
  content: "\ea49";
  display: inline-block;
  font-family: main;
  font-size: 11px;
}

.pane-marches-product .pane-marches-product__pill-trend.up {
  background-color: #a0ca4b;
}

.pane-marches-product .pane-marches-product__pill-trend.up::before {
  transform: rotate(-45deg);
}

.pane-marches-product .pane-marches-product__pill-trend.down {
  background-color: #FC8029;
}

.pane-marches-product .pane-marches-product__pill-trend.down::before {
  transform: rotate(45deg);
}

.pane-marches-product .pane-marches-product__pill-trend.same {
  background-color: #9F9F9F;
}

.pane-marches-product .pane-marches-product__current-price {
  margin-bottom: 40px;
}

.pane-marches-product .pane-marches-product__current-price-title {
  color: #9F9F9F;
  font-family: "Work Sans", arial, sans-serif;
  font-weight: normal;
  font-size: 13px;
  text-transform: uppercase;
}

.pane-marches-product .pane-marches-product__current-price-date {
  color: #0e0d0e;
  font-weight: 600;
}

.pane-marches-product .pane-marches-product__current-price-price {
  display: inline-block;
  vertical-align: middle;
  font-weight: 600;
}

.pane-marches-product .pane-marches-product__current-price-value {
  font-family: "Work Sans", arial, sans-serif;
  font-weight: normal;
  font-size: 24px;
  margin-top: 5px;
}

.pane-marches-product .pane-marches-product__current-price-unit {
  font-family: "Work Sans", arial, sans-serif;
  font-weight: normal;
  font-size: 13px;
  line-height: 13px;
}

.pane-marches-product .pane-marches-product-graph__controls {
  background-color: #F0F0F0;
  border-radius: 4px;
  font-size: 13px;
  line-height: 13px;
  margin-bottom: 10px;
  padding: 4px 10px;
  text-transform: uppercase;
}

.pane-marches-product .pane-marches-product-graph__controls ul {
  padding: 0;
  margin: 0;
  text-align: right;
}

.pane-marches-product .pane-marches-product-graph__controls ul li {
  background-color: #9F9F9F;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  display: none;
  margin: 0 5px;
  padding: 5px;
  text-align: center;
  transition: all .3s ease;
}

.pane-marches-product .pane-marches-product-graph__controls ul li:hover,
.pane-marches-product .pane-marches-product-graph__controls ul li.active {
  background-color: #0e0d0e;
  color: white;
}

.pane-marches-product .pane-marches-product__graph-loading {
  height: 100px;
  background: no-repeat center center url("/sites/all/themes/enacarbon_sb/images/tail-spin.svg");
}

.pane-marches-product .pane-marches-product__prices-title-product {
  font-weight: bold;
}

.pane-marches-product .pane-marches-product__table {
  margin: 0;
  width: 100%;
}

.pane-marches-product .pane-marches-product__table thead {
  border-top: 1px solid #0e0d0e;
  border-bottom: 2px solid #0e0d0e;
}

.pane-marches-product .pane-marches-product__table thead th {
  text-align: left;
  font-size: 12px;
  line-height: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.pane-marches-product .pane-marches-product__table thead th.pane-marches-product__trendhead {
  text-align: center;
}

.pane-marches-product .pane-marches-product__table tbody tr {
  border-bottom: 1px solid #9F9F9F;
}

.pane-marches-product .pane-marches-product__table tbody tr td {
  font-size: 13px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.pane-marches-product .pane-marches-product__table tbody tr td.pane-marches-product__trend {
  text-align: center;
}

.pane-marches-product .pane-marches-product__table tbody tr td.pane-marches-product__trend.up {
  background-color: rgba(160, 202, 75, 0.5);
}

.pane-marches-product .pane-marches-product__table tbody tr td.pane-marches-product__trend.up .percentage {
  color: #6a8c1f;
}

.pane-marches-product .pane-marches-product__table tbody tr td.pane-marches-product__trend.down {
  background-color: rgba(252, 128, 41, 0.5);
}

.pane-marches-product .pane-marches-product__table tbody tr td.pane-marches-product__trend.down .percentage {
  color: #DA3C0A;
}

.pane-marches-product .pane-marches-product__table tbody tr td.pane-marches-product__trend.same {
  background-color: rgba(240, 240, 240, 0.5);
}

.pane-marches-product .pane-marches-product__table tbody tr td.pane-marches-product__trend.same .percentage {
  color: #9F9F9F;
}

.pane-marches-product .pane-marches-product__table .allow-inline {
  display: inline-block;
  padding-right: 5px;
}

.pane-marches-product .pane-marches-product__stats .pane-marches-product__stats-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.pane-marches-product .pane-marches-product__stats-item {
  border-bottom: 1px solid #0e0d0e;
  margin-right: 10px;
  padding: 20px 35px 20px 0;
}

.pane-marches-product .pane-marches-product__stats-item:first-child,
.pane-marches-product .pane-marches-product__stats-item:nth-child(2) {
  border-top: 1px solid #0e0d0e;
}

.pane-marches-product .pane-marches-product__stats-item-name {
  font-family: "Work Sans", arial, sans-serif;
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
}

.pane-marches-product .pane-marches-product__stats-item-period {
  font-family: "Work Sans", arial, sans-serif;
  font-weight: normal;
  font-size: 13px;
  text-transform: uppercase;
  color: #9F9F9F;
}

.pane-marches-product .pane-marches-product__stats-item-price {
  font-family: "Work Sans", arial, sans-serif;
  font-weight: 600;
  font-size: 24px;
}

.pane-marches-product .pane-marches-product__stats-item-unit {
  font-family: "Work Sans", arial, sans-serif;
  font-weight: normal;
  font-size: 13px;
}

.pane-marches-product__pill-market {
  background-color: #F0F0F0;
  color: #0e0d0e;
  border-radius: 4px;
  display: inline-block;
  font-family: "Work Sans", arial, sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  margin-left: 10px;
  padding: 5px 7px;
  text-transform: uppercase;
  vertical-align: middle;
}

/*
// leaflet marker cluster styling
.marker-cluster-small {
	background-color: rgba(181, 226, 140, 0.6);
	}
.marker-cluster-small div {
	background-color: rgba(110, 204, 57, 0.6);
	}

.marker-cluster-medium {
	background-color: rgba(241, 211, 87, 0.6);
	}
.marker-cluster-medium div {
	background-color: rgba(240, 194, 12, 0.6);
	}

.marker-cluster-large {
	background-color: rgba(253, 156, 115, 0.6);
	}
.marker-cluster-large div {
	background-color: rgba(241, 128, 23, 0.6);
	}*/

/* IE 6-8 fallback colors */

/*.leaflet-oldie .marker-cluster-small {
	background-color: rgb(181, 226, 140);
	}
.leaflet-oldie .marker-cluster-small div {
	background-color: rgb(110, 204, 57);
	}

.leaflet-oldie .marker-cluster-medium {
	background-color: rgb(241, 211, 87);
	}
.leaflet-oldie .marker-cluster-medium div {
	background-color: rgb(240, 194, 12);
	}

.leaflet-oldie .marker-cluster-large {
	background-color: rgb(253, 156, 115);
	}
.leaflet-oldie .marker-cluster-large div {
	background-color: rgb(241, 128, 23);
}
*/

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}

.marker-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;
  text-align: center;
  border-radius: 15px;
  font: 12px "Open Sans", arial, sans-serif;
}

.marker-cluster span {
  line-height: 30px;
}

.leaflet-cluster-anim .leaflet-marker-icon,
.leaflet-cluster-anim .leaflet-marker-shadow {
  -webkit-transition: -webkit-transform 0.3s ease-out, opacity 0.3s ease-in;
  -moz-transition: -moz-transform 0.3s ease-out, opacity 0.3s ease-in;
  -o-transition: -o-transform 0.3s ease-out, opacity 0.3s ease-in;
  transition: transform 0.3s ease-out, opacity 0.3s ease-in;
}

.leaflet-cluster-spider-leg {
  /* stroke-dashoffset (duration and function) should match with leaflet-marker-icon transform in order to track it exactly */
  -webkit-transition: -webkit-stroke-dashoffset 0.3s ease-out, -webkit-stroke-opacity 0.3s ease-in;
  -moz-transition: -moz-stroke-dashoffset 0.3s ease-out, -moz-stroke-opacity 0.3s ease-in;
  -o-transition: -o-stroke-dashoffset 0.3s ease-out, -o-stroke-opacity 0.3s ease-in;
  transition: stroke-dashoffset 0.3s ease-out, stroke-opacity 0.3s ease-in;
}

.arche {
  margin-top: 160px;
}

.arche .-not-lg {
  display: block !important;
}

.-is-toggle {
  content-visibility: visible;
  opacity: 1;
  transition: opacity .3s ease;
  visibility: visible;
}

r-footer {
  display: grid;
  grid-template-areas: 'top top top' '. . .';
  grid-column-gap: 40px;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 16px 25px 60px;
  border-top: 1px solid #0e0d0e;
  color: #6a8c1f;
}

r-footer a,
r-footer .r-footer--link {
  color: #0e0d0e;
}

.r-page--shopping r-footer {
  background: white;
  border: none;
}

.arche r-footer {
  grid-template-columns: repeat(3, 1fr);
  margin: auto;
  padding: 16px;
  width: 996px;
  background: white;
}

r-footer--top {
  display: grid;
  grid-area: top;
}

r-footer--section {
  display: block;
  grid-column-start: 1;
  grid-column-end: -1;
  text-transform: uppercase;
}

r-footer--section.is-open .r-footer--title:after {
  transform: rotate(90deg);
}

r-footer--section.is-open r-footer--list {
  display: block;
  max-height: 100%;
  opacity: 1;
  overflow: visible;
  transition: all 0.3s linear;
}

.arche r-footer--section:nth-of-type(odd) r-footer--list {
  columns: 1;
}

r-footer--list {
  display: block;
}

.arche r-footer--list {
  columns: 1;
}

.r-footer--title {
  font-family: "Open Sans", arial, sans-serif;
  font-size: 1.05rem;
  font-weight: bold;
}

.r-footer--link {
  display: block;
  text-align: left;
  text-transform: uppercase;
  margin: 0 8px 8px 0;
  padding-left: 0;
}

.r-footer--social {
  align-items: center;
  border-bottom: none;
  grid-column: 1 / span 3;
  margin: 32px 0 16px;
  padding: 16px;
  text-align: center;
  color: #0e0d0e;
}

.r-footer--social a {
  align-items: center;
  display: inline-flex;
  padding: 8px 16px;
}

.r-footer--social a:hover {
  text-decoration: none;
  color: #6a8c1f;
}

.r-footer--social r-icon {
  font-size: 2rem;
  margin-right: 8px;
}

.r-footer--logo {
  column-gap: 8px;
  display: flex;
  grid-area: logo;
  grid-column: 1 / span 2;
}

.r-footer--logo svg {
  max-width: 258px;
}

.r-footer--logo .logo-footer-white {
  display: none;
}

.r-footer--newsletter {
  grid-area: nl-abo;
  grid-column: 2;
  padding-left: 16px;
  padding-right: 16px;
  display: none;
}

.r-abonne .r-footer--newsletter {
  display: block;
}

.r-footer--newsletter h4 {
  color: #0e0d0e;
  font-size: 17px;
  font-family: "Work Sans", arial, sans-serif;
  font-weight: bold;
}

.r-footer--abo {
  grid-area: nl-abo;
  grid-column: 2;
}

.r-abonne .r-footer--abo {
  display: none;
}

.r-footer--abo p {
  color: #0e0d0e;
  font-size: 17px;
  font-family: "Work Sans", arial, sans-serif;
  font-weight: bold;
  margin-bottom: 8px;
}

.r-footer--abo .r-btn--primary {
  background-color: #a0ca4b;
  border: none;
  color: white;
  font-family: "Open Sans", arial, sans-serif;
  font-size: 18px;
}

.r-footer--abo .r-btn--primary:hover {
  background-color: #6a8c1f;
}

.r-footer--top {
  display: flex;
  align-items: center;
  align-self: start;
  font-size: .9rem;
  grid-area: top;
  grid-column: 3;
  grid-template-columns: 1fr 1fr 1fr;
  justify-self: right;
}

.r-footer--top:hover {
  color: #9f9f9f;
  text-decoration: none;
}

.r-footer--top r-icon {
  font-size: 2rem;
  margin-left: 16px;
}

.r-footer--copyright {
  grid-column: 1 / span 3;
}

.r-footer--source-sure {
  float: left;
  height: 40px;
  margin-right: 16px;
}

.r-footer--cim {
  float: right;
  height: 60px;
  margin-left: 16px;
}

.r-bottom--service {
  display: block;
}

.r-dropdown--search {
  background: white;
  border-bottom: 1px solid #0e0d0e;
  display: flex;
  justify-content: center;
  left: 0;
  margin: auto;
  padding: 16px;
  position: absolute;
  right: 0;
  top: 100%;
}

.r-dropdown--search .r-search--input {
  border: 1px solid #0e0d0e;
  border-radius: 4px;
  color: #0e0d0e;
  flex: 0 0 40%;
  margin-right: 20px;
  max-width: 1200px;
  padding: 8px;
}

.r-dropdown--search .r-search--submit {
  background: #a0ca4b;
  border: 0;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-weight: bold;
  padding: 4px 16px;
}

.r-dropdown--search .r-search--submit:hover {
  opacity: .8;
}

.r-sso--menu {
  background: #a0ca4b;
  display: flex;
  margin: 0;
  padding: 0;
}

.r-sso--menu .r-sso--item {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  list-style: none;
  padding: 16px;
}

.r-sso--menu .r-sso--item:hover {
  text-decoration: none;
}

.r-sso--menu .r-sso--item span {
  color: #6a8c1f;
}

.r-sso--menu .r-sso--item r-icon {
  background: white;
  border: 1px solid #6a8c1f;
  border-radius: 50%;
  box-shadow: 0 0 0 5px inset #a0ca4b;
  display: block;
  font-size: 25px;
  height: 60px;
  line-height: 56px;
  margin-bottom: 8px;
  text-align: center;
  width: 60px;
}

.r-connecte .r-sso--menu .r-sso--item .r-icon--user {
  background: #6a8c1f;
  color: white;
}

.r-abonne .r-sso--menu .r-sso--item .r-icon--user {
  background: #0e0d0e;
  border-color: #0e0d0e;
}

.r-sso--menu .r-sso--item .r-icon--newsletter {
  font-size: 50px;
}

.r-sso--menu .r-sso--user r-icon {
  background: #6a8c1f;
  color: white;
}

.r-connecte .r-sso--menu .r-sso--user r-icon {
  background: #0e0d0e;
  border-color: #0e0d0e;
}

.r-newsletter .newsletter--error {
  color: #d04343;
  display: none;
  font-weight: bold;
}

.r-newsletter .r-btn.r-btn--secondary {
  font-size: 16px;
  padding: 10px 16px;
}

.r-newsletter .r-newsletter--input {
  border: 1px solid #0e0d0e;
  border-radius: 5px;
  color: #0e0d0e;
  font-size: 16px;
  padding: 8px;
  width: 300px;
  position: relative;
}

.r-newsletter .r-newsletter--input::placeholder {
  color: #9f9f9f;
  font-size: 14px;
}

.r-newsletter .r-newsletter--input::after {
  width: 39px;
  height: 39px;
  position: absolute;
  right: 40px;
}

.r-newsletter .r-newsletter--checkbox {
  border: none;
  margin: 16px 0;
  padding: 0;
}

.r-newsletter .r-newsletter--checkbox .newsletter-checkbox--list {
  column-count: 2;
  padding: 0 10px;
}

.r-newsletter .r-newsletter--checkbox .newsletter-checkbox {
  margin: 0 5px 5px 0;
}

.r-newsletter .r-newsletter--checkbox legend {
  font-weight: bold;
  padding-bottom: 10px;
}

.r-newsletter .r-newsletter--checkbox input[type="checkbox"] {
  accent-color: #0e0d0e;
  appearance: checkbox;
  background-color: white;
  margin-right: 3px;
  height: 20px;
  width: 20px;
}

.r-newsletter .r-newsletter--checkbox.checkbox-sector label {
  display: initial;
  font-weight: normal;
}

.r-newsletter .r-newsletter--checkbox.checkbox-agree {
  position: relative;
}

.r-newsletter .r-newsletter--checkbox.checkbox-agree input {
  position: absolute;
}

.r-newsletter .r-newsletter--checkbox.checkbox-agree label {
  display: block;
  margin-left: 28px;
}

.r-newsletter .r-newsletter--checkbox.checkbox-agree label a {
  font-weight: bold;
  color: #0e0d0e;
}

.r-newsletter.newsletter--panel--thanks {
  display: none;
  font-weight: bold;
}

.r-newsletter .r-newsletter--detailed {
  display: none;
}

.panel-newsletters {
  background-color: #a0ca4b;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%23a2cd4c' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%23a5d04d' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%23a7d34f' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%23aad650' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%23ACD951' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%23a6d24e' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%23a1cb4c' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%239bc449' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%2396bd47' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%2390B644' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;
  border-radius: 10px;
  column-gap: 20px;
  display: grid;
  grid-column-start: 1;
  grid-column-end: -1;
  grid-template-areas: 'title  desc    form' '.      .       .';
  grid-template-columns: 25% 25% 50%;
  margin: 20px;
  padding: 40px;
}

.panel-newsletters .r-newsletter--title {
  font-size: 28px;
  font-weight: 700;
  grid-area: title;
  line-height: 32px;
  margin: 0;
}

.panel-newsletters .r-newsletter--title span {
  color: white;
}

.panel-newsletters .r-newsletter--desc {
  grid-area: desc;
}

.panel-newsletters .r-newsletter {
  grid-area: form;
  margin-left: 10px;
  margin-top: 5px;
}

.panel-newsletters .r-newsletter .r-newsletter--input {
  border: none;
  height: 43px;
}

.r-footer--newsletter {
  color: #0e0d0e;
}

.r-footer--newsletter .r-newsletter--input-group {
  display: inline-block;
  position: relative;
}

.r-footer--newsletter .r-newsletter--input-group .r-btn {
  background-color: #a0ca4b;
  height: 35px;
  position: absolute;
  right: 3px;
  top: 3px;
}

.r-footer--newsletter .r-newsletter--input-group .r-btn:before {
  color: white;
  content: "\eab5";
  font-family: main;
  font-size: 20px;
}

.r-footer--newsletter .r-newsletter--input-group .r-btn:hover {
  background-color: #0e0d0e;
}

.r-footer--newsletter .r-newsletter--input-group .r-btn--secondary {
  padding: 4px 10px;
}

.r-footer--newsletter .r-newsletter--detailed {
  max-width: 500px;
}

.r-selligent--pave-newsletter-immo .r-selligent--pave-content {
  background-color: #ffe500;
  color: #0e0d0e;
}

.r-selligent--pave-newsletter-emploi .r-selligent--pave-content {
  background-color: #209e97;
}

.r-selligent--pave-newsletter-eco .r-selligent--pave-content,
.r-selligent--pave-newsletter-eco .r-selligent--under-rs-content,
.r-selligent--under-rs-newsletter-eco .r-selligent--pave-content,
.r-selligent--under-rs-newsletter-eco .r-selligent--under-rs-content {
  background-color: #E83C35;
}

.r-selligent--pave-newsletter-eco .r-btn--secondary,
.r-selligent--under-rs-newsletter-eco .r-btn--secondary {
  background-color: #FED9D4;
  color: #E83C35;
}

.r-selligent--pave-offre-vds .r-selligent--pave-content {
  background-color: #9eb5d6;
  color: #E6332A;
}

.r-selligent--pave-offre-vds .r-btn--secondary {
  background-color: #233652;
  color: white;
}

.r-selligent--pave-offre-eco .r-selligent--pave-content {
  background-color: #f7d5d5 !important;
  color: #FF4034 !important;
}

.r-selligent--pave-offre-eco .r-btn--secondary {
  background-color: #FF4034 !important;
  color: white !important;
}

@media (min-width: 664px) {
  r-modal--content {
    max-height: calc(100vh - 160px);
  }

  .glider::-webkit-scrollbar {
    display: none;
  }

  r-pager {
    grid-column-start: 1;
    grid-column-end: -1;
  }

  .r-pub--rec > div > *,
  .r-pub--imu > div > *,
  .r-pub--flexs > div > *,
  .r-pub--flexm > div > *,
  .r-pub--flexl > div > * {
    position: sticky;
  }

  .r-pub--rec.-is-row-3 {
    grid-column-end: -1;
    grid-row: 3 / span 2;
  }

  .r-pub--rec:not(.r-imu--top):not([class*='-is-']) {
    grid-row: auto / span 2;
  }

  i-mini-panel > .r-pub--rec:not(.r-imu--top):not([class*='-is-']) {
    grid-column-end: -1;
    grid-row: 2 / span 2;
  }

  .r-pub--nat.-is-wide {
    grid-column: auto / span 2;
  }

  .-in-2-rows {
    grid-row: auto / span 2;
  }

  .-in-area-inread-top {
    grid-area: inread-top;
  }

  .-in-area-inread-mid {
    grid-area: inread-mid;
  }

  blockquote {
    font-size: 2rem;
  }

  blockquote::before {
    font-size: 3.4rem;
  }

  epigraph {
    font-size: 2rem;
  }

  .r-digiteka--title {
    font-size: 2.3rem;
    padding: 24px 0 16px;
  }

  .r-contact .r-article--header h1 {
    font-size: 3rem;
  }

  .r-contact label,
  .r-contact p {
    font-size: 1.3rem;
  }

  .r-mini-panel--menu {
    top: 110px;
  }

  .r-mini-panel--menu i-mini-panel {
    width: 350px;
    height: calc(100vh - 110px);
  }

  .r-menu--user {
    right: max(7.8vw, calc(50vw - 664px));
    top: 80px;
  }

  .r-mini-panel--menu {
    top: 110px;
  }

  .r-mini-panel--menu i-mini-panel {
    width: 350px;
    height: calc(100vh - 110px);
  }

  r-mini-panel .r-btn--more {
    order: 10;
  }

  i-mini-panel .r-pub--imu {
    grid-row: auto;
  }

  .r-m {
    top: -160px;
  }

  .r-mini-panel--title {
    margin: -1px 0;
    font-size: 2.3rem;
    grid-column-start: 1;
    grid-column-end: -1;
    padding: 24px 16px 16px 16px;
  }

  .r-mini-panel--offres-emploi .r-mini-panel--title .r-btn--more {
    color: #209e97;
    font-family: "Work Sans", arial, sans-serif;
    font-size: 0.9rem;
    font-weight: 700;
    text-decoration: underline;
    text-transform: uppercase;
  }

  .r-mini-panel--offres-emploi .r-mini-panel--title .r-btn--more:hover {
    text-decoration: none;
  }

  .r-mini-panel--offres-emploi .r-mini-panel--title .r-btn--more r-icon {
    display: none;
    margin-right: 0;
  }

  .r-mini-panel-immo-list .r-mini-panel--title,
  .r-mini-panel--offres-emploi .r-mini-panel--title {
    padding-left: 8px;
    padding-right: 8px;
  }

  .r-btn--macommune {
    align-self: end;
    margin-bottom: 4px;
    margin-left: 16px;
  }

  .r-mini-panel--section {
    grid-column-start: 1;
    grid-column-end: -1;
    margin-left: calc(-50vw + 50%);
    width: 100vw;
    margin-top: 32px;
    margin-top: 0;
    padding-bottom: 32px;
    padding-top: 16px;
  }

  .r-mini-panel--section i-mini-panel {
    display: grid;
    grid-auto-flow: row dense;
    grid-gap: 1px;
  }

  .r-mini-panel--section.-is-mini-panel--horoscope i-mini-panel {
    column-gap: 4px;
    grid-template-areas: '.';
    grid-template-columns: repeat(12, 1fr);
  }

  .r-mini-panel--section.-is-mini-panel--horoscope .r-mini-panel--title {
    grid-column-start: 1;
    grid-column-end: -1;
  }

  .r-mini-panel--section.-is-mini-panel--2 i-mini-panel {
    grid-template-areas: '. .';
    grid-template-columns: 50% 50%;
  }

  .r-mini-panel--partenaires i-mini-panel {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .r-mini-panel--partenaires .r-mini-panel--title {
    width: 100%;
  }

  .r-mini-panel--partenaires a {
    flex: 0 0 10%;
    padding: 16px;
  }

  .r-mini-panel--recruteurs {
    grid-column-start: 1;
    grid-column-end: -1;
    margin-left: calc(-50vw + 50%);
    width: 100vw;
  }

  .r-mini-panel--services,
  .r-mini-panel--tabs {
    grid-column-start: 1;
    grid-column-end: -1;
    margin-left: calc(-50vw + 50%);
    width: 100vw;
    z-index: 7;
  }

  .r-mini-panel--services i-mini-panel > *,
  .r-mini-panel--tabs i-mini-panel > * {
    background: none;
  }

  .r-mini-panel--tabs {
    padding-bottom: 48px;
  }

  .r-mini-panel--service i-mini-panel {
    background: white;
    grid-template-areas: '. .';
    padding-top: 32px;
  }

  .r-mini-panel--list {
    grid-column-start: 1;
    grid-column-end: -1;
    margin-left: calc(-50vw + 50%);
    width: 100vw;
    z-index: 8;
  }

  .r-mini-panel--list i-mini-panel {
    display: grid;
    grid-auto-flow: row dense;
    grid-gap: 1px;
    grid-template-areas: '. .';
    grid-template-columns: 50% 50%;
  }

  .r-mini-panel--list .r-mini-panel--title {
    grid-column-end: auto;
    padding: 48px 16px 16px;
  }

  .r-mini-panel--list .r-viewmode--panel-left i-article {
    border-radius: 32px;
    left: 16px;
    margin-top: -80px;
    width: 80%;
  }

  .r-froomle--anonyme,
  .r-froomle--cookies {
    height: 300px;
  }

  .r-froomle--anonyme a,
  .r-froomle--cookies a {
    font-size: .9rem;
  }

  r-mini-panel.-has-panel i-mini-panel {
    background: white;
    margin-top: -1px;
    padding: 0 2px;
  }

  r-mini-panel.-has-panel .r-mini-panel--title {
    padding: 16px;
  }

  r-mini-panel.-has-panel r-panel {
    margin: 0 16px 16px;
  }

  r-mini-panel.-has-panel .r-btn--more::after {
    margin-left: 16px;
    margin-right: 0;
  }

  r-mini-panel.-has-panel .r-btn--more::before {
    margin-left: 0;
    margin-right: 16px;
  }

  r-mini-panel.-has-imu i-mini-panel {
    background: white;
    column-gap: 32px;
    display: grid;
  }

  r-mini-panel.-has-bg {
    z-index: 10;
  }

  r-mini-panel.-has-bg i-mini-panel > * {
    background: none;
  }

  r-mini-panel.-has-bg .r-meta-info span,
  r-mini-panel.-has-bg .r-meta-info time {
    font-size: 1rem;
  }

  .-is-livetv .r-viewmode--article,
  .-is-livetv .r-viewmode--manchette {
    display: contents;
  }

  .-is-livetv .r-viewmode--manchette .r-article--img,
  .-is-livetv .r-viewmode--manchette #\31 _visible_player_brother,
  .-is-livetv .r-viewmode--manchette #\30 _visible_player_brother {
    aspect-ratio: 16/9;
    grid-area: bigimg;
    height: auto !important;
    margin-left: 16px;
  }

  .-is-livetv .r-viewmode--manchette i-article {
    grid-area: bigtitle;
  }

  .-is-livetv .r-viewmode--manchette time {
    margin: 24px 0 16px;
  }

  .-is-livetv .r-viewmode--manchette .r-article--title {
    font-size: 2rem;
    margin-bottom: 16px;
  }

  .-is-livetv .r-viewmode--niveau-1 {
    padding: 8px 0;
  }

  .-is-livetv .r-viewmode--niveau-1 .r-viewmode--article {
    display: grid;
  }

  .-is-tabs .r-tabs ul {
    justify-content: center;
  }

  .-is-mp-aussi-en i-mini-panel > *,
  .-is-mp-gray-light i-mini-panel > *,
  .-is-mp-primary-light i-mini-panel > *,
  .-is-mp-primary i-mini-panel > * {
    background: none;
  }

  .-has-bg-emploi-light {
    width: auto;
  }

  .r-mini-panel-serviciels r-panel {
    padding: 16px 24px;
  }

  .r-mini-panel-serviciels r-panel--body {
    font-size: 20px;
  }

  .r-mini-panel-serviciels r-panel--body r-icon {
    font-size: 40px;
  }

  .-is-horizontal i-mini-panel {
    column-gap: 32px;
    display: flex;
    padding: 0 8px;
  }

  .-is-horizontal r-panel {
    grid-template-columns: 2fr 1fr;
    flex: 2 1 0;
  }

  .-is-horizontal .r-btn r-icon {
    display: none;
  }

  .-is-vertical {
    grid-area: direct;
  }

  .-is-vertical r-panel--body {
    flex-direction: column;
  }

  .-is-vertical r-panel--footer {
    padding-top: 16px;
  }

  .-is-legales--hp i-mini-panel {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 0;
  }

  .-is-legales--hp r-btn {
    padding: 0 16px;
    width: 50%;
  }

  .arche .-is-legales--hp r-btn {
    padding: 0 16px 0 0;
  }

  .arche .-is-legales--hp r-btn:last-of-type {
    padding: 0;
  }

  r-modal--content {
    padding: 32px 48px;
  }

  .r-modal--limit-connect .r-modal--header {
    margin: -32px -48px 32px;
    padding: 16px 48px;
  }

  .r-modal--limit-connect .r-modal--title {
    font-size: 2rem;
  }

  .r-modal--macommune .r-modal--body.-is-connecte {
    grid-column-gap: 24px;
    grid-template-areas: '. . .';
    grid-template-columns: repeat(3, calc((100% - 48px) / 3));
  }

  .r-modal--macommune .r-modal--body.-is-anonyme {
    display: flex;
  }

  r-pager {
    padding: 64px 16px;
  }

  .r-panel--carousel {
    grid-column-start: 1;
    grid-column-end: -1;
  }

  .r-panel--carousel r-panel--header {
    padding: 8px 0 24px;
  }

  .r-panel--carousel r-panel--footer {
    padding: 0;
  }

  .r-panel--carousel .owl-stage {
    margin-left: -50px;
  }

  .r-panel--carousel .owl-item {
    padding: 16px;
  }

  .r-panel--info-commune {
    padding: 60px 60px 40px;
  }

  .r-panel--info-commune.lazyloaded {
    background-image: url("/sites/all/themes/enacarbon_vdn/images/communes.jpg");
  }

  .r-panel--info-commune h2 {
    font-size: 2.3rem;
    padding: 24px 0;
  }

  .r-panel--info-commune h2::before {
    background: white;
    content: '';
    -webkit-flex: 1;
    flex: 1;
    height: 1px;
    margin: 16px;
  }

  .r-panel--info-commune h2::after {
    margin: 16px;
  }

  .r-panel--info-commune r-menu {
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
  }

  .r-panel--info-commune form {
    margin: 16px auto 32px;
    max-width: 450px;
  }

  .r-panel--info-commune-immobilier {
    padding: 16px;
  }

  .r-panel--info-commune-immobilier h2 {
    padding-top: 0;
  }

  .r-panel--macommune,
  .r-menu--dropdown {
    padding: 32px;
  }

  .r-panel--chronique {
    border-radius: 12px;
  }

  .r-panel--chronique .r-panel--title {
    font-size: 1.05rem;
  }

  .r-panel--edito .r-img--background {
    border-radius: 8px;
  }

  .-is-kroll.r-panel--image {
    border-radius: 8px;
    padding: 8px 16px 16px;
  }

  .r-panel--boutons {
    margin: 0;
  }

  .r-panel--boutons r-panel--body {
    display: flex;
    margin: 0 16px;
  }

  .r-panel--titre {
    border-radius: 12px;
  }

  .r-panel--titre .r-panel--title {
    font-size: 1.05rem;
  }

  .r-panel--top-titre r-panel--list {
    column-gap: 32px;
    display: flex;
    flex-wrap: wrap;
  }

  .r-panel--top-titre .r-panel--item {
    margin-top: 24px;
    padding-bottom: 24px;
  }

  .r-panel--top-titre .r-panel--item {
    border-bottom: 1px solid #f3f3f3;
    flex: 0 0 calc(50% - 16px);
  }

  .r-panel--top-titre .r-panel--item {
    border-bottom: 1px solid #e6e6e6;
  }

  .r-panel--edition-immo {
    align-items: center;
    display: grid;
    grid-column-start: 1;
    grid-column-end: -1;
    grid-template-columns: max(calc(7.8vw + 32px), calc(50vw - 664px)) 3fr 2fr;
    grid-template-areas: '. . .';
    margin-left: calc(-50vw + 50%);
    margin-top: 1rem;
    width: 100vw;
  }

  .r-panel--edition-immo h2 {
    font-size: 30px;
    margin-top: 16px;
  }

  .r-panel--edition-immo form {
    margin-bottom: 16px;
  }

  .r-panel--edition-immo r-panel--body {
    grid-column: 2;
    padding-left: 16px;
  }

  .r-panel--edition-immo label {
    font-size: 1.15rem;
  }

  .r-panel--edition-immo .r-form--group {
    max-width: 500px;
    width: 80%;
  }

  .r-panel--edition-immo img {
    grid-column: 3;
    justify-self: end;
  }

  .r-panel--edition-immo .-is-location-set {
    padding: 16px 16px 32px;
  }

  r-pager {
    padding: 64px 16px;
  }

  .rossel-leader-middle > div:before,
  .rossel-leader-bottom > div:before {
    left: auto;
    margin-bottom: 4px;
    position: relative;
    top: auto;
    transform: none;
  }

  .r-search--widget {
    display: none;
  }

  .r-selligent--bandeau .r-selligent--body {
    display: flex;
    justify-content: flex-start;
  }

  .r-selligent--menu {
    display: block;
    float: right;
    width: 350px;
  }

  .r-selligent--overlay .r-selligent--overlay-content {
    width: auto;
  }

  .r-viewmode--o-list article,
  .r-viewmode--list article {
    grid-template-areas: 'list' '.';
  }

  .r-viewmode--o-list i-article,
  .r-viewmode--list i-article {
    background: rgba(106, 140, 31, 0.6);
    color: white;
    grid-area: list;
    margin-bottom: 12px;
    padding: 8px 12px;
    transition: background .3s ease;
    z-index: 13;
  }

  .r-viewmode--o-list .r-article--img,
  .r-viewmode--list .r-article--img {
    grid-area: list;
  }

  .r-viewmode--o-panel .r-article--title,
  .r-viewmode--panel .r-article--title,
  .r-viewmode--o-panel-left .r-article--title,
  .r-viewmode--panel-left .r-article--title,
  .r-viewmode--o-panel-3row .r-article--title,
  .r-viewmode--panel-3row .r-article--title,
  .r-viewmode--panel-center .r-article--title {
    font-size: 2.3rem;
  }

  .r-viewmode--o-panel i-article,
  .r-viewmode--panel i-article,
  .r-viewmode--o-panel-left i-article,
  .r-viewmode--panel-left i-article,
  .r-viewmode--o-panel-3row i-article,
  .r-viewmode--panel-3row i-article,
  .r-viewmode--panel-center i-article {
    padding: 24px;
    width: 75%;
    border-left: 2px solid #6a8c1f;
  }

  .r-viewmode--o-panel-left i-article,
  .r-viewmode--panel-left i-article,
  .r-viewmode--o-panel-3row i-article,
  .r-viewmode--panel-3row i-article {
    padding: 24px;
    width: 75%;
    border-left: 2px solid #6a8c1f;
  }

  .r-viewmode--o-panel,
  .r-viewmode--panel,
  .r-viewmode--o-panel-left,
  .r-viewmode--panel-left {
    grid-column: auto / span 2;
    grid-row: auto / span 2;
  }

  .r-link--grid-immo {
    grid-template-areas: '. .';
    grid-template-columns: 1fr 1fr;
  }

  .r-viewmode--o-panel-center .r-article--title,
  .r-viewmode--panel-center .r-article--title {
    font-size: 2.3rem;
  }

  .r-viewmode--o-panel-center .r-article--list,
  .r-viewmode--panel-center .r-article--list {
    margin: 24px auto 0;
    width: 80%;
  }

  .r-viewmode--image-title i-article {
    background: white;
    border-left: 2px solid #6a8c1f;
    padding: 16px 0 16px 16px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 40%;
  }

  .r-viewmode--o-dossier .r-article--title,
  .r-viewmode--dossier .r-article--title {
    background: white;
    font-size: 2rem;
    margin-right: 25%;
    margin-top: -80px;
    padding: 8px;
    font-weight: 700;
    padding: 16px;
  }

  .r-viewmode--o-dossier .r-article--list,
  .r-viewmode--o-dossier r-article--chapo,
  .r-viewmode--o-dossier r-article--meta,
  .r-viewmode--dossier .r-article--list,
  .r-viewmode--dossier r-article--chapo,
  .r-viewmode--dossier r-article--meta {
    padding-left: 8px;
    padding-left: 16px;
  }

  .r-viewmode--o-dossier .r-aside--title,
  .r-viewmode--dossier .r-aside--title {
    font-size: 1.05rem;
  }

  .r-viewmode--o-dossier {
    grid-area: dossier;
  }

  .r-viewmode--horizontal .r-article--title,
  .r-viewmode--o-horizontal .r-article--title {
    font-size: 1.8rem;
  }

  .r-selligent--megamenu-container .r-selligent--title {
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 0;
  }

  .r-selligent--megamenu-container .r-selligent--logo-carre {
    padding-right: 16px;
  }

  .r-selligent--megamenu-container h2 {
    text-align: left;
  }

  .r-selligent--megamenu-container .r-selligent--baseline {
    font-size: 14px;
  }

  .r-selligent--megamenu-container .r-btn {
    font-size: 14px;
  }

  .r-selligent--pave-offre-container .r-selligent--logo {
    padding-right: 16px;
  }

  .r-selligent--pave-offre-container .r-selligent--btn {
    display: block;
  }

  .r-selligent--pave-offre-container .r-selligent--baseline {
    margin-bottom: 16px;
    width: 100%;
  }

  .page-archive-index .pane-dpiindex-dpiindex-dates .item-list li a,
  .page-archive-index .pane-dpiindex-dpiindex-dates .date-list li a,
  .page-archive-index .pane-dpiindex-dpiindex-dates .index-articles:first-of-type li a,
  .page-archive-index .pane-dpiindex-dpiindex .item-list li a,
  .page-archive-index .pane-dpiindex-dpiindex .date-list li a,
  .page-archive-index .pane-dpiindex-dpiindex .index-articles:first-of-type li a,
  .page-archive-index .pane-dpiindex-dpiindex-navigation-links .item-list li a,
  .page-archive-index .pane-dpiindex-dpiindex-navigation-links .date-list li a,
  .page-archive-index .pane-dpiindex-dpiindex-navigation-links .index-articles:first-of-type li a {
    font-size: 1rem;
  }

  .page-archive-index .pane-dpiindex-dpiindex-dates h3 {
    font-size: 2.3rem;
  }

  .page-archive-index .pane-dpiindex-dpiindex .index-articles h3 {
    font-size: 2.3rem;
  }

  .page-archive-index .pane-dpiindex-dpiindex-navigation-links:first-of-type h3 {
    font-size: 2.3rem;
  }

  r-footer--section {
    display: block;
    grid-column: auto;
    margin-top: 32px;
  }

  .r-footer--social {
    display: flex;
    justify-content: center;
  }

  .r-footer--logo {
    column-gap: 16px;
    grid-column: 1;
  }

  .r-footer--top r-icon {
    font-size: 2.7rem;
  }
}

@media (min-width: 998px) {
  .r-pub--rec > div {
    min-height: 600px;
  }

  .r-img:hover {
    opacity: .9;
    transform: scale(1.05);
  }

  .r-mini-panel--section.-is-mini-panel--4 i-mini-panel {
    grid-template-areas: '. . . .';
    grid-template-columns: 25% 25% 25% 25%;
  }

  .r-mini-panel--services i-mini-panel,
  .r-mini-panel--tabs i-mini-panel {
    margin-top: 16px;
  }

  .r-mini-panel--services .r-panel--horizontal r-panel--header,
  .r-mini-panel--tabs .r-panel--horizontal r-panel--header {
    border-top: 1px solid #6a8c1f;
  }

  .-is-tabs .r-tabs--btn {
    font-size: 1.5rem;
  }

  .-is-tabs tab {
    margin: 0;
  }

  .-has-bg-emploi-light {
    border-radius: 0 0 20px 20px;
    margin: auto;
  }

  i-mini-panel .r-panel--carousel {
    width: calc(100% - 16px);
  }

  .r-panel--macommune r-panel--body,
  .r-menu--dropdown r-panel--body {
    display: grid;
    margin: auto;
  }

  .r-panel--macommune r-panel--body {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    max-width: 800px;
  }

  .r-menu--dropdown r-panel--body {
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 850px;
    padding-left: 26px;
  }

  .r-panel--boutons .r-panel--link {
    width: 20%;
  }

  .r-panel--top-titre .r-panel--item::before {
    align-items: center;
    font-size: 5rem;
    padding-left: 24px;
  }

  .r-panel--produits r-panel--list {
    column-gap: 48px;
  }

  .rossel-leader-bottom > div {
    min-height: 0;
  }

  .r-pub--leader.rossel-leader-middle-top > iframe,
  .r-pub--leader.rossel-leader-middle-top > div,
  .r-pub--leader.rossel-leader-middle-bottom > iframe,
  .r-pub--leader.rossel-leader-middle-bottom > div {
    min-height: 132px;
  }

  .r-pub--leader.rossel-leader-middle-top {
    grid-area: leader-middle;
    margin-left: 0;
    margin-top: 16px;
    width: 100%;
  }

  .rossel-imu-top > i-pub,
  .rossel-imu-bottom > i-pub {
    min-height: 632px;
  }

  .r-viewmode--o-list i-article:hover,
  .r-viewmode--list i-article:hover {
    background: rgba(106, 140, 31, 0.9);
  }

  .r-viewmode--image-title .r-article--img {
    width: 70%;
  }

  .r-viewmode--image-title .r-article--title {
    font-size: 2rem;
  }

  r-footer--section:nth-of-type(odd) r-footer--list {
    columns: 2;
  }

  r-footer--list {
    columns: 2;
  }

  .r-footer--title {
    padding-bottom: 16px;
  }
}

@media (min-width: 1330px) {
  .r-btn--collapse {
    margin-left: -1vw;
  }

  .r-grand-format .r-menu--share-link r-icon,
  .r-article .r-menu--share-link r-icon {
    background-color: #f3f3f3;
  }

  .r-digiteka--title {
    margin: auto max(7.8vw, calc(50vw - 664px)) 16px;
  }

  i-mini-panel {
    margin: auto max(7.8vw, calc(50vw - 664px));
  }

  .r-mini-panel--section i-mini-panel {
    grid-template-areas: '. . . .';
    grid-template-columns: 25% 25% 25% 25%;
    margin: auto max(1px, calc(50vw - 664px));
  }

  .r-mini-panel--section.-is-mini-panel--3 i-mini-panel {
    grid-template-areas: '. . . ';
    grid-template-columns: 33.33% 33.33% 33.33%;
  }

  .r-mini-panel--recruteurs i-mini-panel {
    grid-template-areas: '. . .';
    grid-template-columns: 1fr 1fr 1fr;
  }

  .r-mini-panel--service i-mini-panel {
    grid-template-columns: 75% 25%;
  }

  .r-mini-panel--list .r-viewmode--panel-left .r-viewmode--article {
    margin-top: 16px;
  }

  .r-mini-panel--article r-pub {
    grid-column: 4;
    grid-row: 2 / span 2;
  }

  .r-mini-panel--froomle w-viewmode {
    display: contents;
    overflow: inherit;
  }

  .r-mini-panel--froomle w-viewmode r-viewmode {
    width: auto;
  }

  r-mini-panel.-has-imu i-mini-panel {
    grid-template-columns: 1fr 1fr 1fr 332px;
  }

  .-is-livetv i-mini-panel {
    grid-column-gap: 32px;
    grid-template-areas: '. . .' 'bigimg bigimg bigtitle' 'bigimg bigimg .' 'bigimg bigimg .';
    grid-template-columns: repeat(3, calc((100% - 64px) / 3));
  }

  .-is-tabs .r-tabs {
    margin: auto max(7.8vw, calc(50vw - 664px));
  }

  .-is-tabs tab {
    grid-template-areas: '. . . ';
    grid-template-columns: 33.33% 33.33% 33.33%;
  }

  i-mini-panel .r-panel--carousel {
    grid-column: span 3;
  }

  .r-panel--edito .-is-square {
    display: none;
  }

  .-is-kroll.r-panel--image {
    grid-column: span 2;
    grid-row: span 2;
  }

  .-is-kroll .r-article--img {
    margin: 0 48px;
  }

  .r-panel--top-titre .r-panel--title {
    font-size: 1.5rem;
  }

  .r-panel--horizontal .r-panel--item,
  .r-panel--wrap .r-panel--item {
    flex: 1;
    padding: 16px;
  }

  .r-panel--wrap .r-panel--item {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .r-panel--edition-immo h2 {
    width: 60%;
  }

  .r-panel--edition-immo img {
    display: block;
  }

  .r-panel--paywall {
    margin-right: -25%;
    margin-left: -25%;
  }

  .r-viewmode--o-panel-3row,
  .r-viewmode--panel-3row {
    grid-column: auto / span 2;
    grid-row: auto / span 3;
  }

  .r-viewmode--o-panel-center,
  .r-viewmode--panel-center {
    grid-column: 2 / span 2;
    grid-row: auto / span 2;
  }

  .r-mini-panel--section.-is-mp-markets i-mini-panel {
    grid-template-areas: 'mp-title   mp-title    mp-title    mp-title' 'mp-trends  mp-trends   mp-latest   mp-latest';
  }

  .r-mini-panel--section.-is-mp-markets-home i-mini-panel {
    grid-template-areas: 'intro   sections    sections    latest' '.  .   .   .';
  }

  r-footer {
    padding: 32px max(calc(7.8vw + 16px), calc(50vw - 664px)) 60px;
  }
}

@media (max-width: 1920px) {
  .r-mini-panel--menu {
    max-width: 1920px;
  }

  .r-mini-panel--menu {
    max-width: 1920px;
  }
}

@media (max-width: 1329px) {
  .markets-home--sectionlist {
    max-height: initial;
  }
}

@media (max-width: 997px) {
  .r-contact {
    padding: 32px 16px;
  }

  .r-contact .form-select {
    width: 100%;
  }

  .r-mini-panel--service .r-panel--horizontal {
    padding: 16px;
  }

  .-is-livetv i-mini-panel {
    display: block;
  }

  .-is-tabs .r-tabs ul {
    flex-wrap: inherit;
  }

  .-is-kiosque i-mini-panel {
    display: flex;
    flex-direction: column-reverse;
  }

  .r-panel--macommune r-panel--body,
  .r-menu--dropdown r-panel--body {
    columns: 2;
  }

  .r-panel--macommune .r-search--autocomplete,
  .r-menu--dropdown .r-search--autocomplete {
    font-size: 0.9rem;
  }

  .r-panel--macommune r-panel--body {
    columns: 2;
  }

  .r-panel--edito i-panel {
    background-position: inherit;
    grid-template-areas: 'title title' 'body img';
    grid-template-rows: auto;
    grid-template-columns: 2fr 1fr;
    grid-gap: 16px;
    padding: 16px;
  }

  .r-panel--edito r-panel--header {
    grid-area: title;
    padding: 0 0 8px;
  }

  .r-panel--edito r-panel--body {
    align-self: flex-start;
    grid-area: body;
    padding: 0;
    text-align: left;
  }

  .r-panel--edito r-panel--body h4 {
    font-size: 1.05rem;
    margin: 0;
  }

  .r-panel--edito r-panel--img {
    grid-area: img;
  }

  .r-panel--top-titre .r-panel--item {
    grid-template-areas: 'num titre';
    grid-template-columns: 1fr 4fr;
  }

  .r-panel--top-titre .r-panel--item {
    grid-template-areas: 'num titre' 'num meta';
  }

  .r-panel--top-titre .r-panel--img {
    display: none;
  }

  .r-panel--horizontal,
  .r-panel--wrap {
    padding: 16px 0 16px 16px;
  }

  .r-pub--inread > i-pub > div {
    padding: 16px 0;
  }

  .r-viewmode--horizontal .r-viewmode--article .r-article--img,
  .r-viewmode--o-horizontal .r-viewmode--article .r-article--img {
    aspect-ratio: auto;
  }

  .r-viewmode--horizontal .r-viewmode--article .r-article--img::after,
  .r-viewmode--o-horizontal .r-viewmode--article .r-article--img::after {
    top: 8px;
  }

  .gr-nav-articles {
    margin: 32px 16px 16px;
  }

  .gr-nav-articles li:first-child a,
  .gr-nav-articles li:last-child a {
    font-size: 0;
  }

  .gr-nav-articles a {
    padding: 12px 16px;
  }

  .gr-nav-articles a r-icon {
    font-size: 1rem;
  }

  .gr-nav-articles a r-icon.r-icon--arrow-left {
    margin-right: 0;
  }

  .gr-nav-articles a r-icon.r-icon--arrow-right {
    margin-left: 0;
  }

  .gr-nav-articles li:nth-of-type(2) a {
    padding: 8px 16px;
  }

  .panel-markets-une-trends ul {
    column-count: 1;
  }

  r-footer--top {
    grid-template-areas: "logo top" "nl-abo nl-abo" ". .";
  }

  .r-footer--newsletter {
    grid-column-start: 1;
    grid-column-end: -1;
    padding-top: 16px;
  }

  .r-footer--abo {
    grid-column-start: 1;
    grid-column-end: -1;
    padding-top: 8px;
    text-align: center;
  }

  .r-footer--top {
    grid-column: 2;
  }

  .panel-newsletters .r-newsletter--title {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 16px;
  }
}

@media (max-width: 663px) {
  .glider::-webkit-scrollbar {
    height: 3px;
    opacity: 1;
    -webkit-appearance: none;
    width: 7px;
  }

  .glider::-webkit-scrollbar-thumb {
    background-color: rgba(230, 230, 230, 0.25);
    border-radius: 99px;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.25);
    opacity: 1;
  }

  .r-pub--nat {
    background: none;
  }

  .r-grand-format .r-menu--share-link r-icon,
  .r-article .r-menu--share-link r-icon {
    font-size: 1.7rem;
    height: 30.4px;
    width: 30.4px;
  }

  .r-carousel--kiosque {
    padding: 0;
    margin-right: -16px;
    margin-left: -8px;
    width: auto;
  }

  .r-mini-panel--menu i-mini-panel {
    padding-bottom: 70px;
    transform: translateX(-100%);
    transition: transform .3s ease;
  }

  .r-mini-panel--menu.-is-toggle i-mini-panel {
    transform: translateX(0);
  }

  .r-mini-panel--menu i-mini-panel {
    transform: translateX(-100%);
    transition: transform .3s ease;
  }

  .r-mini-panel--menu.-is-toggle i-mini-panel {
    transform: translateX(0);
  }

  r-mini-panel {
    margin-bottom: 16px;
    padding: 0 16px 16px;
  }

  .r-mini-panel--title {
    padding-right: 0;
  }

  .r-mini-panel--title .r-btn--more {
    order: 10;
  }

  .-is-ma-commune .r-mini-panel--title {
    grid-template-columns: 30px auto 110px;
  }

  .-is-ma-commune .r-mini-panel--title::after {
    display: none;
  }

  .r-mini-panel-immo-list .r-mini-panel--title span {
    display: none;
  }

  .r-mini-panel-immo-list .r-mini-panel--title,
  .r-mini-panel--offres-emploi .r-mini-panel--title {
    font-size: 1.05rem;
    padding: 24px 0 16px;
  }

  .r-btn--macommune span {
    display: none;
  }

  .r-mini-panel--services r-panel--header {
    margin-bottom: 16px;
  }

  .r-mini-panel--services r-panel--header::after,
  .r-mini-panel--services .r-mini-panel--title::after {
    padding-right: 0;
  }

  .r-mini-panel--services r-panel--header::before,
  .r-mini-panel--services .r-mini-panel--title::before {
    padding-left: 0;
  }

  .r-mini-panel--list .r-viewmode--panel-left {
    padding: 0 32px 16px 16px;
    margin: 0;
  }

  .r-mini-panel--froomle w-viewmode r-viewmode {
    padding: 16px;
  }

  .r-mini-panel--froomle w-viewmode r-viewmode article {
    display: block;
  }

  .r-mini-panel--froomle w-viewmode r-viewmode figure {
    margin-bottom: 12px;
  }

  .r-froomle--anonyme,
  .r-froomle--cookies {
    background-size: auto;
    background-position: left;
    padding: 32px 24px;
  }

  r-mini-panel.-has-panel .r-mini-panel--title {
    padding: 16px;
  }

  .-has-subtitle .r-viewmode--panel r-article--chapo {
    display: none;
  }

  .-is-swipable {
    position: relative;
  }

  .-is-swipable i-mini-panel {
    display: flex;
    overflow: auto;
    padding: 80px 0 40px;
  }

  .-is-swipable i-mini-panel > * {
    flex: 0 0 80%;
    margin: 8px;
  }

  .-is-swipable.-is-mini-panel--horoscope i-mini-panel > * {
    flex: 0 0 24%;
    margin: 8px;
  }

  .-is-swipable .r-mini-panel--title {
    margin: 0;
    position: absolute;
    top: 0;
    width: calc(100% - 32px);
  }

  .-is-swipable .r-btn--more {
    bottom: 0;
    margin: 0;
    position: absolute;
    width: calc(100% - 32px);
  }

  .-is-livetv .r-viewmode--manchette .r-article--img {
    margin-left: auto;
    margin-right: auto;
  }

  .-is-livetv .r-viewmode--manchette .r-article--title {
    padding: 0;
    margin: 0;
  }

  .-is-tabs .r-tabs ul li {
    flex: 0 0 auto;
  }

  .-is-tabs tab r-viewmode:last-child,
  .-is-tabs tab r-viewmode:nth-last-child(2) {
    display: none;
  }

  .r-mini-panel-serviciels r-panel:first-child {
    margin-bottom: 12px;
  }

  .-is-horizontal {
    padding: 0 16px 16px;
  }

  .-is-horizontal .r-btn {
    border-radius: 50%;
    font-size: 0;
    height: 40px;
    width: 40px;
  }

  .-is-panel-right.r-mini-panel--section .r-viewmode--panel r-article--chapo {
    display: none;
  }

  .-is-panel-right.r-mini-panel--section .r-viewmode--panel r-article--meta time {
    font-size: 0.75rem;
  }

  .-is-panel-right.r-mini-panel--section .r-viewmode--niveau-2 r-article--meta time {
    font-size: 0.75rem;
  }

  .-is-mp-rulabe.r-mini-panel--section .r-mini-panel--title {
    padding-bottom: 0;
  }

  .-is-mp-rulabe.r-mini-panel--section .rulabe {
    margin-left: -5px;
    margin-right: -5px;
  }

  .-is-mp-jobbo.r-mini-panel--section .panel-jobbo-job {
    margin-left: 0;
  }

  .-is-mp-jobbo.r-mini-panel--section .panel-jobbo-cta {
    background-position: right 60px bottom 90px, center;
    border-bottom: 1px solid #CECEC9;
    min-height: 280px;
    margin-bottom: 32px;
    margin-left: 0;
  }

  .-is-mp-jobbo.r-mini-panel--section .panel-jobbo-cta h3 {
    padding: 32px 80px 32px 70px;
  }

  .r-modal--macommune .r-modal--title {
    font-size: 1.3rem;
    line-height: 1.2;
    margin: 0 8px 24px;
  }

  .r-modal--macommune .r-modal--body {
    max-height: calc(100vh - 350px);
    overflow-y: auto;
  }

  .r-modal--macommune .r-modal--body .r-btn {
    grid-column: span 2;
    width: fit-content;
    margin: auto;
  }

  .r-modal--macommune .r-modal--footer {
    font-size: .8rem;
  }

  .-is-kiosque {
    padding: 0;
  }

  .-is-kiosque .r-carousel--kiosque {
    padding: 0 32px 16px;
  }

  .-is-kiosque r-panel--footer {
    margin-bottom: 32px;
  }

  .-is-kiosque r-panel--footer .r-btn {
    justify-content: center;
    width: 180px;
  }

  .r-panel--carousel r-panel--header::before {
    margin: 16px 16px 16px 0;
  }

  .r-panel--carousel r-panel--header::after {
    margin: 16px 0 16px 16px;
  }

  .r-panel--info-commune r-menu::after {
    background: linear-gradient(to left, #6a8c1f, rgba(106, 140, 31, 0));
    color: white;
    content: "\ea03";
    display: inline-block;
    font-family: 'main';
    padding: 8px 12px 8px 24px;
    pointer-events: none;
    position: absolute;
    right: 10px;
  }

  .r-panel--info-commune-immobilier r-menu::after {
    background: linear-gradient(to left, #ffe500, rgba(255, 229, 0, 0));
    right: 16px;
  }

  .r-panel--edito {
    min-height: 60vw;
    overflow: hidden;
  }

  .r-panel--edito .r-panel--title {
    font-size: 1.3rem;
  }

  .r-panel--edito .r-panel--title {
    width: 60%;
  }

  .r-panel--edito .r-img--background {
    bottom: 0;
    position: absolute;
  }

  .r-panel--edito .-is-vertical {
    display: none;
  }

  .r-panel--edito {
    margin-bottom: 16px;
  }

  .r-panel--top-titre {
    margin: -1px 0 0;
  }

  .r-panel--top-titre .r-panel--item::before {
    justify-self: center;
  }

  .r-panel--top-titre .r-panel--item r-panel--meta {
    font-size: 0.75rem;
  }

  .r-panel--horizontal .r-panel--item,
  .r-panel--wrap .r-panel--item {
    flex: 0 0 85%;
    margin-right: 16px;
  }

  .r-panel--section-description {
    padding: 16px;
  }

  .r-panel--section-description h2 {
    font-size: 1.2rem;
    margin-bottom: 16px;
  }

  .r-panel--edition-immo {
    padding: 24px 24px 32px 24px;
  }

  .r-panel--edition-immo h2 {
    font-size: 25px;
    text-align: center;
  }

  .r-panel--edition-immo .-is-location-set {
    text-align: center;
  }

  .pane-enrichissement {
    padding: 0 16px 32px 16px;
  }

  .r-panel--paywall::before {
    top: -390px;
  }

  .r-panel--paywall r-paywall--btns .r-btn--primary {
    font-size: 1.3rem;
  }

  .r-panel--paywall r-paywall--footer .r-btn--secondary {
    margin-bottom: 16px;
  }

  .-has-bg [class*='rossel-native-'],
  .-has-bg-light [class*='rossel-native-'],
  .-has-bg-dark [class*='rossel-native-'] {
    background: white;
    padding-top: 32px;
  }

  r-mini-panel [class*='rossel-native-'] {
    margin: 0 -12px;
  }

  r-selligent {
    margin-bottom: 16px;
  }

  [data-scrolldir='up'] .-has-header-fixed .reabon {
    top: 85px;
  }

  [data-scrolldir='up'] .page-node:not(.-is-grand-format).-has-header-fixed .reabon {
    top: 85px;
  }

  .r-viewmode--o-list .r-article--list,
  .r-viewmode--list .r-article--list {
    grid-area: aside;
  }

  .r-viewmode--o-panel .r-article--title,
  .r-viewmode--panel .r-article--title,
  .r-viewmode--o-panel-left .r-article--title,
  .r-viewmode--panel-left .r-article--title,
  .r-viewmode--o-panel-3row .r-article--title,
  .r-viewmode--panel-3row .r-article--title,
  .r-viewmode--panel-center .r-article--title {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .r-viewmode--o-panel .r-article--img,
  .r-viewmode--o-panel .r-article--meta,
  .r-viewmode--panel .r-article--img,
  .r-viewmode--panel .r-article--meta,
  .r-viewmode--o-panel-left .r-article--img,
  .r-viewmode--o-panel-left .r-article--meta,
  .r-viewmode--panel-left .r-article--img,
  .r-viewmode--panel-left .r-article--meta,
  .r-viewmode--o-panel-3row .r-article--img,
  .r-viewmode--o-panel-3row .r-article--meta,
  .r-viewmode--panel-3row .r-article--img,
  .r-viewmode--panel-3row .r-article--meta,
  .r-viewmode--panel-center .r-article--img,
  .r-viewmode--panel-center .r-article--meta {
    margin-left: -16px;
    margin-right: -16px;
  }

  .r-viewmode--o-panel i-article,
  .r-viewmode--panel i-article,
  .r-viewmode--o-panel-left i-article,
  .r-viewmode--panel-left i-article,
  .r-viewmode--o-panel-3row i-article,
  .r-viewmode--panel-3row i-article,
  .r-viewmode--panel-center i-article {
    text-align: center;
  }

  .r-viewmode--o-panel figure,
  .r-viewmode--panel figure,
  .r-viewmode--o-panel-left figure,
  .r-viewmode--panel-left figure,
  .r-viewmode--o-panel-3row figure,
  .r-viewmode--panel-3row figure,
  .r-viewmode--panel-center figure {
    margin: 0;
  }

  .r-viewmode--o-panel-left i-article,
  .r-viewmode--panel-left i-article,
  .r-viewmode--o-panel-3row i-article,
  .r-viewmode--panel-3row i-article {
    text-align: center;
  }

  .r-viewmode--o-panel-left r-article--section,
  .r-viewmode--panel-left r-article--section,
  .r-viewmode--o-panel-3row r-article--section,
  .r-viewmode--panel-3row r-article--section {
    display: none;
  }

  .r-viewmode--o-panel-left .r-article--title,
  .r-viewmode--panel-left .r-article--title {
    margin-left: 0;
    margin-right: 0;
  }

  .r-viewmode--o-panel,
  .r-viewmode--panel,
  .r-viewmode--o-panel-left,
  .r-viewmode--panel-left {
    margin: 0;
  }

  .r-viewmode--o-panel .r-article--title,
  .r-viewmode--panel .r-article--title,
  .r-viewmode--o-panel-left .r-article--title,
  .r-viewmode--panel-left .r-article--title {
    font-size: 1.3rem;
    margin-top: 20px;
  }

  .r-viewmode--o-panel r-article--chapo,
  .r-viewmode--panel r-article--chapo,
  .r-viewmode--o-panel-left r-article--chapo,
  .r-viewmode--panel-left r-article--chapo {
    display: none;
  }

  .r-viewmode--o-panel-center,
  .r-viewmode--panel-center {
    margin-left: 0;
    margin-right: 0;
  }

  .r-viewmode--o-panel-center .r-article--title,
  .r-viewmode--panel-center .r-article--title {
    font-size: 1.3rem;
    font-weight: bold;
    margin-left: 0;
    margin-right: 0;
  }

  .r-viewmode--o-panel-center .r-article--img,
  .r-viewmode--panel-center .r-article--img {
    margin-left: 0;
    margin-right: 0;
  }

  .r-viewmode--o-panel-center .r-article--title,
  .r-viewmode--panel-center .r-article--title {
    font-size: 1.3rem;
    margin-top: 20px;
  }

  .r-viewmode--o-panel-center r-article--chapo,
  .r-viewmode--panel-center r-article--chapo {
    display: none;
  }

  .r-viewmode--o-panel-center figure,
  .r-viewmode--panel-center figure {
    margin: 0;
  }

  .r-viewmode--cta {
    padding: 32px 16px;
  }

  .r-viewmode--image-title {
    margin: 0 0 12px;
  }

  .r-viewmode--o-dossier,
  .r-viewmode--dossier {
    background: #a0ca4b;
    border-radius: 20px;
    margin: 0 0 12px;
    padding: 16px;
  }

  .r-viewmode--o-dossier article,
  .r-viewmode--dossier article {
    grid-template-areas: '.';
    grid-template-columns: 1fr;
  }

  .r-viewmode--o-dossier .r-article--title,
  .r-viewmode--dossier .r-article--title {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .r-viewmode--o-dossier .r-alerte,
  .r-viewmode--dossier .r-alerte {
    background: #a0ca4b;
    display: inline-block;
    left: 50%;
    padding: 4px 16px;
    position: absolute;
    transform: translate(-50%, -100%);
    top: 0;
  }

  .r-viewmode--o-dossier .r-alerte::after,
  .r-viewmode--dossier .r-alerte::after {
    display: none;
  }

  .r-viewmode--horizontal article,
  .r-viewmode--o-horizontal article {
    column-gap: 12px;
  }

  .r-selligent--pave-newsletter-container .r-selligent--pave-content {
    height: auto;
  }

  .r-selligent--under-rs-newsletter-container .r-selligent--under-rs-content {
    height: auto;
  }

  .r-selligent--pave-offre-container .r-selligent--pave-content {
    height: auto;
  }

  .r-selligent--pave-app .r-selligent--pave-content {
    height: auto;
  }

  .page-archive-index .pane-dpiindex-dpiindex-dates .date-list li,
  .page-archive-index .pane-dpiindex-dpiindex .date-list li,
  .page-archive-index .pane-dpiindex-dpiindex-navigation-links .date-list li {
    font-size: 0.9rem;
  }

  .page-marches .r-panel--paywall::before {
    top: -430px;
  }

  .page-marches .r-panel--paywall r-paywall--content h2 {
    font-size: 1.3rem;
  }

  .page-marches .r-panel--paywall r-paywall--footer .r-btn--secondary {
    margin-bottom: 16px;
  }

  .r-mini-panel--section.-is-mp-markets-home {
    padding-left: 0;
    padding-right: 0;
  }

  .r-mini-panel--section.-is-mp-markets-home i-mini-panel {
    display: grid;
    grid-template-areas: 'sections' 'intro' 'latest' '.';
    grid-template-columns: 100%;
    padding-left: 0;
  }

  .r-mini-panel--section.-is-mp-markets-home i-mini-panel .markets-home--intro {
    margin-left: 0;
  }

  .r-mini-panel--section.-is-mp-markets-home i-mini-panel .markets-home--intro {
    background-size: 100% auto;
    padding-bottom: 60px;
  }

  .r-mini-panel--section.-is-mp-markets-home i-mini-panel .markets-home--sectionlist h2 {
    padding-left: 0;
    padding-right: 0;
  }

  .pane-marches__accordion > .pane-marches__accordion__trigger > h3.pane-marches__accordion__trigger__title::after {
    right: 20px;
  }

  .pane-marches__accordion > .pane-marches__accordion__trigger > h5.pane-marches__accordion__trigger__title::after {
    right: 22px;
  }

  .pane-marches-section h3 {
    margin: 5px 0 0 0;
    padding-left: 2px;
    padding-right: 45px;
  }

  .pane-marches-section h4 {
    margin: 10px 0 0 0;
    padding-left: 10px;
  }

  .pane-marches-section h5 {
    margin: 0;
  }

  .pane-marches-section__table-wrap {
    margin: 10px 0 0 0;
  }

  .pane-marches-section__table thead th.pane-marches-section__trendhead {
    min-width: 65px;
  }

  .pane-marches-section__table thead th.pane-marches-section__arrowhead {
    width: 30px;
  }

  .pane-marches-section__table tbody tr:hover td.pane-marches-section__arrow i,
  .pane-marches-section__table tbody tr:active td.pane-marches-section__arrow i,
  .pane-marches-section__table tbody tr:focus td.pane-marches-section__arrow i {
    transform: translateY(2px);
  }

  .pane-marches-section__table tbody td.pane-marches-section__trend {
    padding-left: 3px;
    padding-right: 3px;
  }

  .pane-marches-section__table tbody td.pane-marches-section__arrow i {
    left: -6px;
  }

  r-footer--list {
    display: none;
    overflow: hidden;
    opacity: 0;
    transition: all 0.3s linear;
  }

  .r-footer--title {
    align-items: center;
    display: flex;
    height: 48px;
    margin-bottom: 0px;
    margin-top: 0px;
    padding-bottom: 0px;
    position: relative;
  }

  .r-footer--title:after {
    content: "\ea03";
    font-family: main;
    position: absolute;
    right: 20px;
    transition: all 0.25s ease 0s;
  }

  .r-footer--logo svg {
    max-width: 200px;
  }

  .r-footer--newsletter {
    padding-bottom: 16px;
    padding-left: 0;
    padding-right: 0;
  }

  .r-footer--abo {
    padding-bottom: 16px;
  }

  .r-footer--top span {
    display: none;
  }

  .r-footer--services {
    display: block;
    grid-column-start: 1;
    grid-column-end: -1;
    margin: 0 0;
  }

  .r-footer--services r-footer--list {
    columns: 2;
  }

  .r-footer--copyright {
    border-top: 1px solid white;
    color: #6a8c1f;
    font-size: 0.75rem;
    margin-top: 8px;
    padding-top: 8px;
  }

  .panel-newsletters {
    display: block;
    padding: 20px;
    margin: 0;
  }

  .panel-newsletters .r-newsletter {
    margin-left: 0;
    text-align: center;
  }

  .panel-newsletters .r-newsletter .r-newsletter--input {
    margin-bottom: 5px;
    width: 100%;
  }

  .panel-newsletters .r-newsletter .r-newsletter--checkbox {
    text-align: left;
  }
}

@media (min-width: 998px) and (max-width: 1329px) {
  .r-digiteka--title {
    max-width: 996px;
    margin: auto max(64px, calc(50vw - 498px)) 16px;
  }

  i-mini-panel {
    max-width: 996px;
    margin: auto max(64px, calc(50vw - 498px));
  }

  .r-mini-panel--section i-mini-panel {
    grid-template-areas: '. . .';
    grid-template-columns: 33.33% 33.33% 33.33%;
    margin: auto;
  }

  .r-mini-panel--recruteurs {
    margin-left: 0;
    width: auto;
  }

  .r-mini-panel--service i-mini-panel {
    grid-template-columns: 66.66% 33.33%;
  }

  .r-mini-panel--article r-pub {
    grid-column: 3;
    grid-row: 2 / span 2;
  }

  r-mini-panel.-has-panel i-mini-panel {
    grid-gap: 16px;
    grid-template-areas: ". .";
    grid-template-columns: calc(50% - 8px) calc(50% - 8px);
  }

  r-mini-panel.-has-imu i-mini-panel {
    grid-template-columns: 1fr 1fr 332px;
  }

  .-is-livetv i-mini-panel {
    grid-column-gap: 32px;
    grid-template-areas: '. . .' 'bigimg bigimg bigtitle' 'bigimg bigimg .' 'bigimg bigimg .';
    grid-template-columns: repeat(3, calc((100% - 64px) / 3));
  }

  .-is-tabs .r-tabs {
    margin: auto max(64px, calc(50vw - 498px));
    max-width: 996px;
  }

  .-is-tabs .r-btn--more {
    margin: auto max(64px, calc(50vw - 498px));
    max-width: 996px;
  }

  .-is-panel-right.r-mini-panel--section i-mini-panel {
    grid-template-areas: '. . .' '. manchette manchette' '. manchette manchette' '. . .' !important;
  }

  .-is-mp-jobbo.r-mini-panel--section .panel-jobbo-cta {
    background-position: right 60px bottom 90px, center;
  }

  .-is-mp-jobbo.r-mini-panel--section .panel-jobbo-cta h3 {
    padding: 32px 120px 32px 120px;
  }

  i-mini-panel .r-panel--carousel {
    grid-column: span 2;
    grid-row: span 2;
    width: calc(100% - 32px);
  }

  .r-panel--edito .-is-vertical {
    display: none;
  }

  .r-panel--horizontal .r-panel--item,
  .r-panel--wrap .r-panel--item {
    flex: 1;
    padding: 8px;
  }

  .r-panel--wrap .r-panel--item {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .r-viewmode--o-panel-3row,
  .r-viewmode--panel-3row {
    grid-column: auto / span 2;
    grid-row: auto / span 4;
  }

  .r-viewmode--o-panel-center,
  .r-viewmode--panel-center {
    grid-column: auto / span 2;
    grid-row: auto / span 2;
  }

  .r-viewmode--horizontal .r-viewmode--article .r-article--img,
  .r-viewmode--o-horizontal .r-viewmode--article .r-article--img {
    aspect-ratio: auto;
  }

  .r-viewmode--horizontal .r-viewmode--article .r-article--img::after,
  .r-viewmode--o-horizontal .r-viewmode--article .r-article--img::after {
    top: 8px;
  }

  r-footer {
    padding: 32px max(64px, calc(50vw - 490px)) 60px;
  }

  .panel-newsletters .r-newsletter .r-newsletter--input {
    width: 250px;
  }
}

@media (min-width: 664px) and (max-width: 997px) {
  .r-digiteka--title {
    max-width: 664px;
    margin: auto auto 16px;
  }

  r-mini-panel r-panel {
    margin: 8px;
  }

  i-mini-panel {
    max-width: 696px;
    margin: auto;
  }

  .r-mini-panel--section i-mini-panel {
    grid-template-areas: '. .';
    grid-template-columns: 50% 50%;
  }

  .r-mini-panel--section.-is-mini-panel--horoscope .r-mini-panel--item-horoscope {
    font-size: .7rem;
  }

  .r-mini-panel--services i-mini-panel,
  .r-mini-panel--tabs i-mini-panel {
    max-width: none;
  }

  .r-mini-panel--services r-panel,
  .r-mini-panel--tabs r-panel {
    margin: 0;
  }

  .r-mini-panel--service i-mini-panel {
    display: block;
  }

  .r-mini-panel--list .r-panel--list {
    padding: 0 16px;
  }

  .r-mini-panel--list .r-viewmode--panel-left {
    grid-column: span 2;
  }

  .r-mini-panel--article r-pub {
    grid-column: 2;
    grid-row: 2 / span 2;
  }

  r-mini-panel.-has-imu i-mini-panel {
    grid-template-columns: 1fr 332px;
  }

  .-is-panel-right.r-mini-panel--section i-mini-panel {
    grid-template-areas: '. .' 'manchette manchette' '. .';
  }

  i-mini-panel .r-panel--carousel {
    grid-row: span 2;
  }

  .r-panel--edito .-is-square {
    display: none;
  }

  .r-panel--boutons r-panel--body {
    flex-wrap: wrap;
  }

  .r-panel--boutons .r-panel--link {
    max-width: 50%;
  }

  .r-panel--horizontal .r-panel--item,
  .r-panel--wrap .r-panel--item {
    flex: 0 0 40%;
    margin-right: 16px;
  }

  .r-viewmode--o-panel-3row,
  .r-viewmode--panel-3row {
    grid-column: auto / span 2;
    grid-row: auto / span 2;
  }

  .r-viewmode--o-panel-center,
  .r-viewmode--panel-center {
    grid-column: auto / span 2;
    grid-row: auto / span 2;
  }

  .page-marches .r-panel--paywall::before {
    top: -345px;
  }

  .r-mini-panel--section.-is-mp-markets i-mini-panel {
    margin: 0 auto 16px auto;
  }

  .r-mini-panel--section.-is-mp-markets-home i-mini-panel {
    margin: 0 auto 16px auto;
  }

  r-footer--section {
    padding-left: 32px;
  }

  .panel-newsletters {
    display: block;
    padding: 20px;
  }

  .panel-newsletters .r-newsletter {
    margin-left: 0;
  }
}

@media (min-width: 664px) and (max-width: 1329px) {
  .r-mini-panel--list .r-viewmode--panel-left .r-viewmode--article {
    margin-top: 8px;
  }

  .r-froomle--anonyme,
  .r-froomle--cookies {
    flex-basis: 100%;
  }

  .-is-mp-jobbo.r-mini-panel--section i-mini-panel {
    grid-template-areas: '. .';
    grid-template-columns: 50% 50%;
  }

  .r-panel--top-titre .r-panel--title {
    font-size: 1.3rem;
  }

  .r-viewmode--horizontal,
  .r-viewmode--o-horizontal {
    grid-column: span 2 / -1;
  }

  .r-mini-panel--section.-is-mp-markets i-mini-panel {
    grid-template-areas: "mp-title" "mp-trends" "mp-latest";
    grid-template-columns: 100%;
  }

  .r-mini-panel--section.-is-mp-markets-home i-mini-panel {
    grid-template-areas: 'sections sections' 'intro latest' '. .';
    grid-template-columns: 50% 50%;
  }
}

@media print {
  *,
  *::after,
  *::before {
    background: transparent !important;
    box-shadow: none !important;
    color: #0e0d0e !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]::after {
    content: " (" attr(href) ")";
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  a[href^='#']::after,
  a[href^='javascript:']::after {
    content: '';
  }

  pre {
    white-space: pre-wrap !important;
  }

  blockquote,
  pre {
    border: 1px solid #e6e6e6;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  img,
  tr {
    page-break-inside: avoid;
  }

  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  r-header,
  r-menu,
  r-pub,
  .r-sticky--panel,
  r-footer {
    display: none !important;
  }

  figure {
    margin: 0 !important;
    width: 35%;
  }

  .page-node r-embed,
  .page-node r-panel,
  .page-node r-mini-panel {
    display: none !important;
  }

  /* Prevent printers from removing background-images of controls. */

  .leaflet-control {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}
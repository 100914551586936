@include scope('critical') {
.r-viewmode--o-niveau-2,
.r-viewmode--niveau-2 {
    @include smmdlg {
        grid-column: auto;
        grid-row: auto;
    }
}
r-main > {
    .r-viewmode--o-niveau-2,
    .r-viewmode--niveau-2 {
        &:last-of-type {
            @include wallpaper() {
                display: none;
            }
        }

        @include scope('sb') {
            article {
                r-article--meta {
                    color: color(gray, dark);
                    time {
                        &:before {
                            content: '\2022';
                            display: inline-block;
                            padding-left: 3px;
                            padding-right: 4px;
                        }
                    }
                }
            }
        }
    } 
}

.r-viewmode--o-niveau-1,
.r-viewmode--niveau-1,
.r-viewmode--o-niveau-2,
.r-viewmode--niveau-2,
.r-viewmode--o-list,
.r-viewmode--list {
    @include smmdlg {
        grid-column: auto;
        grid-row: auto;
    }

    article {
            @include xs {
                column-gap: space(.75);
                grid-template-areas: 'titre img'
            'meta img'
            'aside aside';
                grid-template-columns: 2fr 1fr;
            }

        .-is-swipable & {
            @include xs {
                display: block;
            }
        }

        @include scope('special') {
            @include xs {
                column-gap: space(.75);
                grid-template-areas: 'img'
                    'titre'
                    'meta'
                    'aside';
                grid-template-columns: 1fr;
            }
        }
    }

    .-is-swipable & {
        @include xs {
            padding-right: space(2);
        }

        figure {
            @include xs {
                margin-bottom: space();
            }
        }
    }

    .r-article--title {
        @include xs {
            grid-area: titre;
            margin-right: space(.5);

            @include scope('!special') {
                margin-top: 0;
            }
        }
    }

    .r-article--img {
        @include xs {
            grid-area: img;
        }
    }

    figure {
        @include xs {
            margin-bottom: 0;
        }
    }

    @include wallpaper(md) {
        &.-not-arche {
            display: none;
        }
    }
}

.r-viewmode--niveau-2,
.r-viewmode--niveau-1,
.r-viewmode--agenda {
    i-mini-panel & {
        margin-left: 0;
        margin-right: 0;
    }
}

.r-viewmode--o-niveau-1,
.r-viewmode--niveau-1 {
    @include smmdlg {
        grid-column: 3 / span 2;
    }

    @include sm {
        grid-column: auto / span 2;
    }

    .r-viewmode--article {
        display: grid;
        column-gap: 16px;

        @include smmdlg {
            display: grid;
            @include scope('!sb') {
                grid-template-columns: 1fr 2fr;
            }    
        }

        .-is-livetv & {
            @include wallpaper(md) {
                display: block;
            }
        }
        @include wallpaper(md) {
            grid-template-columns: 3fr;
        }

        .r-article--img{
            
            @include wallpaper(md) {
                display: none;
            }
        }   
        
        @include scope('sb') { 
            grid-template-areas: 
            'img title'
            'img meta';
            grid-template-columns: 2fr 3fr;
            grid-template-rows: min-content;

            .r-article--img {
                grid-area: img;
            }

            .r-article--title {
                grid-area : title;
            }

            r-article--meta {
                grid-area:meta;
            }
        }
    }

    //.lazy {
    //    @include lg {
    //        display: none;
    //    }
    //}
    //
    //.not-lazy {
    //    @include lg {
    //        display: block;
    //    }
    //}

    figure {
        margin: 0;
    }

    r-article--chapo {
        line-height: 1.3;
    }

    .r-article--title {
        @include smmdlg {
            font-size: font-size(h4);
            margin-top: 0;
        }
    }

    .r-article--img {
        .-is-livetv & {
            aspect-ratio: 1/1;
            @include wallpaper(md) {
                display: none;
            }
        }
    }

    .r-article--list {
        @include smmdlg {
            display: none;
        }
    }

    //&.r-layout--video,

    &.r-layout--audio,
        // &.r-layout--info,
    &.r-layout--image,
    &.r-picto--photo {
        .r-article--img {
            &::before {
                @include smmdlg {
                    right: 0;
                    bottom: 0;
                    transform: scale(.75);
                }
            }
        }
    }
}
} // scope

@include scope(('une','!critical')) {
.r-viewmode--o-list,
.r-viewmode--list {
    article {
        display: grid;

        @include smmdlg {
            grid-template-areas: 'list'
    '.';
        }
    }

    i-article {
        @include smmdlg {
            background: color(primary, .6);
            color: color(light);
            grid-area: list;
            margin-bottom: space(.75);
            padding: space(.5) space(.75);
            transition: background .3s ease;
            z-index: z-index();
        }

        &:hover {
            @include mdlg {
                background: color(primary, .9);
            }
        }
    }

    .r-article--title {
        &::before {
            content: '';
            display: block;
            float: right;
            height: 20px;
            width: 20px;
        }
    }

    .r-article--link {
        display: inline;
    }

    .r-article--img {
        @include smmdlg {
            grid-area: list;
        }
    }

    .r-alerte {
        //@include smmdlg {
        //    color: color(light);
        //}
        //
        //&::after {
        //    background: color(light);
        //}
    }

    //.r-article--link,
    //.r-author--link {
    //    @include smmdlg {
    //        color: color(light);
    //    }
    //}

    .r-article--list {
        @include xs {
            grid-area: aside;
        }
    }
}
}// scope !critical

@include scope(('mag','!critical')) {
    .r-viewmode--o-niveau-2,
    .r-viewmode--niveau-2 {
        .r-viewmode--article {
            @include xs {
                display: grid;
            }
        }
    }
}

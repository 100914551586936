@include scope('!critical') {
@include scope('an', 'cp', 'pn') {
// Bloc dessin
 .-is-kroll {
     &.r-panel--image {
         box-shadow: 0 0 3px color(gray, light);

         @include smmdlg {
             border-radius: space(.5);
             padding: space(.5) space() space();
         }

         @include lg {
             grid-column: span 2;
             grid-row: span 2;
         }

         @include wallpaper {
             grid-column: auto;
             grid-row: auto;
         }

         r-panel--header {
             margin-bottom: space();
             padding-top: space(.5);

             &::before {
                 @include scope('vdn') {
                     color: color(secondary);
                     content: $icon-lesoir;
                     font-family: $font-family-icons;
                     font-size: 70%;
                     margin-right: space(.5);
                 }
             }
         }
     }

     .r-viewmode--article,
     .r-panel--item {
         padding: 0 space();

         .r-anonyme &,
         .r-connecte-sd & {
            background-repeat: no-repeat;
            background-position: center center;
             @include scope('vdn') {
                 background-image: url('#{$domain}/sites/all/themes/enacarbon_lesoir/images/kroll.svg');
             }
            @include scope('an') {
                background-image: url("#{$domain}/sites/all/themes/enacarbon_an/images/alex.svg");
            }
            @include scope('cp') {
                background-image: url("#{$domain}/sites/all/themes/enacarbon_cp/images/alex.svg");
            }
            @include scope('pn') {
                background-image: url("#{$domain}/sites/all/themes/enacarbon_pn/images/alex.svg");
            }
            @include scope('ard') {
                background-image: url("#{$domain}/sites/all/themes/enacarbon_ard/images/chaunu.svg");
            }
            @include scope('ee') {
                background-image: url("#{$domain}/sites/all/themes/enacarbon_ee/images/chaunu.svg");
            }
            @include scope('lc') {
                background-image: url("#{$domain}/sites/all/themes/enacarbon_lc/images/chaunu.svg");
            }
            @include scope('uni') {
                background-image: url("#{$domain}/sites/all/themes/enacarbon_uni/images/chaunu.svg");
            }
         }
     }

     .r-article--img {
         margin: 0 space();

         @include lg {
             margin: 0 space(3);
         }

         .r-anonyme &,
         .r-connecte-sd & {
             @include scope('vdn') {
                 filter: blur(10px) opacity(.5);
             }
         }
     }

     .r-img {
         &:hover {
             transform: none;
         }
     }
 }
}// SCOPE

.r-mini-panel--services {
    r-panel--header {
        background-repeat: no-repeat;

        @include scope('!mag') {
            background-position: bottom left;
            text-indent: -999em;
        }
    }
}

@include scope('vdn', 'nl') {
    .r-panel--vozer {
        r-panel--header {
            background-image: url($logo-vozer);
            background-size: auto 36px;

            @include scope('mag') {
                background-position: center right;
            }
        }
    }
}

@include scope('!sb') {
    .r-panel--diverto {
        r-panel--header {
            background-image: url($logo-diverto);
            background-size: auto 30px;
        }
    }
}

@include scope('lereo', 'an', 'cp', 'pn') {
    .r-panel--lereo {
        r-panel--header {
            background-image: url($logo-lereo);
            background-size: 120px auto;
        }
    }
}

@include scope('cipiz', 'cp') {
    .r-panel--cipiz {
        r-panel--header {
            background-image: url($logo-cipiz);
            background-size: 120px auto;
        }
    }
}

@include scope('lifestyle', 'ard', 'ee', 'lc', 'uni') {
    .r-panel--lifestyle {
        r-panel--header {
            background-image: url($logo-lifestyle);
            background-size: 120px auto;
        }
    }
}

@include scope('special') {
 .r-panel--vozer {
     r-panel--header {
         background-size: auto 20px;

         @include mdlg {
             // border-top: 1px solid color(primary);
             margin: 0 space();
             //padding-top: space();
         }

         @include md {
             margin: 0 space(.5);
         }

         &::after {
             display: none;
         }
     }
 }
}

.lazyloaded {
     &.r-panel--vozer {
         r-panel--header {
             @include scope('special','mag') {
                 background-image: url('#{$domain}/sites/all/themes/enacarbon_vdn/images/vozer.svg');
             }
             @include scope('special') {
                 background-size: auto 60px;
             }
             @include scope('mag') {
                 background-size: auto 16px;
                 background-position: center right;

                 @include xssm {
                     font-size: 1rem;
                     padding: 0;
                 }

                 @include mdlg {
                     background-size: auto 30px;
                 }
             }
         }
     }
}

//
//.r-panel--bandeau {
//    margin: -1px 0 0;
//    position: relative;
//
//    r-panel--body {
//        align-items: center;
//        background-color: color(primary);
//        color: color(light);
//        justify-content: space-between;
//        margin: 0 0 space();
//        overflow: hidden;
//        padding: space() space(3);
//        position: relative;
//
//        @include xs {
//            display: block;
//        }
//
//        @include smmdlg {
//            border-radius: space(1.5);
//            display: flex;
//            margin: 0 space() space();
//        }
//    }
//
//    small {
//        display: block;
//        font-family: $font-family-base;
//        font-size: font-size(base);
//    }
//
//    .r-img {
//        left: 0;
//        position: absolute;
//        top: 0;
//        z-index: 0;
//
//        @include xs {
//            max-width: none;
//            height: 100%;
//            width: auto;
//        }
//
//        &:hover {
//            transform: none;
//        }
//    }
//
//    .r-panel--title {
//        position: relative;
//        text-shadow: 1px 1px 1px color(gray);
//    }
//
//    .r-btn {
//        position: relative;
//    }
//
//    .r-panel--link {
//        color: color(light);
//    }
//}

.r-panel--boutons {
    @include smmdlg {
        margin: 0;
    }

    r-panel--body {
        @include smmdlg {
            display: flex;
            margin: 0 space();
        }

        @include sm {
            flex-wrap: wrap;
        }
    }

    .r-panel--link {
        flex: 1;

        @include sm {
            max-width: 50%;
        }

        @include mdlg {
            width: 20%;
        }
    }
}
}// scope

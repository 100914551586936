@include scope('!critical') {
.-is-collapse {
    // height: $collapse-height;
    height: 300px;
    overflow: hidden;
    position: relative;

    @include scope('sudinfo') {
        height: 500px;
    }

    &::after {
        background: linear-gradient(to top, color(light) 20%, color(light, 0) 100%);
        bottom: 0;
        content: '';
        height: 30%;
        left: 0;
        position: absolute;
        right: 0;
        z-index: z-index();

        @include scope('sudinfo') {
            background: color(light);
            height: space();
        }
    }

    .r-btn--collapse {
        display: flex;

        @include scope('!sudinfo') {
            align-items: flex-start;
            padding: space(.5) space(.5) 6px space();
        }
    }
}

.r-btn--collapse {
    bottom: space();
    display: none;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    z-index: z-index();

    @include lg {
        margin-left: -1vw;
        
        @include scope('sudinfo') {
            margin-left: 0;
        }
    }

    @include scope('sudinfo') {
        bottom: 0;
    }
}
}// scope

@include scope('critical') {
    .r-panel--services-immo {
        grid-column: 1 / -1;

        @include smmdlg {
            padding: 0 space(.5);
        }

        r-panel--list {
            column-gap: space(1.5);

            .-is-mobile-swipable & {
                @include xssm {
                    display: flex;
                    overflow-x: auto;
                }
            }

            @include mdlg {
                grid-row-gap: space();
                display: grid;
                grid-template-areas: '. . . .';
                grid-template-columns: repeat(4, calc((100% - #{space(4.5)}) / 4));
            }
        }

        .r-panel--item {
            border-radius: 10px;
            height: 60px;
            overflow: hidden;
            position: relative;

            @include xssm {
                flex: 0 0 80%;
                margin-bottom: space(.5);
            }

            &:hover {
                .r-img {
                    opacity: .9;
                    -webkit-transform: scale(1.05);
                    transform: scale(1.05);
                }
            }
        }

        .r-article--title {
            align-items: center;
            bottom: 0;
            color: color(light);
            display: flex;
            font-size: 1.2rem;
            height: 100%;
            justify-content: center;
            margin: 0;
            padding: space();
            pointer-events: none;
            position: absolute;
            text-align: center;
            text-transform: uppercase;
            width: 100%;

            .-has-bg-immo-light & {
                @include xssm {
                    font-size: 1.2rem;
                    font-weight: 400;
                }
            }
        }
    }

    .r-panel--offres-emploi {
        grid-column: 1 / -1;

        r-panel--list {
            display: flex;
            column-gap: space();

            @include xssm {
                overflow-x: auto;
            }

            .-has-bg-emploi-light & {
                column-gap: space(3);
            }
        }

        .r-panel--item {
            border: 1px solid color(emploi);
            border-radius: 10px;
            flex: 2 1 0;
            position: relative;

            @include wallpaper(md) {
                &:last-child {
                    display: none;
                }
            }

            @include xssm {
                min-width: 200px;
            }

            &:hover {
                background: color(emploi, light);
                border-color: color(emploi, light);
            }

            .-has-bg-emploi-light & {
                border: none;
            }

            .has-bg-emploi-light {
                border: none;
            }
        }
        
        a {
            padding: space(.75) space(.75) space(2) space(.75);
            text-decoration: none;
        }

        figure {
            margin: auto;
            width: 40%;
        }

        .r-article--img {
            align-items: center;
            background: color(light);
            display: flex;
            height: 70px;
            justify-content: center;
        }

        adress {
            display: block;
            font-family: $font-family-title;
            font-size: font-size(small);
            font-weight: 700;
            margin-bottom: space(.5);
            text-transform: uppercase;
        }

        p {
            font-size: .9rem;
        }

        adress,
        p {
            color: color(emploi);
        }

        .r-article--title,
        a {
            color: color(dark);
            font-size: .9rem;
            font-weight: 700;
        }

        .r-article--title {
            margin-bottom: 0;
            text-transform: uppercase;
        }

        .r-article--link {
            bottom: space();
            text-decoration: underline;
            position: absolute;

            &:hover {
                color: color(dark);
                text-decoration: none;
            }
        }
    }

    .pane-tester-votre-salaire,
    .pane-evalutation-du-salaire,
    .pane-estimation-de-bien {
        grid-column-start: 1;
        grid-column-end: 4;
    }

    .estimation-immo,
    .estimation-salaire {

        r-main {
            grid-template-areas: "event event event event" "direct manchette manchette ." "direct manchette manchette ." "mp-top mp-top mp-top mp-top" ". . . imu-top" ". . . imu-top"  "mp-mid mp-mid mp-mid mp-mid" "dossier dossier . ." "dossier dossier . ." "mp-bottom mp-bottom mp-bottom mp-bottom" "inread-mid inread-mid . ." ". . . .";
        } 
    }

    .r-article--shopping-right {
        @include scope('article') {
            @include lg {
                grid-column-start: 4;
                grid-column-end: 5;
                grid-row: 3;
                position: relative;
            }
        }
    }

    .r-article--content-right {
        @include scope('article') {
            @include lg {
                position: absolute;
                width: 100%;
            }
        }
    }

    .pane-promos-du-moment {
        @include scope('article') {
            margin: auto auto space();
            max-width: 600px + space(6);
            padding: 0;
            position: relative;
            width: 100%;
        }
    }

    .r-panel--widget-shopping {
        padding: space();
        @include scope('article') {
            @include lg {
                background-color: color(shopping);
                padding: space(0) space() space() space();
                width: 100%;
            }
        }

        i-panel {
            border: 1px solid color(primary);
            display: block;
            padding: space();
        }

        r-panel--header {
            text-align: left;
            text-transform: none;
            font-size: font-size(h2);

            &::before,
            &::after {
                content: none;
            }
        }

        .r-panel--item {
            border-bottom: 1px solid color(light);
            padding: space(.5) 0;

            &:last-of-type {
                border-bottom: none;
            }

            a {
                align-items: center;
                display: flex;

                &:hover {
                    text-decoration: none;
                }
            }

            r-icon {
                align-items: center;
                border: 2px solid color(primary);
                border-radius: 50%;
                color: color(primary);
                display: flex;
                font-size: 16px;
                font-weight: bold;
                height: 30px;
                justify-content: center;
                text-align: right;
                width: 30px;
            }
        }

        .r-btn {
            display: block;
            font-weight: 700;
            font-size: 16px;
            margin-top: space();
            text-align: center;
            text-transform: uppercase;
        }
        figure {
            padding-right: space(.5);
            width: 20%;
        }

        .r-article--title {
            color: color(dark);
            font-size: .9rem;
            font-weight: normal;
            margin: 0;
            padding-right: space(.5);
            width: 70%;
        }
    }

    .r-panel--widget-shopping-comparateur {
        figure {
            width: 40%;
        }

        .r-article--title {
            width: 60%;
        }
    }

    .-grid-row-3 {
        grid-row: span 3;
    }
    
    .pane-iframe-elections-nord,
    .pane-iframe-election-62,
    .pane-iframe-regionales,
    .pane-iframe-resultat-election {
        grid-column: 1 / -1;

        iframe {
            border: 0;
            display: block;
            height: 2850px;
            margin: auto;
            max-width: 320px;
        }

        @include sm {

            iframe {
                height: 2550px;
                max-width: 700px;
            }
        }

        @include md {
            grid-column: 1 / 3;

            iframe {
                height: 3000px;
                max-width: 550px;
                margin-left:0;
            }
        }

        @include lg {
            grid-column: 1 / 4;

            iframe {
                height: 1700px;
                max-width: 100%;
            }
        }
    }

    .pane-iframe-resultat-election {

        iframe {
            height: 1500px;
            max-width: 100%;
            width: 100%;  
        }


        @include md {
            grid-column: 1 / 3;

            iframe {
                height: 1500px;
                max-width: 100%;
            }
        }

        @include lg {
            
            iframe {
                height: 1500px;
                max-width: 100%;
            }
        }
    }

    
    .pane-search-municipales {
        margin: space(.5) 0 0;
        grid-column: 1 / -1;
        padding: 0 space();
        
        @include md {
            grid-column: 1 / 3;
        }

        @include lg {
            grid-column: 1 / 4;
        }


        form {
            border: 1px solid color(gray, light); 
            border-radius: space(1.5); 
            display: flex;
            padding: 0;

            @include lg {
                width: 70%;
            }

            input {
                background: none;
                border:0; 
                padding: space(.5) 0;
                width: 100%;
            }

            .r-search--submit {
                width: 30px;
                margin: 0 space();

                r-icon {
                    color: color(gray, light); 
                    font-size: font-size(h6);
                    padding-top: space(.5);
                }
            }
        }
    }

    .r-panel--election {   
        margin-top: space(2);
        padding: 0 space(); 

            @include mdlg {
                padding: 0; 
            }
        
        .r-panel--body {
            display: flex;
            flex-wrap: wrap;  
            padding: 0; 
        }

        .r-panel---title {
            font-size: font-size(h3);  
            text-align: center;
            
            @include smmdlg {
                font-size: font-size(h2); 
                text-align: left; 
            }
        }

        .r-panel--link {
            list-style:  none;
            width: calc(100% / 2);

            @include sm {
                width: calc(100% / 3);
            }

            @include mdlg {
                width: calc(100% / 4);
            }

            a {  
                color: color(dark);
                font-size: font-size(aside);

                @include mdlg {
                    color: color(dark);
                    font-size: font-size(h6);                        
                }
            }
        }
    }

    .txt-election {
        padding: 0 space();

        @include mdlg {
            padding: 0;
        }

        h2 {
            font-size: font-size(h4);

            @include smmdlg {
                font-size: font-size(h2);
            }
        }
    }

    .r-panel--vertical {
        r-panel--header {
            color: color(light);
            font-size: font-size(h3);
            padding-left: space();
            padding-top: 0;

            @include xs {
                padding-left: space();
            }

            &:before {
                display: none;
            }

            &::after {
                background: color(light);
                content: '';
                -webkit-flex: 1;
                flex: 1;
                height: 1px;
                margin: space() 0 space() space();
                padding: 0 space();
            }

            @include scope('sb') {
                @include scope('video') {
                    color: color(primary, light);
                    &::after {
                        background: color(light, 0.5);
                    }
                }
            }
        }

        .r-panel--item {
            column-gap: space();
            display: grid;
            grid-template-columns: 2fr 1fr;
            padding: space();

            @include xs {
                grid-template-areas:
                'titre img';
            }

            @include mdlg {
                grid-template-columns: 1fr 2fr;
            }

            @include wallpaper(sm) {
                display: block;
            }
        }

        .r-panel--title {
            font-size: font-size(h6);
            margin-top: 0;

            @include smmdlg {
                font-size: 1.3rem;
            }
        }

        i-article {
            @include xs {
                grid-area: titre;
            }
        }

        .r-panel--img {
            @include xs {
                grid-area: img;
            }

            @include wallpaper(sm) {
                display: none;
            }
        }
    }

    .-is-voir-aussi {
        .r-article--title {
            order: -1;
        }

        .r-ratio--16-9 {

            img {
                aspect-ratio: auto;
            }
        }
    }

    .-in-area-event {
        .r-panel--emploi {
            margin-top: space(2);
            margin-left: 0;
            margin-right: -15px;

            @include xs {
                margin: 0 0 space() 0;
            }
        }
    }

    .r-panel--emploi-small {
        align-items: center;
        background-color: color(emploi);
        margin-bottom: space();
        padding: space();

        @include xs {
            margin: space();
        }

        @include mdlg {
            border-radius: 20px 20px 0 0;
            display: grid;
            grid-template-areas: '. .';
            grid-template-columns: 160px 1fr;
        }

        .r-panel--title {
            color: color(light);
            font-size: font-size(h5);

            @include mdlg {
                border-right: 1px solid rgba(color(light),.4);
                margin-right: space();
                padding-right: space();
            }
        }

        form {
            font-size: font-size(base);

            @include mdlg {
                display: flex;
                font-size: font-size(article-xs);
                justify-content: space-between;
            }
        }

        .r-form--group {
            margin-bottom: space();

            @include mdlg {
                margin: 0 0 0 space(1.5);
                width: 50%;
            }
        }

        input[type="text"] {
            border: none;
            border-radius: 5px;
            padding: space(.75) space(2);
            width: 100%;
        }

        .r-btn {
            background: color(emploi, dark);
            border: none;
            cursor: pointer;
            color: color(light);
            padding: space() space(2);

            @include mdlg {
                margin-left: space(1.5);
            }

            @include xssm {
                display: block;
                margin: space(1.5) 0 0;
                width: 100%;
            }

            &:hover {
                background: lighten(color(emploi, dark), 5%);
            }
        }
    }

    .r-panel--emploi {
        background-color: color(emploi);
        align-items: center;
        grid-column-start: 1;
        grid-column-end: -1;
        margin: space() 0;
        padding: space() space() space(1.5);

        @include mdlg {
            background: color(emploi) center right url('https://lvdneng.rosselcdn.net/sites/default/files/mediastore/1671553499_emploi.png') no-repeat;
            background-size: contain;
            border-radius: 20px 20px 0 0;
            display: grid;
            grid-template-areas: '. .';
            grid-template-columns: 25% 75%;
            margin: space(2) space();
            padding: space(1.5) space(2);
        }

        .r-panel--title {
            color: color(light);
            font-size: 1.5rem;
            padding-right: space(2);

            @include mdlg {
                //border-right: 1px solid rgba(color(light),.4);
                font-size: 2.15rem;
            }

            @include lg {
                padding: space(2) 105px space(2) 0;
            }

            @include wallpaper {
                padding-right: space(2);
            }
        }

        r-panel--body {
            //margin: space(2) 0;

            @include mdlg {
                border-left: 1px solid rgba(color(light),.4);
                padding: space() space(3);
            }

            a {
                display: block;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }

                &:first-child {
                    margin-bottom: space(.75);
                }
            }
        }

        label {
            display: inline-block;
            width: 20%;
        }

        input[type="text"] {
            border: none;
            border-radius: 5px;
            padding: space(.75) space(2);
            width: 100%;

            @include mdlg {
                width: 80%;
            }
        }

        form {
            font-size: 1rem;

            @include mdlg {
                font-size: 1.2rem;
                width: 80%;
            }
        }

        .r-form--group {
            margin-bottom: space(.5);

            @include mdlg {
                align-items: center;
                display: flex;
                grid-column: 1;
            }
        }

        r-icon {
            font-size: 20px;
            margin-right: space();
        }

        .r-btn {
            background: color(emploi, dark);
            border: none;
            cursor: pointer;
            color: color(light);
            justify-self: flex-start;
            padding: space() space(2);

            @include xssm {
                display: block;
                margin: space(1.5) auto 0;
                width: 100%;
            }

            &:hover {
                background: lighten(color(emploi, dark), 5%);
            }
        }

        a {
            color: color(light);
        }
    }

    .r-panel--emploi-chiffre,
    .r-panel--immobilier-chiffre,
    .r-panel--edito-defaut {
        align-self: flex-start;
        background: color(emploi, light);
        border-radius: 10px;
        font-size: font-size(article-xs);
        margin: space();
        padding: space() space() space(2);

        a {
            color: color(emploi, dark);
        }

        strong {
            display: block;
            font-size: 4rem;
            font-weight: font-weight(h1);
        }

        r-btn {
            background: color(emploi);
            border-radius: 5px;
            color: color(light);
            display: inline-block;
            font-size: font-size(button);
            margin-top: space();
            padding: space(.5) space(.75);

            @include xs {
                margin-bottom: space();
            }
        }
    }

    .r-panel--immobilier-chiffre {
        background: color(immo);
        a {
            color: color(dark);
        }

        r-btn {
            background: color(dark);
        }
    }

    .r-panel--edito-defaut {
        background: #F6F8FA;
        padding: space();

        a {
            color: #0D2C4B;
        }

        r-btn {
            background: none;
            color: #0D2C4B;
            font-weight: bold;
        }

        @include scope('mobilite') {
            background: color(gray, lighter);

            h3 {
                color: color(sport);
            }

            a {
                color: color(dark);
            }
    
            r-btn {
                color: color(dark);
            }
        }

        @include scope('entreprendre') {
            background: color(gray, lighter);
            h3 {
                color: color(sport);
            }

            a {
                color: color(sport);
            }
    
            r-btn {
                color: color(sport);
            }
        }

        @include scope('webco') {
            &.r-panel--edito-entreprendre {
                background: color(gray, lighter);

                h3,
                a,
                r-btn {
                    color: color(entreprendre);
                }
            }

            &.r-panel--edito-mobilite {
                background: color(gray, lighter);

                h3 {
                    color: color(mobilite);
                }
    
                a,
                r-btn {
                    color: color(dark);
                }
            }
        }
    }
}// scope critical

@include scope('!critical') {
    .r-panel--edition-immo {
        background: color(immo);

        @include smmdlg {
            align-items: center;
            display: grid;
            grid-column-start: 1;
            grid-column-end: -1;
            grid-template-columns: maxi(calc(7.8vw + #{space(2)}), calc(50vw - #{$content-max-width / 2})) 3fr 2fr;
            grid-template-areas: '. . .';
            margin-left: calc(-50vw + 50%);
            margin-top: 1rem;
            width: 100vw;
        }

        @include xs {
            padding: space(1.5) space(1.5) space(2) space(1.5);
        }

        @include wallpaper() {
            grid-template-areas: '. .';
            grid-template-columns: none;
            margin-left: 0;
            width: auto;
        }

        //i-panel {
        //    //@include max {
        //    //    grid-template-columns: calc(((100vw - #{$content-max-width}) / 2) + #{space(1.5)}) 3fr 2fr
        //    //}
        //}

        h2 {
            @include xs {
                font-size: 25px;
                text-align: center;
            }

            @include smmdlg {
                font-size: 30px;
                margin-top: space();
            }

            @include lg {
                width: 60%;
            }
        }

        form {
            @include smmdlg {
                margin-bottom: space();
            }
        }

        r-panel--body {
            @include smmdlg {
                grid-column: 2;
                padding-left: space();
            }
        }

        label {
            display: block;
            font-size: 0.9rem;
            margin-bottom: space(.75);

            @include smmdlg {
                font-size: 1.15rem;
            }
        }

        .r-form--group {
            position: relative;

            @include smmdlg {
                max-width: 500px;
                width: 80%;
            }
        }

        input {
            border-radius: 5px;
            border: none;
            height: 45px;
            padding: space(.75) space(3) space(.75) space();
            width: 100%;
        }

        .r-search--immo-submit {
            align-items: center;
            background: color(immo);
            border-radius: 5px;
            color: color(light);
            cursor: auto;
            display: flex;
            font-size: 24px;
            height: 39px;
            justify-content: center;
            margin: 0;
            padding: 0;
            position: absolute;
            right: 3px;
            top: 3px;
            width: 39px;
        }

        img {
            display: none;

            @include smmdlg {
                grid-column: 3;
                justify-self: end;
            }

            @include lg {
                display: block;
            }

            @include wallpaper() {
                display: none;
            }
        }

        .r-btn {
            background-color: color(immo,light);
            color: color(dark);
            grid-row: 2;
            grid-column: 2;
            justify-self: baseline;
        }

        .-is-location-set {
            display: none; //un js le fait apparaître au momnet voulu

            @include smmdlg {
                padding: space() space() space(2);
            }

            @include xs {
                text-align: center;
            }
        }
    }

    .r-panel--produits {
        r-panel--list {
            @include mdlg {
                column-gap: space(3);
            }
        }

        .r-panel--item {
            background: radial-gradient(ellipse closest-side at 50% 70%, color(dark), color(primary));
            border-radius: 10px;
            overflow: hidden;
            padding: space(2.75) 0 0;
            position: relative;
        }

        .r-article--img {
            display: block;
            margin: auto;
            width: 65%;
        }

        .r-panel--title {
            padding-bottom: space(.75);
        }

        i-article {
            background: color(gray, light);
            bottom: 0;
            display: block;
            padding: space();
            position: absolute;
            text-align: center;
            width: 100%;
        }
    }

    .pane-enrichissement {
        padding-bottom: space(2);
        grid-column-start: 1;
        grid-column-end: -1;

        @include xs {
            padding: 0 space() space(2) space();
        }
    }
}// scope

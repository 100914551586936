// Input, select

@include scope('critical') {
input:not( [type='radio'] ),
select {
    appearance: none;
    outline: 0;
    box-shadow: none;
}

// Remove IE arrow

select::-ms-expand {
    display: none;
}
}// scope


@include scope('!critical') {
.r-contact {
    padding: 1.5rem .25rem;

    .r-article--header {
        h1 {
            @include smmdlg {
                font-size: font-size(section);
            }
        }
    }

    label,
    p {
        font-size: font-size(base);

        @include smmdlg {
            font-size: font-size(article);
        }
    }

    label {
        display: flex;
        padding: 1.5rem .25rem .3rem;
    }

    input[type='text'],
    input[type='email'],
    input[type='phone'],
    input[type='password'],
    textarea {
        border: 1px solid color(gray, light);
        border-radius: space(.3);
        max-width: 100%;
        padding: space(.5);

        &:focus {
            border-color: color(secondary);
            box-shadow: inset 0 1px 1px color(dark, .075), 0 0 8px rgba(253, 200, 0, .6);
        }
    }

    input[type='submit'] {
        display: flex;
        margin: space() 0;
    }

    select {
        appearance: auto;
        border: 1px solid color(gray, light);
        border-radius: space(.3);
        padding: space(.5);
    }

    .r-text--small {
        font-size: font-size(button);
        color: color(gray);
    }

    @include scope('sb') {
        padding: 0;

        @include xssm {
            padding: space(2) space();
        }

        .r-article--header p {
            max-width: none;
            margin-bottom: space(2);
        }

        .form-select {
            width: 40%;
            @include xssm {
                width: 100%;
            }
        }

        select,
        input[type='text'],
        input[type='email'],
        input[type='phone'],
        input[type='password'],
        textarea  {

            border: 1px solid color(gray, dark);
            &:focus {
                border-color: black;
                box-shadow: none;
            }
        }

        .r-btn--primary {
            margin-top: space(2);
        }

        .r-text--small {
            color: color(gray, dark);
            margin-top: space(2);
        }

    }
}
}// scope

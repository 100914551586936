//-------------------------------------------------------------------
// SIDEBAR MENU -----------------------------------------------------------
//-------------------------------------------------------------------

@include scope('critical') {
.r-mini-panel--menu {
    grid-column: 1; // pour empêcher du cls
    visibility: hidden;
}
}// scope

@include scope('!critical') {
.r-mini-panel--menu {
    background: color(gray, dark, .5);
    content-visibility: hidden;
    display: flex;
    opacity: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: z-index(high);

    @include smmdlg {
        @include scope(('!special', '!sport')) {
            top: $header-desktop-top-height;
        }

        @include scope('special', 'sport') {
            top: $header-desktop-top-height + $header-desktop-bottom-height;
        }
    }

    @include max {
        max-width: $html-max-width;
    }

    i-mini-panel {
        background: color(light);
        height: 100vh;
        overflow: auto;
        position: relative;
        width: 80%;
        z-index: z-index();

        @include xs {
            padding-bottom: 70px;
            transform: translateX(-100%);
            transition: transform .3s ease;
        }

        @include smmdlg {
            width: 350px;

            @include scope(('!special', '!sport')) {
               height: calc(100vh - #{$header-desktop-top-height});
            }

            @include scope('special', 'sport') {
                height: calc(100vh - #{$header-desktop-top-height + $header-desktop-bottom-height});
            }
        }


        @include scope('special', 'sport', 'article') {
            @include sm {
                margin: auto 0;
            }

            @include md {
                margin: auto maxi(calc(7.8vw + 24px), calc(50vw - #{$content-max-width / 2}));
            }

            @include lg {
                margin: auto maxi(calc(7.8vw + 24px), calc(50vw - (#{($content-max-width - 4)/ 2})));
            }

            @include wallpaper {
                margin: auto maxi(calc(7.8vw + 24px), calc(50vw - (#{($content-max-width - 4)/ 2})));
            }
        }
    }

    @include scope('special') {
        r-back {
            background-color: color(primary);
            display: flex;
            position: relative;

            span {
                color: color(light);
                font-weight: 900;
                padding-right: space(3);
                text-transform: uppercase;
                overflow: inherit;
                width: auto;
            }
        }
    }

    .r-back--left {
        @include scope('special') {
            flex: 0 0 100%;
            justify-content: space-between;
            padding: space(.5) space();

            &::before {
                background-color: color(light);
                border-radius: 3px;
                padding: space(.5) space();
            }
        }

        @include scope('mag') {
            justify-content: space-around;
        }
    }

    &.-is-toggle {
        i-mini-panel {
            @include xs {
                transform: translateX(0);
            }
        }
    }
    .r-menu--btn {
        @include scope('mag') {
            background-color: color(dark);
            border-radius: 4px;
            color: color(light);
            display: block;
            font-weight: 700;
            margin: space(.75) auto;
            padding: space(.75);
            text-align: center;
            text-transform: uppercase;
            width: 80%;
        }
    }
}

.r-mini-panel--overlay {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
}

.-is-toggle-r-mini-panel--menu {
    overflow: hidden;
}

.r-mini-panel--menu {
    .r-menu--topics {
        .r-btn--outline {
            @include scope('sb') {
                border: 1px solid color(primary, light);
                font-size: font-size(small);
                height:auto;
                padding: 6px space(.5);
            }
        }
    }
}

.r-menu--main {
    position: relative;

    .r-menu--link,
    .r-submenu--link {
        align-items: center;
        color: color(dark);
        display: flex;
        font-size: font-size(h6);
        margin: 0 space(2);
        padding: space() 0;

        @include scope('!mag') {
            border-bottom: 1px solid color(gray, light);
            text-transform: uppercase;
        }

        @include scope(('!special', '!sport')) {
            font-weight: bold;
        }

        @include scope('special', 'sport') {
            font-weight: 900;
        }

        &::before {
            color: color(gray, light);
            font-family: main;
            font-size: font-size(h4);
            line-height: 1;
            padding-right: 10px;
            position: relative;
            top: -1px;
            vertical-align: middle;

            @include scope('mag') {
                color: color(dark);
            }
        }

        &:hover {
            text-decoration: none;

            @include scope(('!special', '!sport')) {
                color: color(primary);

                &::after {
                    color: color(primary);
                }
            }

            @include scope('special') {
                color: color(primary);

                &::after {
                    color: color(primary);
                }
            }

            @include scope('sport') {
                color: color(sport);

                &::after {
                    color: color(sport);
                }
            }
        }
    }

    .r-link--image {
        padding: space(.5) space(.5) space(.5) space(1.5);

        &:hover {
            background: color(primary, lighter);
        }
    }

    [class*='r-icon--'] {
        font-weight: bold;
        text-transform: none;

        @include scope(('!sport', '!mag')) {
            color: color(primary);
        }

        @include scope('sport') {
            color: color(sport);
        }

        @include scope('mobilite') {
            color: color(primary);
        }

        @include scope('mag') {
            color: color(dark);
        }

        &::before {
            font-weight: normal;

            @include scope(('!special', '!sport', '!mag')) {
                color: color(primary);
            }

            @include scope('special') {
                color: color(primary);
            }

            @include scope('sport') {
                color: color(sport);
            }

            @include scope('mobilite') {
                color: color(primary);
            }
        }
    }

    &[data-menu="megamenu"] {
        r-submenu {
            background: color(light);
            z-index: 1;

            &.-is-parent {
                margin-top: -55px;
                position: relative;
                background: color(light);

                > .r-submenu--link {
                    display: none;

                    &.-is-open {
                        display: block;
                    }
                }
            }

            &.-is-active {
                z-index: 2;
            }
        }
    }

    &[data-menu="expand"] {
        .r-menu--link,
        .r-submenu--link {
            font-size: font-size(h5);
            text-transform: none;
        }

        .-has-submenu {
            position: relative;

            &::after {
                content: $icon-arrow-right;
                font-family: main;
                font-size: font-size(base);
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        r-submenu {
            .r-submenu--link {
                border-bottom: 1px solid color(gray, light);
                font-size: font-size(h6);
                position: relative;

                &::after {
                    @include scope('mag') {
                        content: $icon-arrow-diago;
                        font-family: main;
                        font-size: font-size(small);
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
}
}// scope

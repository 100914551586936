@include scope('!critical') {
.r-mini-panel--section {
    @include smmdlg {
        grid-column-start: 1;
        grid-column-end: -1;
        margin-left: calc(-50vw + 50%);
        width: 100vw;

        @include scope(('!special','!mag')) {
            margin-top: space(2);
        }

        @include scope('sb') {
            margin-top: 0;
            padding-bottom: space(2); 
            padding-top: space();
        }

        @include scope('mag') {
            padding: space() 0 space(2);
        }
    }

    @include wallpaper {
        @include scope('!article') {
            margin-left: 0;
            width: auto;
        }

        //@include scope('article') {
        //    margin: auto;
        //    max-width: $arche-width;
        //}
    }

    i-mini-panel {
        @include smmdlg {
            display: grid;
            grid-auto-flow: row dense;
            grid-gap: 1px;
        }

        @include lg {
            grid-template-areas: '. . . .';
            grid-template-columns: 25% 25% 25% 25%;

            @include scope('sb') {
                margin: auto maxi(1px, calc(50vw - #{$content-max-width / 2}));
            }
        }

        @include wallpaper(md) {
            grid-template-areas: '. . .';
            grid-template-columns: 33.33% 33.33% 33.33%;
            margin: auto;
        }

        @include sm {
            grid-template-areas: '. .';
            grid-template-columns: 50% 50%;
        }
    }

    &.-is-mini-panel--horoscope {
        margin-bottom: space(2);
        i-mini-panel {
            @include smmdlg {
                column-gap: space(.25);
                grid-template-areas: '.';
                grid-template-columns: repeat(12, 1fr);
            }
        }

        .r-mini-panel--title {
            @include smmdlg {
                grid-column-start: 1;
                grid-column-end: -1;
            }
        }
        .r-mini-panel--item-horoscope {
            align-items: center;
            color: color(dark);
            display: flex;
            flex-direction: column;
            row-gap: space(.5);

            @include sm {
                font-size: .7rem;
            }

            &:hover {
                color: color(dark);
                text-decoration: none;
            }
        }

        svg {
            background: color(gray, lighter);
            border-radius: 50%;
            fill: none;
            height: 50px;
            padding: space(.25);
            width: 50px;
        }
    }
    
    @include scope('vdn', 'cp', 'pn', 'ee', 'uni', 'ard') {
    &.-is-mini-panel--manchette-video {
        i-mini-panel {
            @include mdlg {
                grid-auto-flow: column;
            }
            > * {
                margin-bottom: space();
            }
        }

        .r-viewmode--o-video-manchette {
            @include sm {
                grid-column: auto / span 2;
            }

            @include wallpaper(md) {
                grid-column: auto / span 2;
                grid-row: auto / span 2;
            }

            @include lg {
                grid-column: auto / span 3;
                grid-row: auto / span 2;
            }
        }
        article {
            background: color(dark);
        }
        r-embed {
            margin-bottom: 0;
        }
        .r-article--title,
        p {
            margin: 0;
        }
        .r-article--link,
        .r-article--desc {
            color: color(light);
            padding: space();
        }
    }    
    }

    &.-is-mini-panel--2 {
        i-mini-panel {
            @include smmdlg {
                grid-template-areas: '. .';
                grid-template-columns: 50% 50%;
            }
        }
    }

    &.-is-mini-panel--3 {
        i-mini-panel {
            @include lg {
                grid-template-areas: '. . . ';
                grid-template-columns: 33.33% 33.33% 33.33%;
            }
        }
    }

    &.-is-mini-panel--4 {
        i-mini-panel {
            @include mdlg {
                grid-template-areas: '. . . .';
                grid-template-columns: 25% 25% 25% 25%;
            }
        }
    }

    @include scope ('mag') {
        margin-bottom: 0;

        .r-mini-panel--title {
            grid-column-start: 1;
            grid-column-end: -1;

            @include xs {
                padding: space(1.5) space(.5) space();
            }
        }

        r-viewmode {
            @include xs {
                margin: 0;
                padding: space(.5);
            }
        }

        &.-is-mini-panel--3r {
            i-mini-panel {
                @include smmdlg {
                    grid-template-areas: ". ."
                            ". ."
                            ". .";
                    grid-template-columns: 50% 50%;
                }
            }
        }

        &.-is-mini-panel--3c {
            i-mini-panel {
                display: grid;
                grid-template-areas: ". .";
                grid-template-columns: 50% 50%;

                @include smmdlg {
                    grid-template-areas: ". . ."
                                         ". . .";
                    grid-template-columns: 40% 30% 30%;
                }
            }
        }

        &.-is-mini-panel--2r {
            i-mini-panel {
                display: grid;
                grid-template-areas: ". .";
                grid-template-columns: 50% 50%;

                @include smmdlg {
                    grid-template-areas: ". . ."
                    ". . ."
                                         ". . .";
                    grid-template-columns: 50% 25% 25%;
                }
            }
        }

        &.-is-mp--sortie {
            i-mini-panel {
                display: grid;
                grid-template-areas:    ". .";
                grid-template-columns: 50% 50%;
                padding-bottom: space(2);

                @include smmdlg {
                    grid-template-areas: ". . . .";
                    grid-template-columns: 25% 25% 25% 25%;
                }
            }

            .r-section--big,
            .r-section--small {
                padding: space(.5);

                @include smmdlg {
                    padding: space();
                }
            }

            .r-section--big {
                grid-column: span 2;
            }

            .r-section--small {
                aspect-ratio: 1/1;
            }

            img {
                height: 100%;
                width: 100%;
            }

            .r-section--link {
                display: block;
                height: 100%;
                position: relative;

                &::after {
                    background: linear-gradient(360deg, #1F1F1F 0%, rgba(13, 13, 13, 0.7) 16.67%, rgba(31, 31, 31, 0.05) 100%),
                    linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
                    content: "";
                    height: 100%;
                    left: 0;
                    position: absolute;
                    top: 0;
                    width: 100%;
                }
            }

            .r-article--title {
                bottom: space(1.5);
                color: color(light);
                font-family: $font-family-title;
                left: space(1.5);
                position: absolute;
                z-index: 1;
            }
        }
    }

    .r-viewmode--niveau-2 {
        .r-article--img {
            @include wallpaper(md) {
                // display: none;
            }
        }

        @include scope('video') {
            &:nth-of-type( 1 ) {
                @include xs {
                    article {
                        grid-template-areas: 'img'
                        'titre';
                        grid-template-columns: auto;
                    }

                    .r-article--title {
                        font-size: font-size(h5);
                        margin-top: space(.5);
                    }

                    &.r-layout--video,
                    &.r-picto--video {
                        .r-article--img {
                            &::after {
                                bottom: space();
                                right: space();
                                transform: scale(1.5);
                            }
                        }
                    }
                }
            }
        }
    }

    .page-node & {
        margin-bottom: space();
    }
}

.r-mini-panel--partenaires {
    i-mini-panel {
        @include smmdlg {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
    .r-mini-panel--title {
        @include smmdlg {
            width: 100%;
        }
    }
    a {
        flex: 0 0 60%;
        padding: 0;

        @include smmdlg {
            flex: 0 0 10%;
            padding: space();
        }
    }
}

@include scope('main') {
    .r-mini-panel--recruteurs {
        @include smmdlg {
            grid-column-start: 1;
            grid-column-end: -1;
            margin-left: calc(-50vw + 50%);
            width: 100vw;
        }

        @include wallpaper(md) {
            margin-left: 0;
            width: auto;
        }

        i-mini-panel {
            @include lg {
                grid-template-areas: '. . .';
                grid-template-columns: 1fr 1fr 1fr;
            }

            r-viewmode:last-of-type {
                margin-bottom: space();
            }
        }
    }
}

}// scope

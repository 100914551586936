@include scope('!app') {
    @include scope('newpub') {
        @include scope('critical') {
            r-pub {
                display: block;
                text-align: center;
        
                @include xs {
                    background: $bg-pub-color;
                    width: 100%;
                }
        
                i-pub {
                    display: block;
                    margin: 0 auto;
                }
        
                > div {
                    margin: 0 auto;

                    @include scope('!max') {
                        max-width: calc(100% - #{space(2)});
                        margin: space() auto;
                    }
                }
        
                iframe {
                    margin: 0;
            
                    @include xssm {
                        display: block;
                        margin: auto;
                    }
                }
        
                &[class*="-m-"] {
                    @include mdlg {
                        display: none;
                    }
                }
        
                &[class*="-d-"] {
                    @include xssm {
                        display: none;
                    }
                }
            }
        
            .r-pub--wal {
                > div {
                    margin: 0;
                }
            }
            
            .r-pub--hor {
                align-items: center;
                background: $bg-pub-color;
                display: flex;
                grid-column: 1 / -1;
                justify-content: center;
        
                > div {
                    align-items: center;
                    background: url($bg-pub-img) center center no-repeat;
                    background-size: auto $height-bg-pub;
                    display: flex;
                    justify-content: center;
                    margin: 0;
                    max-width: 100%;
                    padding: 0;
                    width: 100%;
        
                    &:not(:empty) {
                        padding: space() 0;
                    }
        
                    &.adm-ad-rendered {
                        background: none;
                    }
                }
            }
        
            [class*=hdr-hor-d] {
                @include mdlg {
                    > div {
                        min-height: 250px + space(2);
                        padding: space() 0;
                    }
                }
            }
        
            [class*=hdr-hor-m],
            [class*=hdr-flexs-m] {
                @include xssm {
                    > div {
                        min-height: 100px + space(2);
                        padding: space() 0;
                    }
                }
            }
        
            [class*=ctr-hor-d] {
                @include mdlg {
                    margin-left: calc(-50vw + 50%);
                    width: 100vw;

                    @include wallpaper {
                        margin-left: 0;
                        width: 100%;
                    }
                }
            }
        
            [class*=rgt-rec-d] {
                > div {
                    @include mdlg {
                        min-height: 600px + space(2);
                    }
                }
            }
        
            [class*=rgt-rec-m] {
                > div {
                    @include xssm {
                        min-height: 250px + space(2);
                    }
                }
            }
        
            .r-imu--top {
                @include smmdlg {
                    grid-area: imu-top;
                }
            }
        }

        @include scope('!critical') {
            .r-pub--crea {
                grid-column: 1 / -1;                
            }

            .r-pub--rec,
            .r-pub--imu,
            .r-pub--flexs,
            .r-pub--flexm,
            .r-pub--flexl {
                > div {
                    background: $bg-pub-color url($bg-pub-img) center center no-repeat;
                    background-size: auto $height-bg-pub;
                    width: 100%;

                    &.adm-ad-rendered {
                        background: none;
                    }
                    
                    @include scope('!article') {
                        @include scope('!sb') {
                            height: calc(100% - #{space(2)});
                        }
                    }
                    
                    > * {
                        @include smmdlg {
                            position: sticky;
                            
                            @include scope('ls') {
                                top: $header-bottom-height + space();
                                
                                @include smmdlg {
                                    top: $header-desktop-bottom-height + space();
                                }
        
                                [data-scrolldir='up'] & {
                                    top: $header-height + space();
        
                                    @include smmdlg {
                                        top: $header-desktop-height + space();
                                    }
                                }
                            }
        
                            @include scope('rtl') {
                                top: $header-height + space();
                                
                                @include smmdlg {
                                    top: $header-desktop-bottom-height + space();
                                }
                            }
        
                            @include scope('sudinfo') {
                                top: space();
        
                                @include smmdlg {
                                    top: $header-desktop-height + space();
                                }
                            }
                        }
                    }

                    @include scope('rtl') {
                        r-pub--pave & {
                            > * {
                                @include smmdlg {
                                    position: relative;
                                    top: auto;
                                }
                            }
                        }
                    }
                }
            }

            .r-pub--rec {
                &.-is-row-3 {
                    @include smmdlg {
                        grid-column-end: -1;
                        grid-row: 3 / span 2;
                    }
                }
                
                &:not(.r-imu--top):not([class*='-is-']) {
                    @include smmdlg {
                        grid-row: auto / span 2;
                    }

                    i-mini-panel > & {
                        @include smmdlg {
                            grid-column-end: -1;
                            grid-row: 2 / span 2;
                        }
                    }
                }
                
                > div {
                    min-height: 250px;

                    @include mdlg {
                        min-height: 600px;
                    }
                }
            }

            .r-pub--imu {
                > div {
                    min-height: 250px;
                }
            }

            .r-pub--nat {
                @include scope('une') {
                    @include scope('rtl') {
                        @include xs {
                            flex: 0 0 calc(50% - #{space(.5)});
                            max-width: calc(50% - #{space(.5)});
                        }
                    }
                }

                &.-is-wide {
                    @include smmdlg {
                        grid-column: auto / span 2;
                    }
                }

                @include scope(('article', '!direct')) {
                    > div {
                        margin: 0;
                        max-width: 100%;

                        r-mini-panel & {
                            margin: space();
                            max-width: calc(100% - #{space(2)});
                        }
                    }
                }
               
                @include xs {
                    background: none;

                    > div {
                        @include scope('ls') {
                            margin: 0;
                        }

                        @include scope('rtl') {
                            margin: 0 0 space(.75);
                            max-width: 100%;
                        }
                    }
                }
            }

            .r-pub--flexs {
                + .r-pub--flexs {
                    margin-top: space();
                }
            }

            .r-pub--flexl {
                align-items: center;
                background: $bg-pub-color;
                display: flex;
                grid-column: 1 / -1;
                justify-content: center;
                margin-left: calc(-50vw + 50%);
                width: 100vw;

                @include wallpaper {
                    margin-left: 0;
                    width: 100%;
                }
        
                > div {
                    align-items: center;
                    background: url($bg-pub-img) center center no-repeat;
                    background-size: auto $height-bg-pub;
                    display: flex;
                    justify-content: center;
                    margin: 0;
                    max-width: 100%;
                    padding: 0;
        
                    &:not(:empty) {
                        padding: space() 0;
                    }
        
                    &.adm-ad-rendered {
                        background: none;
                    }
                }
            }
            
            .-in-2-rows {
                @include smmdlg {
                    grid-row: auto / span 2;
                }
            }

            .-in-area-inread-top {
                @include smmdlg {
                    grid-area: inread-top;
                }
            }

            .-in-area-inread-mid {
                @include smmdlg {
                    grid-area: inread-mid;
                }
            }

            @include scope('article') {
                body:not(.-is--native) {
                    .r-pub--inread {
                        background: $bg-pub-color;
                        margin-bottom: space();
    
                        @include xs {
                            margin-left: calc(-50vw + 50%);
                            padding: 0 space(.5);
                            width: 100vw;
                        }
    
                        i-pub {
                            display: block;
                            
                            > [id*="out-dm-"] { //250
                                background: $bg-pub-color url($bg-pub-img) center center no-repeat;
                                background-size: auto $height-bg-pub;
                                padding: space() 0;
                                
                                &.adm-ad-rendered {
                                    background: $bg-pub-color;
                                }
        
                                @include scope('!inread') {
                                    min-height: 250px + space(2);
                                }
        
                                @include scope('inread') {
                                    background-position: 50% calc(50% + 12.5px);
                                    min-height: 250px + space(1.5) + 25px;
                                    padding-top: 0;
        
                                    &:before {
                                        background: $inread-bg-color;
                                        color: $inread-txt-color;
                                        content: $inread-txt;
                                        display: block;
                                        font-size: font-size(extrasmall);
                                        height: 25px;
                                        letter-spacing: .5px;
                                        line-height: 24px;
                                        margin: 0 0 space(.25);
                                        padding-top: space(.25);
                                        text-align: center;
                                        text-transform: uppercase;
                                        width: 100%;
                                    }
                                }
                            }
        
                            > [id*="-d-"] {
                                @include mdlg {
                                    background: $bg-pub-color url($bg-pub-img) center center no-repeat;
                                    background-size: auto $height-bg-pub;
                                    padding: space() 0;
                                    
                                    &.adm-ad-rendered {
                                        background: $bg-pub-color;
                                    }
            
                                    @include scope('inread') {
                                        background-position: 50% calc(50% + 12.5px);
                                        padding-top: 0;
            
                                        &:before {
                                            background: $inread-bg-color;
                                            color: $inread-txt-color;
                                            content: $inread-txt;
                                            display: block;
                                            font-size: font-size(extrasmall);
                                            height: 25px;
                                            letter-spacing: .5px;
                                            line-height: 24px;
                                            margin: 0 0 space(.25);
                                            padding-top: space(.25);
                                            text-align: center;
                                            text-transform: uppercase;
                                            width: 100%;
                                        }
                                    }
                                }
                            }
        
                            > [id*="-m-"] {
                                @include xssm {
                                    background: $bg-pub-color url($bg-pub-img) center center no-repeat;
                                    background-size: auto $height-bg-pub;
                                    padding: space() 0;
                                    
                                    &.adm-ad-rendered {
                                        background: $bg-pub-color;
                                    }
            
                                    @include scope('inread') {
                                        background-position: 50% calc(50% + 12.5px);
                                        padding-top: 0;
            
                                        &:before {
                                            background: $inread-bg-color;
                                            color: $inread-txt-color;
                                            content: $inread-txt;
                                            display: block;
                                            font-size: font-size(extrasmall);
                                            height: 25px;
                                            letter-spacing: .5px;
                                            line-height: 24px;
                                            margin: 0 0 space(.25);
                                            padding-top: space(.25);
                                            text-align: center;
                                            text-transform: uppercase;
                                            width: 100%;
                                        }
                                    }
                                }
                            }

                            > [id*="flexl-d-"],
                            > [id*="flexm-d-"],
                            > [id*="flexmv-d-"],
                            > [id*="rec-d-"] { // 600
                                @include mdlg {
                                    @include scope('!inread') {
                                        min-height: 600px + space(2);
                                    }
            
                                    @include scope('inread') {
                                        min-height: 600px + space(1.5) + 25px;
                                    }
                                }
                            }

                            > [id*="imu-d-"],
                            > [id*="flexs-d-"],
                            > [id*="flexsv-d-"] { // 250
                                @include mdlg {
                                    @include scope('!inread') {
                                        min-height: 250px + space(2);
                                    }
            
                                    @include scope('inread') {
                                        min-height: 250px + space(1.5) + 25px;
                                    }
                                }
                            }

                            > [id*="lea-d-"] { // 135
                                @include mdlg {
                                    @include scope('!inread') {
                                        min-height: 135px + space(2);
                                    }
            
                                    @include scope('inread') {
                                        min-height: 135px + space(1.5) + 25px;
                                    }
                                }
                            }

                            > [id*="-d-99"],
                            > [id*="-m-99"] { // no min-height
                                @include mdlg {
                                    @include scope('!inread') {
                                        min-height: auto;
                                    }
            
                                    @include scope('inread') {
                                        min-height: auto;
                                    }
                                }
                            } 

                            > [id*="flexm-m-"],
                            > [id*="flexmv-m-"] { // 600
                                @include xssm {
                                    @include scope('!inread') {
                                        min-height: 600px + space(2);
                                    }
            
                                    @include scope('inread') {
                                        min-height: 600px + space(1.5) + 25px;
                                    }
                                }
                            }

                            > [id*="flexs-m-"],
                            > [id*="flexsv-m-"],
                            > [id*="hor-m-"],
                            > [id*="imu-m-"] { // 250
                                @include xssm {
                                    @include scope('!inread') {
                                        min-height: 250px + space(2);
                                    }
            
                                    @include scope('inread') {
                                        min-height: 250px + space(1.5) + 25px;
                                    }
                                }
                            }

                            > [id*="halfimu-m-"] { // 125
                                @include xssm {
                                    @include scope('!inread') {
                                        min-height: 125px + space(2);
                                    }
            
                                    @include scope('inread') {
                                        min-height: 125px + space(1.5) + 25px;
                                    }
                                }
                            }
    
                            > div {
                                > div {
                                    text-align: center;
                                    position: sticky !important;
    
                                    > * {
                                        margin: auto;
                                    }
    
                                    @include scope('ls') {
                                        top: $header-bottom-height + space();
                                        
                                        @include smmdlg {
                                            top: $header-desktop-bottom-height + space();
                                        }
                
                                        [data-scrolldir='up'] & {
                                            top: $header-height + space();
                
                                            @include smmdlg {
                                                top: $header-desktop-height + space();
                                            }
                                        }
                                    }
                
                                    @include scope('rtl') {
                                        top: $header-height + space();
                                        
                                        @include smmdlg {
                                            top: $header-desktop-bottom-height + $header-desktop-top-height + space();
                                        }
                                    }
                
                                    @include scope('sudinfo') {
                                        top: space();
                
                                        @include smmdlg {
                                            [data-scrolldir='up'] & {
                                                top: $header-top-height + space();
                                            }
                                        }
                                    }
    
                                    @include scope('ctr') {
                                        top: $header-height + space();
                                    }
                                }
                            }
                        } 
                    }
                }

                r-pub {
                    &:last-child {
                        .r-article--sidebar & {
                            @include mdlg {
                                position: sticky;
    
                                @include scope('ls') {
                                    top: $header-desktop-bottom-height + space();
    
                                    [data-scrolldir='up'] & {
                                        top: $header-top-height + space();
                                    }
                                }
    
                                @include scope('!ls') {
                                    top: $header-top-height + space();
                                }
                            }
                        }
                    }
                }

                .r-mini-panel--pub-bas-article {
                    border-top: 1px solid color(gray, light);
                    margin: space() auto;

                    @include xs {
                        padding: 0 0 space();
                    }

                    i-mini-panel {
                        display: block;
                        margin: 0;

                        @include smmdlg {
                            display: grid;
                            gap: space(1.5);
                            grid-auto-flow: row dense;
                            grid-template-columns: repeat(2, 1fr);
                        }
                    }

                    .r-title {
                        background: url('/sites/all/themes/enacarbon/images/pub/lettrine-rab.svg') center right no-repeat;
                        background-size: auto 100%;
                        font-size: 0;
                        grid-column: 1 / -1;
                        height: 20px;
                        margin-bottom: space(.75);
                        margin-top: space(2);
                        position: relative;

                        @include smmdlg {
                            margin-bottom: space(-.75);
                        }

                        @include wallpaper {
                            margin-left: 0;
                        }
                    }

                    .r-pub--nat {
                        @include xs {
                            margin-bottom: space();
                        }

                        @include smmdlg {
                            grid-column: auto / span 3;
                        }

                        &.-is-full {
                            grid-column: 1 / -1;
                        }

                        > div {
                            margin: 0;
                            max-width: 100%;
                            position: relative;
                        }  
                    }
                }

                .r-mini-panel--pub {
                    grid-column: 1 / -1;

                    i-mini-panel {
                        display: block;
                        margin: 0;

                        @include smmdlg {
                            display: grid;
                            gap: space(1.5);
                            grid-auto-flow: row dense;
                            grid-template-columns: repeat(6, 1fr);
                        }
                    }

                    .r-pub--imu {
                        @include xs {
                            margin-bottom: space();
                        }

                        @include smmdlg {
                            grid-column: auto / span 3;
                        }

                        > div {
                            margin: 0;
                            max-width: 100%;
                        }
                    }

                    r-viewmode {
                        padding: 0;

                        @include xs {
                            border-bottom: none;
                            margin: 0 0 space();

                            &:last-of-type {
                                margin: 0 0 space();
                            }
                        }

                        @include smmdlg {
                            grid-column: auto / span 3;
                        }

                        .r-viewmode--article {
                            @include xs {
                                display: grid;
                                column-gap: space(.75);
                                grid-auto-flow: row dense;
                                grid-template-columns: 2fr 1fr;
                            }

                            &.r-layout--video,
                            &.r-layout--videos {
                                figure {
                                    @include smmdlg {
                                        margin-bottom: space(.75);
                                    }
                                }
                            }

                            .r-article--img {
                                @include xs {
                                    grid-column: 2;
                                }
                            }
                        }

                        img {
                            display: block;
                        }
                        
                        figure {
                            display: block;
                            width: 100%;
                        }

                        h3 {
                            margin: 0;
                        }

                        .r-article--img {
                            position: relative;
                        }

                        .r-article--title {
                            text-indent: 28px;
                            position: relative;

                            a {
                                display: -webkit-box;
                                overflow: hidden;
                                -webkit-line-clamp: 4;
                                -webkit-box-orient: vertical;
                            } 

                            &:before {
                                border-radius: 50%;
                                content:"";
                                display: inline-block;
                                height: 20px;
                                left: 0;
                                position: absolute;
                                width: 20px;

                                @include scope('sudinfo') {
                                    background: color(light) url('../images/sudinfo-icon.svg') center center no-repeat;
                                    background-size: 20px auto;
                                }

                                @include scope('ls') {
                                    background: color(light) url('../images/s-lesoir.svg') center center no-repeat;
                                    background-size: 20px auto;
                                }
                            }

                            .r-icon--abonne {
                                text-indent: 0;
                            }
                        }

                        r-article--meta {
                            display: none;
                        }
                    }

                    // r-mini-panel,
                    // r-mini-panel i-mini-panel,
                    // r-mini-panel w-viewmode {
                    //     display: contents;
                    // }

                    // .-is-mp-center.r-mini-panel--froomle {
                    //     .r-article--title {
                    //         font-size: font-size(h6);
                    //         margin: 0;
                    //         text-align: left;
                    //     }
                    // }
                }
            }
        }
    }
}

@include scope('app') {
    @include scope('newpub') {
        r-pub {
            background: $bg-pub-color;
            display: flex;
            justify-content: center;
            margin-bottom: space();
            margin-top: space();
            text-align: center;

            > div {
                text-align: center;
            }
        }

        %r-pub--inread {
            align-items: center;
            background: $bg-pub-color url("#{$domain}#{$bg-pub-img}") center center no-repeat;
            background-position: 50% calc(50% + 12.5px);
            background-size: auto $height-bg-pub;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0 auto space();
            margin-left: calc(-50vw + 50%);
            min-height: 250px + space(1.5) + 25px;
            padding: 0 space(.5) space();
            text-align: center;
            width: 100vw;

            > * {
                display: flex;
                flex: 1 1 auto;
                flex-direction: column;
                justify-content: center;
                width: 100%;
            }

            .adm-ad-rendered {
                background: $bg-pub-color;
            }

            &:before {
                background: $bg-pub-color;
                color: $inread-txt-color;
                content: $inread-txt;
                display: block;
                font-size: font-size(extrasmall);
                height: 29px;
                letter-spacing: .5px;
                line-height: 24px;
                margin: 0 0 space(.25);
                padding-top: space(.25);
                text-align: center;
                text-transform: uppercase;
                width: 100%;
            }
        }

        .r-pub--inread {
            @extend %r-pub--inread;
        }
    }
}

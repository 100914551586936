@include scope('!critical') {
    .r-mini-panel--list {
        margin-bottom: 0;
        padding: 0 0 space() space();

        @include smmdlg {
            grid-column-start: 1;
            grid-column-end: -1;
            margin-left: calc(-50vw + 50%);
            //padding-bottom: space(3);
            width: 100vw;
            z-index: z-index();
        }

        i-mini-panel {
            @include smmdlg {
                display: grid;
                grid-auto-flow: row dense;
                grid-gap: 1px;
                grid-template-areas: '. .';
                grid-template-columns: 50% 50%;
            }
        }

        &.r-panel--horizontal {
            .r-panel--title {
                font-size: font-size(h3);
            }
        }

        .r-mini-panel--title {
            padding: space(3) 0 space();

            @include smmdlg {
                grid-column-end: auto;
                padding: space(3) space() space();
            }
        }

        .r-panel--list {
            grid-column: 1 / -1;
            grid-row: 2;
            padding: 0;

            @include sm {
                padding: 0 space();
            }
        }

        .r-viewmode--panel-left {
            grid-column: 1;

            @include xs {
                padding: 0 space(2) space() space();
                margin: 0;
            }

            @include sm {
                grid-column: span 2;
            }

            .r-viewmode--article {
                position: relative;

                @include smmd {
                    margin-top: space(.5);
                }

                @include lg {
                    margin-top: space();
                }
            }

            i-article {
                background-color: color(light);
                border-radius: space();
                left: 0;
                margin-top: -40px;
                padding: space();
                position: relative;
                text-align: left;
                z-index: z-index();
                width: 100%;

                @include scope('lifestyle-custom') {
                    border-radius: 0;
                }

                @include smmdlg {
                    border-radius: space(2);
                    left: space();
                    margin-top: -80px;
                    width: 80%;

                    @include scope('lifestyle-custom') {
                        border-radius: 0;
                    }
                }
            }

            .r-article--title {
                margin: 0;
            }

            figure {
                margin-bottom: 0;
            }
        }

        .r-panel--panel-left {
            padding: space() 0;

            @include wallpaper {
                @include scope('special') {
                    grid-column: auto;

                    .r-panel--item {
                        flex: 0 0 50%;
                        max-width: 50%;
                    }
                }
            }
        }
    }

    .r-mini-panel--article {
        r-selligent {
            grid-row: 3;
        }

        r-pub {
            @include sm {
                grid-column: 2;
                grid-row: 2 / span 2;
            }

            @include wallpaper(md) {
                grid-column: 3;
                grid-row: 2 / span 2;
            }

            @include lg {
                grid-column: 4;
                grid-row: 2 / span 2;
            }
        }
    }

    @include scope('sport') {
        .r-mini-panel--une {
            @include smmdlg {
                grid-area: une;
                margin-left: 0;
                width: auto;
            }

            i-mini-panel {
                @include smmdlg {
                    margin: 0;
                }
            }

            .r-btn--more {
                @include md {
                    margin-left: 50%;
                }

                @include lg {
                    margin-left: calc(35% - 20px);
                }
            }
        }

        .r-mini-panel--result {
            border: 1px solid color(gray, light);
            grid-area: resultat;
            margin: space();
            padding: space();

            @include xs {
                border-radius: space();
            }

            @include smmdlg {
                margin: space() 0;
            }

            @include wallpaper {
                margin: space() 0;
            }
        }
    }// scope

    @include scope('video') {
        .r-mini-panel--half {
            @include lg {
                margin: 0;
                width: auto;
            }

            &:nth-child( odd ) {
                @include lg {
                    grid-column: 1 / span 2;
                }

                @include wallpaper() {
                    grid-column-start: 1;
                    grid-column-end: -1;
                }
            }

            &:nth-child( even ) {
                @include lg {
                    grid-column: 3 / span 2;
                }

                @include wallpaper() {
                    grid-column-start: 1;
                    grid-column-end: -1;
                }
            }

            i-mini-panel {
                @include lg {
                    margin: 0;
                }
            }
        }
    }// scope video

    .r-mini-panel--froomle {
        background: color(primary);

        i-mini-panel {
            color: color(light);
        }

        &:not(:has(.r-froomle--anonyme)) {
            a {
                color: color(light);

                &:hover {
                    color: color(light);
                }
            }
        }

        .r-mini-panel--title {
            span {
                display: flex;
                align-items: center;

                &::after {
                    content: "\ea03";
                    font-family: main;
                    font-size: 1.2rem;
                    margin-left: space();
                    font-weight: 700;
                }
            }
        }

        w-viewmode {
            display: flex;
            overflow: auto;
            grid-column: 1/-1;

            @include lg {
                display: contents;
                overflow: inherit;
            }

            r-viewmode {
                flex: 0 0 300px;
                width: 300px;

                @include lg {
                    width: auto;
                }

                @include xs {
                    padding: space();
                }

                article {
                    @include xs {
                        display: block;
                    }
                }

                figure {
                    @include xs {
                        margin-bottom: space(.75);
                    }
                }

                &:last-child {
                    @include wallpaper {
                        display: none;
                    }
                }
            }
        }
    }

    // froomle
    .r-froomle--anonyme,
    .r-froomle--cookies {
        align-items: center;
        background-image: url('#{$domain}/sites/all/themes/#{$theme}/images/bg_froomle_anonyme.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        box-shadow: inset 0 0 0 1000px color(gray,light,.7);
        border-radius: 10px;
        border: 1px solid color(primary);
        color: color(primary);
        display: flex;
        flex-direction: column;
        font-weight: bold;
        grid-column: 1/-1;
        margin: space();
        padding: space(3);
        text-align: center;

        @include scope('vdn') {
            box-shadow: inset 0 0 0 1000px color(primary,light,.7);
        }

        @include smmdlg {
            height: 300px;
        }

        @include smmd {
            flex-basis: 100%;
        }

        @include xs {
            background-size: auto;
            background-position: left;
            padding: space(2) space(1.5);
        }

        p {
            font-size: 1.5rem;
            margin-bottom: space(3);
        }

        a {
            font-size: 1rem;

            @include smmdlg {
                font-size: .9rem;
            }
        }
    }

    .r-froomle--login {
        margin-top: space();
        text-decoration: underline;
    }
}// scope !critical

@include scope('!critical') {
// Newsletter Immobilier
.r-selligent--pave-newsletter-immo {
    .r-selligent--pave-content {
        background-color: color(immo, base);
        color: color(dark);
    }
}
// Newsletter Emploi
.r-selligent--pave-newsletter-emploi {
    .r-selligent--pave-content {
        background-color: color(emploi, base);
    }
}
// Newsletter Economie
.r-selligent--pave-newsletter-eco,
.r-selligent--under-rs-newsletter-eco  {
    .r-selligent--pave-content,
    .r-selligent--under-rs-content {
        background-color: #E83C35;
    }
    .r-btn--secondary {
        background-color: #FED9D4;
        color: #E83C35;
    }
}
// Offre VDS
.r-selligent--pave-offre-vds {
    .r-selligent--pave-content {
        background-color: lighten(color(sport), 50%);
        color: #E6332A;
    }
    .r-btn--secondary {
        background-color: color(sport);
        color: color(light);
    }
}
// Offre SB eco
.r-selligent--pave-offre-eco {
    .r-selligent--pave-content {
        background-color: lighten(#d8282c, 40%) !important;
        color: #FF4034 !important;
    }
    .r-btn--secondary {
        background-color: #FF4034 !important;
        color: color(light) !important;
    }
}
}// scope

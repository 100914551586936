@include scope('critical') {
.r-mini-panel--title {
    align-items: center;
    display: flex;
    font-size: font-size(h4);
    line-height: line-height(h1);
    margin: 0;
    padding: space(1.5) 0;

    &::after {
        background: color(gray, light);
        content: '';
        flex: 1;
        height: 1px;
        margin: space() 0 space() space();
        padding: 0 space();

        @include xs {
            padding-right: 0;
        }

        .r-mini-panel--offres-emploi & {
            background: color(emploi);
        }

        .r-mini-panel--offres-immobilier & {
            background: color(immo);
        }
    }

    @include scope('mag') {
        text-align: center;
        text-transform: uppercase;

        &::before {
            background: color(gray, light);
            content: '';
            flex: 1;
            height: 1px;
            margin: space() space() space() 0;
            padding: 0 space();
        }
    }

    a {
        @include scope('!video') {
            color: color(dark);
        }
    }

    @include scope('sb') {
        &::after, &::before {
            background: black;
            content: '';
            flex: 1;
            height: 1px;
            @include scope('video') {
                background: color(light, 0.5);
            }
        }

        &::before {
            margin: space() space() space() 0;
            padding: 0 space();
    
            @include xs {
                padding-left: 0;
            }
        }

        a {
            text-align: center;
            @include scope('video') {
                color: color(primary, light);
                font-family: $font-family-title;
            }
        }
    }
}
}

@include scope('!critical') {
    r-mini-panel {
        display: block;
        z-index: 0; // pour cacher les bout de filets

        @include scope('special') {
            padding-bottom: space(2);
        }

        @include xs {
            margin-bottom: space();
            padding: 0 space() space();
        }

        @include wallpaper {
            @include scope('!article') {
                margin-left: 0;
                width: auto;
            }

            @include scope(('article', '!sport')) {
                &:not( .r-mini-panel--menu ) {
                    margin: auto;
                    max-width: $arche-width;
                }
            }

            @include scope('sport') {
                &:not( .r-mini-panel--result ):not( .r-mini-panel--menu ) {
                    margin: auto;
                    max-width: $arche-width;
                }
            }
        }

        .r-btn--more {
            @include smmdlg {
                order: 10;
            }

            @include scope('special') {
                grid-column: -2;
                grid-row: 1;
                justify-content: flex-end;
                padding: space(3) space(1) space(1);
            }

            .r-btn {
                margin: 0 space(.5);
            }
            @include scope('sb') {
                @include scope('video') {
                    .r-btn--primary {
                        background: color(primary, light);
                        border: none;
                        color: black;
                    }
                }
            }
        }

        r-panel {
            @include sm {
                margin: space(.5);
            }
        }

        @include scope('special') {
            figure {
                border-radius: space(2);
                @include scope('lifestyle-custom') {
                    border-radius: 0;
                }
            }

            time {
                color: color(primary);
                display: inline-block;
                font-family: $font-family-title;
                font-size: font-size(btn);
                text-transform: uppercase;
                margin: 0 space() space() 0;

                @include xs {
                    display: none;
                }
            }
        }// scope
    }

    i-mini-panel {
        display: block;
        margin: 0;

        @include lg {
            margin: auto maxi(7.8vw, calc(50vw - #{$content-max-width / 2}));
        }

        @include md {
            max-width: 900px + space(6);
            margin: auto maxi(space(4), calc(50vw - #{450px + space(3)}));
        }

        @include sm {
            max-width: 600px + space(6);
            margin: auto;
        }

        @include wallpaper {
            margin: auto;
        }

        r-viewmode {
            &:last-of-type {
                border: 0;
                margin-bottom: 0;
            }
        }

        .r-pub--imu {
            @include smmdlg {
                grid-row: auto;
            }
        }

        r-sidebar & {
            margin: auto;
        }
    }

    .r-m {
        display: block;
        position: relative;
        top: -$header-height;

        @include smmdlg {
            top: -$header-desktop-height;
        }

        [data-scrolldir='down'] & {
            top: -$header-bottom-height;
        }
    }

    .r-mini-panel--title {
        @include scope('special') {
            font-family: $font-family-base;
            font-weight: 900;
            text-decoration: underline;

            @include xs {
                margin: 0 space() space(.75);
            }

            &::after {
                display: none;
            }

            a {
                color: color(dark);
            }
        }
        @include scope('lifestyle-custom') {
            font-family: $font-family-title;
        }

        @include scope('video') {
            color: color(light);
            @include scope('sb') {
                color: color(primary, light);
            }
        }

        @include xs {
            @include scope('!special') {
                padding-right: 0;
            }
        }

        @include smmdlg {
            margin: -1px 0;

            @include scope('!mag') {
                font-size: font-size(h1);
            }

            @include scope('mag') {
                font-size: font-size(h3);
            }

            @include scope('!special') {
                grid-column-start: 1;
                grid-column-end: -1;
                padding: space(1.5) space() space() space();
            }

            @include scope('special') {
                grid-column: 1/-2;
                padding: space(3) 0 space() space();
            }
        }

        r-icon {
            font-size: 1.2rem;
            margin-left: space();
            font-weight: 700;

            .-has-bg-light & {
                background: color(gray, lighter);
                border-color: color(gray, lighter);
            }
        }

        .r-icon--location {
            color: color(primary);
            font-size: 36px;
            margin-left: 0;
            margin-right: space(.75);
        }

        .r-icon--pencil-square {
            font-size: .9rem;
            margin-left: 0;
        }

        .r-btn--more {
            font-family: $font-family-base;
            font-weight: normal;
            margin-left: 16px;
            padding: 0;
            text-transform: none;

            @include xs {
                order: 10;
            }

            .r-mini-panel--offres-emploi & {
                color: color(emploi);
                font-size: 0;

                @include smmdlg {
                    color: color(emploi);
                    font-family: $font-family-title;
                    font-size: font-size(aside);
                    font-weight: 700;
                    text-decoration: underline;
                    text-transform: uppercase;

                    &:hover {
                        text-decoration: none;
                    }
                }

                r-icon {
                    margin: 0 space() 0 0;

                    @include smmdlg {
                        display: none;
                        margin-right: 0;
                    }
                }
            }
        }

        .-is-ma-commune & {
            align-items: end;
            display: grid;
            grid-template-columns: 37px minmax(0, auto) minmax(0, auto) 2fr;
            grid-template-rows: 1fr 1fr;

            @include xs {
                grid-template-columns: 30px auto 110px;
            }

            .r-icon--location {
                grid-column: 1;
                grid-row: span 2;
                margin-bottom: 4px;
            }

            span {
                color: color(primary);
                font-size: .9rem;
                grid-column: 2;
                grid-row: 1;
            }

            strong {
                grid-column: 2;
                grid-row: 2;
                line-height: 1;
            }

            &::after {
                grid-column: 4;
                grid-row: span 2;

                @include xs {
                    display: none;
                }
            }
        }
        .r-mini-panel-immo-list & {
            span {
                margin-left: space(.5);

                @include xs {
                    display: none;
                }
            }
        }

        .r-mini-panel-immo-list &,
        .r-mini-panel--offres-emploi & {
            font-size: font-size(h3);

            @include xs {
                font-size: font-size(h6);
                padding: space(1.5) 0 space();
            }

            @include smmdlg {
                padding-left: space(.5);
                padding-right: space(.5);
            }
        }

        .r-mini-panel--offres-emploi & {
            color: color(emploi);
        }
    }

    .r-btn--macommune {
        border: 1px solid color(primary);
        border-radius: 30px;
        color: color(primary);
        font-size: 1rem;
        font-weight: bold;
        grid-column: 3;
        grid-row: span 2;
        justify-content: center;
        padding: space(.5) space();
        text-transform: none;

        @include smmdlg {
            align-self: end;
            margin-bottom: 4px;
            margin-left: 16px;
        }

        .page-section-r_gion & {
            @include scope('an', 'cp', 'pn') {
                @include smmdlg {
                    align-self: center;
                    margin-bottom: space();
                }
            }
        }

        span {
            font-size: 1rem;
            margin-left: 4px;

            @include xs {
                display: none;
            }
        }
    }
}// scope

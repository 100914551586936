//-------------------------------------------------------------------
// SIDEBAR MENU -----------------------------------------------------------
//-------------------------------------------------------------------

@include scope('critical') {
.r-mini-panel--menu {
    grid-column: 1; // pour empêcher du cls
    visibility: hidden;
}
}// scope

@include scope('!critical') {
.r-mini-panel--menu {
    background: color(gray, dark, .5);
    content-visibility: hidden;
    display: flex;
    opacity: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: z-index(high);

    @include smmdlg {
        @include scope(('!mag', '!sport')) {
            top: $header-desktop-top-height;
        }

        @include scope('mag', 'sport') {
            top: 160px;
        }
    }

    @include max {
        max-width: $html-max-width;
    }

    i-mini-panel {
        background: color(light);
        height: 100vh;
        overflow: auto;
        position: relative;
        width: 80%;
        z-index: z-index();

        @include xs {
            transform: translateX(-100%);
            transition: transform .3s ease;
        }

        @include smmdlg {
            width: 350px;

            @include scope(('!mag', '!sport')) {
               height: calc(100vh - #{$header-desktop-top-height});
            }

            @include scope('mag', 'sport') {
                height: calc(100vh - 160px);
            }
        }


        @include scope('mag', 'sport', 'article') {
            @include sm {
                margin: auto 0;
            }

            @include md {
                margin: auto maxi(calc(7.8vw + 24px), calc(50vw - #{$content-max-width / 2}));
            }

            @include lg {
                margin: auto maxi(calc(7.8vw + 24px), calc(50vw - (#{($content-max-width - 4)/ 2})));
            }

            @include wallpaper {
                margin: auto maxi(calc(7.8vw + 24px), calc(50vw - (#{($content-max-width - 4)/ 2})));
            }
        }
    }

    @include scope('mag') {
        r-back {
            background-color: color(primary);
            display: flex;
            position: relative;

            span {
                color: color(light);
                font-weight: 900;
                padding-right: space(3);
                text-transform: uppercase;
                overflow: inherit;
                width: auto;
            }
        }

        .r-back--left {
            flex: 0 0 100%;
            justify-content: space-between;
            padding: space(.5) space();

            &::before {
                background-color: color(light);
                border-radius: 3px;
                padding: space(.5) space();
            }
        }
    }

    &.-is-toggle {
        i-mini-panel {
            @include xs {
                transform: translateX(0);
            }
        }
    }
}

.r-mini-panel--overlay {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
}

.-is-toggle-r-mini-panel--menu {
    overflow: hidden;
}

.r-menu--main {
    position: relative;

    .r-menu--link,
    .r-submenu--link {
        align-items: center;
        border-bottom: 1px solid color(gray, base);
        color: color(black);
        display: flex;
        font-size: font-size(h6);
        margin: 0 space(2);
        padding: space() 0;
        text-transform: uppercase;

        // @include scope(('!mag', '!sport')) {
            font-weight: bold;
        // }

        // @include scope('mag', 'sport') {
        //     font-weight: 900;
        // } 

        &::before {
            color: color(gray, dark);
            font-family: main;
            font-size: font-size(h4);
            line-height: 1;
            padding-right: 10px;
            position: relative;
            top: -1px;
            vertical-align: middle;
        }

        &:hover {
            text-decoration: none;

            @include scope(('!mag', '!sport')) {
                color: color(primary);

                &::after {
                    color: color(primary);
                }
            }

            @include scope('mag') {
                color: color(mag);

                &::after {
                    color: color(mag);
                }
            }

            @include scope('lereo') {
                color: color(lereo);

                &::after {
                    color: color(lereo);
                }
            }

            @include scope('cipiz') {
                color: color(cipiz);

                &::after {
                    color: color(cipiz);
                }
            }

            @include scope('lifestyle') {
                color: color(lifestyle);

                &::after {
                    color: color(lifestyle);
                }
            }

            @include scope('sport') {
                color: color(sport);

                &::after {
                    color: color(sport);
                }
            }
        }
    }

    .r-link--image {
        padding: space(.5) space(.5) space(.5) space(1.5);

        &:hover {
            background: color(primary, lighter);
        }
    }

    [class*='r-icon--'] {
        font-weight: bold;
        text-transform: none;

        @include scope(('!mag', '!sport')) {
            color: color(primary);
            text-transform: none;
        }

        @include scope('mag') {
            color: color(mag);
        }

        @include scope('lereo') {
            color: color(lereo);
        }

        @include scope('cipiz') {
            color: color(cipiz);
        }

        @include scope('lifestyle') {
            color: color(lifestyle);
        }

        @include scope('sport') {
            color: color(sport);
        }

        &::before {
            font-weight: bold;

            @include scope(('!mag', '!sport')) {
                color: color(primary);
            }

            @include scope('mag') {
                color: color(mag);
            }

            @include scope('lereo') {
                color: color(lereo);
            }

            @include scope('cipiz') {
                color: color(cipiz);
            }

            @include scope('lifestyle') {
                color: color(lifestyle);
            }

            @include scope('sport') {
                color: color(sport);
            }
        }
    }

    r-submenu {
        background: color(light);
        bottom: 0;
        left: 0;
        padding-top: 57px;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;

        &.-is-active {
            z-index: 2;
        }
    }
}
}// scope

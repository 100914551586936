// Page marches

$gray-base:		  		 #242424;
$gray-darker:            lighten($gray-base, 8%); // #222
$gray-dark:              lighten($gray-base, 20%);   // #333
$gray:                   lighten($gray-base, 33.5%); // #555
$gray-light:             lighten($gray-base, 70%); // #777
$gray-lighter:           lighten($gray-base, 80%); // #eee
$gray-extralight:        lighten($gray-base, 85%); // #eee

$sb-gray:       #F0F0F0;
$sb-gray-light: #F7F7F7;
$sb-gray-dark:  #9F9F9F;
$sb-red:        #FC8029;
$sb-red-dark:   #DA3C0A;

// general marches

.fa-long-arrow-right {
    font-style: normal;
    &::before {
        font-family: $font-family-icons;
        content: $icon-right-arrow;
    }
}

.fa-chevron-right {
    font-style: normal;
    &::before {
        font-family: $font-family-icons;
        content: $icon-arrow-right;
    }
}



// marches page paywall
.page-marches {
    .r-panel--paywall {
        margin:0;
        &::before {
            top: -320px;

            @include sm {
                top: -345px;
            }
            @include xs {
                top: -430px;
            }
        }

        r-paywall--content {
            h2 {
                font-family: $font-family-base;
                font-size: font-size(h3);
                margin: space(2) 0 space();
    
                @include xs {
                    font-size: font-size(h5);
                }
            }

           .r-paywall--heading {
                font-size: 1.2rem;
            }
        }

       

        r-paywall--footer {
            .r-btn--secondary {
                @include xs {
                    margin-bottom: space();
                }
            }
        }
    }
}

.pane-marches-trend {
    border-radius: 4px;
    font-size: 16px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    width: 25px;

    &::before {
        display: inline-block;
    }

    &.pane-marches-trend__down {
        color: white;
        background-color: $sb-red;
        &::before {
            transform: rotate(45deg);
        }
    }

    &.pane-marches-trend__up {
        color: white;
        background-color: color(primary, light);
        &::before {
            transform: rotate(-45deg);
        }
    }

    &.pane-marches-trend__same {
        color: white;
        background-color: $sb-gray-dark;
    }
}

.marches-trend-small {
    font-size: 16px;
    line-height: 5px;
    text-align: center;

    &::before {
        display: inline-block;
    }

    &.marches-trend-small__down {
        &::before {
            color: $sb-red-dark;
            transform: rotate(45deg);
        }
    }

    &.marches-trend-small__up {
        &::before {
            color: color(primary);
            transform: rotate(-45deg);
        }
    }

    &.marches-trend-small__same {
        &::before {
            color: $sb-gray-dark;
        }
    }
}

.pane-marches_crumbs {
    font-family: $font-family-title;
    font-weight: font-weigh(base);
    font-size: 13px;
    
    a {
        border: 1px solid $sb-gray;
        color: color(black);
        display: inline-block;
        padding: 10px 15px 10px 10px;
        margin-right: 3px;
        text-decoration: none;
        background-color: white;
        border-color: color(black);

        &:hover {
            background-color: color(black);
            color: white;
        }
        &::before {
            font-size: 12px;
            line-height: 12px;
            padding-right: 10px;
            font-family: $font-family-icons;
            content: $icon-arrow-left;
        }
    }
}


// marches - minipanels
.r-mini-panel--section {
    &.-is-mp-markets {

        i-mini-panel {
            padding-bottom: space(2);

            @include lg { 
                grid-template-areas:
                'mp-title   mp-title    mp-title    mp-title'
                'mp-trends  mp-trends   mp-latest   mp-latest';
            }

            @include smmd { 
                grid-template-areas: 
                    "mp-title"
                    "mp-trends"
                    "mp-latest";
                grid-template-columns: 100%;
            }

            @include sm {
                margin: 0 auto 16px auto;
            }

            .r-mini-panel--title {
                grid-area: mp-title;
                padding-bottom: 0;

                &:after {
                    display: none;
                }
                &:before {
                    display: none;
                }
            }

        }

    }

    &.-is-mp-markets-home {
        
        @include xs { 
            padding-left: 0;
            padding-right: 0;
        }

        i-mini-panel {
            padding-bottom: space(2);

            @include lg { 
                grid-template-areas:
                'intro   sections    sections    latest'
                '.  .   .   .';
            }

            @include smmd {
                grid-template-areas: 
                    'sections sections'
                    'intro latest'
                    '. .';
                grid-template-columns: 50% 50%;
            }

            
            @include sm {
                margin: 0 auto 16px auto;
            }

            @include xs { 
                display: grid;
                grid-template-areas: 
                    'sections'
                    'intro'
                    'latest'
                    '.';
                grid-template-columns: 100%;
                padding-left: 0;
            }

            .markets-home--intro {

                background: no-repeat left bottom url('/sites/all/themes/enacarbon_sb/images/marches-landscape.svg');
                grid-area: intro;
                margin-left: space();
                padding: space();

                @include xs {
                    margin-left: 0;
                }

                @include xs {
                    background-size: 100% auto;
                    padding-bottom: 60px;
                }

                h3 {
                    margin-bottom: space(2);
                }

                ul {
                    list-style-type: none;
                    margin: space(2) 0;
                    padding: 0;

                    li {
                        font-size: 16px;
                        line-height: 20px;
                        padding: 5px 0;

                        &::before {
                            color: color(primary,dark);
                            content: $icon-checked;
                            font-family: $font-family-icons;
                            font-size: 20px;
                            line-height: 20px;
                            padding-right: 7px;
                        }
                    }

                }

            }

            .markets-home--sectionlist {
                grid-area: sections;
                padding: space();

                h2 {
                    @include xs { 
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
            }

            .markets-home--latest {
                grid-area: latest;
                padding: space();
            }

        }
    }
}

.panel-markets-une-trends {
    grid-area: mp-trends;
    padding: space(1);
    h3 {
        font-size: 20px;
    }
    ul {
        column-count: 2;
        column-gap: 20px;
        list-style-type: none;
        margin-top: 0;
        padding: 0;

        @include xssm { 
            column-count: 1;
        }

        li {
            border-top: 1px dashed color(gray,dark);
            padding: 6px 0;

            a {
                color: color(black);
                display: flex;
                text-decoration: none;
                &:hover {
                    text-decoration: none;
                    .markets-trends-section {
                        text-decoration: underline;
                    }
                }
            }

            .pane-marches-trend {
                font-size: 22px;
                height: 36px;
                line-height: 36px;
                width: 35px;
            }

            .markets-trends-section {
                font-size: 20px;
                line-height: 36px;
                padding-left: space(0.5);
                padding-right: space(0.5);
                &:hover {
                    text-decoration: underline;
                }
            }

            .pane-marches-home__icon {
                height: 36px;
                position: initial;
                width: 36px;

                &.pane-marches-home__icon-1 {
                    background-size: 80%;
                }
                &.pane-marches-home__icon-2 {
                    background-position: 0px 17px,9px 11px;
                }
                &.pane-marches-home__icon-3 {
                    background-size: auto 70%;
                }
                &.pane-marches-home__icon-4 {
                    background-size: 80%;
                }
                &.pane-marches-home__icon-5 {
                    background-position: 2px 17px,15px 12px;
                }
                &.pane-marches-home__icon-6 {
                    background-size: 65%;
                }
                &.pane-marches-home__icon-7 {
                    background-size: 60%;
                }
                &.pane-marches-home__icon-8 {
                    background-size: 50%;
                }
                &.pane-marches-home__icon-9 {
                    background-size: 50%;
                }
                &.pane-marches-home__icon-10 {
                    background-size: 52%;
                }
                &.pane-marches-home__icon-11 {
                    background-size: 80%;
                }
            }
        }
    }
}


.pane-marches__accordion {

    & > .pane-marches__accordion__trigger {
        cursor: pointer;
        list-style-type: none;

        &::-webkit-details-marker { 
            display:none; 
        }
        &:focus {
            outline: none;
        }
        
        & > .pane-marches__accordion__trigger__title {    
            border-bottom: 1px solid white;
            &::after {
                font-family: $font-family-icons;
                content: $icon-plus-square;
                font-size: 22px;
                position: absolute;
            }
        }
        & > h2.pane-marches__accordion__trigger__title {
            margin-bottom: 0;
            &::after {
                right: 24px;
                font-size: 20px;
            }
        }
        & > h3.pane-marches__accordion__trigger__title {
            &::after {
                right: 0;
                @include xs {
                    right: 20px;
                }
            }
        }
        & > h4.pane-marches__accordion__trigger__title {
            &::after {
                right: 20px;
            } 
        }
        & > h5.pane-marches__accordion__trigger__title {
            background-color: $sb-gray;
            &::after {
                font-size: 18px; 
                right: 30px;
                @include xs {
                    right: 22px;
                }
            }
        }

        &:hover {
            & > h2.pane-marches__accordion__trigger__title {
                background-color: $sb-gray-light;
            }
            & > h3.pane-marches__accordion__trigger__title {
                border-bottom: 1px solid color(black);
            }
            & > h4.pane-marches__accordion__trigger__title {
                border-bottom: 1px solid color(black);
            }
            & > h5.pane-marches__accordion__trigger__title {
                border-bottom: 1px solid color(black);
            }
        }

        &.market-name {

            list-style: none;
            & > .pane-marches__accordion__trigger__title {    
                &::after {
                    content:  $icon-chevron-down;
                    transform: rotate(0);
                    transition: 0.15s transform ease-in;
                }
            }
        }
    }

    & > .pane-marches__accordion__content {
        background-color: white;
        max-height: 0;
        transition: 1s max-height ease-in-out;
        overflow: hidden;
    }

    &[open] {
        & > .pane-marches__accordion__trigger {
            & > .pane-marches__accordion__trigger__title {
                &::after {
                    //content: $icon-fa-minus;
                    content: $icon-minus-square;
                }
            }
            & > h4.pane-marches__accordion__trigger__title, & > h5.pane-marches__accordion__trigger__title {
                background-color: color(black);
                color: white;
            }
            &.market-name {
                & > .pane-marches__accordion__trigger__title {
                    &::after {
                        content:  $icon-chevron-down;
                        transform: rotate(180deg);
                    }
                }
            }
        }
        & > .pane-marches__accordion__content {
            max-height: 10000px;
        }
        .pane-marches-section__news {
            display: block;
        }
    }  
    
    h4.pane-marches__accordion__trigger__title, 
    h5.pane-marches__accordion__trigger__title {
        margin-right:0px;
        position:relative;
    }

    h5.pane-marches__accordion__trigger__title {
        &::after {
            right:22px !important;
        }   
    }
}
.pane-marches-section__news {
    display: block;
    background-color: #F5F5F5;
    font-size: 13px;
    padding: 12px 21px;
    .pane-marches-section__news-title {
        color: color(black);
        font-family: $font-family-title;
        font-weight: font-weight(base);
        text-transform: uppercase;
    }
    .pane-marches-section__news-market {
        color: #9F9F9F;
    }
    .pane-marches-section__news-text {
        padding-top: 3px;
    }
}
.pane-marches__accordion .pane-marches-section__news {
    display: none;
}

.pane-marches-product__news {
    font-size: 13px;
    margin-bottom: 20px;
    margin-top: -18px;
    max-width: 500px;
    .pane-marches-product__news-title {
        color: color(black);
        font-family: $font-family-title;
        font-weight: font-weight(base);
        text-transform: uppercase;
    }
    .pane-marches-product__news-market {
        color: #9F9F9F;
    }
    .pane-marches-product__news-text {
        padding-top: 5px;
    }
}


.markets-home--sectionlist {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: stretch;

    h2 {
        border-bottom: 1px solid $sb-gray;
        font-family: $font-family-title;
        font-size: 20px;
        font-weight:500;
        margin: 0;
        position: relative;

        display: block;
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        align-self: auto;

        max-width: 500px;
        width: 98%;

        a {
            color: color(black);
            display: block;
            padding: 15px 0 15px 60px;
            text-decoration: underline;

            &:hover, &:active, &:focus {
                background-color: $sb-gray;
                color: color(black);
            }

            &::after {
                color: color(black);
                font-size: 20px;
                line-height: 30px;
                position: absolute;
                right: 10px;
                content:  $icon-arrow-right;
                font-family: $font-family-icons;
            }
        }
        .pane-marches-trend {
            margin-top: 3px;
            position: absolute;
            right: 35px; 
        }

        // @include xssmmd {
        //     max-width: 500px;
        //     width: 98%;
        // }
    }
    &-title {
        text-decoration: underline;
    }

    @include xssmmd {
        max-height: initial;
    }
}


.pane-marches-home__icon {
    display: inline-block;
    height: 50px;
    left: 0;
    position: absolute;
    top: 3px;
    width: 50px;
    &.pane-marches-home__icon-1 {
        background: no-repeat center center url('/sites/all/themes/enacarbon_sb/images/icons/markets/cow.svg');
        transform: scaleX(-1);
    }
    &.pane-marches-home__icon-2 {
        background-image: url('/sites/all/themes/enacarbon_sb/images/icons/markets/cow.svg'), url('/sites/all/themes/enacarbon_sb/images/icons/markets/cow.svg');
        background-size: 60%, 60%;
        background-position: 2px 24px,15px 15px;
        background-repeat: no-repeat, no-repeat;
        transform: scaleX(-1);
    }
    &.pane-marches-home__icon-3 {
        background: no-repeat center center url('/sites/all/themes/enacarbon_sb/images/icons/markets/milk.svg');
    }
    &.pane-marches-home__icon-4 {
        background: no-repeat center center url('/sites/all/themes/enacarbon_sb/images/icons/markets/porc.svg');
        transform: scaleX(-1);
    }
    &.pane-marches-home__icon-5 {
        background-image: url('/sites/all/themes/enacarbon_sb/images/icons/markets/porc.svg'), url('/sites/all/themes/enacarbon_sb/images/icons/markets/porc.svg');
        background-size: 60%, 60%;
        background-position: 2px 25px,15px 20px;
        background-repeat: no-repeat, no-repeat;
        transform: scaleX(-1);
    }
    &.pane-marches-home__icon-6 {
        background: no-repeat center center url('/sites/all/themes/enacarbon_sb/images/icons/markets/sheep.svg');
    }
    &.pane-marches-home__icon-7 {
        background: no-repeat center center url('/sites/all/themes/enacarbon_sb/images/icons/markets/eggs-y.svg');
    }
    &.pane-marches-home__icon-8 {
        background: no-repeat center center url('/sites/all/themes/enacarbon_sb/images/icons/markets/chicken.svg');
    }
    &.pane-marches-home__icon-9 {
        background: no-repeat center center url('/sites/all/themes/enacarbon_sb/images/icons/markets/grains.svg');
    }
    &.pane-marches-home__icon-10 {
        background: no-repeat center center url('/sites/all/themes/enacarbon_sb/images/icons/markets/mat-prem.svg');
    }
    &.pane-marches-home__icon-11 {
        background: no-repeat center center url('/sites/all/themes/enacarbon_sb/images/icons/markets/potatoes.svg');
    }
    &.pane-marches-home__icon-12 {
        background: no-repeat center center url('/sites/all/themes/enacarbon_sb/images/icons/markets/petrol.svg');
    }

    &.pane-marches-home__icon-13 {
        background: no-repeat center center url('/sites/all/themes/enacarbon_sb/images/icons/markets/oleo.svg');
        background-size: 56%;
    }
    &.pane-marches-home__icon-14 {
        background: no-repeat center center url('/sites/all/themes/enacarbon_sb/images/icons/markets/sugar.svg');
        background-size: 65%;
    }
    &.pane-marches-home__icon-15 {
        background: no-repeat center center url('/sites/all/themes/enacarbon_sb/images/icons/markets/fruitveg.svg');
        background-size: 75%;
    }
    &.pane-marches-home__icon-16 {
        background: no-repeat center center url('/sites/all/themes/enacarbon_sb/images/icons/markets/wine.svg');
        background-size: 50%;
    }
}


.pane-marches-section {
    
    h1 {
        font-family: $font-family-title;
        font-weight: 600;
    }
    h2 {
        background-color: $sb-gray;
        border-top: 1px solid color(black);
        font-family: $font-family-title;
        font-size: 20px;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 0;
        padding: 15px 40px 15px 20px;
        position: relative;
        &.eu {
            background-size: 30px auto;
            background-position-x: 20px;
            background-position-y: center;
            background-repeat: no-repeat;
            background-image: url("/sites/all/themes/enacarbon_sb/images/europeanunion.svg");
            padding-left: 60px;
        }
    }
    h3 {
        font-family: $font-family-title;
        font-size: 20px;
        font-weight: 600;
        line-height: 20px;
        margin: 5px 20px 0 20px;
        padding: 16px 25px 15px 0px;
        position: relative;
        @include xs {
            margin: 5px 0 0 0;
            padding-left: 2px;
            padding-right: 45px;
        }
    }
    h4 {
        font-family: $font-family-title;
        font-size: 17px;
        font-weight:font-weight(h1);
        line-height: 17px;
        margin: 0 20px;
        padding: 10px 20px;
        @include xs {
            margin: 10px 0 0 0;
            padding-left: 10px;
        }
    }
    h5 {
        font-family: $font-family-title;
        font-size: 15px;
        font-weight: normal;
        line-height: 15px;
        margin: 0px 20px;
        padding: 10px 20px 10px 25px;
        @include xs {
            margin: 0;
        }
    }
}

.pane-marches-section__table {
    margin: 0;
    width: 100%;
    &-wrap {
        margin: 10px 20px 0 20px; 
        
        @include xs {
            margin: 10px 0 0 0; 
        }
    }
    thead {

        border-top: 1px solid color(black);
        border-bottom: 2px solid color(black);
        
        th {
            text-align: left;
            font-size: 12px;
            line-height: 12px;
            padding-top: 8px;
            padding-bottom: 8px;
            &.pane-marches-section__itemhead {
                min-width: 40%;
            } 
            // &.pane-marches-section__pricehead {
            //     max-width: 150px;
            // }
            &.pane-marches-section__trendhead {
                min-width: 70px;
                text-align: center;
                @include xs {
                    min-width: 65px;
                }
            }
            &.pane-marches-section__arrowhead {
                width: 28px;
                @include xs {
                    width: 30px;
                }
            }
        }
    }
    tbody {
        tr {
            border-top: 1px solid $sb-gray-dark;
            cursor: pointer;
            &:hover, &:active, &:focus {
                background-color: #f0f0f0;
                td.pane-marches-section__arrow {
                    i {
                        transform:translateX(2px);
                        @include xs {
                            transform:translateY(2px);
                        }
                    }
                }
            }
        }
        td {
            font-size: 14px;
            line-height: 18px;
            padding-top: 15px;
            padding-bottom: 15px;
            padding-right: 10px;
            vertical-align: top;

            a:hover {
                text-decoration: none;
            }
            &.pane-marches-section__item {
                a {
                    color: color(black);
                    text-decoration: underline;
                }
            }
            &.pane-marches-section__trend {
                text-align: center;
                @include xs {
                    padding-left: 3px;
                    padding-right: 3px;
                }
                .percentage {
                    font-weight: bold;
                    line-height: 20px;
                }
                &.up {
                    background-color: rgba(color(primary, light), 0.5);
                    .percentage {
                        color: color(primary, base);
                    }
                }
                &.down {
                    background-color: rgba($sb-red, 0.5);
                    .percentage {
                        color: $sb-red-dark;
                    }
                }
                &.same {
                    background-color: rgba($sb-gray, 0.5);
                    .percentage {
                        color: $sb-gray-dark;
                    }
                }
            }
            &.pane-marches-section__arrow {
                padding: 0;
                position: relative;
                
                // @include xs {
                //     padding-right: 3px;
                //     text-align: right;
                // }
                i {
                    background-color: color(black);
                    border-radius: 25px;
                    color: white;
                    font-size: 17px;
                    height: 35px;
                    left: 3px;
                    line-height: 35px;
                    padding-left: 3px;
                    position: absolute;
                    top: 17px;
                    text-align: center;
                    transition: transform 0.3s ease;
                    width: 35px;
                    @include xs {
                        left: -6px;
                    }
                }
            }
            .units {
                font-size: 12px;
                line-height: 20px;
                color: $sb-gray-dark;;
            }
            .allow-inline {
                display: inline-block;
            }
        }
    }

}

.pane-marches-product {

    h1 {
        font-family: $font-family-title;
        font-weight: 600;
    }
    h2 {
        font-family: $font-family-title;
        font-weight: normal;
        font-size: 17px;
        margin-top: 40px;
    }

    .pane-marches-product__pill-trend {
        border-radius: 4px;
        color: white;
        display: inline-block;
        font-family: $font-family-title;
        font-weight: bold;
        font-size: 14px;
        line-height: 14px;
        padding: 3px 7px;
        vertical-align: middle;

        &::before {
            content: $icon-right-arrow;
            display: inline-block;
            font-family: $font-family-icons;
            font-size: 11px;
        }
        &.up {
            background-color: color(primary, light);
            &::before {
                transform: rotate(-45deg);
            }
        }
        &.down {
            background-color: $sb-red;
            &::before {
                transform: rotate(45deg);
            }
        }
        &.same {
            background-color: $sb-gray-dark;
        }
    }

    .pane-marches-product__current-price {
        margin-bottom: 40px;

        &-title {
            color: $sb-gray-dark;
            font-family: $font-family-title;
            font-weight: normal;
            font-size: 13px;
            text-transform: uppercase;
        }

        &-date {
            color: color(black);
            font-weight:600;
        }

        &-price {
            display: inline-block;
            vertical-align: middle;
            font-weight:600;
        }

        &-value {
            font-family: $font-family-title;
            font-weight: normal;
            font-size: 24px;
            margin-top: 5px;
        }

        &-unit {
            font-family: $font-family-title;
            font-weight: normal;
            font-size: 13px;
            line-height: 13px;
        }
    }

    .pane-marches-product-graph__controls {
        background-color: $sb-gray;
        border-radius: 4px;
        font-size: 13px;
        line-height: 13px;
        margin-bottom: 10px;
        padding: 4px 10px;
        text-transform: uppercase;

        ul {
            padding: 0;
            margin: 0; 
            text-align: right;
            li {
                background-color: $sb-gray-dark;
                border-radius: 4px;
                color: white;
                cursor: pointer;
                //display: inline-block;
                display: none;
                margin: 0 5px;
                padding: 5px;
                text-align: center;
                transition: all .3s ease;

                &:hover, &.active {
                    background-color: color(black);
                    color: white;
                    
                }
            }
        }
    }

    .pane-marches-product__graph-loading {
        height: 100px;
        background: no-repeat center center url('/sites/all/themes/enacarbon_sb/images/tail-spin.svg');

    }

    .pane-marches-product__prices {
        &-title-product {
            font-weight: bold;
        }
    }

    .pane-marches-product__table {
        margin: 0;
        width: 100%;
        thead {

            border-top: 1px solid color(black);
            border-bottom: 2px solid color(black);
            
            th {
                text-align: left;
                font-size: 12px;
                line-height: 12px;
                padding-top: 8px;
                padding-bottom: 8px;
                &.pane-marches-product__trendhead {
                    text-align: center;
                }
            }

        }
        tbody {
            tr {
                border-bottom: 1px solid $sb-gray-dark;
                td {
                    font-size: 13px;
                    padding-top: 5px;
                    padding-bottom: 5px;

                    &.pane-marches-product__trend {
                        text-align: center;
                        &.up {
                            background-color: rgba(color(primary, light), 0.5);
                            .percentage {
                                color: color(primary, base);
                            }
                        }
                        &.down {
                            background-color: rgba($sb-red, 0.5);
                            .percentage {
                                color: $sb-red-dark;
                            }
                        }
                        &.same {
                            background-color: rgba($sb-gray, 0.5);
                            .percentage {
                                color: $sb-gray-dark;
                            }
                        }
                    }
                }
            }
        }
        .allow-inline {
            display: inline-block;
            padding-right: 5px;
        }
    }

    .pane-marches-product__stats {
        
        .pane-marches-product__stats-wrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 0px;
            grid-row-gap: 0px;
        }
        &-item {
            border-bottom: 1px solid color(black);
            margin-right: 10px;
            padding: 20px 35px 20px 0;
            &:first-child, &:nth-child(2) {
                border-top: 1px solid color(black);
            }
        }
        &-item-name {
            font-family: $font-family-title;
            font-weight: 600;
            font-size: 13px;
            text-transform: uppercase;
        }

        &-item-period {
            font-family: $font-family-title;
            font-weight: normal;
            font-size: 13px;
            text-transform: uppercase;
            color: $sb-gray-dark;
        }

        &-item-price {
            font-family: $font-family-title;
            font-weight: 600;
            font-size: 24px;
        }

        &-item-unit {
            font-family: $font-family-title;
            font-weight: normal;
            font-size: 13px;
        }

    }

}

.pane-marches-product__pill-market {
    background-color: $sb-gray;
    color: color(black);
    border-radius: 4px;
    display: inline-block;
    font-family: $font-family-title;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    margin-left: 10px;
    padding: 5px 7px;
    text-transform: uppercase;
    vertical-align: middle;
}

@include scope('!critical') {
.r-search--widget {
    background: #F4F4F4;
    // border: 1px solid color(gray, lighter);
    border-radius: 5px;
    display: flex;
    font-size: font-size(h6);
    margin: space() space() 0;
    padding: space(.25);

    @include smmdlg {
        display: none;
    }

    &::before {
        color: black;
        content: $icon-search;
        font-family: main;
        font-size: font-size(h4);
        padding: 0 space(.75);
        vertical-align: middle;
    }

    .r-search--input {
        background: none;
        border: 0;
        box-shadow: none;
        color: color(gray, dark);
        font-size : 0.8rem;;
        height: auto;
        outline: none;
        padding: 0;
        width: 100%;

        &::placeholder {
            color: color(gray, dark);
            font-family: $font-family-base;
        }

        &:focus {
            + .r-search--submit {
                display: block;
            }
        }
    }

    .r-search--submit {
        background: color(primary);
        border: 0;
        border-radius: 20px;
        color: color(light);
        cursor: pointer;
        display: none;
        font-size: font-size(small);
        padding: 3px space(.75);

        &:hover {
            display: block;
        }

        &:focus {
            outline: none;
        }
    }
}
}

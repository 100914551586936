@include scope('!critical') {
.r-modal--old-browser {
    background: color(dark, .8);
    display: flex;

    i-modal {
        display: block;
        width: 80%;
        background: color(light);
        // height: 80%;
        margin: auto;
    }

    header {
        background: color(primary);
        color: color(light);
        padding: space(2);
    }

    .r-modal--title {
        font-size: font-size(h5);
        margin-bottom: space();
    }

    .r-modal--list {
        display: flex;
        justify-content: center;
        list-style: none;
        padding: space(2);
    }

    .r-modal--item {
        flex: 1;
        padding: 0 space();
        text-align: center;
    }

    .r-modal--label {
        font-size: font-size(h6);
    }
}

}// scope

@include scope('critical') {
r-menu {
    display: block;
}

r-submenu {
    display: none;
}

.r-menu--link {
    display: block;
    padding: space(.5) space();
    white-space: nowrap;
}

.r-icon--home {
    align-items: center;
    column-gap: space(.5);
    display: flex;

    &::before {
        display: inline-block;
        font-family: $font-family-icons;
    }

    @include scope('mag', 'sport') {
        &::before {
            display: none;
        }
    }
}
}// scope

@include scope('!critical') {
r-submenu {
    .-is-open + & {
        display: block;
    }
}

r-menu[data-menu="megamenu"] {
    .-has-submenu {
        background: color(light);
        position: relative;

        &::after {
            color: color(gray, dark);
            content: $icon-menu-right;
            font-family: main;
            font-size: font-size(h4);
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        &.-is-open {
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 2;
    
            &::after {
                display: none;
            }
    
            &::before {
                content: $icon-menu-left;
            }
        }
    }
}
}// scope

// -----------------------------------------------------------------------------
// Variables
// -----------------------------------------------------------------------------

// Media Queries
// -----------------------------------------------------------------------------
/// Breakpoints
$breakpoints:                               (
    min:                                    (
        min:                                320px,
        max:                                none
    ),
    xs:                                     (
        min:                                none,
        max:                                663px
    ),
    sm:                                     (
        min:                                664px,
        max:                                997px // 959
    ),
    md:                                     (
        min:                                998px, // 960
        max:                                1329px // 1279px
    ),
    lg:                                     (
        min:                                1330px, //1280px
        max:                                none
    ),
    max:                                    (
        min:                                none,
        max:                                1920px
    )
);


// Colors
// -----------------------------------------------------------------------------
$colors:                             (
    // Black
    black:                          #0E0D0E,
    dark:                           #0E0D0E,

    // Grayscale
    //
    gray:                            (
        lighter:                     #ffffff,
        light:                       #f3f3f3,
        base:                        #E6E6E6,
        dark:                        #9F9F9F,
        darker:                      #0E0D0E,
    ),

    // Branding
    //
    primary:                         (
        lighten:                     #F5FBEB,
        lighter:                     rgba(160,202,75,0.7),
        light:                       #A0CA4B,
        base:                        #6A8C1F,
        dark:                        #6A8C1F,
        darker:                      darken(#6A8C1F, 30%),
        ),
    secondary:                       (
        lighter:                     #f3f3f3,
        base:                        #0E0D0E
        ),
    
    mag:                              (
        lighter:                     #FAF2F6,
        light:                       #D999BB,
        base:                        #A00054,
        darker:                      darken(#A00054, 30%),
        ),
    immo:                            (
        light:                       #fff49e,
        base:                        #FFE500,
        dark:                        #8B392E,
    ),
    emploi:                          (
        light:                       #CBEBE9,
        base:                        #209E97,
        dark:                        #0B514D,
    ),
    meteo:                           (
        light:                       #CCEEFF,
        base:                        #008FD5,
        dark:                        #063851,
    ),
    
    //

    // Extra theme colours
    lightyellow:                     #FFFABA,
    lightgreen:                      #DFEFBD,
    darkblue:                        #156875, // not used
    red:                             #FC8029, 

    // Carbon colours
    premium:                         #fec92e,
    shopping:                        #DFDFDF, // not used
    sport:                           #233652, // not used
    rss:                             #EE802F, // not used
    perspective:                     #B97960, // not used
    pub:                             #f3f3f3, // ok
    pubnative:                       #f3f3f3, // ok
    video:                           black,
    surf-connecte:                   #a0ca4b,

    // UI Colors
    //
    success:                         #09b081,
    warning:                         #d04343,
    info:                            #005dc4,

    // Social Colors
    //
    facebook:                       #1778f2,
    twitter:                        #000000,
    bluesky:                        #1083FE,
    linkedin:                       #0d66c2,
    whatsapp:                       #25D366,
);

// Font
// Font, line-height, and color for body text, headings, and more.
// -----------------------------------------------------------------------------

// Base font family
$font-family-base:                   'Open Sans', arial, sans-serif;
$font-family-alt:                    'Open Sans', arial, sans-serif; 
// Title font family
$font-family-title:                  'Work Sans', arial, sans-serif;
$font-family-icons:                  main;

// Font size
$font-size:                          (
    section:                         3rem,
    h1:                              2.3rem,
    h2:                              2rem,
    h3:                              1.7rem,
    h4:                              1.5rem,
    h5:                              1.3rem,
    h6:                              1.05rem,
    aside:                           .9rem,
    base:                            1rem,
    small:                           .75rem,
    extrasmall:                      .6rem,
    article:                         1.3rem,
    article-xs:                      1.2rem,
    alt:                             1rem,
    nav:                             1rem,
    button:                          .9rem,
    icon:                            .7rem
);

// Line height
$line-height:                        (
    base:                            1.4,
    h1:                              1.2,
    h2:                              1.35,
    h3:                              1.35,
    h4:                              1.35,
    h5:                              1.35,
    h6:                              1.35,
    alt:                             1.35,
    nav:                             28px,
    small:                           1.3,
);

// Font weight
$font-weight:                       (
    base:                            400,
    h1:                              700,
    h2:                              300,
    h3:                              300,
    h4:                              300,
    h5:                              300,
    h6:                              300,
    alt:                             300,
    nav:                             300,
    small:                           300,
);

// z-index
$z-index:                                   (
    base:                                   1,
    low:                                    50,
    medium:                                 100,
    high:                                   150,
);

// Grids
// -----------------------------------------------------------------------------
$html-max-width: 1920px;
$content-max-width: 1328px;
$arche-width: 996px;

$header-desktop-height: 160px;
$header-desktop-top-height: 110px;
$header-desktop-bottom-height: 50px;

$header-height: 110px;
$header-top-height: 60px;
$header-bottom-height: 50px;
$header-surf-height: 49px;

// Links
// Style anchor elements.
// -----------------------------------------------------------------------------
/// Color link
// $link-color:                                color(primary) !default;
/// Link decoration
// $link-decoration:                           none !default;
/// Color link hover
$link-hover-color:                          color(primary);

/// Link decoration hover
// $link-hover-decoration:                     underline !default;


// Modal
$modal-z-index: 150;
$modal-backdrop-bg-color: color(primary, 0.5);

// $domain: '';

// Core module
// -----------------------------------------------------------------------------
$sticky-panel-bottom: 54px;
$sticky-panel-bottom-desktop: 39px;
$sticky-panel-width: 80%;
$sticky-panel-width-desktop: 400px;

// Logo
$logo : "/sites/all/themes/enacarbon_sb/images/logo-carre-plein.svg"; // TODO

$theme: 'enacarbon_sb';

// Pub
$bg-pub-color: color(pub);
//$bg-pub-img: '/sites/all/themes/sudpresse/enacarbon_sudinfo/images/pub/pub_sudinfo.svg';
//$height-bg-pub: 26px;
// $inread-txt: '';
// $inread-txt-color: #a5a5a5;

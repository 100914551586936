@include scope('!critical') {
r-selligent {
    display: block;

    @include xs {
        margin-bottom: space();
    }

    h3 {
        font-family: $font-family-base;
    }
}

.r-selligent--body {
    margin: space();
    padding: space(1.5);
}

.r-selligent--article-mobile,
.r-selligent--article-desktop,
.r-selligent--imu-bottom,
.r-selligent--imu, 
.r-selligent--agenda {
    .r-selligent--body {
        color: color(light);
        height: calc(100% - #{space(2)});

        @include scope('!special') {
            background-color: color(primary);
        }

        @include scope('special') {
            background-color: color(primary);
        }

        @include scope('sb') {
            background-color: color(primary, light);
            border-radius: 10px;
            color: black;

            .r-selligent--title {
                display: grid;
                grid-template-columns: 3fr 2fr;
            }

            h6 {
                margin: 0;
            }

            p {
                margin-top: space(1.5);
            }

            r-icon {
                color: color(light);
                font-size: 4rem;
                justify-self: flex-end; 
            }
        }

        @include scope('vdn') {
            background-color: color(secondary, lighter);

            h2 {
                color: color(primary);
                font-family: $font-family-title;
                font-size: 1.8rem;
                line-height: 1.2;
            }

            p {
                color: color(dark);
                font-family: $font-family-title;
                font-size: 1.52rem;
                line-height: 1.2;
            }
        }
    }

    svg {
        margin-bottom: space();
        width: 90px;
    }

    h3 {
        font-size: font-size(h5);
    }
}

.r-selligent--imu {
    grid-area: selligent;
}

.r-selligent--agenda {
    
    @include scope('sb') {
        grid-area: agenda;

        .r-selligent--body {
            r-icon {
                font-size: 5rem;
            }

            h4 {
                font-weight: font-weight(base);
                margin-right: 30%;
            }
        }
    }
}

.r-selligent--bandeau {
    grid-column-start: 1;
    grid-column-end: -1;
    margin-top: -1px;

    .r-selligent--body {
        align-items: center;
        background-color: color(gray, light);
        border-radius: 15px;
        color: color(primary);

        @include smmdlg {
            display: flex;
            justify-content: flex-start;
        }
    }

    .r-selligent--intro {
        margin-right: space(2);
    }

    h3 {
        font-size: font-size(h5);
    }
}

.r-selligent--menu {
    display: none;
    margin: space(.5);

    @include smmdlg {
        display: block;
        float: right;
        width: 350px;
    }
}

.r-selligent--newsletter {
    margin: space(.5) auto space(.5);
    max-width: 662px;

    h3 {
        align-items: center;
        display: flex;
        font-family: $font-family-title;
        font-size: font-size(h5);

        @include scope('!special') {
            color: color(primary);
        }

        @include scope('special') {
            color: color(primary);
        }

        &::after {
            content: '';
            display: block;
            height: 1px;
            margin-left: space();
            width: 100%;

            @include scope('!special') {
                background: color(primary);
            }

            @include scope('special') {
                background: color(primary);
            }
        }
    }

    .r-btn {
        white-space: nowrap;
    }

    p {
        margin: 0 space() 0 0;
    }

    .r-selligent--body {
        align-items: center;
        display: flex;
        margin: 0;
        padding: 0 0 space();

        @include scope('!special') {
            border-bottom: 1px solid color(primary);
        }

        @include scope('special') {
            border-bottom: 1px solid color(primary);
        }
    }
}

.reabon {
    .-has-header-fixed & {
        position: fixed;
        left: 0;
        right: 0;
        top: 35px;
        z-index: 90;

        [data-scrolldir='up'] & {
            top: 115px;

            @include xs {
                top: 85px;
            }
        }
    }

    .page-node:not( .-is-grand-format ).-has-header-fixed & {
        [data-scrolldir='up'] & {
            top: 115px;

            @include xs {
                top: 85px;
            }
        }

        [data-scrolldir='down'] & {
            top: 0;
        }
    }
}

// Overlay
.r-selligent--overlay {
    font-family: $font-family-base;
    .r-selligent--overlay-container {
        display: none;
        font-family: $font-family-base;
        position: relative;
        z-index: z-index(high);
        &.show {
            display: block;
        }
    }
    .r-selligent--overlay-background {
        background-color: rgba(0, 0, 0, .3);
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
    .r-selligent--overlay-content {
        background-color: color(light);
        box-shadow: 0px 0px 8px rgba(0, 0, 0, .2);
        max-width: 500px;
        padding: space(2);
        position: fixed;
        text-align: center;
        width: 100%;
        @include smmdlg {
            width: auto;
        }
    }
    .r-selligent--overlay-content-center {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .r-selligent--overlay-content-bottom {
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
    }
    .r-selligent--overlay-content-bottom-right {
        bottom: 0;
        right: 0;
    }
    .r-icon--cancel {
        cursor: pointer;
        font-size: .7rem;
        padding: space();
        position: absolute;
        right: 0;
        top: 0;
    }
}

.r-selligent--overlay-btn-active {
    background-color: color(secondary);
    bottom: 10%;
    color: color(dark);
    cursor: pointer;
    font-weight: bold;
    position: fixed;
    right: 0;
    writing-mode: vertical-rl;
    z-index: z-index(high);

    a {
        color: color(dark);
        padding: space(.5);
    }

    .r-icon--cancel {
        background: color(secondary);
        border: 2px solid color(light);
        border-radius: 50%;
        font-size: 0.5rem;
        left: 0;
        padding: space(.5);
        right: auto;
        transform: translate(-50%, -50%);
    }
}


// First question Satisfactory
.first-question-satisfactory {
    border: none;
    margin: 0;
    padding: 0;
    .title_first_question {
        font-size: 1.2rem;
        font-weight: bold;
        margin-bottom: space(.5);
        margin-top: 0;
    }
    .subtitle_first_question {
        font-size: 1rem;
        font-weight: normal;
        margin-bottom: space();
        margin-top: 0;
    }
}

// Global rating
.rating {
    .r-btn {
        margin-top: space(.5);
        opacity: 0;
        pointer-events: none;
        &.show {
            opacity: 1;
            pointer-events: all;
        }
    }
}
// Stars rating
.rating_stars {
    form {
        direction: rtl;
        text-align: center;
    }
    input.star {
        display: none;
    }
    label.star {
        cursor: pointer;
        display: inline-block;
        font-size: 30px;
        line-height: 1;
        padding: space(.5);
        position: relative;
        transition: all .3s;
    }
    .link_satisfactory {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
    input.star:checked ~ label.star:before,
    input.star:hover ~ label.star:before {
        content:$icon-star;
        transition: all .25s;
    }
    label.star:before {
    color: color(primary);
    content:$icon-star-empty;
    font-family: 'main';
    }
}

// NPS rating
.rating_nps {
    form {
        display: flex;
        justify-content: space-between;
        margin-bottom: space(.5);
        text-align: center;
    }
    input.nps {
        display: none;
    }
    label.nps {
        background: color(gray, lighter);
        border: 1px solid color(light);
        cursor: pointer;
        display: inline-block;
        font-size: 16px;
        height: 32px;
        line-height: 1;
        padding: space(.5);
        position: relative;
        transition: all .3s;
        width: 32px;
        &:hover,
        &:focus {
            .link-nps {
                color: color(light);
            }

        }
    }
    input.nps-1:checked + label.nps-1,
    input.nps-2:checked + label.nps-2,
    input.nps-3:checked + label.nps-3,
    input.nps-4:checked + label.nps-4,
    input.nps-5:checked + label.nps-5,
    input.nps-6:checked + label.nps-6,
    label.nps-1:hover, label.nps-1:focus,
    label.nps-2:hover, label.nps-2:focus,
    label.nps-3:hover, label.nps-3:focus,
    label.nps-4:hover, label.nps-4:focus,
    label.nps-5:hover, label.nps-5:focus,
    label.nps-6:hover, label.nps-6:focus {
        background: #fa5454;
        color: color(light);
    }
    input.nps-7:checked + label.nps-7,
    input.nps-8:checked + label.nps-8,
    label.nps-7:hover, label.nps-7:focus,
    label.nps-8:hover, label.nps-8:focus {
        background: #FAA154;
        color: color(light);
    }
    input.nps-9:checked + label.nps-9,
    input.nps-10:checked + label.nps-10,
    label.nps-9:hover, label.nps-9:focus,
    label.nps-10:hover, label.nps-10:focus {
        background: #57cb4a;
        color: color(light);
    }
    .link_nps {
        align-items: center;
        color: inherit;
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        &:hover {
            text-decoration: none;
        }
    }
}

}// scope

@include scope(('article', '!critical')) {
.r-selligent--article-desktop {
    background: none;
    padding: 0;

    @include lg {
        align-self: flex-start;
        grid-column: 4;
        grid-row: span 50;
        position: sticky;
        top: 1px;
        margin-bottom: 30rem;

        [data-scrolldir='up'] & {
            top: 115px;
        }
    }

    @include wallpaper {
        grid-column: span 4;
        position: relative;
        margin-bottom: 0;
    }
}

#r-selligent--newsletter {
    padding: 0;
}
}// scope

@include scope(('critical', '!special', '!sport', '!ne')) {
// Surf connecté
.r-selligent--surf-connecte {
    display: contents;
    grid-area: surf;
    margin-bottom: 0;

    &:has(.r-selligent--surf-connecte-content) {
        display: block;
    }

    // Comportement Header
    
    // [data-scrolldir='down'] .-has-header-fixed:not( .-is-toggle-r-mini-panel--menu ) & {
    //     @include scope('vdn') {
    //         display: none;
    //     }
    // }

    .page-node-paywall & {
        display: none;
    }

    .r-selligent--surf-connecte-content {
        align-items: center;
        background: color(surf-connecte);
        column-gap: space();
        display: flex;
        justify-content: space-between;
        padding: space(.5);
        z-index: 0;

        &::before {
            background-color: color(surf-connecte);
            content: "";
            height: $header-surf-height;
            left: 0;
            position: absolute;
            right: 0;
            z-index: -1;
        }
    }

    p {
        color: color(dark);
        margin: 0;
        overflow: auto;
        white-space: nowrap;

        span {
            font-weight: bold;
        }

        &::-webkit-scrollbar {
            display: none; // Safari and Chrome
        }

        @include smmdlg {
            scrollbar-width: none;
        }
    }
    .r-btn {
        white-space: nowrap;
    }
}
} //scope

@include scope(('critical', 'special', 'sport')) {
    .r-selligent--surf-connecte {
        display: none;
    }
}

@include scope('critical') {
.r-selligent--pave-offre-container {

    .r-selligent--pave-surf-connecte-t1 {
        background-color: lighten(color(primary, light), 40%);
        border-radius: space(.3);
        color: color(gray, darker);
        margin: 0;

        h3 {
            @include mdlg {
            text-align: center;
            }            
        }

        p {
            color: color(gray, darker);
            font-size: 16px;
        }

        hr {
            background-color: color(gray,darker);
            color: color(gray,darker);
            display: block;
            height: 3px;
            margin-bottom:space();
            width: 50%; 

            @include xssm {
                margin-left:0;
            }    
        }

        r-icon {
            border: 1px solid color(primary);
            border-radius: space(3);
            color: color(primary);            
            margin-right: space(.5);
        }

        .r-selligent--btn {            
            text-align: left;
            width: 100%;
            
            @include xssm {
                text-align: center;
            }  
        }

        .r-btn {
            background-color: color(primary);
            border-radius: space(1.5);
            color: white;
            font-size: space();
            margin: auto;            
            text-align: center;
            width: auto;
          }
    }
}
}

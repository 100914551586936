@include scope('!critical') {
//banniere
.r-selligent--banniere-container {     
    background-color: color(primary);
}
.r-selligent--banniere-surf {  

    &.r-selligent--body {   
    border-color:color(primary);
    }

    h3 {
        color:color(primary);
    }
}

// Méga-menu
.r-selligent--megamenu-container {
    display: contents;
    .r-selligent--megamenu-content {
        background-color: lighten(color(primary), 50%);
        margin-top: space(2);
        padding: space(2);

        @include scope('mes') {
            background-color: color(gray, light);
        }
    }
    .r-selligent--title {
        align-items: center;
        display: block;
        //flex-direction: column;
        margin-top: - space(3);
        @include smmdlg {
            flex-direction: row;
            justify-content: flex-start;
            margin-top: 0;
        }

        @include scope('vdn') {
            display: flex;
            flex-direction: column;
        }


    }
    .r-selligent--logo-carre {
        &::before {
            content: url($logo);
            display: block;
            margin: auto;
            width: 80px;
        }
        @include smmdlg {
            padding-right: space(1);
        }
    }
    h2 {
        color: color(primary);
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        @include smmdlg {
            text-align: left;
        }
    }
    ul {
        padding-left: 26px;
    }
    li {
        color: color(gray, base);
        font-size: 16px;
        list-style: square;
        &::marker {
            color: color(primary);
            font-size: 2rem;
        }
    }
    .r-selligent--megamenu-btn {
        align-items: center;
        background-color: lighten(color(primary), 40%);
        display: flex;
        justify-content: center;
        padding: space(1);

        @include scope('mes') {
            background-color: color(gray, light);
        }
    }
    .r-selligent--megamenu-sticky {
        bottom: 0;
        position: sticky;
    }
    .r-selligent--baseline {
        color: color(primary);
        font-size: 12px;
        padding-right: space(.5);
        @include smmdlg {
            font-size: 14px;
        }
    }
    .r-btn {
        font-size: 11px;
        @include smmdlg {
            font-size: 14px;
        }
    }
}

// Pavé newsletter
.r-selligent--pave-newsletter-container {
    .r-selligent--pave-content {
        align-items: flex-start;
        border-radius: 10px;
        color: color(light);
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: space(1);
        @include xs {
            height: auto;
        }
    }
    .r-selligent--title {
        align-items: flex-start;
        display: flex;
        font-weight: bold;
        justify-content: space-between;
        margin-bottom: space(2);
    }
    h2 {
        font-size: 1rem;
        margin-top: 0;
        padding-right: space(1);
    }
    r-icon {
        font-size : 3rem;
        margin-bottom: space(1);
    }
    .logo_horizontal {
        height: auto;
        margin-bottom: space(1);
        width: 120px;
    }
    .logo_carre {
        height: auto;
        margin-bottom: space(1);
        width: 50px;
    }
    .r-selligent--baseline {
        font-size: 1.2rem;
        display: block;
        margin-bottom: space(1);
    }
    .r-btn {
        border-radius: 5px;
        &.r-btn--black {
            background-color: color(dark);
            color: color(light);
        }
        &.r-btn--white {
            background-color: color(light);
            color: color(dark);
        }
    } 
}

//underRS
.r-selligent--under-rs-newsletter-container {
    .r-selligent--under-rs-content {
        align-items: flex-start;
        border-radius: 10px;
        color: color(light);
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: space(1);
        @include xs {
            height: auto;
        }
    }
    .r-selligent--title {
        align-items: flex-start;
        display: flex;
        font-weight: bold;
        justify-content: space-between;
        margin-bottom: space(2);
    }
    h2 {
        font-size: 1rem;
        margin-top: 0;
        padding-right: space(1);
    }
    r-icon {
        font-size : 3rem;
        margin-bottom: space(1);
    }
    .logo_horizontal {
        height: auto;
        margin-bottom: space(1);
        width: 120px;
    }
    .logo_carre {
        height: auto;
        margin-bottom: space(1);
        width: 50px;
    }
    .r-selligent--baseline {
        font-size: 1.2rem;
        display: block;
        margin-bottom: space(1);
    }
    .r-btn {
        border-radius: 5px;
        &.r-btn--black {
            background-color: color(dark);
            color: color(light);
        }
        &.r-btn--white {
            background-color: color(light);
            color: color(dark);
        }
    } 
}

// Pavé offre
.r-selligent--pave-offre-container {
    .r-selligent--pave-content {
        align-items: flex-start;
        background-color: color(primary);
        color: color(light);
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: space(1);
        @include xs {
            height: auto;
        }

        @include scope('vdn') {
            background-color: lighten(color(primary), 50%) !important;
            color: color(primary);
        }
    }
    h2 {
        font-size: 1.8rem;
        font-weight: bold;
        line-height: 1;
        margin: 12px 0;
    }
    .r-selligent--logo {
        &::before {
            content: url($logo);
            display: block;
        }

        @include smmdlg {
            padding-right: space(1);
        }

        @include scope('cp', 'ard', 'ee', 'lc', 'uni', 'nl') {
            content: url($logo-white);
            width: 150px;
        }
    }
    .r-selligent--logo-carre {
        &::before {
            width: 50px;
        }
    }
    .r-selligent--logo-horizontal {
        &::before {
            width: 120px;
        }
    }
    .logo_horizontal {
        height: auto;
        margin-bottom: space(1);
        width: 120px;
    }
    .logo_carre {
        height: auto;
        margin-bottom: space(1);
        width: 50px;
    }
    .r-selligent--btn {
        align-items: center;
        display: flex;
        justify-content: space-between;
        @include smmdlg {
            display: block;
        }
    }
    .r-selligent--baseline {
        display: block;
        font-size: 1rem;
        margin-right: space(1);
        width: 50%;
        @include smmdlg {
            margin-bottom: space(1);
            width: 100%;
        }
    }
    .r-btn {
        background-color: color(light);
        border-radius: 20px;
        color: color(primary);
        text-align: center;
        width: auto;

        @include scope('vdn') {
            background-color: color(primary);
            border-radius: 20px;
            color: color(light);
        }
    }
}

.r-selligent--pave-surf {  
    
    &.r-selligent--body {   
    border-color:color(primary);
    }

    h3 {
        color:color(primary);
    }
}

// Boutons anonyme/connecte
.r-anonyme {
    .btn_ano {
        display: inline-block;
    }
    .btn_co {
        display: none;
    }
}
.r-connecte {
    .btn_ano {
        display: none;
    }
    .btn_co {
        display: inline-block;
    }
}

// Pavé App
.r-selligent--pave-app {
    .r-selligent--pave-content {
        align-items: flex-start;
        background-color: color(primary) !important;
        color: color(light) !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include xs {
            height: auto;
        }
        
        h2 {
            color: color(light);
        }
    }
    .r-selligent--btn-app {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
    }
    .r-selligent--baseline {
        margin: 0;
        width: 100%;
    }
    .r-selligent--btn-app-apple,
    .r-selligent--btn-app-google {
        text-align: center;
        width: 50%;
    }
}
}

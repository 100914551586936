@include scope('!critical') {

    .-is-kiosque {
        @include scope('sb') {
            @include xs {
                padding: 0;
                .r-carousel--kiosque {
                    padding: 0 space(2) space();
                }
    
                r-panel--footer {
                    margin-bottom: space(2);
    
                    .r-btn {
                        justify-content: center;
                        width:180px;
                    }
                }
            }

            i-mini-panel {
                @include xssm {
                    display: flex;
                    flex-direction: column-reverse;
                }
            }
        }
    }

    .r-panel--carousel {
        background: color(pub);
        margin-bottom: space();
        padding: space();
        position: relative;

        @include smmdlg {
            grid-column-start: 1;
            grid-column-end: -1;
        }

        @include wallpaper {
            margin: 0 space();
        }

        r-panel--header {
            margin: 0;
            padding: space() 0 space(.75);

            @include smmdlg {
                padding: space(.5) 0 space(1.5);
            }

            &::before {
                @include xs {
                    margin: space() space() space() 0;
                }
            }

            &::after {
                @include xs {
                    margin: space() 0 space() space();
                }
            }
        }

        r-panel--footer {
            padding: space(.5) 0 0;

            @include smmdlg {
                padding: 0;
            }

            @include scope('sb') {
                text-align: center;
            }
        }

        .r-btn {

            @include scope('!sb') {
                    text-decoration: underline;

                @include smmdlg {
                    background: none;
                    border: 0;
                    padding: 0;
                }

                &:hover {
                    text-decoration: none;

                    @include smmdlg {
                        color: color(primary, darker);
                    }
                }
            }

            @include scope('sb') {
                background-color: color(primary);
                color: white;
                margin: auto;
                &:hover {
                    color: #fff;
                    opacity: .8;
                }
            }
        }

        .owl-stage {
            @include smmdlg {
                margin-left: -50px;
            }
        }

        .owl-item {
            @include smmdlg {
                padding: space();
            }
        }

        .owl-nav {
            display: flex;
            position: absolute;
            right: 0;
            top: -40px;
        }

        i-mini-panel & {
            @include mdlg {
                width: calc(100% - #{space()});
            }

            @include sm {
                grid-row: span 2;
            }

            @include lg {
                grid-column: span 3;
                //grid-row: span 2;
            }

            @include wallpaper(md) {
                grid-column: span 2;
                grid-row: span 2;
                width: calc(100% - #{space(2)});
            }
        }
    }

    .r-panel--liseuse {
        r-panel--footer {
            text-align: center;
        }
    }
}// scope

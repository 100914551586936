@include scope(('une', '!critical')) {
.r-digiteka {
    padding: space() space() space(2);
    z-index: z-index(medium);

    // @include smmdlg {
    //     margin-left: calc(-50vw + 50%);
    //     width: 100vw;
    // }
}

.r-digiteka--title {
    border-bottom: 1px solid color(primary);
    //color: color(light);
    font-size: font-size(h2);
    line-height: line-height(h1);
    margin: 0 0 space();
    padding: space() 0;
    text-align: center;

    @include smmdlg {
        font-size: font-size(h1);
        padding: space(1.5) 0 space();
    }

    @include lg {
        margin: auto maxi(7.8vw, calc(50vw - #{$content-max-width / 2})) space();
    }

    @include md {
        max-width: 900px + space(6);
        margin: auto maxi(space(4), calc(50vw - #{450px + space(3)})) space();
    }

    @include sm {
        max-width: 600px + space(4);
        margin: auto auto space();
    }

    @include wallpaper {
        margin: auto auto space();
    }
}

#ultimedia_wrapper,
#ultimedia_live {
    margin: auto;
}

@include scope('cp') {
    #ultimedia_livecp {
        margin: auto;
    }
}
}

@include scope(('article', '!critical')) {
    @include scope('!sb') {
        .r-image--duo {
            @include smmdlg {
                align-items: flex-start;
                flex-direction: row;
                max-width: 4 * 300px + space(2);
            }

            @include md {
                margin: space() -30%;
            }

           /* @include lg {
                margin: space() -312px;
                max-width: 1232px;
                width: 100vw;
            } */

            figure {
                margin: space(.25);
            }
        }

        .tiktok-embed {
            border: none;

            &::before {
                display: none;
            }
        }
    }

    .tiktok-embed,
    .instagram-media {
        border: none;

        &::before {
            display: none;
        }
    }
}

@include scope('!critical') {
    .gr-media--digiteka {
        .gr-date {
            display: none;
        }
    }
}

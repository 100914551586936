@include scope('critical') {
.r-sso--header {
    align-items: center;
    display: flex;
    grid-area: login;
    justify-content: flex-end;

    [data-scrolldir='down'] .-has-header-fixed:not( .-is-toggle-r-mini-panel--menu ) & {
        display: none;
    }
}

.r-sso--login {
    color: color(dark);
    margin-right: space(1.25);

    @include xssm {
        margin-right: space(.75);
    }

    @include scope(('!mag', '!sport')) {
        color: color(dark);
        margin-right: space(1.25);
    }

    @include smmdlg {
        display: flex;
        padding: 0;
    }

    @include xs {
        padding: 0; 
    }

    &:hover {
        color: color(dark);

        @include smmdlg {
            text-decoration: underline;
        }
    }

    &:focus {
        outline: none;
        text-decoration: none;
    }

    .js-sso--user,
    span {
        border-radius: 5px;
        font-weight: 700;
        padding: 7px space(.75);
        border: 1px solid color(dark);

        @include xs {
            //display: none;
            border-radius: 5px;
            font-size: .8rem;
            margin-right: space(.5);
            padding: 6px space(.75) 4px;
        }
    }

    r-icon {
        font-size: 20px;
        font-weight: 700;
        margin: 0;

        @include smmdlg {
            display: none;
        }
        @include xs {
            .not-logged-in & {
                display: none;
            }
        }

    }
}

.r-sso--toggle-menu {
    color: color(dark);

    &:hover {
        color: color(dark);
        r-icon {
            text-decoration: none;
        }
        .js-sso--user {
            text-decoration: underline;
        }
    }

    @include xs {
        font-size: 24px;
        margin-top: -1px;
        padding: 0;
    }

    @include smmdlg {
        font-size: font-size(base);
        padding-right: 0;
    }

    .r-abonne & {
        @include smmdlg {
            margin-right: 0;
        }
    }

    span {
        @include xs {
            display: none;
        }
    }

    .r-icon--user {
        color: color(primary, base);
        font-size: 19px;

        @include xs {
            font-size: 23px;
        }
    }

    .js-sso--user {
        font-weight: 400;
        font-size: 13px;
        max-width: 80px;
        max-height: 35px;
        overflow: hidden; 
        margin: 0 5px 0 0;
        position: relative;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-word;
    }
}

.r-sso--abo {

    @include xs {
        border-radius: 5px;
        font-size: .8rem;
        margin-right: space(.5);
        padding: 6px space(.75) 4px;
        .r-anonyme & {
            display: none; 
        }
    }

    .r-abonne & {
        display: none;
    }

    span {
        margin-left: 4px;

        @include xs {
            display: none;
        }
    }
}
}

// scope

@include scope('!critical') {
.r-sso--menu {
    background: color(primary, lighter);
    display: flex;
    margin: 0;
    padding: 0;

    .r-sso--item {
        align-items: center;
        display: flex;
        flex-direction: column;
        font-size: font-size(base);
        list-style: none;
        padding: space();

        &:hover {
            text-decoration: none;
        }

        span {
            color: color(primary);
        }

        r-icon {
            background: color(light);
            border: 1px solid color(primary);
            border-radius: 50%;
            box-shadow: 0 0 0 5px inset color(primary, lighter);
            display: block;
            font-size: 25px;
            height: 60px;
            line-height: 56px;
            margin-bottom: space(.5);
            text-align: center;
            width: 60px;
        }

        .r-icon--user {
            .r-connecte & {
                background: color(primary);
                color: color(light);
            }

            .r-abonne & {
                background: color(secondary);
                border-color: color(secondary);
            }
        }

        .r-icon--newsletter {
            font-size: 50px;
        }
    }

    .r-sso--user {
        r-icon {
            background: color(primary);
            color: color(light);

            .r-connecte & {
                background: color(secondary);
                border-color: color(secondary);
            }
        }
    }
}
}  // scope

@include scope('!critical') {
.r-viewmode--o-panel,
.r-viewmode--panel,
.r-viewmode--o-panel-left,
.r-viewmode--panel-left,
.r-viewmode--o-panel-3row,
.r-viewmode--panel-3row,
.r-viewmode--panel-center {
    .r-article--title {
        @include xs {
            font-size: font-size(h4);
            font-weight: bold;

            @include scope(('!sb', '!mag')) {
                margin-left: space(-1);
                margin-right: space(-1);
            }
        }

        @include smmdlg {
            @include scope(('!sport','!mag')) {
                font-size: font-size(h1);
            }

            @include scope('sport') {
                font-size: font-size(section);
            }

            @include scope('mag') {
                font-size: font-size(h3);
            }
        }
    }

    .r-meta-info {
        @include xs {
            @include scope(('!sb','!mag')) {
                margin-left: space(-1);
                margin-right: space(-1);
            }
        }
    }

    .r-article--img,
    .r-article--meta {
        @include xs {
            @include scope('!mag') {
                margin-left: space(-1);
                margin-right: space(-1);
            }
        }
    }

    .r-article--section {
        margin-top: space(.5);

        @include scope('!mag') {
            font-weight: 700;
        }
    }

    i-article {
        display: block;
        position: relative;
        z-index: 1;

        @include scope(('!special', '!sport', '!mag')) {
            background: color(light);
            margin-top: -80px;
        }

        @include scope(('!sport', '!mag')) {
            padding: 16px;
        }

        @include smmdlg {
            @include scope(('!sport','!mag')) {
                padding: 24px;
                width: 75%;
            }

            @include scope(('!special', '!sport','!mag')) {
                border-left: 2px solid color(primary);
            }

            @include scope('sport') {
                width: 85%;
            }
        }

        @include xs {
            @include scope('!mag') {
                text-align: center;
            }
        }
    }

    figure {
        @include xs {
            margin: 0;
        }
    }
}

.r-viewmode--o-panel-left,
.r-viewmode--panel-left,
.r-viewmode--o-panel-3row,
.r-viewmode--panel-3row {
    i-article {
        display: block;
        position: relative;
        z-index: 1;

        @include scope(('!special', '!sport', '!mag')) {
            background: color(light);
            color: color(dark);
            margin-top: -80px;
        }

        @include scope(('!sport','!mag')) {
            padding: 16px;
        }

        @include smmdlg {
            @include scope(('!sport','!mag')) {
                padding: 24px;
                width: 75%;
            }

            @include scope(('!special', '!sport', '!mag')) {
                border-left: 2px solid color(primary);
            }

            @include scope ('sport') {
                width: 85%;
            }
        }

        .-has-bg & {
            .r-article--link {
                color: color(dark);
            }
            .r-author--link {
                color: color(primary);
            }
        }

        @include xs {
            @include scope('!mag') {
                text-align: center;
            }
        }

        @include scope('special', 'sport') {
            .page-sports &,
            .page-maga & {
                margin-top: -80px;
                padding: 16px;
            }
        }
    }

    .r-article--meta {
        @include scope('mag') {
            align-items: center;
            display: flex;
        }
    }

    .r-article--list {
        margin-top: space(1.5);
    }

    @include scope('!special') {
        r-article--section {
            color: color(primary);

            @include xs {
                display: none;
            }
        }
    }
}

.r-viewmode--o-panel-left,
.r-viewmode--panel-left {
    .r-article--title {
        @include xs {
            margin-left: 0;
            margin-right: 0;
        }
    }
}

.r-viewmode--o-panel,
.r-viewmode--panel,
.r-viewmode--o-panel-left,
.r-viewmode--panel-left {
    @include smmdlg {

            grid-column: auto / span 2;
            grid-row: auto / span 2;

        @include scope('mag') {
            .-is-mini-panel--3c &,
            .-is-mini-panel--3r & {
                grid-column: 1;
                grid-row: auto / span 3;
            }
        }
    }

    @include scope('!mag') {
        .front-maga.arche & {
            grid-column: auto / span 1;
            grid-row: auto / span 1;
        }
    }
    @include scope('sb') {
        @include xs {
            margin: 0;
        }

        .r-article--title {
            font-weight: font-weight(h1);
            @include xs {
                font-size : font-size(h5);
                margin-top : space(1.25);
            }
        }
        r-article--chapo {
            display: block;
            margin-top: space(.5);
            @include xs {
                display: none;
            }
        }
    }
}

@include scope('mag') {
    .r-panel--img {
        display: block;
    }

    .r-viewmode--o-list-mag {
        .-is-mini-panel--2r & {
            .r-article--img,
            .r-panel--img {
                width: 100%;
            }
        }
    }

    .r-viewmode--niveau-2-mag {
        .r-viewmode--article {
            column-gap: space();
            display: grid;
            grid-template-areas: "img ."
                            "img .";
            grid-template-columns: 100px auto;

            @include mdlg {
                grid-template-columns: minmax(auto, 18%) auto;
            }
        }

        .r-article--meta {
            align-self: start;
            font-size: 0;

            @include xs {
                align-items: center;
                display: flex;
                flex-wrap: wrap;
                margin-top: space(.5);
            }
        }
    }

    .r-viewmode--niveau-2-mag,
    .r-viewmode--o-list-mag {
        .r-article--title,
        .r-panel--title {
            font-weight: normal;
            margin: 0;
        }

        .r-article--img,
        .r-panel--img {
            grid-area: img;
            aspect-ratio: 1/1;
        }

        img {
            aspect-ratio: 1/1;
        }

        r-article--meta {
            align-items: center;
            align-self: end;
            padding: 0;
        }

        .presente {
            color: color(gray);
            font-family: $font-family-title;
        }
    }

    .r-viewmode--horizontal-top-mag {
        .-is-mini-panel--2r & {
            grid-column: span 2;

            @include xs {
                margin: 0 space(-0.5);
                padding: 0;
            }

            @include smmdlg {
                grid-column: span 3;
            }
        }

        i-article {
            left: 0;
            margin: auto;
            padding: space() space() space(3);
            right: 0;

            @include smmdlg {
                width: 60%;
            }
        }

        .r-article--title {
            font-size: font-size(h3);

            @include smmdlg {
                font-size: 2.5rem;
            }
        }
    }

    .r-viewmode--horizontal-mag {
        .-is-mini-panel--2r & {
            grid-column: span 2;

            @include smmdlg {
                grid-column: 1;
            }

            figure,
            img {
                aspect-ratio: 17/8;
            }

            .r-article--title {
                @include smmdlg {
                    font-size: font-size(h3);
                }
            }
        }
    }

    .r-viewmode--panel-right-mag {
        grid-row: span 2;

        @include xs {
            grid-column: span 2;
            margin: 0 space(-0.5);

            .-is-mini-panel--3c & {
                padding: 0;
            }
        }

        i-article {
            padding: space() space() space(4);
        }

        .r-panel--title,
        .r-article--title {
            font-size: font-size(h3);
            font-weight: normal;
        }
    }

    .r-viewmode--panel-right-mag,
    .r-viewmode--horizontal-top-mag {
        article {
            position: relative;

            &::before {
                background: $degrade-articles;
                bottom: 0;
                content: '';
                height: 100%;
                left: 0;
                pointer-events: none;
                position: absolute;
                width: 100%;
                z-index: 1;
            }
        }

        i-article {
            bottom: 0;
            left: 0;
            position: absolute;
            text-align: center;
            z-index: 1;
        }
    }

    .r-viewmode--niveau-1-mag {
        .r-article--title {
            margin-bottom: 0;
        }

        $lines : 2;
        $line-height: 1.2em;

        .r-article--link {
            display: block;
            display: -webkit-box;
            -webkit-line-clamp: $lines;
            -webkit-box-orient: vertical;
            max-height: $lines * $line-height;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .-is-mp-aussi-en & {
            @include xs {
                .r-viewmode--article {
                    align-items: center;
                    display: flex;
                }

                .r-article--img {
                    font-size: 0;
                }

                figure {
                    margin: 0;
                }

                .r-article--title {
                    flex-basis: 60%;
                    font-weight: 600;
                    margin: 0;
                    min-width: 60%;
                    padding-left: space();
                }
            }
            .r-article--link {
                -webkit-line-clamp: initial;
                -webkit-box-orient: initial;
                max-height: none;
            }
        }
    }
}

.is-page-immo {
    .r-link--immo {
        align-items: center;
        background: color(immo);
        color: color(dark);
        display: flex;
        flex-direction: column;
        font-weight: bold;
        justify-content: center;
        height: 165px;
        padding: space();
        text-align: center;

        r-icon {
            font-size: 2rem;
            margin-bottom: space(.5);
        }

        span {
            align-items: center;
            display: flex;
            &::after {
                content: $icon-arrow-right;
                font-family: 'main';
                font-size: font-size(button);
                margin-left: space(.5);
            }
        }
    }
}

.r-link--grid-immo {
    display: grid;
    grid-gap: space();
    grid-template-areas: '.';
    grid-template-columns: 100%;
    grid-column: 1/3;
    padding: space() 0;

    @include smmdlg {
        grid-template-areas: '. .';
        grid-template-columns: 1fr 1fr;
    }
}

.r-viewmode--o-panel-3row,
.r-viewmode--panel-3row {
    @include lg {
        grid-column: auto / span 2;
        grid-row: auto / span 3;
    }

    @include wallpaper(md) {
        grid-column: auto / span 2;
        grid-row: auto / span 4;
    }

    @include sm {
        grid-column: auto / span 2;
        grid-row: auto / span 2;
    }

    @include scope('sb') {
        r-article--chapo {
            margin-top: space(.5);
        }
    }

    i-article {
        @include scope('special') {
            background-color: color(light);
            border-radius: space();
            left: 0;
            margin-top: -40px;
            z-index: z-index();
            width: 100%;

            @include smmdlg {
                border-radius: space(2);
                left: space();
                margin-top: -80px;
                width: 80%;
            }
        }

        .r-article--title {
            @include scope('special') {
                @include xs {
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
    }
}

.r-viewmode--o-panel-center,
.r-viewmode--panel-center {
    @include lg {
        grid-column: 2 / span 2;
        grid-row: auto / span 2;
    }

    @include wallpaper(md) {
        grid-column: auto / span 2;
        grid-row: auto / span 2;
    }

    @include sm {
        grid-column: auto / span 2;
        grid-row: auto / span 2;
    }

    @include xs {
        margin-left: 0;
        margin-right: 0;
    }

    @include smmdlg {
        @include scope('!sb') {
        text-align: center;
        }
    }

    .r-article--title {
        @include xs {
            font-size: font-size(h5);
            font-weight: bold;
            margin-left: 0;
            margin-right: 0;
        }

        @include smmdlg {
            font-size: font-size(h1);
        }
    }

    .r-article--img {
        @include xs {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .r-icon--abonne + .r-alerte {
        margin-left: space(.5);
    }

    @include scope('sb') {

        .r-article--title {
            font-weight: font-weight(h1);
            @include xs {
                font-size: font-size(h5);
                margin-top: space(1.25);
            }
        }

        .r-article--img {
            margin-right : -16px;
            margin-left: -16px;
        }
        r-article--chapo {
            display: block;
            margin-top: space(.5);
            @include xs {
                display: none;
            }
        }
    }

    strong {
        width: 100%;
    }

    figure {
        @include xs {
            margin: 0;
        }
    }

    .r-article--list {
        margin-top: space(1.5);
        text-align: left;

        @include smmdlg {
            margin: space(1.5) auto 0;
            width: 80%;
        }
    }
}
} // scope

@include scope('!critical') {
r-pager {
    padding: space(2) space();

    @include smmdlg {
        padding: space(4) space();
    }
}

.r-pager--dynamic {
    display: flex;
    list-style: none;
    padding: 0;

    span,
    .r-pager--link {
        align-items: center;
        border: 1px solid color(gray, light);
        border-radius: 100%;
        display: flex;
        height: space(2);
        justify-content: center;
        padding: 0;
        width: space(2);
    }

    .r-pager--link {
        @include scope('sb') {
            border: 1px solid black;
        }
    }

    span {
        background-color: color(gray, lighter);
        color: color(gray);
        margin: space(.2);
    }

    .r-pager--select {
        button {
            align-items: center;
            background-color: color(gray, lighter);
            border: 1px solid color(gray, light);
            border-radius: space(2);
            display: flex;
            height: space(2);
            justify-content: center;
            margin: space(.2);
            padding: 0 space(1.5) 0 space(.5);
            position: relative;
            width: space(4);

            @include scope('sb') {
                border: 1px solid black;
            }

            &::after {
                content: $icon-arrow-right;
                font-family: 'main';
                font-size: font-size(button);
                position: absolute;
                transform: rotate(90deg);
                transition: all .3s ease-in-out;
                right: space(.8);
                top: space(.4);
            }
        }

        &.-is-toggle {
            button {
                &::after {
                    transform: rotate(-90deg) translateX(1px);
                }
            }

            .r-pager--sublist {
                height: 260px;
                overflow: scroll;
                border: 1px solid color(gray, light);

                @include scope('sb') {
                    border: 1px solid black;
                    padding: 0 space();
                    z-index: 10;
                }
            }

            li {
                border-bottom: 1px solid color(gray, lighter);
                @include scope('sb') {
                    border-bottom: 1px solid black;
                }
            }
        }
    } 

    .r-pager--sublist {
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 80%, rgba(0, 0, 0, .1));
        background-color: color(light);
        border-radius: space(2);
        display: flex;
        flex-wrap: wrap;
        height: 0;
        list-style: none;
        left: 50%;
        margin-top: space(.5);
        overflow: hidden;
        transform: translateX(-50%);
        transition: all .3s ease-in-out;
        padding: 0;
        position: absolute;
        width: 200px;
        z-index: z-index(base);

        @include scope('sb') {
            border-radius: 0;
        }

        li {
            flex: 0 0 100%;
            transition: all .3s ease-in-out;
            padding: space(.2) 0;
        }

        .r-pager--link {
            border: 0;
            margin: auto;
            width: space(4);
        }
    }

    .r-pager--active {
        font-weight: font-weight(h1);
    }
}


.r-pager--link {
    box-shadow: none;
    color: color(dark);
    margin: space(.2);

    @include scope('mag') {
        border-radius: 0;

        &.-is-active {
            background: none;
            border: 1px solid color(dark);
            color: color(dark);
        }
    }
}
}// scope

@include scope('critical') {
    @font-face {
        font-display: block;
        font-family: 'main';
        font-weight: normal;
        font-style: normal;
        src: url('#{$domain}/sites/all/themes/enacarbon_sb/fonts/icons/main.woff2') format('woff2');
    }

    r-icon,
    .r-icon[class^='r-icon-'] {
        display: inline-block;
        font-family: 'main';
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-style: normal;
        font-variant: normal;
        font-weight: normal;
        line-height: 1;
        text-transform: none;
    }

    $icons: (
        menu: '\EA01',
    arrow-left: '\EA02',
    arrow-right: '\EA03',
    check: '\EA04',
    menu-left: '\EA05',
    menu-right: '\EA06',
    dossier-large: '\EA07',
    minus-square: '\EA08',
    plus-square: '\EA09',
    home: '\EA0A',
    uncheck: '\EA0B',
    bourse: '\EA0C',
    facebook: '\EA0D',
    crop-wheat-o_sencrop-icon: '\EA0E',
    linkedin-letters: '\EA0F',
    mail: '\EA10',
    print: '\EA11',
    abonne: '\EA12',
    camera: '\EA13',
    user-menu: '\EA14',
    dew-point-o_sencrop-icon: '\EA15',
    search: '\EA16',
    hygro-o_sencrop-icon: '\EA17',
    square: '\EA18',
    fullscreen: '\EA19',
    linkedin: '\EA1A',
    audio: '\EA1B',
    chart: '\EA1C',
    photos: '\EA1D',
    podcast: '\EA1E',
    etoile: '\EA1F',
    rain-o_sencrop-icon: '\EA20',
    mobile-phone: '\EA21',
    pencil: '\EA22',
    pinterest-logo: '\EA23',
    login: '\EA24',
    video-circle: '\EA25',
    less: '\EA26',
    more: '\EA27',
    bookmark: '\EA28',
    pin: '\EA29',
    star-empty: '\EA2A',
    star-half-empty: '\EA2B',
    star: '\EA2C',
    arrow-down: '\EA2D',
    arrow-up: '\EA2E',
    car: '\EA2F',
    rounded-square: '\EA30',
    sencrop-icon_sencrop-icon: '\EA31',
    quotes: '\EA32',
    direct: '\EA33',
    more-horizontal: '\EA34',
    more-vertical: '\EA35',
    award: '\EA36',
    trending-down: '\EA37',
    trending-up: '\EA38',
    user-x: '\EA39',
    dossier: '\EA3A',
    editos: '\EA3B',
    essentiel: '\EA3C',
    video-outline: '\EA3D',
    pencil-square: '\EA3E',
    cancel: '\EA3F',
    temp-hum-o_sencrop-icon: '\EA40',
    play-circle: '\EA41',
    messenger: '\EA42',
    whatsapp: '\EA43',
    temp-o_sencrop-icon: '\EA44',
    checked: '\EA45',
    wind-direction-e_sencrop-icon: '\EA46',
    carousel-expand: '\EA47',
    wind-direction-n_sencrop-icon: '\EA48',
    right-arrow: '\EA49',
    location: '\EA4A',
    location-o: '\EA4B',
    search-o: '\EA4C',
    job: '\EA4D',
    arrow-list: '\EA4E',
    calcul: '\EA4F',
    carousel-close: '\EA50',
    chevron-down: '\EA51',
    chevron-up: '\EA52',
    check-b: '\EA53',
    filinfo: '\EA54',
    pie-chart: '\EA55',
    play: '\EA56',
    arrow-bottom: '\EA57',
    left-arrow: '\EA58',
    wind-direction-ne_sencrop-icon: '\EA59',
    agenda: '\EA5A',
    sport: '\EA5B',
    wind-direction-no_sencrop-icon: '\EA5C',
    journal: '\EA5D',
    bulle: '\EA5E',
    legales: '\EA5F',
    partager: '\EA60',
    wind-direction-o_sencrop-icon: '\EA61',
    wind-direction-s_sencrop-icon: '\EA62',
    quote: '\EA63',
    wind-direction-se_sencrop-icon: '\EA64',
    wind-direction-so_sencrop-icon: '\EA65',
    wind-gust_sencrop-icon: '\EA66',
    wind_sencrop-icon: '\EA67',
    windcrop-o_sencrop-icon: '\EA68',
    raincrop-with-feet: '\EA69',
    map: '\EA6A',
    house: '\EA6B',
    kroll: '\EA6C',
    marches: '\EA6D',
    newsletters: '\EA6E',
    notifications: '\EA6F',
    rain: '\EA70',
    sun: '\EA71',
    compass-dial: '\EA72',
    umbrella: '\EA73',
    fluxrss: '\EA74',
    wind: '\EA75',
    water: '\EA76',
    arrow-long: '\EA77',
    emoji-frown: '\EA78',
    emoji-meh: '\EA79',
    jeux: '\EA7A',
    meteo: '\EA7B',
    emoji-smile: '\EA7C',
    help: '\EA7D',
    droplet: '\EA7E',
    seedling: '\EA7F',
    temperature: '\EA80',
    arrow-up-right-from-square: '\EA81',
    jobbo-icon-bw: '\EA82',
    calendar: '\EA83',
    clock2: '\EA84',
    computer: '\EA85',
    euro: '\EA86',
    instagram: '\EA92',
    jemedeconnecte: '\EA93',
    lena: '\EA9B',
    user: '\EA9E',
    newsletter: '\EAA2',
    notification: '\EAA3',
    recherche: '\EAA8',
    retourenhautdusite: '\EAAA',
    twitter: '\EAB0',
    pubdate: '\EAB1',
    video: '\EAB2',
    modifie: '\EAB3',
    clock: '\EAB4',
    newsletter-2: '\EAB5',
    rula: '\EAB6'
    );

    @each $name, $icon in $icons {
        .r-icon--#{$name}::before {
            content: $icon;
        }
    }
}

$icon-menu: '\EA01';
$icon-arrow-left: '\EA02';
$icon-arrow-right: '\EA03';
$icon-check: '\EA04';
$icon-menu-left: '\EA05';
$icon-menu-right: '\EA06';
$icon-dossier-large: '\EA07';
$icon-minus-square: '\EA08';
$icon-plus-square: '\EA09';
$icon-home: '\EA0A';
$icon-uncheck: '\EA0B';
$icon-bourse: '\EA0C';
$icon-facebook: '\EA0D';
$icon-crop-wheat-o_sencrop-icon: '\EA0E';
$icon-linkedin-letters: '\EA0F';
$icon-mail: '\EA10';
$icon-print: '\EA11';
$icon-abonne: '\EA12';
$icon-camera: '\EA13';
$icon-user-menu: '\EA14';
$icon-dew-point-o_sencrop-icon: '\EA15';
$icon-search: '\EA16';
$icon-hygro-o_sencrop-icon: '\EA17';
$icon-square: '\EA18';
$icon-fullscreen: '\EA19';
$icon-linkedin: '\EA1A';
$icon-audio: '\EA1B';
$icon-chart: '\EA1C';
$icon-photos: '\EA1D';
$icon-podcast: '\EA1E';
$icon-etoile: '\EA1F';
$icon-rain-o_sencrop-icon: '\EA20';
$icon-mobile-phone: '\EA21';
$icon-pencil: '\EA22';
$icon-pinterest-logo: '\EA23';
$icon-login: '\EA24';
$icon-video-circle: '\EA25';
$icon-less: '\EA26';
$icon-more: '\EA27';
$icon-bookmark: '\EA28';
$icon-pin: '\EA29';
$icon-star-empty: '\EA2A';
$icon-star-half-empty: '\EA2B';
$icon-star: '\EA2C';
$icon-arrow-down: '\EA2D';
$icon-arrow-up: '\EA2E';
$icon-car: '\EA2F';
$icon-rounded-square: '\EA30';
$icon-sencrop-icon_sencrop-icon: '\EA31';
$icon-quotes: '\EA32';
$icon-direct: '\EA33';
$icon-more-horizontal: '\EA34';
$icon-more-vertical: '\EA35';
$icon-award: '\EA36';
$icon-trending-down: '\EA37';
$icon-trending-up: '\EA38';
$icon-user-x: '\EA39';
$icon-dossier: '\EA3A';
$icon-editos: '\EA3B';
$icon-essentiel: '\EA3C';
$icon-video-outline: '\EA3D';
$icon-pencil-square: '\EA3E';
$icon-cancel: '\EA3F';
$icon-temp-hum-o_sencrop-icon: '\EA40';
$icon-play-circle: '\EA41';
$icon-messenger: '\EA42';
$icon-whatsapp: '\EA43';
$icon-temp-o_sencrop-icon: '\EA44';
$icon-checked: '\EA45';
$icon-wind-direction-e_sencrop-icon: '\EA46';
$icon-carousel-expand: '\EA47';
$icon-wind-direction-n_sencrop-icon: '\EA48';
$icon-right-arrow: '\EA49';
$icon-location: '\EA4A';
$icon-location-o: '\EA4B';
$icon-search-o: '\EA4C';
$icon-job: '\EA4D';
$icon-arrow-list: '\EA4E';
$icon-calcul: '\EA4F';
$icon-carousel-close: '\EA50';
$icon-chevron-down: '\EA51';
$icon-chevron-up: '\EA52';
$icon-check-b: '\EA53';
$icon-filinfo: '\EA54';
$icon-pie-chart: '\EA55';
$icon-play: '\EA56';
$icon-arrow-bottom: '\EA57';
$icon-left-arrow: '\EA58';
$icon-wind-direction-ne_sencrop-icon: '\EA59';
$icon-agenda: '\EA5A';
$icon-sport: '\EA5B';
$icon-wind-direction-no_sencrop-icon: '\EA5C';
$icon-journal: '\EA5D';
$icon-bulle: '\EA5E';
$icon-legales: '\EA5F';
$icon-partager: '\EA60';
$icon-wind-direction-o_sencrop-icon: '\EA61';
$icon-wind-direction-s_sencrop-icon: '\EA62';
$icon-quote: '\EA63';
$icon-wind-direction-se_sencrop-icon: '\EA64';
$icon-wind-direction-so_sencrop-icon: '\EA65';
$icon-wind-gust_sencrop-icon: '\EA66';
$icon-wind_sencrop-icon: '\EA67';
$icon-windcrop-o_sencrop-icon: '\EA68';
$icon-raincrop-with-feet: '\EA69';
$icon-map: '\EA6A';
$icon-house: '\EA6B';
$icon-kroll: '\EA6C';
$icon-marches: '\EA6D';
$icon-newsletters: '\EA6E';
$icon-notifications: '\EA6F';
$icon-rain: '\EA70';
$icon-sun: '\EA71';
$icon-compass-dial: '\EA72';
$icon-umbrella: '\EA73';
$icon-fluxrss: '\EA74';
$icon-wind: '\EA75';
$icon-water: '\EA76';
$icon-arrow-long: '\EA77';
$icon-emoji-frown: '\EA78';
$icon-emoji-meh: '\EA79';
$icon-jeux: '\EA7A';
$icon-meteo: '\EA7B';
$icon-emoji-smile: '\EA7C';
$icon-help: '\EA7D';
$icon-droplet: '\EA7E';
$icon-seedling: '\EA7F';
$icon-temperature: '\EA80';
$icon-arrow-up-right-from-square: '\EA81';
$icon-jobbo-icon-bw: '\EA82';
$icon-calendar: '\EA83';
$icon-clock2: '\EA84';
$icon-computer: '\EA85';
$icon-euro: '\EA86';
$icon-instagram: '\EA92';
$icon-jemedeconnecte: '\EA93';
$icon-lena: '\EA9B';
$icon-user: '\EA9E';
$icon-newsletter: '\EAA2';
$icon-notification: '\EAA3';
$icon-recherche: '\EAA8';
$icon-retourenhautdusite: '\EAAA';
$icon-twitter: '\EAB0';
$icon-pubdate: '\EAB1';
$icon-video: '\EAB2';
$icon-modifie: '\EAB3';
$icon-clock: '\EAB4';
$icon-newsletter-2: '\EAB5';
$icon-rula: '\EAB6';
@include scope('critical') {
r-carousel {
    display: block;
    grid-column-start: 1;
    grid-column-end: -1;

    @include mdlg {
        position: relative;
        //width: 100%; // Fix Firefox
    }

    i-mini-panel & {
        margin-top: space(.5);
        padding-left: space();
        padding-right: space();

        @include smmdlg {
            margin-bottom: space(2);
        }
    }
}

i-carousel {
    display: flex;

    @include xssm {
        overflow: auto;
    }

    @include mdlg {
        display: block;
        opacity: 0;
        transition: opacity .3s ease;
    }

    @include xs {
        margin: 0 auto;
        opacity: 1;
        overflow-y: hidden;
        position: relative;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none;
        scrollbar-width: none; // Fix Firefox Scrollbar
        transform: translateZ(0);
    }

    > * {
        @include xssm {
            flex: 0 0 80%;
            margin: space(.5);
        }
    }
}

.r-carousel--item {
    @include xssm {
        margin-right: space();
    }

    @include xs {
        flex: 0 0 85%;
    }

    @include sm {
        flex: 0 0 40%;
    }
}
}// scope

@include scope('!critical') {
.icon-arrow-left {
    &::before {
        content: $icon-arrow-left;
        font-family: $font-family-icons;
    }
}

.icon-arrow-right {
    &::before {
        content: $icon-arrow-right;
        font-family: $font-family-icons;
    }
}

//.r-carousel--partenaires {
//    padding: space(2) space();
//    width: 100%;
//
//    r-carousel--title {
//        color: color(primary);
//        display: block;
//        font-size: font-size(h3);
//        margin: space(.5);
//        text-align: center;
//
//        @include scope('vdn', 'an', 'cp', 'pn') {
//            font-family: $font-family-base;
//            font-weight: bold;
//        }
//
//    }
//
//    r-article--meta {
//        color: color(red);
//
//        a {
//            color: color(red);
//        }
//    }
//
//    .r-article--title {
//        color: color(gray, dark);
//        font-family: $font-family-base;
//    }
//
//    .r-carousel--link {
//        color: color(gray, dark);
//    }
//
//    .glider-next,
//    .glider-prev {
//        font-size: font-size(h3);
//    }
//}

.glider-track {
    margin: 0 auto;
}

.r-carousel--kiosque {
    @include xs {
        padding: 0;
        margin-right: space(-1);
        margin-left: space(-0.5);
        width: auto;
    }
}

.r-carousel--liseuse {
    margin: 0 space();
    width: auto;

    .glider-prev,
    .glider-next {
        background: color(primary);
        color: color(light);
        padding: space(1.5) space(.75);
    }

    .glider-prev {
        border-radius: 50px 0 0 50px;
        left: space(-1.5);
    }

    .glider-next {
        border-radius: 0 50px 50px 0;
        right: space(-1.5);
    }
}
}// scope

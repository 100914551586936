// Page Archives

@include scope(('archive', 'critical')) {
// Archives form bloc
.r-archive--form {
    display: grid;
    gap: space(2) space();
    grid-auto-flow: row dense;
    grid-auto-rows: auto;
    grid-template-areas: ". . . .";
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: space-between;
    margin-bottom: space(2);
}

.r-archive--text {
    border: 1px solid color(primary);
    border-radius: space(3);
    grid-column: 1 / span 4;
    padding: space(0.6) space(2);

    @include mdlg {
        grid-column: 1 / span 3;
    }

    &:focus-visible {
        border-radius: space(3);
    }

    &::placeholder {
        color: color(primary);
        font-style: italic;
        font-weight: font-weight(small);
    }
}

.r-archive--select {
    appearance: none;
    background-clip: padding-box;
    background-color: transparent;
    background-image: url(https://lvdneng.rosselcdn.net/sites/default/files/mediastore/1657529416_uea51-chevron-down.svg);
    // background-image: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDEyOSAxMjkiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMjkgMTI5Ij4NCiAgICA8cGF0aCBmaWxsPSIjZmZmIiBkPSJtMTIxLjMsMzQuNmMtMS42LTEuNi00LjItMS42LTUuOCwwbC01MSw1MS4xLTUxLjEtNTEuMWMtMS42LTEuNi00LjItMS42LTUuOCwwLTEuNiwxLjYtMS42LDQuMiAwLDUuOGw1My45LDUzLjljMC44LDAuOCAxLjgsMS4yIDIuOSwxLjIgMSwwIDIuMS0wLjQgMi45LTEuMmw1My45LTUzLjljMS43LTEuNiAxLjctNC4yIDAuMS01Ljh6Ii8+DQo8L3N2Zz4NCg=='),url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5' height='5' version='1.1'%3E%3Crect width='5' height='5' fill='%230854e8'/%3E%3C/svg%3E");
    background-position: right space(.5) center,100% 0;
    background-repeat: no-repeat;
    background-size: 25px,2.5rem 2.5rem;
    border-radius: 5px;
    border: 1px solid color(gray, dark);
    border-radius: space(0.3);
    // box-shadow: none;
    color: color(gray, dark);
    cursor: pointer;
    font-style: italic;
    grid-column: span 2;
    grid-row: 2;
    justify-self: stretch;
    padding: space(.5) 2.5rem space(.5) space();

    @include mdlg {
        grid-column: 1;
    }

    &:nth-of-type(2) {
        grid-column: 3 / span 2;

        @include mdlg {
            grid-column: 2;
        }
    }
}

.r-archive--submit {
    background-color: color(primary);
    border: 0;
    border-radius: space(3);
    color: color(light);
    cursor: pointer;
    font-weight: bold;
    font-size: font-size(base);
    grid-column: 1 / span 4;
    justify-self: center;
    max-width: 160px;
    padding: 11px 30px;

    &:hover {
        opacity: .8;
    }

    @include mdlg {
        grid-column: 4;
        grid-row: 2;
    }
}

.r-archive--results {
    text-align: center;
    color: color(primary);
    margin-top: space(3);

    @include sm {
        line-height: 1;
    }

    @include mdlg {
        line-height: 2;
    }
}

.-is-page-archive {
    .r-pub--imu {
        grid-area: sidebar;
    }
}

// Archives Sidebar
.r-sidebar--archives {
    > ul {
        > li:first-child {
            padding-top: 0px;
        }
    }

    ul {
        list-style: none;
        margin-left: 0px;
        padding-left: 0px;
        margin-top: 0px;

        > li {
            padding-top: space();
        }

        &:nth-child(1) > li {
            padding-top: space();
            padding-bottom: space();
            padding-left: 26px;

            &:not(:last-child) {
                border-bottom: 1px solid color(gray, light);
            }

        }
    }

    h3 {
        background-color: color(gray, light);
        color: color(primary);
        padding: space(.5);
        font-family: $font-family-base;
    }

    a {
        align-self: center;
        align-items: center;
        display: inline-flex;
        height: 28px;
        margin-left: 6px;
        padding-left: 34px;
        position: relative;

        &::before {
            border: 1px solid color(primary);
            border-radius: 5px;
            content: "";
            height: 26px;
            left: 0;
            position: absolute;
            width: 26px;
        }

        &::after {
            border-bottom: 2px solid color(light);
            border-left: 2px solid color(light);
            content: '';
            display: block;
            height: 8px;
            left: 7px;
            position: absolute;
            top: 8px;
            transform: rotate(-45deg) scale(0);
            transition: transform ease 0.25s;
            width: 13px;
            will-change: transform;
        }

        &.-is-active {
            &::before {
                background-color: color(primary);
            }

            &::after {
                transform: rotate(-45deg) scale(1);
            }
        }
    }
}

// Archives Viewmode
.r-viewmode--archive {

    @include xs {
        margin:0;
    }

    .r-viewmode--article {
        border-top: 1px solid color(gray);
        display: grid;
        grid-column-gap: space(1.5);
        grid-template-columns: 2fr 4fr;
        padding: space(2) 0 0 0;
        @include smmdlg {
            grid-template-areas: 
            'img title'
            'img meta'
            'img chapo';
        }
        @include xs {
            grid-template-areas:
                "title img"
                "meta meta"
                'chapo chapo';
            grid-template-columns:4fr 2fr;
        }
    }
   

    .r-article--title {
        grid-area: title;
        margin-top:0;
        grid-area: title;
        margin-top:0;
        @include smmdlg {
            font-size: font-size(h3);
            font-weight: font-weight(h1);
        }
    }

    .r-article--link {
        color: color(black);
    }

    .r-article--img {
        grid-area: img;
        figure {
            margin: 0;
        }

        &::after {
            top: space(.5);
        }
    }
    r-article--meta {
        grid-area: meta;

        time {
            font-size: font-size(aside);
            @include xs {
                font-size: font-size(small);
            }
        }
    }

    r-article--chapo {
        grid-area: chapo;
        margin : space() 0;

        em {
            font-weight: font-weight(h1);
        }
    }
}

.-is-premium {
    background: color(primary, lighter);
}

/* Texte */

.r-text-sup {
    color: color(primary);

    @include mdlg {
        font-size: font-size(h5);
    }
}

.r-text-upper {
    color: color(primary);
    font-family: $font-family-base;
    font-weight: font-weight(base);
    font-size: font-size(h4);
    text-transform: uppercase;
    text-align: center;
}
}// scope

@include scope(('article', 'critical')) {
.r-archive--retour {
    display: flex;
    font-weight: bold;
    margin: space() auto;

    @include mdlg {
        margin: space() 0 0;
    }

    &::before {
        content: $icon-search;
        font-family: $font-family-icons;
        font-size: 90%;
        margin-right: space(.5);
    }

    &:hover {
        color: color(dark);
        text-decoration: none;
    }
}
}

@include scope(('archive', '!critical')) {
.page-archive-index {
    .pane-dpiindex-dpiindex-dates {
        margin-top: space(2);
    }
    .pane-dpiindex-dpiindex-dates,
    .pane-dpiindex-dpiindex, 
    .pane-dpiindex-dpiindex-navigation-links {
        h3 {
            margin: 0 0 space();
            font-weight: font-weight(h1);
        }

        ul {
            margin-bottom: space();
            padding: 0;
        }

        .index-articles,
        .item-list,
        .date-list {
            background: black;
            color: color(light);
            margin-bottom: space(2);
            padding: space(2);
        }

        .date-list {
            padding: space(2);
        }

        .item-list, .date-list, .index-articles:first-of-type {
            li {
                display: inline-block;
                margin: space(.5) space(.25);

                a {
                    background: color(light);
                    border: 1px solid color(light);
                    border-radius: 30px;
                    color: color(dark);
                    font-size: .9rem;
                    padding: space(.25) space();

                    @include smmdlg {
                        font-size: 1rem;
                    }

                    &:hover {
                        background: color(primary);
                        border: 1px solid color(primary);
                        color: color(light);
                        text-decoration: none;
                    }
                }

                .selected {
                    background: color(primary);
                    border: 1px solid color(primary);
                    color: color(light);
                    font-weight: font-weight(h1);
                }
            }
        }

        .index-articles {
            li {
                margin: space(.5);
            }
        }

        .index-articles:first-of-type {
            li {
                width: 100%;
            }
        }

        .date-list {
            ul {
                columns: auto;
                list-style: none;
            }

            li {
                margin: 0 space(.5) space(.5) 0;

                @include xs {
                   font-size: font-size(aside);
                }
            }
        }
    }

    .pane-dpiindex-dpiindex-dates {
        h3 {
            @include smmdlg {
                font-size: font-size(h1);
            }     
        } 
    }

    .pane-dpiindex-dpiindex { 
        .index-articles {
            h3 {
                @include smmdlg {
                    font-size: font-size(h1);
                }     
            }
        }
    
    }

    .pane-dpiindex-dpiindex-navigation-links:first-of-type {
        h3 {
            @include smmdlg {
                font-size: font-size(h1);
            }     
        }
    }

    .pane-dpiindex-dpiindex-navigation-links {
        .item-list {
            li {
                display: block;
                margin: 0 0 space() 0;
            }
        }
    }
}

.gr-nav-articles {
    display: flex;
    justify-content: space-between;
    margin-top: space(2);
    padding: 0;

    @include xssm {
        margin: space(2) space() space();
    }

    li {
        list-style: none;
        padding: 0;

        &:first-child,
        &:last-child {
            a {
                @include xssm {
                    font-size: 0;
                }
            }
        }
    }

    a {
        background:black;
        border: 1px solid black;
        color: color(light);
        font-weight: font-weight(h1);
        padding: space(.5) space();
        border-radius: 25px;
        display: inline-block;

        &:hover,
        &:focus {
            background: color(light);
            border: 1px solid black;
            text-decoration: none;
            color: black;
        }
        
        @include xssm {
            padding: space(.75) space();
        }
       

        r-icon {
            vertical-align: middle;

            @include xssm {
                font-size: font-size(base);
            }

            &.r-icon--arrow-left {
                margin-right: space(.5);
                margin-top: -1px;

                @include xssm {
                    margin-right: 0;
                }
            }

            &.r-icon--arrow-right {
                margin-left: space(.5);
                margin-top: -1px;

                @include xssm {
                    margin-left: 0;
                }
            }
        }
    }

    li:nth-of-type(2) {
        a {
            background: color(light);
            border: 1px solid black;
            color: black;
            &:hover,
            &:focus {
                opacity: .6;
            }
    
            @include xssm {
                padding: space(.5) space();
            }
        }
    }
}
}// scope

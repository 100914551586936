// -----------------------------------------------------------------------------
// Base
// -----------------------------------------------------------------------------

@include scope('!critical') {
@media print {
    r-header,
    r-menu,
    r-pub,
    .r-sticky--panel,
    r-footer {
        display: none !important;
    }

    figure {
        margin: 0 !important;
        width: 35%;
    }

    .page-node {
        r-embed,
        r-panel,
        r-mini-panel {
            display: none !important;
        }
    }
}
}// scope

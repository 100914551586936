@include scope('!critical') {
r-pager {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: space();

    @include smmdlg {
        // flex-wrap: nowrap;
        grid-column-start: 1;
        grid-column-end: -1;
    }
}

.r-pager--link {
    border-radius: 5px;
    box-shadow: 0 0 space() color(gray, light);
    margin: space(.5);
    padding: space(.5) space();
    white-space: nowrap;

    @include scope('!sudinfo') {
        &.-is-active {
            background: color(primary);
            color: color(light);
        }
    }
}
}// scope

@include scope('critical') {
html {
    font-size: 16px;

    @include xs {
        margin-bottom: 50px;
    }

    @include smmdlg {
        font-size: 14px;
        overflow-x: hidden;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-family-title;
    margin-bottom: space(.5);
    margin-top: space(.5);
}

h1 {
    align-items: center;
    display: flex;
    font-size: font-size(h2);
    line-height: line-height(h1);
    margin: 0 0 space() 0;
    padding: space() 0;

    @include scope(('!immo', '!article')) {
        grid-area: event;
    }

    @include smmdlg {
        font-size: font-size(section);
        grid-column-start: 1;
        grid-column-end: -1;
        padding: space() 0 0;
    }

    @include scope('mag') {
        .page-section & {
            display: flex;
            font-size: font-size(h3);
            text-align: center;
            text-transform: uppercase;

            &::after,
            &::before {
                background: color(gray, light);
                content: '';
                flex: 1;
                height: 1px;
                padding: 0 space();
            }

            &::after {
                margin: space() 0 space() space();

                @include xs {
                    padding-right: 0;
                }
            }

            &::before {
                margin: space() space() space() 0;
            }
        }
    }

    a {
        color: color(dark);
    }

    .-in-area-event & {
        @include scope('!mag') {
            font-family: $font-family-base;
            font-weight: 900;
            margin-bottom: space();
            padding: 0 space();

            @include smmdlg {
                font-size: 3.5rem;
            }
        }

        @include scope('mag') {
            padding: space();
        }

        .page-commune & {
            display: block;
            padding-left: space(3);
            position: relative;
            text-align: left;

            @include xs {
                margin: space();
            }

            @include smmdlg {
                padding-left: space(4);
            }
        }
    }

    &.-in-area-event {
        @include xs {
            padding: space();
        }
    }

    span {
        color: color(primary);
        display: block;
        font-size: font-size(aside);
        font-weight: font-weight(base);

        @include smmdlg {
            font-size: font-size(h5);
        }
    }

    r-icon {
        // bottom: 7px;
        color: color(primary);
        font-size: 3rem;
        left: 0;
        position: absolute;

        @include smmdlg {
            font-size: 4.5rem;
        }
    }
}

h2 {
    font-size: font-size(h2);
    line-height: line-height(h2);

    @include smmdlg {
        font-size: font-size(h2);
    }
}

h3 {
    font-size: font-size(h5);
    line-height: line-height(h3);

    @include smmdlg {
        font-size: font-size(h3);
        font-weight: 600;

        @include scope('mag') {
            font-weight: 400;
        }
    }
}

h4 {
    font-size: font-size(h5);
    line-height: line-height(h4);

    @include smmdlg {
        font-size: font-size(h4);
        font-weight: 600;
    }
}

h5 {
    font-size: font-size(h5);
    line-height: line-height(h5);

    @include smmdlg {
        font-size: font-size(h5);
    }
}

h6 {
    font-size: font-size(h6);
    line-height: line-height(h6);

    @include smmdlg {
        font-size: font-size(h6);
    }
}

figure {
    margin-bottom: space(.75);
    overflow: hidden;
}

figcaption {
    color: color(gray);
    font-size: font-size(nav);
    padding: space(.5);

    @include mdlg {
        padding: space(.5) 0;
    }
    @include scope('sb') {
        color: color(gray, dark);
    }
}

button {
    background: none;
    border: none;
    cursor: pointer;
}

address {
    font-size: font-size(aside);
    font-style: normal;

    a {
        font-weight: bold;
    }

    @include xs {
        font-size: 0.7rem;
    }
}

a {
    @include scope('!special') {
        color: color(primary);
    }

    @include scope('special') {
        color: color(primary);

        &:hover {
            color: color(primary);
        }
    }
}

p {
    a {
        display: inline;
        text-decoration: underline;
    }
}
}

@include scope('!critical') {
    .r-blockquote {
        margin: space(2) 0;

        @include scope('mag') {
            background: color(gray,lighter);
            padding: space(2);

            @include smmdlg {
                max-width: calc(100% - #{space()});
            }

            @include xs {
                margin: space(2) space(-1);
            }
        }

        figcaption {
            margin-top: space(.5);

            @include scope('!mag') {
                color: color(dark);
            }

            @include scope('mag') {
                color: color(gray);
                text-align: right;
            }
        }
    }
    
    blockquote {
        font-family: $font-family-title;
        font-size: font-size(h4);
        font-weight: font-weight(h1);
        margin: 0;

        @include scope('mag') {
            border-top: 1px solid color(gray);
            padding-top: 24px;
            position: relative;
        }

        @include smmdlg {
            @include scope('!mag') {
                font-size: font-size(h2);
            }

            @include scope('mag') {
                font-size: font-size(h4);
            }
        }
    
        &::before {
            color: color(primary);
            content: $icon-quotes;
            display: block;
            font-family: $font-family-icons;
            font-size: 2.5rem;
            margin-bottom: space(.5);

            @include smmdlg {
                @include scope('!mag') {
                    font-size: 3.4rem;
                }
            }

            @include scope('mag') {
                display: inline-block;
                background: color(gray,lighter);
                color: color(dark);
                font-size: 25px;
                margin: 0;
                padding: 0 0 0 space();
                position: absolute;
                top: -20px;
                transform: rotate(180deg);
            }
        }
    
        blockquote {
            border: 0;
            margin: 0;
            padding: 0;
    
            &::before {
                display: none;
            }
        }
    }

    .r-epigraph {
        border-left: 3px solid color(primary);
        padding-left: space();

        @include scope('!mag') {
            margin: space(2) 0;
            max-width: 400px;
        }

        @include scope('mag') {
            color: color(primary);
            margin: space(2) 0;

            @include xs{
                margin-left: 0;
            }
        }
    }

    epigraph {
        display: block;
        font-size: font-size(h4);
        font-weight: font-weight(h1);

        @include scope('mag') {
            font-size: font-size(h5);
        }

        @include smmdlg {
            @include scope('!mag') {
                font-size: font-size(h2);
            }
        }
    }

    li {
        &::marker {
            .r-article & {
                color: color(primary);
                font-size: 1.5rem;
            }
        }
    }
}

// scope

@include scope('!critical') {
r-footer {
    display: grid;
    grid-template-areas: 
    'top top top'
    '. . .';
    grid-column-gap: space(2.5);
    grid-template-columns: 1fr 1fr 1fr;
    //margin-top: 100px;
    padding: space() 25px 60px;
    border-top: 1px solid color(black);
    color: color(primary);

    @include lg {
        padding: space(2) maxi(calc(7.8vw + #{space()}), calc(50vw - #{$content-max-width / 2})) 60px;
    }

    @include md {
        padding: space(2) maxi(space(4), calc(50vw - #{450px + space(2.5)})) 60px;
    }

    @include scope('video') {
        background-color: white;
    }

    a,
    .r-footer--link {
        color: color(black);
    }

    .r-page--shopping & {
        background: color(light);
        border: none;
    }

    @include wallpaper {
        grid-template-columns: repeat(3, 1fr);
        margin: auto;
        padding: space();
        width: $arche-width;
        background: color(light);
    }
} 

r-footer--top {
    display: grid;
    grid-area: top;

    @include xssm {
        grid-template-areas: 
            "logo top"
            "nl-abo nl-abo"
            ". ."
    }
}

r-footer--section {
    display: block;
    grid-column-start: 1;
    grid-column-end: -1;
    text-transform: uppercase;

    &.is-open {
        .r-footer--title:after {
            transform: rotate(90deg);
        }

        r-footer--list {
            display: block;
            max-height: 100%;
            opacity: 1;
            overflow: visible;
            transition: all 0.3s linear;
        }
    }

    &:nth-of-type( odd ) {
        r-footer--list {
            @include mdlg {
                columns: 2;
            }

            @include wallpaper {
                columns: 1;
            }
        }
    }

    @include sm {
        padding-left: space(2);
    }

    @include smmdlg {
        display: block;
        grid-column: auto;
        margin-top: space(2);
    }
}

r-footer--list {
    display: block;

    @include xs {
        //columns: 2;
        display: none;
        //max-height: 0;
        overflow: hidden;
        opacity: 0;
        //transition: .3s;
        transition: all 0.3s linear;
    }

    @include mdlg {
        columns: 2;
    }

    @include wallpaper {
        columns: 1;
    }
}

.r-footer--title {
    font-family: $font-family-base;
    font-size: font-size(h6);
    font-weight: bold;
    
    @include xs {
        align-items: center;
        display: flex;
        height: 48px;
        margin-bottom: space(0);
        margin-top: space(0);
        padding-bottom: space(0);
        position: relative;

        &:after {
            content: $icon-arrow-right;
            font-family: main;
            position: absolute;
            right: 20px;
            transition: all 0.25s ease 0s;
        }
    }

    @include mdlg {
        padding-bottom: space();
    }

}

.r-footer--link {
    display: block;
    text-align: left;
    text-transform: uppercase;
    margin: 0 space(.5) space(.5) 0;
    padding-left: 0;
}

.r-footer--social {
    align-items: center;
    border-bottom: none;
    grid-column: 1 / span 3;
    margin: space(2) 0 space();
    padding: space();
    text-align: center;
    color: color(black);

    @include smmdlg {
        display: flex;
        justify-content: center;
    }

    a {
        align-items: center;
        display: inline-flex;
        padding: space(.5) space();

        &:hover {
            text-decoration: none;
            color: color(primary);
        }
    }

    r-icon {
        font-size: 2rem;
        margin-right: space(.5);
    }
}

.r-footer--logo {
    column-gap: space(.5);
    display: flex;
    grid-area: logo;
    grid-column: 1 / span 2;

    @include smmdlg {
        column-gap: space();
        grid-column: 1;
    }

    svg {
        max-width: 258px;

        @include xs {
            max-width: 200px;
        }
    }

    .logo-footer-white {
        display: none;
    }

}

.r-footer--newsletter {
    grid-area: nl-abo;
    grid-column: 2;
    padding-left: space();
    padding-right: space();
    display: none;

    @include xssm {
        grid-column-start: 1;
        grid-column-end: -1;
        padding-top: space();
    }
    @include xs {
        padding-bottom: space();
        padding-left: 0;
        padding-right: 0;
    }

    .r-abonne & {
        display: block;
    }

    h4 {
        color: color(black);
        font-size: 17px;
        font-family: $font-family-title;
        font-weight: bold;
    }

}

.r-footer--abo {
    grid-area: nl-abo;
    grid-column: 2;

    @include xssm {
        grid-column-start: 1;
        grid-column-end: -1;
        padding-top: space(0.5);
        text-align: center;
    }
    @include xs {
        padding-bottom: space();
    }

    .r-abonne & {
        display: none;
    } 

    p {
        color: color(black);
        font-size: 17px;
        font-family: $font-family-title;
        font-weight: bold;
        margin-bottom: space(0.5);
    }

    .r-btn--primary {
        background-color: color(primary, light);
        border: none ;
        color: white;
        font-family: $font-family-base;
        font-size: 18px;
        &:hover {
            background-color: color(primary);
        }
    }
}

.r-footer--top {
    display: flex;
    align-items: center;
    align-self: start;
    font-size: .9rem;
    grid-area: top;
    grid-column: 3;
    grid-template-columns: 1fr 1fr 1fr;
    justify-self: right;

    &:hover {
        color: color(gray, dark);
        text-decoration: none;
    }

    @include xssm {
        grid-column: 2;
    }

    r-icon {
        font-size: 2rem;
        margin-left: space();

        @include smmdlg {
            font-size: 2.7rem;
        }
    }

    span {
        @include xs {
            display: none;
        }
    }
}

.r-footer--services {
    @include xs {
        display: block;
        grid-column-start: 1;
        grid-column-end: -1;
        margin: 0 0;

        r-footer--list {
            columns: 2;
        }
    }
}

.r-footer--copyright {
    grid-column: 1 / span 3;

    @include xs {
        border-top: 1px solid color(light);
        color: color(primary);
        font-size: font-size(small);
        margin-top: space(.5);
        padding-top: space(.5);
    }

}

.r-footer--source-sure {
    float: left;
    height: 40px;
    margin-right: space();
}

.r-footer--cim {
    float: right;
    height: 60px;
    margin-left: space();
}

.r-bottom--service {
    display: block;
}
}  // scope

@include scope(('article', 'critical')) {
.r-toggle--share {
    display: flex;

    @include xs {
        background: color(light);
        border: 1px solid color(primary);
        border-bottom: 0;
        bottom: 100%;
        content-visibility: hidden;
        opacity: 0;
        padding: space(.5);
        position: absolute;
        right: 0;
        visibility: hidden;

        @include scope('special') {
            border-color: color(primary);
        }
    }
}
}// scope

@include scope('!critical') {
.-is-toggle {
    content-visibility: visible;
    opacity: 1;
    transition: opacity .3s ease;
    visibility: visible;
}
}// scope

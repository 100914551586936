@include scope('critical') {
    r-modal {
        display: none;
    }
}

@include scope('!critical') {

i-modal {
    z-index: z-index();
}
}// scope

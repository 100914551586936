@include scope(('meteo', 'critical')) {

    // meteo homepage
    .r-mini-panel--section { 
        &.-is-mp-meteo-report {
            background-size: cover;
            background-color: color(black);
            margin-top: 0;
            @include xs {
                padding-top: space(1);
            }
            
            i-mini-panel {
                padding-bottom: space(1);

                @include smmdlg {
                    display: grid;
                    grid-auto-flow: row dense;
                    grid-gap: 1px;
                }
        
                @include lg {
                    grid-template-areas: 
                    '. .'
                    'meteo-map-report meteo-promo-1'
                    'meteo-map-report meteo-promo-2';
                    grid-template-columns: 75% 25%;
                    margin: auto maxi(1px, calc(50vw - #{$content-max-width / 2}));
                }
                @include smmd {
                    grid-template-areas: 
                    '. .'
                    'meteo-map-report meteo-map-report' 
                    'meteo-promo-1 meteo-promo-2';
                    grid-template-columns: 50% 50%;
                    margin: auto;
                }
            }
            .r-mini-panel--title {
                @include xs {
                    padding-left: 0;
                    padding-right: 0;
                    text-align: center;
                }
            }
            .r-meteo-map--report {
                grid-area: meteo-map-report;

                @include smmdlg {
                    display: grid;
                    grid-auto-flow: row dense;
                    grid-gap: 1px;
                }

                .r-meteo-map--report-body {
                    height: 660px;
                    margin: space(1);

                    @include smmd {
                        height: 500px;
                        margin: space(1);
                    }

                    @include xs {
                        height: 400px;
                        margin: space(1) 0 space(2) 0;
                    }
                }
            }
            .r-meteo-report--intro { 
                grid-area: meteo-intro;
                padding: space(1);

                h3 {
                    font-weight: 700;
                }
            }
            r-selligent {
                grid-column: auto;
                grid-row: auto;

                &.meteo-promo-1 {
                    grid-area: meteo-promo-1;
                }
                &.meteo-promo-2 {
                    grid-area: meteo-promo-2;
                }
            }
        }
    }

    // sencrop landing page
    //.r-sencrop-form {}

    .r-mini-panel--section {
        &.-is-mp-sencrop-contact {
            padding-top: space(3);

            i-mini-panel {

                @include lg {
                    grid-template-areas: '. contact contact .';
                }

                @include smmd {
                    grid-template-areas: 
                    'contact'
                    '.';
                    grid-template-columns: 100%;
                }
            }

            .sencrop-contact-form {
                grid-area: contact;
                padding: 0 space(2);

                @include sm {
                    padding: 0 space();
                }

            }
        }

        &.-is-mp-sencrop-products {

            padding-bottom: space(0.5);

            @include xs {
                padding-top: space();
            }

            &.-has-bg-light {
                background-color: color(meteo, light);
            }
            
            i-mini-panel {
        
               @include lg {
                    grid-template-areas: 
                    'title title title title'
                    '. description description .'
                    'product product product product'
                    'logo . . .';
                }
                @include smmd {
                    grid-template-areas: 
                    'title title'
                    'description description'
                    '. .'
                    '. .'
                    'logo .';
                    grid-template-columns: 50% 50%;
                }

            } 
            .r-mini-panel--title {
                grid-area: title;

                &::after {
                    background: none;
                }
                &::before {
                    background: none;
                }
                @include xs {
                    padding-left: 0;
                    padding-right: 0;
                    text-align: center;
                }
            }
            .r-mini-panel--description {
                font-size: 16px;
                grid-area: description;
                margin-bottom: space();
                padding: 0 space(3);
                text-align: center;
                @include xs {
                    font-size: 14px;
                    padding: 0;
                }
            }
            .sencrop-product {
                background-color: white;
                grid-area: product;
                grid-column: auto;
                margin: space(0.5) space();
                padding: space(2);
                text-align: center;

                @include smmd {
                    grid-row: auto;
                }

                @include xs {
                    margin: space(0.5) 0;
                    padding: space(1);
                }
 
                h3 {
                    font-family: $font-family-title;
                    font-size: 30px;
                    font-weight: 700;
                    line-height: 36px;
                    @include lg {
                        height: 72px;
                    }
                    @include md {
                        height: initial;
                    }
                    @include sm {
                        height: 72px;
                    }
                    @include xs {
                        font-size: 24px;
                        height: initial;
                    }
                }

                ul {
                    list-style-type: none;
                    margin: 0 sapce();
                    min-height: 130px;
                    padding-left: 0;

                    @include xs {
                        margin: 0;
                        min-height: initial;
                    }

                    li {
                        p {
                            display: inline-block;
                            font-size: 18px;
                            line-height: 30px;
                            margin: 0;
                            padding: 0;

                            @include xs {
                                font-size: 16px;
                            }

                            &::before {
                                color: color(meteo);
                                content: $icon-checked;
                                font-family: $font-family-icons;
                                font-size: 14px;
                                padding-right: 5px;
                            }
                        }
                    }
                }

                .sencrop-product-pic {
                    img {
                        max-width: 300px;
                        width: 100%;
                        @include xs {
                            max-width: 250px;
                        }
                    }
                }

                .sencrop-product-price {
                    color: color(meteo);
                    font-family: $font-family-title;
                    font-size: 36px;
                    font-weight: 700;
                    line-height: 36px;
                    @include xs {
                        font-size: 30px; 
                    }
                }

                .sencrop-product-price-note {
                    font-family: $font-family-title;
                    font-size: 13px;
                    line-height: 19px;
                    margin-bottom: 10px;
                }

                .r-btn--primary {
                    background: color(meteo);
                    border: 1px solid color(meteo);
                }

            }
            .sencrop-logo {
                grid-area: logo;
                padding-left: space(0.5);
                img {
                    max-width: 110px;
                }
            }


        }

        
    }


    // sencrop sensor detail page
    .r-meteo-sencrop-detail {
        r-main {
            @include lg {
                grid-template-areas: 
                "mp-top          mp-top          mp-top         mp-top     " 
                "mp-mid          mp-mid          mp-mid         mp-mid     " 
                "mp-bottom       mp-bottom       mp-bottom      mp-bottom  " 
                "event           event           event          event    " 
                "report-meteo    report-meteo    report-meteo   report-meteo"
                "meteo-stats     meteo-stats     meteo-stats    selligent"
                "meteo-stats     meteo-stats     meteo-stats    imu-top"
                "meteo-stats     meteo-stats     meteo-stats    ."
                "meteo-stats-nav meteo-stats-nav meteo-stats-nav ."; 
                //grid-template-columns: minmax(0, 300px + space(2)) minmax(0, 300px + space(2)) minmax(0, 300px + space(2)) 300px + space(2);
                margin-top: 0;
            }
        
            @include md {
                grid-template-areas:
                    "event           event          event        "
                    "report-meteo    report-meteo   report-meteo"
                    "meteo-stats     meteo-stats    selligent"
                    "meteo-stats     meteo-stats    imu-top"
                    "meteo-stats     meteo-stats    ."
                    ".    meteo-stats-nav ."
                    "mp-mid          mp-mid         mp-mid       "
                    "mp-bottom       mp-bottom      mp-bottom    ";
                grid-template-columns: minmax(0, 300px + space(2)) minmax(0, 300px + space(2)) 300px + space(2);
                margin: 0 auto 16px auto;
            }

            @include sm {
                grid-template-areas:
                    "event              event     "
                    "report-meteo       report-meteo"
                    "mp-top             mp-top    "
                    "meteo-stats        meteo-stats"
                    "selligent          selligent"
                    "imu-top imu-top"
                    "meteo-stats-nav    meteo-stats-nav"
                    ".                  ."
                    "mp-mid             mp-mid"
                    "mp-bottom          mp-bottom";
                grid-template-columns: minmax(0, 300px + space(2)) 300px + space(2);
                margin: 0 auto 16px auto;
            }
        }

        &.-is-paywall {
            r-main {
                @include lg {
                    grid-template-areas: 
                    "event           event           event          event    " 
                    "mp-top          mp-top          mp-top          mp-top      " "mp-mid          mp-mid          mp-mid          mp-mid      " "mp-bottom       mp-bottom       mp-bottom       mp-bottom   "
                    "report-meteo    report-meteo    report-meteo   report-meteo"
                    ". paywall paywall ."
                    ". meteo-stats-nav meteo-stats-nav ."
                    ". . . .";
                    margin-top: 0;
                }
            
                @include md {
                    grid-template-areas:
                        "report-meteo    report-meteo   report-meteo"
                        "paywall paywall paywall"
                        ". meteo-stats-nav ."
                        ". . .";
                    grid-template-columns: minmax(0, 300px + space(2)) minmax(0, 300px + space(2)) 300px + space(2);
                    margin: 0 auto 16px auto;
                }
        
                @include sm {
                    grid-template-areas:
                        "report-meteo       report-meteo"
                        "paywall paywall"
                        "meteo-stats-nav    meteo-stats-nav"
                        ". .";
                    grid-template-columns: minmax(0, 300px + space(2)) 300px + space(2);
                    margin: 0 auto 16px auto;
                }

                .r-panel--paywall {
                    grid-area: paywall;
                    margin-top: -200px;
                    z-index: 100;
                    &:before {
                        top: -50px;
                        height:50px;
                    }
                    @include lg {
                        margin-top: -200px;
                    }
                    @include md {
                        margin-top: -50px;
                    }
                    @include sm {
                        margin-top: -50px;
                    }
                }
            }

        }
        
    }
    
    .r-report-meteo {
        background-color: color(meteo, light);
        display: block;
        grid-area: report-meteo;
        margin-left: calc(-50vw + 50%);
        width: 100vw;
        z-index: 10;

        i-panel {
            display: block;
            margin: 0;

            @include lg {
                margin: auto maxi(7.8vw, calc(50vw - #{$content-max-width / 2}));
            }

            @include md {
                max-width: 900px + space(6);
                margin: auto maxi(space(4), calc(50vw - #{450px + space(3)}));
            }

            @include sm {
                max-width: 600px + space(6);
                margin: auto;
            }

            h1 {
                color: color(meteo, base);
                font-size: 24px;
                margin-bottom: 0;
                @include xs {
                    font-size: 18px;
                }
            }

            h2 {
                font-size: 32px;
                margin-bottom: 26px;
                margin-top: 0;
                @include xs {
                    font-size: 24px;
                }
            }

        }

        .r-menu--breadcrumb {
            margin: 26px 0;

            @include xs {
                padding-top: 26px;
            }
        }

    }
    
    .r-meteo--chart {
        background-color: white;
        border-radius: 10px;
        margin-bottom: 20px;
        padding: 10px 0;
        position: relative;
    }
    #meteoChart {
        min-height: 415px;
        background: url('/sites/all/themes/enacarbon_sb/images/tail-spin.svg') no-repeat center 200px;
        .apexcharts-canvas {
            background-color: white; 
        }
    }

    ul.r-meteo--chart--metric {
        list-style-type: none;
        padding-left: 0;
        margin: 0 0 8px 0;

        li {
            color: color(gray, dark);
            cursor: pointer;
            display: inline-block;
            font-size: 16px;
            font-family: $font-family-title;
            font-weight: 600;
            padding: 0 10px 5px 10px;

            @include sm {
                font-size: 16px;
                padding: 0 5px 5px 5px;
            }
            @include xs {
                font-size: 16px;
                padding: 0 5px 5px 5px;
            }

            &::before {
                font-family: $font-family-icons;
                font-size: 23px;
                padding-right: 5px;
                position: relative;
                top: 5px;

                @include sm {
                    font-size: 20px;
                }

                @include xs {
                    font-size: 20px;
                }
            } 

            &.disabled {
                text-decoration: line-through !important;
                &:hover {
                    color: color(gray, dark) !important;
                }
            }
            &.active {
                &::after {
                    background: color(gray, dark);
                    border-radius: 20px;
                    color: white;
                    content: $icon-cancel;
                    font-family: $font-family-icons;
                    font-size: 6px;
                    margin-left: 3px;
                    padding: 4px;
                    position: relative;
                    top: -1px;
                }
                
                &:hover {
                    &::after {
                        background: color(black);
                    }
                }
            }

            &.metric--rain {
                &.active, &:hover {
                    color: color(meteo, base);
                }
                &::before {
                    content: $icon-rain-o_sencrop-icon;
                }
            }
            
            &.metric--relative_humidity {
                &.active, &:hover {
                    color: color(darkblue);
                }
                &::before {
                    content: $icon-hygro-o_sencrop-icon;
                }
            }
    
            &.metric--temperature {
                &.active, &:hover {
                    color: color(primary, base);
                }
                &::before {
                    content: $icon-temp-o_sencrop-icon
                }
            }
    
            &.metric--wet_temperature {
                &.active, &:hover {
                    color: color(primary, light);
                }
                &::before {
                    content: $icon-temp-hum-o_sencrop-icon;
                }
            }
    
            &.metric--dew_point {
                &.active, &:hover {
                    color: color(red);
                }
                &::before {
                    content: $icon-dew-point-o_sencrop-icon;
                }
            }

            &.metric--wind_speed {
                &.active, &:hover {
                    color: color(primary, base);
                }
                &::before {
                    content: $icon-wind_sencrop-icon;
                }
            }

            &.metric--wind_gust {
                &.active, &:hover {
                    color: color(darkblue);
                }
                &::before {
                    content: $icon-wind-gust_sencrop-icon;
                }
            }

        }
    }

    ul.r-meteo--chart--period {
        display: inline-block;
        list-style-type: none;
        margin: 0 10px;
        padding-left: 0;
        @include xs {
            display: block;
        }

        li {
            color: color(black);
            cursor: pointer;
            background-color: color(meteo, light);
            border-radius: 20px;
            display: inline-block;
            font-family: $font-family-base;
            font-size: 12px;
            font-weight: normal;
            margin: 0 0 5px 0;
            padding: 5px 20px;

            &.active, &:hover {
                color: white;
                background-color: color(meteo, base);
            }
            &:first-child {
                font-weight: bold;
            }

            &.cta {
                color: color(meteo, base);
                background-color: white;
                border: 2px solid color(meteo, base);
                font-weight: bold;
                padding: 3px 18px;

                &:after {
                    font-size: 8px;
                    padding-left: 5px;
                    content: $icon-arrow-right;
                    font-family: $font-family-icons;
                }
                &:hover {
                    color: color(meteo, base);
                    background-color: white;
                }
                a {
                    color: color(meteo, base);
                    background-color: white;
                &:hover {
                    color: color(meteo, base);
                    background-color: white;
                    text-decoration: underline;
                }
                }
            }
        }
    }

    ul.r-meteo--chart--res {
        display: inline-block;
        list-style-type: none;
        margin: 0 10px;
        padding-left: 0;
        border-radius: 20px;
        overflow: hidden;
        position: absolute;
        right: 0px;
        
        @include xs {
            position: initial;
        }

        li {
            background-color: color(meteo, light);
            color: color(black);
            cursor: pointer;
            display: inline-block;
            font-family: $font-family-base;
            font-size: 12px;
            font-weight: normal;
            padding: 5px 12px;

            &.active, &:hover {
                color: white;
                background-color: color(meteo, base);
            }

        }
    }

    .r-meteo--chart-paywall {
        img {
            width: 100%;
        }
    }
}

@include scope(('meteo', '!critical')) {
    // meteo homepage 
    .r-selligent--imu {
        &.r-selligent--imu--meteo {
            .r-selligent--body {
                background-color: color(meteo, light);
            }
            .r-selligent--title {
                r-icon {
                    color: color(meteo, base);
                }
            }
            .r-btn--secondary {
                &:hover {
                    background-color: color(meteo, base);
                }
            }
        }

        &.r-selligent--imu--meteo-clear {
            .r-selligent--body {
                background-color: white;
            }
            .r-selligent--title {
                r-icon {
                    color: color(meteo, base);
                }
            }
            .r-btn--secondary {
                &:hover {
                    background-color: color(meteo, base);
                }
            }
        }
    }

    .r-meteo-map--report {
        .leaflet-pane {
            z-index: 0;
        }
        .leaflet-control-container {
            * {
                z-index: 0;
            }
        }
        .leaflet-control-attribution a, .leaflet-control-attribution span {
            display:none;
        }
        .leaflet-div-icon {
            background: none;
            border: none; 
        }
    }
    .r-meteo-map--cluster-icon {
        position: relative;
        width: 45px;
        .cluster-icon_count {
            background-color: white;
            border-radius: 20px;
            color: $sb-gray-dark;
            font-weight: bold;
            height: 20px;
            line-height: 20px;
            position: absolute;
            right: 0;
            text-align: center;
            top: 0;
            width: 20px;
        }
    }

    .leaflet-container {
        .r-meteo-map--popup {
            color: color(black);
            font-family: $font-family-title;
            font-size: 13px;

            .leaflet-popup-content {
                @include xs {
                    margin: 7px 10px 3px 10px;
                }
            }

            h5 {
                border-bottom: 1px solid color(black);
                font-size: 18px;
                margin-left: -21px;
                margin-right: -25px;
                padding-left: 20px;
                padding-bottom: 5px;
                @include xs {
                    font-size: 1rem;
                    line-height: 1rem;
                    margin-left: -13px;
                    margin-right: -13px;
                    padding-bottom: 8px;
                }
            }

            a.leaflet-popup-close-button {
                position: absolute;
                top: 5px;
                right: 7px;
                width: 30px;
                height: 30px;
                font: 30px/30px Tahoma, Verdana, sans-serif;
                color: #9F9F9F;
                @include xs {
                    top: -1px;
                }
            }

            .r-meteo-map--popup-metrics {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(2, 1fr);
                grid-column-gap: 0px;
                grid-row-gap: 10px;
            }

            .pm-title {
                color: color(meteo, base);
                font-size: 16px;
                font-weight: 600;
                height: 36px;

                @include xs {
                    font-size: 13px;
                    height: 25px;
                    padding-right: 2px;
                }

                &.metric-icon {
                    &::before {
                        font-family: $font-family-icons;
                        font-size: 23px;
                        padding-right: 5px;
                        position: relative;
                        top: 5px;

                        @include xs {
                            font-size: 16px;
                            padding-right: 2px;
                            top: 3px;
                        }
                    }
                }

                &.metric--rain {
                    &::before {
                        content: $icon-rain-o_sencrop-icon;
                    }
                }
                
                &.metric--relative_humidity {
                    &::before {
                        content: $icon-hygro-o_sencrop-icon;
                    }
                }
        
                &.metric--temperature {
                    &::before {
                        content: $icon-temp-o_sencrop-icon
                    }
                }
        
                &.metric--wet_temperature {
                    &::before {
                        content: $icon-temp-hum-o_sencrop-icon;
                    }
                }
        
                &.metric--dew_point {
                    &::before {
                        content: $icon-dew-point-o_sencrop-icon;
                    }
                }

                &.metric--wind_speed {
                    &::before {
                        content: $icon-wind_sencrop-icon;
                    }
                }

                &.metric--wind_gust {
                    &::before {
                        content: $icon-wind-gust_sencrop-icon;
                    }
                }
            } 

            .pm-value {
                font-size: 24px;
                font-weight: 600;
                @include xs {
                    font-size: 20px;
                }
                span {
                    font-weight: normal;
                }
            }

            .pm-details {
                @include xs {
                    font-size: 11px;
                    line-height: 1;
                }
            }

            .r-meteo-map--popup-cta-wrap {
                text-align: center;
            }
            .r-meteo-map--popup-cta {
                background-color: color(meteo, base);
                color: white;
                font-size: 13px;
                font-family: $font-family-base;
                margin: 25px auto;
                &:hover {
                    background-color: color(black);
                }
                &:before {
                    font-family: $font-family-icons;
                    font-size: 20px;
                    content: $icon-chart;
                    padding-right: 5px;
                }
                @include xs {
                    margin: 13px auto 5px auto;
                }
            }

            .r-meteo-map--popup-update {
                @include xs {
                    font-size: 11px;
                }

            }

            .r-meteo-map--popup-promo {
                text-align: center;
                @include xs {
                    font-size: 11px;
                }

                a.r-btn {
                    color: white;
                    margin-top: 5px;

                    r-icon {
                        font-size: 10px;
                        padding-left: 5px;
                        margin-right: 0;
                    }
                }
                a.r-btn--secondary:hover {
                    background-color: color(meteo, base);
                }
            }

        }
    }

    // meteo sencrop detail
    .r-meteo--chart--options {
        padding: 0 0 16px 0;
        
        .r-btn {
            border: none;
            font-size: 13px;
            margin-left: 16px;

            &.options-map {
                background-color: color(meteo, base);
                &::before {
                    content: $icon-arrow-left;
                    font-family: $font-family-icons;
                    padding-right: 5px;
                }
            }
            &.options-cta {
                background-color: white;
                color: color(meteo, base);

                &:hover {
                    text-decoration: underline;
                }

                &::after {
                    content: $icon-arrow-right;
                    font-family: $font-family-icons;
                    padding-left: 5px;
                }
            }
        }
    }

    .r-meteo--stats {
        grid-area: meteo-stats;
        margin: 0 16px;

        h3 {
            font-family: $font-family-title;
            font-weight: normal;
            font-size: 17px;
            margin-top: 40px;
            .hl {
                font-weight: bold;
            }
        }
    
        .r-meteo--stats-wrap {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 0px;
            grid-row-gap: 0px;
        }
        .r-ms {
            border-bottom: 1px solid color(black);
            margin-right: 10px;
            padding: 15px 35px 20px 0;
            &:first-child, &:nth-child(2) {
                border-top: 1px solid color(black);
            }
        }

        .r-ms-metric {
            font-size: 16px;
            font-family: $font-family-title;
            font-weight: 600;
            height: 30px;
            margin-bottom: 10px;

            &:before {
                font-family: $font-family-icons;
                font-size: 23px;
                padding-right: 5px;
                position: relative;
                top: 5px;
            }

            &.metric--rain {
                color: color(meteo, base);
                &::before {
                    content: $icon-rain-o_sencrop-icon;
                }
            }
            
            &.metric--relative_humidity {
                color: color(darkblue);
                &::before {
                    content: $icon-hygro-o_sencrop-icon;
                }
            }
    
            &.metric--temperature {
                color: color(primary, base);
                &::before {
                    content: $icon-temp-o_sencrop-icon
                }
            }
    
            &.metric--wet_temperature {
                color: color(primary, light);
                &::before {
                    content: $icon-temp-hum-o_sencrop-icon;
                }
            }
    
            &.metric--dew_point {
                color: color(red);
                &::before {
                    content: $icon-dew-point-o_sencrop-icon;
                }
            }

            &.metric--wind_speed {
                color: color(primary, base);
                &::before {
                    content: $icon-wind_sencrop-icon;
                }
            }

            &.metric--wind_gust {
                color: color(darkblue);
                &::before {
                    content: $icon-wind-gust_sencrop-icon;
                }
            }
        }
        .r-ms-name {
            font-family: $font-family-title;
            font-weight: 600;
            font-size: 13px;
            text-transform: uppercase;
        }

        .r-ms-period {
            font-family: $font-family-title;
            font-weight: normal;
            font-size: 13px;
            text-transform: uppercase;
            color: $sb-gray-dark;
        }

        .r-ms-value {
            font-family: $font-family-title;
            font-weight: 600;
            font-size: 24px;
        }

        .r-ms-unit {
            font-family: $font-family-title;
            font-weight: normal;
            font-size: 13px;
        }

    }
}

/*
// leaflet marker cluster styling
.marker-cluster-small {
	background-color: rgba(181, 226, 140, 0.6);
	}
.marker-cluster-small div {
	background-color: rgba(110, 204, 57, 0.6);
	}

.marker-cluster-medium {
	background-color: rgba(241, 211, 87, 0.6);
	}
.marker-cluster-medium div {
	background-color: rgba(240, 194, 12, 0.6);
	}

.marker-cluster-large {
	background-color: rgba(253, 156, 115, 0.6);
	}
.marker-cluster-large div {
	background-color: rgba(241, 128, 23, 0.6);
	}*/

	/* IE 6-8 fallback colors */
/*.leaflet-oldie .marker-cluster-small {
	background-color: rgb(181, 226, 140);
	}
.leaflet-oldie .marker-cluster-small div {
	background-color: rgb(110, 204, 57);
	}

.leaflet-oldie .marker-cluster-medium {
	background-color: rgb(241, 211, 87);
	}
.leaflet-oldie .marker-cluster-medium div {
	background-color: rgb(240, 194, 12);
	}

.leaflet-oldie .marker-cluster-large {
	background-color: rgb(253, 156, 115);
	}
.leaflet-oldie .marker-cluster-large div {
	background-color: rgb(241, 128, 23);
}
*/
.marker-cluster {
	background-clip: padding-box;
	border-radius: 20px;
	}
.marker-cluster div {
	width: 30px;
	height: 30px;
	margin-left: 5px;
	margin-top: 5px;

	text-align: center;
	border-radius: 15px;
	font: 12px $font-family-base;
	}
.marker-cluster span {
	line-height: 30px;
	}


    .leaflet-cluster-anim .leaflet-marker-icon, .leaflet-cluster-anim .leaflet-marker-shadow {
        -webkit-transition: -webkit-transform 0.3s ease-out, opacity 0.3s ease-in;
        -moz-transition: -moz-transform 0.3s ease-out, opacity 0.3s ease-in;
        -o-transition: -o-transform 0.3s ease-out, opacity 0.3s ease-in;
        transition: transform 0.3s ease-out, opacity 0.3s ease-in;
    }
    
    .leaflet-cluster-spider-leg { 
        /* stroke-dashoffset (duration and function) should match with leaflet-marker-icon transform in order to track it exactly */
       -webkit-transition: -webkit-stroke-dashoffset 0.3s ease-out, -webkit-stroke-opacity 0.3s ease-in;
        -moz-transition: -moz-stroke-dashoffset 0.3s ease-out, -moz-stroke-opacity 0.3s ease-in;
        -o-transition: -o-stroke-dashoffset 0.3s ease-out, -o-stroke-opacity 0.3s ease-in;
        transition: stroke-dashoffset 0.3s ease-out, stroke-opacity 0.3s ease-in;
    }

@include scope('!critical') {
r-modal--backdrop {
    background-color: rgba(color(dark), .4);
    z-index: z-index(high);
}

r-modal {
    background: none;
    z-index: z-index(high) + 1;

    &.-is-toggle {
        display: flex;
    }
}

r-modal--content {
    background: color(light);
    box-shadow: 0 5px 20px rgba(color(dark), .15);
    border-radius: 5px;
    max-width: 800px;
    padding: space();
    width: 90%;
    z-index: z-index(high);

    @include smmdlg {
        padding: space(2) space(3);
    }

    iframe {
        border: none;
    }
}

.r-modal--close {
    font-style: normal;
    line-height: 32px;
    opacity: 1;
    position: absolute;
    right: space(.5);
    top: space(.5);

    &::after {
        display: none;
    }

    &:hover {
        opacity: .5;
    }
}

.r-modal--limit-connect {
    r-modal--close {
        color: color(light);
    }

    .r-modal--header {
        background: color(primary);
        color: color(light);
        margin: space(-1) space(-1) space();
        padding: space() space(2);
        text-align: center;

        @include smmdlg {
            margin: space(-2) space(-3) space(2);
            padding: space() space(3);
        }
    }

    .r-modal--title {
        font-size: font-size(h3);
        margin: 0;

        @include smmdlg {
            font-size: font-size(h2);
        }
    }

    strong,
    p a {
        color: color(primary);
    }

    p a {
        font-weight: bold;
    }

    .r-btn--primary {
        font-size: 1.2rem;
    }
    .r-links {
        border-top: 1px solid color(gray, light);
        margin-top: space();
        padding-top: space();

        p {
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    } 
}

.r-modal--macommune {
    color: color(dark);

    .r-modal--title {
        display: flex;
        flex-direction: column;
        font-weight: 700;
        margin-bottom: space(2);
        text-align: center;

        @include xs {
            font-size: 1.3rem;
            line-height: 1.2;
            margin: 0 space(.5) space(1.5);
        }
    }

    .r-icon--location {
        color: color(primary);
        font-size: 45px;
    }

    .r-modal--body {
        display: grid;
        grid-gap: space();
        grid-template-areas: '. . ';
        grid-template-columns: repeat(2, 1fr);

        @include xs {
            max-height: calc(100vh - 350px);
            overflow-y: auto;
        }

        &.-is-connecte {
            @include smmdlg {
                grid-column-gap: space(1.5);
                grid-template-areas: '. . .';
                grid-template-columns: repeat(3, calc((100% - #{space(3)}) / 3));
            }
        }

        &.-is-anonyme {
            justify-content: center;

            @include smmdlg {
                display: flex;
            }
        }

        a:not( .r-btn ) {
            color: color(dark);
            flex: 0 0 50%;
            text-transform: uppercase;

            &:hover {
                color: color(primary);
                text-decoration: none;
            }
        }

        .r-btn {
            @include xs {
                grid-column: span 2;
                width: fit-content;
                margin: auto;
            }
        }
    }

    // form {
    //     text-align: center;

    //     &::after {
    //         @include xs {
    //             content: "";
    //             box-shadow: 0px -6px 6px rgba(color(dark), .25);
    //             height: 32px;
    //             width: 100%;
    //             position: absolute;
    //             display: block;
    //             z-index: 100;
    //             bottom: 100px;
    //             left: 0;
    //         }
    //     }

    //     div {
    //         align-items: center;
    //         display: flex;
    //         margin-bottom: space(0.5);

    //         &:hover {
    //             color: color(primary);
    //         }
    //     }
    // }

    // fieldset {
    //     border: none;
    // }

    // label {
    //     cursor: pointer;
    //     margin-left: space(.5);
    //     text-transform: uppercase;

    //     @include xs {
    //         font-size: 0.9rem;
    //     }
    // }

    // input {
    //     cursor: pointer;
    // }

    // input:checked + label {
    //     color: color(primary);
    // }

    // button {
    //     font-weight: 700;
    //     grid-column: 2;
    //     justify-self: center;
    //     margin: space() 0 space(1.5);

    //     @include xs {
    //         margin-top: space(1.5);
    //     }
    // }

    .r-modal--footer {
        display: flex;
        justify-content: space-evenly;

        @include xs {
            font-size: .8rem;
        }

        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
}
}

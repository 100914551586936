@include scope('critical') {
.r-panel--macommune,
.r-menu--dropdown{
    grid-column: 1;
    visibility: hidden;
}
}// scope

@include scope('!critical') {
.r-panel--info-commune {
    //background-image: linear-gradient(to bottom, transparent 200px, color(primary, .2) 200px, color(dark, .5)), url('#{$domain}/sites/all/themes/enacarbon_vdn/images/communes.jpg');
    background: 0 / cover color(primary);
    grid-column-start: 1;
    grid-column-end: -1;
    margin-top: 0;
    padding: space(1.5) space();

    @include smmdlg {
        padding: 60px 60px 40px;
    }

    &.lazyloaded {
        @include smmdlg {
            background-image: url('#{$domain}/sites/all/themes/enacarbon_vdn/images/communes.jpg');
        }
    }

    h2 {
        align-items: center;
        color: color(light);
        column-gap: space();
        display: flex;
        font-size: font-size(h4);
        line-height: line-height(h1);
        margin: 0;
        padding: space() 0;
        text-align: center;
        text-transform: uppercase;

        @include smmdlg {
            font-size: font-size(h1);
            padding: space(1.5) 0;
        }

        &::before {
            @include smmdlg {
                background: color(light);
                content: '';
                -webkit-flex: 1;
                flex: 1;
                height: 1px;
                margin: 16px;
            }
        }

        &::after {
            background: color(light);
            content: '';
            -webkit-flex: 1;
            flex: 1;
            height: 1px;
            margin: 16px 0;

            @include smmdlg {
                margin: 16px;
            }
        }
    }

    r-menu {
        align-items: center;
        column-gap: space(.75);
        display: flex;
        overflow-x: auto;

        @include smmdlg {
            flex-wrap: wrap;
            justify-content: center;
            margin: auto;
        }

        &::after {
            @include xs {
                background: linear-gradient(to left, color(primary), rgba(color(primary), 0));
                color: color(light);
                content: $icon-arrow-right;
                display: inline-block;
                font-family: 'main';
                padding: space(.5) space(.75) space(.5) space(1.5);
                pointer-events: none;
                position: absolute;
                right: 10px;
            }
        }
    }

    .r-btn {
        flex: 0 0 auto;
        margin: space(.5) 0;
    }

    $form-height: 45px;

    .r-search--autocomplete {
        border: none;
        height: $form-height;
        padding: space(.75) space() space(.75) space(2);
        width: 100%;
    }

    .r-icon--search {
        font-size: 20px;
        height: $form-height;
        line-height: $form-height;
    }

    form {
        display: block;
        margin: space() auto;
        position: relative;
        width: 100%;

        @include smmdlg {
            margin: space() auto space(2);
            max-width: 450px;
        }
    }

    button {
        position: absolute;
        left: 0;
        top: 0;
    }
}

.r-panel--info-commune-immobilier {
    background: 0 / cover color(immo);
    margin-bottom: space(2);
    padding: space();

    @include smmdlg {
        padding: space();
    }

    h2 {
        color: color(dark);
        flex-wrap: wrap;
        justify-content: center;
        margin: auto;
        padding-top: 0;

        &::before {
            display: none;
        }

        &::after {
            display: none;
        }

        @include smmdlg {
            padding-top: 0;
        }
    }

    r-menu {
        &::after {
            @include xs {
                background: linear-gradient(to left, color(immo), rgba(color(immo), 0));
                right: 16px;
            }
        }
    }

    .r-btn--white {
        &:hover {
            background: color(dark);
        }
    }
}
@include scope('mobilite') {
    .r-panel--info-commune-mobilite {
        background: 0 / cover color(sport);

        r-menu {
            flex-wrap: wrap;
            justify-content: center;
            &::after {
                @include xs {
                    content: none;
                }
            }
        }
    }
}

.r-panel--macommune,
.r-menu--dropdown {
    background: color(light);
    box-shadow: 0 1px 1px color(gray, light);
    color: color(dark);
    padding: space();
    position: absolute;
    top: 100%;
    // visibility: hidden;
    width: 100%;

    @include smmdlg {
        padding: space(2);
    }

    .r-panel--link {
        color: color(dark);
    }

    r-panel--header {
        display: block;
        text-transform: none;

        &::after,
        &::before {
            display: none;
        }

        r-icon {
            color: color(primary);
            display: block;
            font-size: font-size(h2);
            margin-bottom: space(.5);
        }
    }

    r-panel--body {
        @include xssm {
            columns: 2;
        }

        @include mdlg {
            display: grid;
            margin: auto;

            @include scope('ard', 'ee') {
                grid-template-columns: 1fr 1fr;
            }
        }
    }

    .r-panel--link {
        display: block;
        margin-bottom: space(.5);
        text-transform: uppercase;
    }

    .r-search--autocomplete {
        border: 0;
        background: color(gray, lighter);
        padding: space(.5) space(.5) space(.5) space(2);
        width: 300px;
        border-radius: 4px;

        @include xssm {
            font-size: font-size(aside);
        }

        &::before {
            content: $icon-search;
        }
    }

    form {
        display: inline-block;
        position: relative;
    }

    .r-search--submit {
        left: space(.5);
        position: absolute;
        top: 10px;
    }
}

.r-panel--macommune {
    r-panel--header {
        margin-bottom: space();
    }

    r-panel--body {
        @include xssm {
            columns: 2;
        }

        @include mdlg {
            grid-template-columns: 1fr 1fr 1fr 1fr;
            max-width: 800px;
        }
    }

    r-panel--footer {
        margin-top: space();
        text-align: center;
    }
}

.r-menu--dropdown {
    r-panel--header {
        margin-bottom: 6px;
    }

    .r-panel--link-title {
        font-size: 20px;
        font-weight: bold;
        margin-top: space(1.5);
    }

    r-panel--body {
        @include mdlg {
            grid-template-columns: 1fr 1fr 1fr;
            max-width: 850px;
            padding-left: 26px;
        }
    }

    r-panel--footer {
        text-align: center;

        a {
            align-items: center;
            display: flex;
            column-gap: space(.5);
            justify-content: center;
        }
    }
}
}// scope

@include scope('critical') {
    .marches--latest {
        
        ul {

            list-style-type: none;
            margin-top: 0;
            padding: 0;
    
            @include xssm { 
                column-count: 1;
            }
    
            li {
                border-top: 1px dashed color(gray,dark);
                display: grid;
                font-size: 14px;
                grid-template-areas:
                'title      title' 
                'product    price';
                grid-template-columns: 4fr 2fr;
                padding-top: space(0.5);
                padding-bottom: space(0.5);
    
                a {
                    color: color(black);
                }
                .latest-head {
                    grid-area: title;
                }
                .latest-product {
                    grid-area: product;
                }
                .latest-value {
                    grid-area: price;
                    text-align: right;
                }
                .latest-market-section {
                    font-weight: bold;
                }
                .units {
                    color: color(gray,dark);
                    font-size: 12px;
                }
    
            }
    
        }
    }

    // .markets-home--latest {

    //     &.marches--latest {

    //     }

    // }
    
}// scope

@include scope('!critical') {

    .panel-markets-une-latest {

        &.marches--latest {
            grid-area: mp-latest;
            padding: space(1);
            h3 {
                font-size: 20px;
            }
            ul {
                column-count: 2;
                column-gap: 20px;
            }
        }

    }

}

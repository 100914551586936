@include scope('!critical') {
.glider-contain {
    margin: 0 auto;
    position: relative;
    width: 100%;
}

.glider {
    margin: 0 auto;
    opacity: 1;
    overflow-y: hidden;
    position: relative;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none; // Fix Firefox Scrollbar
    //transform: translateZ(0); //14169 supprimer pour un problème avec digiteka

    &::-webkit-scrollbar {
        @include xs {
            height: 3px;
            opacity: 1;
            -webkit-appearance: none;
            width: 7px;
        }

        @include smmdlg {
            display: none;
        }
    }

    &::-webkit-scrollbar-thumb {
        @include xs {
            background-color: color(gray, .25);
            border-radius: 99px;
            box-shadow: 0 0 1px color(light, .25);
            opacity: 1;
        }
    }

    &.draggable {
        cursor: -webkit-grab;
        cursor: grab;
        user-select: none;

        .glider-slide {
            @include scope('tendances-co') {
                aspect-ratio: 16/9;
                text-align: center;
            }

            @include scope('article') {
                align-items: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }

        .glider-slide img {
            pointer-events: none;
            user-select: none;

            @include scope('tendances-co') {
                height: 100%;
                width: auto;
            }

            @include scope(('sudinfo', 'article', '!max')) {
                max-height: 445px;
                width: auto;
            }
        }
    }

    &.drag {
        cursor: -webkit-grabbing;
        cursor: grabbing;
    }
}

.glider-track {
    display: flex;
    margin: 0;
    padding: 0;
    //transform: translateZ(0); //14169 supprimer pour un problème avec digiteka
    width: 100%;
    z-index: 1;
}

.glider-slide {
    align-content: center;
    justify-content: center;
    margin: 0 space(.5);
    max-width: none;
    user-select: none;
    width: 100%;

    img {
        max-width: 100%;
    }

    &::-webkit-scrollbar {
        height: 0;
        opacity: 0;
    }
}

.glider-prev,
.glider-next {
    background: none;
    border: 0;
    color: color(gray);
    cursor: pointer;
    // font-size: 40px;
    left: 0;
    line-height: 1;
    opacity: 1;
    outline: none;
    padding: 0;
    position: absolute;
    text-decoration: none;
    top: 50%;
    // transform: translateY(-50%);
    transition: opacity 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67), color 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    user-select: none;
    z-index: 2;

    // &:hover,
    // &:focus {
    //     color: color(primary);
    // }

    &.disabled {
        display: none;
        // cursor: default;
        // opacity: .25;
    }
}

.glider-next {
    left: auto;
    right: 0;
}

.glider-slide {
    min-width: 150px;
}

.glider-hide {
    opacity: 0;
}

.glider-dots {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    padding: 0;
    user-select: none;
}

.glider-dot {
    background: color(gray, light);
    border: 0;
    border-radius: 999px;
    color: color(gray, light);
    cursor: pointer;
    display: block;
    height: 12px;
    margin: 7px;
    outline: none;
    padding: 0;
    user-select: none;
    width: 12px;

    // &:hover,
    // &:focus,
    &.active {
        background: color(primary);
    }
}
}// scope

@include scope('!critical') {
    .r-mini-panel--services,
    .r-mini-panel--tabs {
        @include smmdlg {
            grid-column-start: 1;
            grid-column-end: -1;
            margin-left: calc(-50vw + 50%);
            width: 100vw;
            z-index: z-index();
        }

        @include scope(('!sb','!mag')) {
            background: #F3F8FF;
        }

        @include scope('mag') {
            margin-bottom: space(2);
        }
        
        @include scope('sb') {
            background: color(gray, base);
        }

        i-mini-panel {
            @include sm {
                max-width: none;
            }

            @include mdlg {
                @include scope('!special') {
                    margin-top: space();
                }
            }

            > * {
                @include smmdlg {
                    background: none;
                }
            }
        }

        r-panel {
            @include sm {
                margin: 0;
            }

            &:first-child {
                r-panel--header {
                    border: 0;
                }
            }
        }

        .r-panel--horizontal {
            &:nth-child( odd ) {
            }

            r-panel--header {
                @include mdlg {
                    border-top: 1px solid color(primary);
                }
            }
        }
    }

    .r-mini-panel--tabs {
        @include smmdlg {
            padding-bottom: space(3);
        }
    }

    #panel-test {
        background: color(light);
    }

    .r-mini-panel--services {
        margin-top: space(2);

        r-panel--header {

            @include scope('!mag') {
                padding-top: space(2);
            }

            &::before,
            &::after {
                display: none;
            }

            @include xs {
                margin-bottom: space();
            }
        }

        @include scope('special') {
            background: lighten(color(primary), 60%);
            figure {
                border-radius: space(2);
            }
            .r-panel--title {
                font-size: font-size(h6);
            }
        }
    
        @include scope('lereo') {
            background: lighten(color(primary), 40%);
        }
    
        @include scope('cipiz') {
            background: lighten(color(primary), 30%);
        }
    
        @include scope('lifestyle') {
            background: lighten(color(primary), 80%);
        }

        @include scope('tanews') {
            background: lighten(color(primary), 60%);
        }

        @include scope('sb') {

            r-panel--header,
            .r-mini-panel--title {
                align-items: center;
                display: flex;
                font-weight: font-weight(base);
                line-height: line-height(h1);
                margin: 0;
                padding: space(1.5) 0;

                &::after, &::before {
                    background: black;
                    content: '';
                    display: block;
                    flex: 1;
                    height: 1px;
                }

                &::after {
                    margin: space() 0 space() space();
                     padding: 0 space();
    
                    @include xs {
                        padding-right: 0;
                    }
                }
        
                &::before {
                    margin: space() space() space() 0;
                    padding: 0 space();
            
                    @include xs {
                        padding-left: 0;
                    }
                }
            }

            .r-panel--item,
            .r-viewmode--niveau-2 {
                .r-panel--title {
                    font-weight:font-weight(base);
                    font-size: font-size(h5);
                }
                .r-panel--link {
                    display: inline;
                }
                .r-article--link {
                    display: inline;
                    font-weight:font-weight(base);
                    font-size: font-size(h5);
                }
            }

            r-panel--meta, 
            r-article--meta {
                display: block;
                margin-top: space(.5); 
                text-transform: uppercase;
                a {
                    color: black;
                    font-weight: font-weight(base);
                }
            }
        }
    }

    
    .r-mini-panel--service {
        i-mini-panel {
            @include smmdlg {
                background: color(light);
                grid-template-areas: '. .';
                padding-top: space(2);
            }

            @include lg {
                grid-template-columns: 75% 25%;
            }

            @include wallpaper(md) {
                grid-template-columns: 66.66% 33.33%;
            }

            @include sm {
                display: block;
            }
        }

        r-panel {
            margin: 0;
        }

        .r-panel--horizontal {
            @include xssm {
                @include scope('!mag') {
                    padding: space();
                }
            }

            r-panel--header {
                border: 0;
                padding: 0;
            }
        }
    }
    @include scope('mag') {
        .r-selligent--pave-newsletter {
            background: linear-gradient(179.97deg, #F7F7F7 0.02%, #E6E6E6 122.95%);

            body:not(.page-node) & {
                grid-column: 1/-1;
                margin-left: calc(-50vw + 50%);
                width: 100vw;
            }

            i-mini-panel {
                padding: space(2) space() space(3);
                text-align: center;

                @include mdlg {
                    body:not(.page-node) & {
                        align-items: center;
                        display: grid;
                        grid-template-areas: ". .";
                    }

                    .page-node & {
                        margin: 0;
                    }
                }

                img {
                    justify-self: end;
                }
            }

            .r-newsletter-text {
                @include smmdlg {
                    margin: space(2);
                    width: 345px;

                    .page-node & {
                        margin: space(2) auto;

                        h4 {
                            margin-left: 0;
                            padding-left: 0;
                        }
                    }
                }

                .r-btn {
                    margin-top: space();
                    width: 100%;
                }
            }
        }
    }
}// scope

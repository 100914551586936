@include scope('critical') {
.r-viewmode--o-galerie,
.r-viewmode--galerie {
    @include smmdlg {
        grid-column: span 2;
    }

    @include wallpaper {
        grid-column: auto;
    }

    r-article--chapo {
        @include xs {
            display: block;
        }
    }

    .r-article--title {
        @include smmdlg {
            font-size: font-size(h2);
        }
    }

    address {
        color: color(dark);
        font-weight: 700;
    }
}

.r-viewmode--o-photo,
.r-viewmode--photo {
    @include smmdlg {
        grid-column: span 4;
    }

    @include wallpaper {
        grid-column: span 3;
    }

    r-article--chapo {
        @include xs {
            display: block;
        }
    }
}

.r-viewmode--o-photo-mp,
.r-viewmode--photo-mp {
    r-article--chapo {
        @include xs {
            display: block;
        }
    }
}

.r-viewmode--galerie-number {
    display: inline-block;
    margin: space(.75) 0;
    text-transform: uppercase;
}

    .r-corner--author {
        color: color(gray,dark);
    }
}// scope

@include scope('!critical') {
    @include scope('main') {
        .r-viewmode--entreprise {
            border: 1px solid color(emploi);
            border-radius: space();
            margin: space();
            padding: space() space(2);

            .r-entreprise-heading {
                border-radius: space(.75);
                margin: 0 space(-1);
                position: relative;
                z-index: 10;
            }

            .r-logo--entreprise {
                background: color(light);
                border: 2px solid color(light);
                border-radius: space(.5);
                height: 90px;
                margin-top: -45px;
                position: relative;
                width: 90px;
                z-index: 15;

                img {
                    max-height: 100%;
                    max-width: 100%;
                }
            }

            h2 {
                color: color(emploi);

                a {
                    color: color(emploi); 
                }
            }

            p {
                color: color(gray);
                font-size: font-size(h6);
                margin: 0;

                r-icon {
                    color: color(emploi);
                    font-size: font-size(base);
                    vertical-align: middle;
                    text-align: center;
                    width: 20px;
                }

                a {
                    color: color(emploi);
                    font-weight: font-weight(h1);
                }
            }

            r-btn {
                font-weight: font-weight(h1);
                margin-top: space(.75);

                r-icon {
                    font-size: font-size(small);
                    margin-left: space(.5);
                    vertical-align: middle;
                }
            }

            .r-btn--primary {
                background: color(emploi);
                border: 1px solid color(emploi);
            }
        }

        .r-viewmode--cta {
            align-items: center;
            background: color(emploi, light);
            border-radius: space();
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: space();
            padding: space();
            text-align: center;

            @include xs {
                padding: space(2) space();
            } 

            h2 {
                color: color(emploi);
                margin: 0;
            }

            p {
                font-size: font-size(h6);
                margin: space() space() space(1.5);
            }

            .r-btn {
                font-size: font-size(article-xs);
            }

            .r-btn--primary {
                background: color(emploi);
                border: 1px solid color(emploi);
            }
        }

        .r-viewmode--agenda {
            h2 {
                line-height: line-height(h1);

                a {
                    color: color(gray, darker);

                    strong {
                        color: color(primary);
                        display: block;
                    }
                }
            }
        }

        .r-viewmode--image-title {
            grid-column: 1 / -1;
            position: relative;

            @include xs {
                margin: 0 0 space(.75);
            }

            i-article {
                @include smmdlg {
                    background: color(light);
                    border-left: 2px solid color(primary);
                    padding: space() 0 space() space();
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 40%;
                }
            }

            .r-article--img {
                @include mdlg {
                    width: 70%;
                }
            }

            .r-article--title {
                @include mdlg {
                    font-size: font-size(h2);
                }
            }
        }
    }

    .r-viewmode--more {
        display: contents;
    }

    > article,
    > r-viewmode {
        .r-viewmode--more & {
            .viewmode-horizon-more1 &:nth-of-type(1n+21) {
                display: none;
            }
            .viewmode-horizon-more2 &:nth-of-type(1n+41) {
                display: none;
            }
        }
    }
}

@include scope('une', 'special') {
@include scope('!critical') {
.r-viewmode--o-dossier,
.r-viewmode--dossier {
    @include xs {
        background: color(primary, lighter);
        border-radius: 20px;
        margin: 0 0 space(.75);
        padding: space();
    }

    article {
        @include xs {
            grid-template-areas: '.';
            grid-template-columns: 1fr;
        }
    }

    .r-article--title {
        position: relative;
        margin-top: 0;

        @include xs {
            font-size: font-size(h4);
            font-weight: bold;
        }

        @include smmdlg {
            background: color(light);
            font-size: font-size(h2);
            margin-right: 25%;
            margin-top: -80px;
            padding: space(.5);

            @include scope('sb') {
                font-weight: font-weight(h1);
                padding: space();
            }
        }
    }

    .r-alerte {
        @include xs {
            background: color(primary, lighter);
            display: inline-block;
            left: 50%;
            padding: space(.25) space();
            position: absolute;
            transform: translate(-50%, -100%);
            top: 0;
        }

        &::after {
            @include xs {
                display: none;
            }
        }
    }

    .r-article--list,
    r-article--chapo,
    r-article--meta {
        @include smmdlg {
            padding-left: space(.5);
            
            @include scope('sb') {
                padding-left: space();
            }
        }
    }

    @include scope('sb') {
        r-article--chapo {
            margin-top: space(.5);
        }
    }

    .r-aside--title {
        @include smmdlg {
            font-size: font-size(h6);
        }
    }
}

.r-viewmode--o-dossier {
    @include smmdlg {
        grid-area: dossier;
    }
}

.r-viewmode--horizontal,
.r-viewmode--o-horizontal {
    grid-column: span 3;
    grid-column-end: -1;

    @include scope('special') {
        grid-column: span 2 / -1;
    }

    .r-page--shopping.arche & {
        grid-column: span 2 / -1;
    }

    @include smmd {
        grid-column: span 2 / -1;
    }

    article {
        column-gap: space();
        display: grid;
        grid-template-areas: 'titre img'
        'chapo img'
        'meta img';
        grid-template-columns: 70% 30%;
        grid-template-rows: auto auto 1fr;

        @include xs {
            column-gap: space(.75);
        }
    }

    .r-article--img {
        aspect-ratio: 16/9;
        grid-area: img;
    }

    figure {
        margin-bottom: 0;
    }

    .r-article--meta {
        grid-area: meta;
    }

    .r-article--title {
        grid-area: titre;

        @include smmdlg {
            font-size: 1.8rem;
        }

        @include scope('special') {
            font-size: font-size(small);

            @include smmdlg {
                font-size: font-size(h3);
            }
        }
    }

    r-article--chapo {
        grid-area: chapo;
    }
}
@include scope('sb') {
.r-viewmode--horizontal,
.r-viewmode--o-horizontal {
    .r-viewmode--article {
        grid-template-columns: 70% 28%;
        .r-article--img {
            max-width: 315px;
            justify-self: stretch;

            @include md {
                aspect-ratio: auto;
                &::after {
                    top: 8px;
                }
            }

            @include xssm {
                aspect-ratio: auto;
                &::after {
                    top: 8px;
                }
            }
        }

        .r-article--title {
            margin-top:0;
        }
    }
}
}

.r-viewmode--agenda {
    article {
        background-color: #F8F8F8;
        border-radius: 6px;
        display: grid;
        grid-template-rows: min-content auto auto;
        height: 100%;
        overflow: hidden;
    }

    address {
        color: color(primary);
        font-weight: 700;
        grid-area: location;

        r-icon {
            margin-right: space(.5);
        }
    }

    time {
        color: color(gray);
        grid-area: date;
    }

    .r-article--title {
        font-size: font-size(h5);
        padding: 0 space(.75) space(.5);
    }

    .r-article--meta-link {
        grid-area: link;
        justify-self: end;
    }

    .r-article--no-picture {
        align-items: center;
        aspect-ratio: 16/9;
        background: color(primary);
        color: color(light);
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 0 space(1.5);
        padding: space(0.75);
        row-gap: space(.5);
        text-align: center;

        &:hover {
            text-decoration: none;
        }
    }

    .r-article--title-no-picture {
        font-size: 1.2rem;
        font-weight: bold;
        text-transform: uppercase;
    }

    r-article--meta {
        align-items: center;
        align-self: end;
        column-gap: space();
        display: grid;
        grid-template-areas:
        "location link"
        "date link";
        padding: 0 space(.75) space(.75);
        row-gap: space(.5);
    }

    .r-icon--fleche {
        border: 1px solid color(dark);
        border-radius: 4px;
        color: color(dark);
        display: inline-block;
        padding: space(.5);
    }

    .r-article--link {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        row-gap: space(.5);
    }
}

}// scope !critical
}// scope une

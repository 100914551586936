//-------------------------------------------------------------------
// ACCOUNT INFO -------------------------------------------------------
//-------------------------------------------------------------------

@include scope('critical') {
.r-menu--user {
    content-visibility: hidden;
    visibility: hidden;
}
}// scope

@include scope('!critical') {
.r-menu--user {
    background: color(light);
    border-radius: 8px;
    box-shadow: 2px 2px 4px color(dark, .2);
    font-family: $font-family-base;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 50px;
    visibility: hidden;
    width: 230px;

    @include smmdlg {
        right: maxi(7.8vw, calc(50vw - 664px));
        top: 80px;
    }

    a {
        color: color(dark);
        display: block;
        padding: space(.5) space(2);

        &:hover {
            background: color(gray, lighter);
            text-decoration: none;
        }

        @include scope('sb') {
            &:hover {
                background: color(gray, light);
                font-weight:font-weight(h1);
            }
        }
    }

    .r-menu--label {
        background: #f3f8ff;
        border-bottom: 0;
        border-radius: 8px 8px 0 0;
        color: color(dark);
        font-family: $font-family-base;
        font-size: font-size(h6);
        font-weight: bold;
        line-height: 1.3;
        margin: 0;
        padding: space();
    }

    span {
        color: color(dark);
        font-size: .9rem;
    }

    strong {
        display: block;
        font-weight: 300;
        line-height: 32px;
    }
}
}// scope

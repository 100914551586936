@include scope('critical') {
.r-btn--tag {
    background: color(gray, lighter);
    border-radius: 30px;
    color: color(dark);
    flex: 0 0 auto;
    font-weight: font-weight(h1);
    padding: 3px 10px;

    @include scope('sb') {
        background: color(gray);
    }
}

.r-btn--more {
    padding: space() 0;
    text-align: center;

    @include scope('mag') {
        grid-column: 1/-1;
    }

    @include smmdlg {
        align-items: center;
        display: flex;
        grid-column-start: 1;
        grid-column-end: -1;
        justify-content: center;
        margin-top: -1px;
        padding: space() 0;
    }

    .-has-bg-light &,
    .-has-bg & {
        @include smmdlg {
            padding-bottom: space(3);
        }
    }

    .r-btn {
        font-size: font-size(base);
    }

    r-main > & {
        &::after,
        &::before {
            display: none;
        }
    }

    r-icon {
        font-size: font-size(small);
        margin: 0 0 0 space(.5);
    }
}

.r-btn--home {
    grid-area: home;
    color: color(primary);
    font-size: font-size(nav);
    height: 30px;
    line-height: 30px;
    margin: auto;
    padding: 0;

    &:hover {
        color: color(primary);
    }

    r-icon {
        font-size: font-size(h4);
        line-height: 30px;
    }
}

.r-btn--burger {
    padding: 0;
    text-transform: uppercase;

    @include scope(('!special', '!sport', '!mag')) {
        color: color(dark);
        flex-direction: column;
        font-size: 12px;
        font-weight: 700;

        &:hover {
            color: color(primary);
        }
    }

    @include scope('an', 'cp', 'pn', 'vdn_base_critical', 'ard', 'ee', 'lc', 'uni', 'nl', 'mes') {
        color: color(light);

        &:hover {
            color: color(gray, lighter);
        }
    }

    @include scope(('!special', '!sport', '!mag')) {
        grid-area: burger;
        height: $header-top-height;
        justify-content: center;
    }

    @include scope('special', 'sport') {
        column-gap: space(.5);
        display: flex;
        flex-direction: column;
        font-size: .5rem;
        font-weight: 900;
        grid-area: home;
    }

    @include scope('mobilite') {
        font-weight: 400;
    }

    @include xs {
        margin: auto;
    }

    @include smmdlg {
        font-size: 15px;

        @include scope(('!special', '!sport', '!mag')) {
            height: $header-desktop-top-height;
        }
    }
    [data-scrolldir='down'] .-has-header-fixed:not( .-is-toggle-r-mini-panel--menu ) & {
        @include scope(('!special', '!sport', '!mag')) {
            display: none;

            // Comportement Header
            
            // @include scope('vdn') {
            //     display: inline-flex;
            //     height: auto;
            //     transform: scale(.7);
            //     transform-origin: left;
            // }
        }

        @include scope('mag') {
            r-icon {
                font-size: font-size(base);
            }
        }
    }

    @include scope('special', 'sport') {
        @include scope('!mag') {
            .front-maga &,
            .front-sports &,
            .-is-une-bis-sports &,
            .-is-page-service-sport & {
                color: color(light);

                &:hover {
                    color: color(light);
                }
            }

            .page-maga &,
            .page-sports & {
                color: color(dark);

                &:hover {
                    color: color(dark);
                }
            }

            @include xs {
                margin: 0 space(.5);
            }

            @include smmdlg {
                flex-direction: row;
                font-size: font-size(h6);
            }
        }
    }

    r-icon {
        margin: 0 0 2px;

        @include scope(('!special', '!sport')) {
            font-size: 23px;

            @include smmdlg {
                font-size: 28px;
                margin: 0 0 5px;
            }
        }

        @include scope('special', 'sport') {
            font-size: 19px;
            margin: 0 0 .3rem;

            @include smmdlg {
                margin: 0;
            }
        }
    }
}

.r-btn--search {
    grid-area: search;
    justify-self: self-end;
    padding: 0;
    width: $search-btn-width;

    @include scope('!mag') {
        font-size: 20px;
    }

    @include scope('mag') {
        font-size: 32px;
    }

    [data-scrolldir='down'] .-has-header-fixed:not( .-is-toggle-r-mini-panel--menu ) & {
     @include scope('mag') {
         font-size: 1.3rem;
     }
    }

    @include scope('sb') {
        flex-direction: row-reverse;
    }

    @include xs {
        @include scope('!mag') {
            display: none;
        }
    }

    .-is-une-bis-sports &,
    .-is-page-service-sport & {
        color: color(light);
    }
}

.r-btn--ma-commune,
.r-btn--ma-commune-preference,
.r-menu--link-edition {
    &::before {
        color: color(primary);
        content: $icon-location;
        font-family: $font-family-icons;
        margin-right: space(.25);
        vertical-align: top;

        @include scope('!special') {
            color: color(primary);
        }

        @include scope('special') {
            color: color(light);
        }

        @include scope('an', 'cp', 'pn', 'vdn_base_critical', 'ard', 'ee', 'lc', 'uni', 'nl', 'mes') {
            color: color(light);
        }
    }
}

.r-btn--ma-commune {
    @include scope('an', 'cp', 'pn', 'uni') {
        &::before {
            content: none;
        }
    }
}

r-back {
    font-size: font-size(icon);
    grid-area: back;
    justify-self: start;

    @include xssm {
        padding-left: space(.5);
    }

    @include mdlg {
        font-size: font-size(base);
        grid-gap: space(.5);
    }

    a {
        align-items: center;
        color: color(dark);
        display: flex;
        grid-gap: space(.2);

        @include scope('lifestyle') {
            flex-wrap: wrap;
        }

        &::before {
            @include scope('!mag') {
                content: $icon-arrow-left;
                font-family: $font-family-icons;
            }
        }

        &:hover {
            text-decoration: none;
        }
    }

    .front-maga &,
    .front-sports & {
        a {
            @include scope('!mag') {
                color: color(light);

                &:hover {
                    color: color(light);
                }
            }
        }
    }

    svg {
        margin-left: space(.6);
        width: 2rem;

        @include scope('lereo', 'cipiz', 'lifestyle') {
            width: 3rem;
        }

        @include mdlg {
            width: 4rem;

            @include scope('lereo', 'cipiz', 'lifestyle') {
                width: 7rem;
            }
        }
    }

    span {
        margin-left: space(.4);

        @include scope('lifestyle') {
            margin-left: space(.6);
            width: 100%;
        }
    }
}
} // scope

@include scope('!critical') {
r-btn--share {
    display: flex;
    flex-wrap: wrap;

    @include scope('ard', 'ee', 'lc', 'uni') {
        @include lg {
            padding-right: space(2);
        }
    } 

    .r-article & {
        @include scope('!mag') {
            .r-icon--facebook {
                &:hover {
                    background-color: color(facebook);
                }
            }

            .r-icon--twitter {
                &:hover {
                    background-color: color(twitter);
                }
            }

            .r-icon--bluesky {
                &:hover {
                    background-color: color(bluesky);
                }
            }

            .r-icon--linkedin {
                &:hover {
                    background-color: color(linkedin);
                }
            }

            .r-icon--messenger {
                &:hover {
                    background-color: color(facebook);
                }
            }

            .r-icon--whatsapp {
                &:hover {
                    background-color: color(whatsapp);
                }
            }

            .r-icon--mail {
                &:hover {
                    background-color: color(secondary);
                }
            }

            .r-icon--print {
                &:hover {
                    background-color: color(secondary);
                }
            }
        }
    }

    r-viewmode & {
        margin-top: space(.5);
    }
}

.r-menu--share-link {
    color: color(dark);

    @include scope('!mag') {
        margin-bottom: space(.7);
    }

    &:hover {
        background: none;
        text-decoration: none;
    }

    .r-grand-format &,
    .r-article & {
        r-icon {
            @include scope('!mag') {
                align-items: center;
                background-color: color(gray, lighter);
                border-radius: 100%;
                display: flex;
                font-size: font-size(h2);
                height: space(2.5);
                justify-content: center;
                width: space(2.5);

                @include xs {
                    font-size: font-size(h3);
                    height: space(1.9);
                    width: space(1.9);
                }

                @include lg {
                    background-color: color(gray, light);
                }

                &:hover {
                    color: color(light);
                }
            }

            @include scope('mag') {
                color: color(gray);
                font-size: 32px;
            }
        }
    }

    r-viewmode & {
        font-size: font-size(h2);
        padding-right: space(.5);
    }
}
}// scope

@include scope('sport') {
r-btns--result {
    display: grid;
    grid-column: 1 / -1;
    grid-gap: space();

    @include smmd {
        grid-template-columns: repeat(2, 1fr);
    }

    @include lg {
        grid-template-columns: repeat(5, 1fr);
    }
}

.r-btn--result {
    align-items: center;
    display: flex;
    grid-column-start: 1;
    grid-column-end: -1;
    justify-content: center;
    margin-top: -1px;
    text-align: center;

    @include smmdlg {
        padding: space() 0;
    }

    .r-btn {
        align-items: center;
        border-radius: space(3);
        border: 1px solid color(gray, light);
        flex-direction: column;
        font-family: $font-family-title;
        font-size: font-size(base);
        font-weight: font-weight(h1);
        justify-content: center;
        padding: space(.7) space(2);

        @include smmdlg {
            font-size: font-size(h5);
        }
    }

    &.-is-big {
        grid-column: auto;
        text-transform: uppercase;

        @include smmd {
            &:last-child {
                grid-column: 1 / -1;

                .r-btn {
                    width: 50%;
                }
            }
        }

        .r-btn {
            border-radius: space(3);
            width: 100%;

            @include smmdlg {
                border-radius: space();
                padding: space(1.4) space(2);
            }
        }

        span {
            font-size: font-size(button);
            text-transform: initial;
        }
    }
}
} // scope

@include scope('!critical') {
.lazyloaded {
    @include scope('vdn') {
        &.-is-mp-pass-sanitaire {
            background-image: linear-gradient(to bottom, transparent 200px, color(primary, .2) 200px, color(dark, .5)), url('#{$domain}/sites/all/themes/enacarbon_vdn/images/pass-sanitaire.jpg');
            background-size: cover;

            @include smmdlg {
                background-image: url('#{$domain}/sites/all/themes/enacarbon_vdn/images/pass-sanitaire.jpg');
            }
        }
    } // scope

    &.-is-mp-video {
        //background-image: linear-gradient(to bottom, transparent 200px, color(primary, .2) 200px, color(dark, .5)), url('#{$domain}/sites/all/themes/enacarbon_vdn/images/pass-sanitaire.jpg');
        background-size: cover;
        background-color: color(video);

        @include smmdlg {
            //background-image: url('#{$domain}/sites/all/themes/enacarbon_vdn/images/pass-sanitaire.jpg');
        }

        time {
            color: color(light);
        }
        @include scope('sb') {
            &.-has-bg {
                .r-mini-panel--title {
                    a {
                        color: color(primary, light);
                    }
                }
            }
        }
    }

    &.-is-mp-digiteka-visible {
        z-index: z-index(medium);
    }

    @include scope('uni', 'lc') {
        &.-is-mp-pass-sanitaire {
            background-image: linear-gradient(to bottom, transparent 200px, color(primary, .2) 200px, color(dark, .5)), url('#{$domain}/sites/all/themes/enacarbon_uni/images/tourisme.jpg');
            background-size: cover;

            @include smmdlg {
                background-image: url('#{$domain}/sites/all/themes/enacarbon_uni/images/tourisme.jpg');
            }
        }
    } // scope

    @include scope('ard', 'ee') {
        &.-is-mp-pass-sanitaire {
            background-image: linear-gradient(to bottom, transparent 200px, color(primary, .2) 200px, color(dark, .5)), url('#{$domain}/sites/all/themes/enacarbon_ard/images/tourisme.jpg');
            background-size: cover;

            @include smmdlg {
                background-image: url('#{$domain}/sites/all/themes/enacarbon_ard/images/tourisme.jpg');
            }
        }
    } // scope

    @include scope('mes') {
        &.-is-sortir {
            background-image: linear-gradient(to bottom, transparent 200px, color(primary, .2) 200px, color(dark, .5)), url('#{$domain}/sites/all/themes/enacarbon_lemessager/images/background_tendance.jpg');
            background-size: cover;

            @include smmdlg {
                background-image: url('#{$domain}/sites/all/themes/enacarbon_lemessager/images/background_tendance.jpg');
            }
        }
        &.-is-decolletage {
            background-image: linear-gradient(to bottom, transparent 200px, color(primary, .2) 200px, color(dark, .5)), url('#{$domain}/sites/all/themes/enacarbon_lemessager/images/background_decolletage.jpg');
            background-size: cover;

            @include smmdlg {
                background-image: url('#{$domain}/sites/all/themes/enacarbon_lemessager/images/background_decolletage.jpg');
            }
        }
    } // scope
}

} // scope
